

import {CommJiangXueJin, remindUnlockLesson, UserProductResource} from '@/services/user';
import {Box, Button} from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';


import useAsync from "@/hooks/useAsync";
import {message} from "antd";
import {ContactProfileResource} from "@/services/admin";
import Clipboard from 'clipboard';
import {useHistory, useParams} from "react-router-dom";

interface MallProps{
    content: string;
    title: string;
    picurl:string;
    trimcontent:string;
    index:string;
}



const p1: React.CSSProperties = {
    // boxSizing: "border-box",
    // marginTop: "4px",
    // marginBottom: "4px",
    // color: "rgba(0, 0, 0, 0.65)",
    // fontSize: "14px",
    // whiteSpace: "normal",
    // backgroundColor: "rgb(255, 255, 255)",
    // // textAlign: "justify"
    //
    //
    // bottom: -30,
    //
    //
    // right: "11%",
    //
    // position: "relative",
    // margin: "0 auto",
    // // maxWidth: "337px",
    // textAlign:"center"



    bottom: -30,
    position: "relative",
    margin: "0 auto",
    maxWidth: "342px",
    textAlign:"left"



}


const p2: React.CSSProperties = {
    // boxSizing: "border-box",
    // marginTop: "4px",
    // marginBottom: "4px",
    // color: "rgba(0, 0, 0, 0.65)",
    // fontSize: "14px",
    // whiteSpace: "normal",
    // backgroundColor: "rgb(255, 255, 255)",
    //
    //
    // bottom: -30,
    //
    //
    // right: "6%",
    //
    // position: "relative",
    // margin: "0 auto",
    // textAlign:"center"

    bottom: -30,
    position: "relative",
    margin: "0 auto",
    maxWidth: "800px",
    textAlign:"left"
}



const p3: React.CSSProperties = {
    boxSizing: "border-box",
    marginTop: "4px",
    marginBottom: "4px",
    color: "rgba(0, 0, 0, 0.65)",
    fontSize: "14px",
    whiteSpace: "normal",
    backgroundColor: "rgb(255, 255, 255)",
    // textAlign: "justify"


    bottom: -30,


    right: "4.2%",

    position: "relative",
    margin: "0 auto",
    // maxWidth: "337px",
    textAlign:"center"
}


const p4: React.CSSProperties = {
    // boxSizing: "border-box",
    // marginTop: "4px",
    // marginBottom: "4px",
    // color: "rgba(0, 0, 0, 0.65)",
    // fontSize: "14px",
    // whiteSpace: "normal",
    // backgroundColor: "rgb(255, 255, 255)",
    // bottom: -30,
    // right: "3.9%",
    // position: "relative",
    // margin: "0 auto",
    // textAlign:"center"

    bottom: -30,
    position: "relative",
    margin: "0 auto",
    maxWidth: "800px",
    textAlign:"left"
}



const p5: React.CSSProperties = {
    // boxSizing: "border-box",
    // marginTop: "4px",
    // marginBottom: "4px",
    // color: "rgba(0, 0, 0, 0.65)",
    // fontSize: "14px",
    // whiteSpace: "normal",
    // backgroundColor: "rgb(255, 255, 255)",
    // bottom: -30,
    // right: "3.9%",
    // position: "relative",
    // margin: "0 auto",
    // textAlign:"center"

    bottom: -47,
    position: "relative",
    margin: "0 auto",
    maxWidth: "800px",
    textAlign:"left",
    fontSize: "15px"
}

const p6: React.CSSProperties = {

    bottom: -41,
    position: "relative",
    margin: "0 auto",
    maxWidth: "800px",
    textAlign:"left",
    fontSize: "15px"
}


const p62: React.CSSProperties = {

    bottom: -43,
    position: "relative",
    margin: "0 auto",
    maxWidth: "800px",
    textAlign:"left",
}


const p61: React.CSSProperties = {

    bottom: -30,
    position: "relative",
    margin: "0 auto",
    maxWidth: "800px",
    textAlign:"left",
}


const p7: React.CSSProperties = {

    bottom: -40,
    position: "relative",
    margin: "0 auto",
    maxWidth: "800px",
    textAlign:"left",
}


const p71: React.CSSProperties = {

    bottom: -35,
    position: "relative",
    margin: "0 auto",
    maxWidth: "800px",
    textAlign:"left",
}

const img8: React.CSSProperties = {

    //自适应
    display: "inline-block",
    // width: "auto",
    maxWidth: "100%",
    height: "auto",


}




const span1: React.CSSProperties = {
    boxSizing: "border-box",
    fontFamily: "Microsoft JhengHei",
    color: "rgb(227, 108, 9)",
    letterSpacing: "0px",
    fontSize: "15px",
    backgroundImage: "initial",
    backgroundPosition: "initial",
    backgroundSize: "initial",
    backgroundRepeat: "initial",
    backgroundAttachment: "initial",
    backgroundOrigin: "initial",
    backgroundClip: "initial"
}


const span2: React.CSSProperties = {
    boxSizing: "border-box",
    fontFamily: "Microsoft JhengHei",
    color: "rgb(124, 124, 124)",
    letterSpacing: "0px",
    fontSize: "15px",
    backgroundImage: "initial",
    backgroundPosition: "initial",
    backgroundSize: "initial",
    backgroundRepeat: "initial",
    backgroundAttachment: "initial",
    backgroundOrigin: "initial",
    backgroundClip: "initial"
}



const Ios:React.FC<MallProps> = (props)=>{

    function createMarkup(str:any) {
        return {__html: str};
    };


    function click() {

        message.success({
            content: '复制成功',
            style: {
                marginTop: '35vh',
            },
        });
    };

    function reset(id:any) {
        window.location.href =`/jiangxuejin/${id}`
    };



    const {picurl,content,title,trimcontent,index} = props


    var userAgent = navigator.userAgent;

    //苹果
    if (userAgent.indexOf("iPhone") > -1) {
        return (
            <Box style={ant_col_ios_toatl}>
                <div style={ant_col_ios1}   dangerouslySetInnerHTML={createMarkup(title)}/>



                <div
                    style={ant_col_ios}
                >

                    <img style={img}
                         src={picurl}
                    />

                </div>

                {/*<div*/}
                {/*    style={ant_col_ios2}*/}
                {/*>*/}
                {/*   长按保存图片*/}
                {/*</div>*/}



                <p style={p1}>
                        <span style={span1}>
                            推荐导语︱
                        </span>
                    <span style={span2}>
                            可直接复制，也可自己撰写
                        </span>
                </p>


                <div style={ant_col_ios3}  dangerouslySetInnerHTML={createMarkup(content)}/>


                <p style={p6}>
                    <a
                        data-clipboard-text={trimcontent}
                        className="btn"
                        onClick={click}
                    >点击一键复制</a>
                </p>
                <p style={p7}>
                    <img style={img1}
                         src={`https://teach-vod.oss-cn-hangzhou.aliyuncs.com/t.gif`}
                    />
                </p>


                {/*<Button  onClick={() => {*/}
                {/*    reset(index)*/}
                {/*}}  >换一张</Button>*/}

            </Box>
        )
    }else{
        return (
            <Box style={ant_col_android1_total}>

                <div style={ant_col_android1}  dangerouslySetInnerHTML={createMarkup(title)}/>

                <div
                    style={ant_col_android}
                >
                    <img style={img}
                         src={picurl}
                    />
                </div>

                {/*<div*/}
                {/*    style={ant_col_android2}*/}
                {/*>*/}
                {/*    长按保存图片*/}
                {/*</div>*/}


                <p style={p2}>
                        <span style={span1}>
                            推荐导语︱
                        </span>
                    <span style={span2}>
                            可直接复制，也可自己撰写
                        </span>
                </p>

                <div style={ant_col_android3}  dangerouslySetInnerHTML={createMarkup(content)}/>



                <p style={p61}>
                    <a
                        data-clipboard-text={trimcontent}
                        className="btn"
                        onClick={click}
                    >点击一键复制</a>
                </p>
                <p style={p71}>
                    <img style={img1}
                         src={`https://teach-vod.oss-cn-hangzhou.aliyuncs.com/t.gif`}
                    />
                </p>

                {/*<Button  onClick={() => {*/}
                {/*    reset(index)*/}
                {/*}}  >换一张</Button>*/}

            </Box>
        )
    }

}


const JiangXueJin: React.FC = () => {

    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    function createMarkup(str:any) {
        return {__html: str};
    };

    // alert(666)

    // const product =  CommJiangXueJin;


    // await remindUnlockLesson(parseInt(params.idd));

    // const product = useAsync(CommJiangXueJin);


    // const state = useAsync(() =>
    //     ContactProfileResource.find(parseInt(match.params.id))
    // );


    const product = useAsync(() =>
        CommJiangXueJin(parseInt(id))
    );


    function reset(id:any) {
        window.location.href =`/jiangxuejin/${id}`
    };


    function click() {
        message.info(`复制成功`)
    };


    // const product =useAsync(CommJiangXueJin);

    console.log(product)


    const aaa =product.value;
    //
    const bbb = aaa?(aaa.haibaourl):(``)
    const title = aaa?(aaa.title):(``)
    const content1 = aaa?(aaa.content):(``)
    const trimcontent = aaa?(aaa.content1):(``)
    const index = aaa?(aaa.index):(``)

    var userAgent = navigator.userAgent;



    const copy = new Clipboard('.btn');
    copy.on('success', e => {
        // message.info(`ok`)
        // copy.destroy()
        // return
    });
    copy.on('error', function (e) {
        // alert('error')
    });

    //桌面
    if (userAgent.indexOf("Windows") > -1) {



        return (
            <Box style={ant_col_windows_total}>


                <div style={ant_col_windows1}   dangerouslySetInnerHTML={createMarkup(title)}/>

                <div
                    style={ant_col_windows}
                >
                    <img style={img}
                         src={bbb}
                    />
                </div>

                {/*<div*/}
                {/*    style={ant_col_windows2}*/}
                {/*>*/}
                {/*    长按保存图片*/}
                {/*</div>*/}


                <p style={p4}>
                        <span style={span1}>
                            推荐导语︱
                        </span>
                    <span style={span2}>
                            可直接复制，也可自己撰写
                        </span>
                </p>

                <div    id="aaa" style={ant_col_windows3}   dangerouslySetInnerHTML={createMarkup(content1)}/>



                <p style={p5}>
                    <a
                        data-clipboard-text={trimcontent}
                        className="btn"
                        onClick={click}
                    >点击一键复制</a>
                </p>
                <p style={p62}>
                    <img style={img8}
                         src={`https://teach-vod.oss-cn-hangzhou.aliyuncs.com/t.gif`}
                    />
                </p>

                {/*<Button  onClick={() => {*/}
                {/*    reset(index)*/}
                {/*}}  >换一张</Button>*/}


            </Box>
        )



    }else{
        //安卓,ios
        return (
            <Ios  picurl={bbb} index={index.toString()} trimcontent={trimcontent} content={content1} title={title}/>
        )
    }
};



const bt_android: React.CSSProperties = {
    width: "50px",
    position: "fixed",
    // bottom: "20px",
    right: "80%",
    fontSize: 0,
    lineHeight: 0,
    zIndex: 100,

    bottom: -10
}


const bt: React.CSSProperties = {
    // position: "sticky",
    // height: "40px",
    // width: "40px",
    // bottom: "90px",
    // right: "50px",
    // backgroundPosition: "0 -62px"


    width: "50px",
    position: "fixed",
    bottom: "20px",
    right: "70%",
    fontSize: 0,
    lineHeight: 0,
    zIndex: 100,

}

const bt_windows: React.CSSProperties = {
    width: "170px",
    position: "fixed",
    // bottom: "20px",
    right: "54.8%",
    fontSize: 0,
    lineHeight: 0,
    zIndex: 100,

    bottom: -5

}


const a: React.CSSProperties = {
    width: "350px",
    height: "50px",
    display: "inline-block",
    backgroundColor: "#cc0000",
    marginBottom: "2px",
    fontSize:18,
    color:"#fff",
    // backgroundPosition: "0 -266px"
}

const b: React.CSSProperties = {
    width: "160px",
    height: "50px",
    display: "inline-block",
    backgroundColor: "#F7F7F7",
    marginBottom: "2px",
    fontSize:18,
    color:"#202124",
}


const bt_ios: React.CSSProperties = {
    width: "50px",
    position: "fixed",
    // bottom: "20px",
    right: "82%",
    fontSize: 0,
    lineHeight: 0,
    zIndex: 100,

    bottom: -10
}

const a_ios: React.CSSProperties = {
    width: "195px",
    height: "45px",
    display: "inline-block",
    backgroundColor: "#cc0000",
    marginBottom: "2px",
    fontSize:18,
    color:"#fff",
    // backgroundPosition: "0 -266px"
}

const b_ios: React.CSSProperties = {
    width: "125px",
    height: "45px",
    display: "inline-block",
    backgroundColor: "#F7F7F7",
    marginBottom: "2px",
    fontSize:18,
    color:"#202124",
}



const a_android: React.CSSProperties = {
    width: "170px",
    height: "45px",
    display: "inline-block",
    backgroundColor: "#cc0000",
    marginBottom: "2px",
    fontSize:18,
    color:"#fff",
    // backgroundPosition: "0 -266px"
}

const b_android: React.CSSProperties = {
    width: "100px",
    height: "45px",
    display: "inline-block",
    backgroundColor: "#F7F7F7",
    marginBottom: "2px",
    fontSize:18,
    color:"#202124",
}


const img: React.CSSProperties = {
    // maxWidth: "128px",
    // maxHeight: "128px",
    // height:"auto",
    // zoom:"expression( function(e) {if(e.width>e.height) {if (e.width>128) { e.height = e.height*(128 /e.width); e.width=128; }}else {if (e.height>128) { e.width = e.width*(128 /e.height); e.height=128; }}e.style.zoom = '1';     }(this));",
    // overflow:"hidden"

    //自适应
    display: "inline-block",
    width: "auto",
    maxWidth: "100%",
    height: "auto"
}

const img1: React.CSSProperties = {

    //自适应
    display: "inline-block",
    // width: "auto",
    maxWidth: "101%",
    height: "auto",
    // marginLeft: "0.5cm",
    // marginTop: "-19px"
}

const img6: React.CSSProperties = {

    //自适应
    display: "inline-block",
    // width: "auto",
    maxWidth: "23%",
    height: "auto",
    marginLeft: "18cm",
    marginTop: "-19px"
}


const img7: React.CSSProperties = {

    //自适应
    display: "inline-block",
    // width: "auto",
    maxWidth: "21%",
    height: "auto",
    marginLeft: "20cm",
    marginTop: "-19px"
}


const img2: React.CSSProperties = {
    // display: "inline-block",
    // width: "auto",
    position: "relative",
    maxWidth: "90%",
    // height: "90%"
}

const ant_col_windows: React.CSSProperties = {
    bottom: 3,
    position: "relative",
    margin: "0 auto",
    maxWidth: "280px"

}


const ant_col_windows_total: React.CSSProperties = {
    // bottom: -10,
    // position: "relative",
    // maxWidth: "800px",
    textAlign:"center",
    marginLeft:"18px",
    marginRight:"18px",
}



const ant_col_windows1: React.CSSProperties = {
    bottom: -10,
    position: "relative",
    margin: "0 auto",
    maxWidth: "800px",
    textAlign:"center"
}

const ant_col_windows2: React.CSSProperties = {
    bottom: -15,
    position: "relative",
    margin: "0 auto",
    maxWidth: "400px",
    textAlign:"center"

}

const ant_col_windows3: React.CSSProperties = {
    bottom: -40,
    position: "relative",
    margin: "0 auto",
    maxWidth: "800px",
    textAlign:"center"

}



const ant_col_android: React.CSSProperties = {
    bottom: -1,

    position: "relative",
    margin: "0 auto",
    maxWidth: "280px"
}



const ant_col_android1_total: React.CSSProperties = {
    textAlign:"center",
    marginLeft:"18px",
    marginRight:"18px",
}


const ant_col_android1: React.CSSProperties = {
    bottom: -10,
    position: "relative",
    margin: "0 auto",
    maxWidth: "800px",
    textAlign:"center"
}


const ant_col_android2: React.CSSProperties = {
    bottom: -18,

    position: "relative",
    margin: "0 auto",
    maxWidth: "280px",
    textAlign:"center"

}


const ant_col_android3: React.CSSProperties = {
    bottom: -30,

    position: "relative",
    margin: "0 auto",
    maxWidth: "800px",
    textAlign:"center"

}


const ant_col_ios: React.CSSProperties = {
    bottom: -5,

    position: "relative",
    margin: "0 auto",
    maxWidth: "280px"
}




const ant_col_ios_toatl: React.CSSProperties = {
    textAlign:"center",
    marginLeft:"18px",
    marginRight:"18px",
}


const ant_col_ios1: React.CSSProperties = {
    bottom: -20,
    position: "relative",
    margin: "0 auto",
    maxWidth: "800px",
    textAlign:"center"
}


const ant_col_ios2: React.CSSProperties = {
    bottom: -20,

    position: "relative",
    margin: "0 auto",
    maxWidth: "300px",
    textAlign:"center"
}



const ant_col_ios3: React.CSSProperties = {
    bottom: -35,

    position: "relative",
    margin: "0 auto",
    maxWidth: "337px",
    textAlign:"center"
}


export default  JiangXueJin;
