import { Box, Container } from '@material-ui/core';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Assistant from './Assistant';
import AssistantClass from './Assistant/Class';
import Class from './Class';
import Class1 from './Class1';
import ClassRecord from './Class/Record';
import Dashboard from './Dashboard';
import StudentPage from './Student';
import StudentCoursePage from './Student/Course';
import StudentLessonPage from './Student/Lesson';
import StudentEventPage from './Student/Event';
import StudentPointPage from './Student/Point';
import StudentPracticePage from './Student/Practice';
import Ticket from './Ticket';
import StudentProfilePage from './Student/Profile';
import logo from "@/assets/logo.png";
import HistoryStRecord from './Student/Record/index';

export interface TeacherProps {}

const Teacher: React.FC<TeacherProps> = () => {
  const { path } = useRouteMatch();

  return (
    <Box flex={1}>
      <Box clone pt={2} pb={8}>
        <Container>
          <Switch>
            <Route path={`${path}/students/history/:id`}>
              <HistoryStRecord />
            </Route>

            <Route path={path} exact>
              <Dashboard />
            </Route>


            <Route path={`${path}/tickets`}>
              <Ticket />
            </Route>



            <Route path={`${path}/classes/:classID/records`}>
              <ClassRecord />
            </Route>
            <Route path={`${path}/classes`}>
              <Class />
            </Route>

            <Route path={`${path}/classes1`}>
              <Class1 />
            </Route>

            <Route path={`${path}/students/:id/profile`}>
              <StudentProfilePage />
            </Route>
            <Route path={`${path}/students/:id/courses/:courseID`}>
              <StudentLessonPage />
            </Route>
            <Route path={`${path}/students/:id/courses`}>
              <StudentCoursePage />
            </Route>
            <Route path={`${path}/students/:id/events`}>
              <StudentEventPage />
            </Route>
            <Route path={`${path}/students/:id/points`}>
              <StudentPointPage />
            </Route>
            <Route path={`${path}/students/:id/practices`}>
              <StudentPracticePage />
            </Route>
            <Route path={`${path}/students`}>
              <StudentPage />
            </Route>
            <Route path={`${path}/assistants/:assistantID/classes/:classID?`}>
              <AssistantClass />
            </Route>
            <Route path={`${path}/assistants`}>
              <Assistant />
            </Route>
          </Switch>
        </Container>
      </Box>
    </Box>
  );
};

export default Teacher;
