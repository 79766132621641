import { AppTab } from '@/components/AppTabs';
import Fallback from '@/components/Fallback';
import {
    TotalLabel,
} from '@/components/ResourceManage';
import useAsyncFn, { AsyncState } from '@/hooks/useAsyncFn';
import {  ProductOrderResource } from '@/services/teacher';
import {HorseResource, Product1Resource} from '@/services/admin';
import { Order} from '@/services/entity';
import { Tab} from '@material-ui/core';
import {
    Button,
    Card,
    Form,
    Input,
    Popconfirm,
    Space,
    Table
} from 'antd';


import { TableProps } from 'antd/lib/table';
import format from 'date-fns/format';
import React from 'react';

const resource = ProductOrderResource;
type Entity = Order;



interface DataTableProps extends TableProps<Entity> {
    onCreate?: () => void;
    onDelete?: () => void;
}
const DataTable: React.FC<DataTableProps> = ({
                                                 onCreate,
                                                 onDelete,
                                                 ...rest
                                             }) => {
    return (
        <Table
            {...rest}
            rowKey="id"
            bordered
            size="small"
            columns={[
                { dataIndex: 'user',
                    title: '姓名',
                    // render: (value) => value.name,

                    render: (value) => (
                        value ? (
                            value.realname
                        ) : (
                            ``
                        )
                    ),
                },
                { dataIndex: 'product',
                    title: '商品',
                    // render: (value) => value.name,

                    render: (value) => (
                        value ? (
                            value.name
                        ) : (
                            ``
                        )
                    ),
                },
                { dataIndex: 'product',
                    title: '价格',
                    // render: (value) => value.name,

                    render: (value) => (
                        value ? (
                            value.qinbei+"琴贝"+"+"+value.price+"元"
                        ) : (
                            ``
                        )
                    ),
                },
                {
                    dataIndex: 'createdAt',
                    title: '时间',
                    render: (createdAt) =>
                        format(new Date(createdAt), 'yyyy-MM-dd HH:mm:ss'),
                },

                {
                    dataIndex: 'status',
                    title: '状态',
                    render: (value, record) => (


                        record.status !== 1 ? (
                            <span  style={{ color: `#FF0000`}} >待完成</span>
                        ) : (
                            <span>已完成</span>
                        )

                    ),
                },

                {
                    title: '操作',
                    render: (value, record) => (
                        record.status !== 1 ? (
                            <>
                                <Popconfirm
                                    title="确认完成?"
                                    onConfirm={async () => {
                                        await Product1Resource.update(record.id)({
                                        });

                                        setTimeout(function () {
                                            window.location.reload();
                                        }, 1000);
                                    }}
                                >
                                    <Button type="link">设置</Button>
                                </Popconfirm>

                            </>

                        ):(``)

                    ),

                },

            ]}
        />
    );
};

const TabPanel: React.FC<{
    state: AsyncState<{ total: number; data: Entity[] }>;
    fetch: typeof resource.query;
}> = ({ state, fetch }) => {
    return (
        <div>
            <Fallback
                state={state}
                initial={<DataTable loading />}
                render={({ data }) => {
                    return (
                        <DataTable
                            dataSource={data}
                            loading={state.loading}
                            onCreate={state.retry}
                            onDelete={state.retry}
                        />
                    );
                }}
            />
        </div>
    );
};

function useOrderTab(): AppTab {
    const [state, fetch] = useAsyncFn(resource.query, []);
    React.useEffect(() => {
        fetch({});
    }, [fetch]);

    return {
        path: `productOrder`,
        tab: <Tab label={<TotalLabel state={state}>订单中心</TotalLabel>} />,
        panel: <TabPanel state={state} fetch={fetch} />,
    };
}

export default useOrderTab;
