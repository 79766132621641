import React from 'react';
import { AxiosResponse, AxiosRequestConfig, AxiosInstance } from 'axios';
import { Entity, Role } from './entity';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

export const ErrorMessage: React.FC<{ error: RequestError | Error }> = ({
  error,
}) => {
  if (error instanceof JSONParseError) {
    return (
      <>
        {error.message}
        <br />
        <br />
        <code>{error.text}</code>
      </>
    );
  }

  return <>{error.message}</>;
};

export class RequestError extends Error {
  public code: number;

  constructor(code: number, message: string) {
    super(message);
    this.name = `RequestError:${code}`;
    this.code = code;
  }
}

export class JSONParseError extends SyntaxError {
  constructor(message: string, readonly text: string) {
    super(message);
  }
}

export class StatusError<T> extends Error {
  public response: AxiosResponse;

  constructor(response: AxiosResponse<Response<T>>) {
    super(response.data.message);
    this.name = 'StatusError';
    this.response = response;
  }
}

export interface Response<T> {
  code: number;
  message: string;
  payload: T;
}

function catchStatusError(err: any): never {
  if (err.response) {
    throw new StatusError(err.response);
  }
  throw err;
}

function throwIfFailure<T>(response: AxiosResponse<T>) {
  return response.data;
}

export type Interface<P = any, R = any> = (
  params: P,
  config?: AxiosRequestConfig
) => Promise<R>;

export type OptionalFn<P = any, R = any> = (
  params?: P,
  config?: AxiosRequestConfig
) => Promise<R>;

export type InterfaceParams<I> = I extends Interface<infer P, any> ? P : never;
export type InterfaceResponse<I> = I extends Interface<any, infer R>
  ? R
  : never;

export function api<T, R>(
  request: (params: T, config?: AxiosRequestConfig) => Promise<AxiosResponse<R>>
) {
  return (params: T, config?: AxiosRequestConfig) =>
    request(params, config).then(throwIfFailure, catchStatusError);
}

export function optional<T, R>(
  request: (
    params?: T,
    config?: AxiosRequestConfig
  ) => Promise<AxiosResponse<R>>
) {
  return (params?: T, config?: AxiosRequestConfig) =>
    request(params, config).then(throwIfFailure, catchStatusError);
}

export function map<I extends Interface, R>(
  fn: I,
  transform: (response: InterfaceResponse<I>) => R
) {
  return async (params: InterfaceParams<I>, config?: AxiosRequestConfig) => {
    const response = await fn(params, config);
    return transform(response);
  };
}

type IfEquals<X, Y, A, B> = (<T>() => T extends X ? 1 : 2) extends <
  T
>() => T extends Y ? 1 : 2
  ? A
  : B;

type WritableKeysOf<T> = {
  [P in keyof T]: IfEquals<
    { [Q in P]: T[P] },
    { -readonly [Q in P]: T[P] },
    P,
    never
  >;
}[keyof T];
export type PickMuteable<T> = Pick<T, WritableKeysOf<T>>;

// export type PickMuteable<T> = Pick<T, MuteableKeys<T>>;

export type PaginationData<T, M = {}> = { total: number; data: T[], meta: M };

export interface QueryParams {
  limit?: number;
  filter?: {};
  id?:number;
}
export type QueryFn<T> = Interface<QueryParams, PaginationData<T>>;

export const encodeQueryParams = ({ filter, ...rest }: QueryParams) => {
  return { filter: JSON.stringify(filter), ...rest };
};
export function restful<T extends Entity, M = {}>(
  axios: AxiosInstance,
  baseURL: string
) {
  return {
    query: api<QueryParams, PaginationData<T, M>>((params = {}) => {
      const query = qs.stringify(encodeQueryParams(params));
      return axios.get(`${baseURL}${query ? '?' + query : ''}`);
    }),
    find: api<number, T>((id) => axios.get(`${baseURL}/${id}`)),
    create: api<PickMuteable<T>, T>((data) => axios.post(baseURL, data)),
    update: (id: number) =>
      api<Partial<PickMuteable<T>>, T>((data) =>
        axios.put(`${baseURL}/${id}`, data)
      ),
    delete: api<number, T>((id) => axios.delete(`${baseURL}/${id}`)),
  };
}

export function applyFilter<T extends QueryFn<any>>(filter: {}, query: T): T {
  return (({ filter: filterParam, ...rest }: QueryParams) =>
    query({ ...rest, filter: { ...filter, ...filterParam } })) as T;
}

export function roleFilter<T extends QueryFn<any>>(role: Role, query: T): T {
  return (({ filter, ...rest }: QueryParams) =>
    query({ ...rest, filter: { ...filter, role } })) as T;
}

export function useQueryParams() {
  const { search } = useLocation();
  return React.useMemo(() => {
    const params = new URLSearchParams(search);
    const getFilter = ()=> {
      if (!params.has('filter')) {
        return {};
      }
      try {
        return JSON.parse(params.get('filter') as string);
      } catch {
        return {};
      }
    }
    return {
      filter: getFilter()
    } as QueryParams
  }, [search]);
}
