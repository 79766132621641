import Fallback from '@/components/Fallback';
import useAsyncFn from '@/hooks/useAsyncFn';
import {  useQueryParams } from '@/services/api';

import {
  QueryPromotionList,
} from '@/services/admin';

import {Form,  Table } from 'antd';
import React from 'react';
import {Box} from "@material-ui/core";

export default function PromotionList() {
  const [form] = Form.useForm();
  const params = useQueryParams();
  const [state, fetch] = useAsyncFn(QueryPromotionList.query);
  React.useEffect(() => {
    fetch(params);
  }, [fetch, params]);
  return (
    <Fallback
      state={state}
      render={({ data}) => (
        <>

          <Table
              title={() => (
                  <Box display="flex" justifyContent="space-between">
                    <div  style={{'fontSize': '20px','fontWeight':'bold'}}>
                      历史活动
                    </div>
                  </Box>
              )}
            rowKey="id"
            bordered
            size="small"
            style={{ minWidth: '100%' }}
            dataSource={data}
            columns={[
              {
                dataIndex: 'name',
                title: '活动名称',
              },
              {
                dataIndex: 'state',
                title: '状态',
                render: (value) => (
                        value === 0 ? (
                            `已结束`
                        ) : (
                            value === 2 ?(
                                '将开始'
                            ) : (
                                '进行中'
                            )

                        )
                ),
              },
            ]}
          />
        </>
      )}
    />
  );
}
