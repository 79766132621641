import DateTime from '@/components/DateTime';
import Fallback from '@/components/Fallback';
import MultilineText from '@/components/MultilineText';
import Username1 from '@/components/Username1';
import useAsync from '@/hooks/useAsync';
import useAsyncFn from '@/hooks/useAsyncFn';
import { LessonNoteResource } from '@/services/user';
import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {Space} from "antd";

interface NoteListProps {
  lessonID: number;
}
export default function NoteList({ lessonID }: NoteListProps) {
  const noteListState = useAsync(
    () => LessonNoteResource.query({ filter: { lessonID } }),
    [lessonID]
  );
  const [content, setContent] = useState('');
  const [, createNote] = useAsyncFn(async () => {
    await LessonNoteResource.create({ content, lessonID });
    setContent('');
    noteListState.retry();
  }, [noteListState.retry, content, lessonID]);
  return (
    <>
      <Box ml={2} mr={2} mt={4} mb={4}>
        <Box mb={1}>
          <TextField
            label="笔记/心得"
            multiline
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: '100%' }}
            rows={4}
            variant="outlined"
            placeholder="请在这里填写笔记或者心得"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            disabled={!content}
            variant="contained"
            color="primary"
            size="small"
            onClick={createNote}
          >
            提交
          </Button>
        </Box>
      </Box>
      <Fallback
        state={noteListState}
        render={({ data }) => {
          if (data.length === 0) {
            return null;
          }
          return (
            <>
              <Divider />
              <List
                subheader={
                  <ListSubheader style={{ backgroundColor: '#fff' }}>
                    <Box display="flex" justifyContent="space-between">
                      <Box>本课笔记</Box>
                      <Box>
                        <Link to={`/assistant/student/mynotes/${lessonID}`}>我的</Link>
                      </Box>




                    </Box>

                  </ListSubheader>
                }
              >
                {data.map((note, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && <Divider variant="inset" component="li" />}
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar src={note.author.avatar} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={<Username1 value={note.author} />}
                        secondary={
                          <Box mt={1}>
                            <MultilineText
                              color="text.primary"
                              value={note.content}
                            />
                            <Box mt={1} textAlign="right">
                              <Typography variant="caption">
                                <DateTime value={note.createdAt} />
                              </Typography>
                            </Box>
                          </Box>
                        }
                      />
                    </ListItem>
                  </React.Fragment>
                ))}
              </List>
            </>
          );
        }}
      />
    </>
  );
}
