import DateTime from '@/components/DateTime';
import Fallback from '@/components/Fallback';
import MultilineText from '@/components/MultilineText';
import Username from '@/components/Username';
import useAsync from '@/hooks/useAsync';
import useAsyncFn from '@/hooks/useAsyncFn';
import {LessonNoteResource, LessonNoteResourceMy} from '@/services/user';
import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import {Space} from "antd";

interface NoteListProps {
  lessonID: number;
}


// LessonNoteResourceMy

export default function NoteListMy({ lessonID }: NoteListProps) {
    const params = useParams<{id: string}>();
  const noteListState = useAsync(
    () => LessonNoteResourceMy.query({ filter: { params } }),
    [lessonID]
  );
  const [content, setContent] = useState('');
  const [, createNote] = useAsyncFn(async () => {
    await LessonNoteResourceMy.create({ content, lessonID });
    setContent('');
    noteListState.retry();
  }, [noteListState.retry, content, lessonID]);
  return (
    <>

      <Fallback
        state={noteListState}
        render={({ data }) => {
          if (data.length === 0) {
            return null;
          }
          return (
            <>
              <Divider />
              <List
                subheader={
                  <ListSubheader style={{ backgroundColor: '#fff' }}>
                    <Box display="flex" justifyContent="space-between">
                      <Box>本课笔记</Box>

                    </Box>

                  </ListSubheader>
                }
              >
                {data.map((note, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && <Divider variant="inset" component="li" />}
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar src={note.author.avatar} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={<Username value={note.author} />}
                        secondary={
                          <Box mt={1}>
                            <MultilineText
                              color="text.primary"
                              value={note.content}
                            />
                            <Box mt={1} textAlign="right">
                              <Typography variant="caption">
                                <DateTime value={note.createdAt} />
                              </Typography>
                            </Box>
                          </Box>
                        }
                      />
                    </ListItem>
                  </React.Fragment>
                ))}
              </List>
            </>
          );
        }}
      />
    </>
  );
}
