import React from 'react';
import PointList from '@/components/PointList';
import useAsync from '@/hooks/useAsync';
import { useParams } from 'react-router-dom';
import { studentPoint } from '@/services/teacher';
import Fallback from '@/components/Fallback';

export default function StudentPointPage() {
  const { id } = useParams<{ id: string }>();
  const api = React.useMemo(() => {
    return studentPoint(parseInt(id));
  }, [id]);
  const state = useAsync(api.query, [api.query]);
  return (
    <Fallback
      state={state}
      initial={<PointList loading />}
      render={({ data, meta }, loading) => (
        <PointList title={() => `${meta.realname}的总积分：${meta.points}`} dataSource={data} loading={loading} />
      )}
    />
  );
}
