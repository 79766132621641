import DateTime from '@/components/DateTime';
import Fallback from '@/components/Fallback';
import GenseeVideo1 from '@/components/GenseeVideo1';
import useAsync from '@/hooks/useAsync';
import useAsyncFn from '@/hooks/useAsyncFn';
import { useProfile } from '@/pages/Profile/context';
import { PickMuteable } from '@/services/api';
import {
  PracticeCommentResource,
  PracticeResource,
  PracticeViewResource,
} from '@/services/user';
import { PracticeComment } from '@/services/entity';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Container,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import format from 'date-fns/format';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import BottomTextarea from './BottomTextarea';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 0,
    border: 'none',
    borderTop: `1px solid ${theme.palette.divider}`,
    '&:first-child': {
      borderTop: 'none',
    },
  },
  actions: {
    padding: '0 8px',
    justifyContent: 'flex-end',
  },
  thumbUpAvatarGroup: {
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
    },
  },
  commentList: {
    '& .MuiAvatar-root': {
      border: '2px solid #fafafa',
      width: 32,
      height: 32,
    },
    '& .MuiListItemAvatar-root': {
      minWidth: 40,
    },
  },
}));

const CommentItem: React.FC<{
  item: PracticeComment;
  onDelete: () => void;
}> = ({ item, onDelete }) => {
  const [open, setOpen] = useState(false);
  const { profile } = useProfile();

  return (
    <>
      <ListItem
        key={item.id}
        disableGutters
        dense
        alignItems="flex-start"
        button
        onClick={() => setOpen(true)}
      >
        <ListItemAvatar>
          <Avatar src={item.commenter.avatar} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Box
              color="primary.main"
              display="flex"
              justifyContent="space-between"
            >
              {item.commenter.realname}
              <Box color="text.secondary">
                {format(new Date(item.createdAt), 'yyyy-MM-dd HH:mm:ss')}
              </Box>
            </Box>
          }
          secondary={
            <Box color="text.primary">
              {item.content.split('\n').map((line, index) => (
                <div key={index}>{line}</div>
              ))}
            </Box>
          }
        />
      </ListItem>
      <Drawer open={open} anchor="bottom" onClose={() => setOpen(false)}>
        <List>
          {(profile.id === item.commenterID ||
            profile.roles.some(({ name }) => name === 'admin')) && (
            <ListItem
              button
              onClick={() => {
                setOpen(false);
                onDelete();
              }}
            >
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary="删除" />
            </ListItem>
          )}
        </List>
      </Drawer>
    </>
  );
};

const Replay: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const practiceID = useMemo(() => parseInt(id), [id]);
  const practice = useAsync(() => PracticeResource.find(practiceID), [
    practiceID,
  ]);
  const comments = useAsync(
    () => PracticeCommentResource.query({ filter: { practiceID } }),
    [practiceID]
  );
  const refreshComments = comments.retry;
  const [content, setContent] = useState('');
  const [, createComment] = useAsyncFn(
    async (values: PickMuteable<PracticeComment>) => {
      await PracticeCommentResource.create(values);
      setContent('');
      refreshComments();
    },
    [refreshComments]
  );
  const [, deleteComment] = useAsyncFn(
    async (item: PracticeComment) => {
      await PracticeCommentResource.delete(item.id);
      refreshComments();
    },
    [refreshComments]
  );

  // const [, view] = useAsyncFn(
  //   () => PracticeViewResource.create({ practiceID }),
  //   [practiceID]
  // );
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     view();
  //   }, 10000);
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [view]);

  return (
    <>
      <Box pb={6}>
        <Container disableGutters maxWidth="sm">
          <Fallback
            state={practice}
            render={(value) => (
              <Card variant="outlined" className={classes.card}>
                <CardContent>
                  <Typography variant="h6">{value.title}</Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    gutterBottom
                  >
                    开始时间：
                    <DateTime value={value.createdAt} />
                  </Typography>
                </CardContent>

                <Box width="100%" clone>
                  <GenseeVideo1
                    videoID={value.videoID}
                    videoToken={value.videoToken}
                    userID = {value.userID}
                    practiceID = {value.id}
                    userLessonID= {value.userLessonID}
                  />
                </Box>
                <Fallback
                  state={comments}
                  render={({ data }) => (
                    <CardContent>
                      <List
                        disablePadding
                        dense
                        className={classes.commentList}
                      >
                        {data.map((item) => (
                          <CommentItem
                            item={item}
                            onDelete={() => deleteComment(item)}
                          />
                        ))}
                      </List>
                    </CardContent>
                  )}
                />
              </Card>
            )}
          />
        </Container>
        <BottomTextarea
          TextareaProps={{
            value: content,
            onChange: (event) => setContent(event.target.value),
          }}
          ButtonProps={{
            disabled: !content,
            onClick: () => createComment({ content, practiceID }),
          }}
        />
      </Box>
    </>
  );
};

export default Replay;
