import { Box } from '@material-ui/core';
import LinearProgress, {
  LinearProgressProps,
} from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import React from 'react';

export default function LinearProgressWithLabel(
  props: Omit<LinearProgressProps, 'value'> & { current: number; total: number }
) {
  const value = (props.current / props.total) * 100;
  return (
    <Box flex={1} display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} value={value} />
      </Box>
      <Box>
        <Typography
          style={{ whiteSpace: 'nowrap', minWidth: 62 }}
          variant="body2"
          color="textSecondary"
        >
          {props.current} / {props.total}
        </Typography>
      </Box>
    </Box>
  );
}
