import React from 'react';
import { format } from 'date-fns';

const DateTime: React.FC<{ value: string }> = ({ value }) => {
  const content = React.useMemo(() => {
    return format(new Date(value), 'yyyy-MM-dd HH:mm:ss');
  }, [value]);
  return <>{content}</>;
};

export default DateTime;
