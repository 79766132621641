import DateTime from '@/components/DateTime';
import Fallback from '@/components/Fallback';
import { useNotice } from '@/components/Notice';
import useAsync from '@/hooks/useAsync';
import { NoticeResource, readNotice } from '@/services/user';
import {
  Avatar,
  Badge,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Box,
} from '@material-ui/core';
import NavigateNext from '@material-ui/icons/NavigateNext';
import Notifications from '@material-ui/icons/Notifications';
import React from 'react';
import {Link, useHistory} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  badge: {
    left: 4,
    top: 4,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}));

export default function NoticePage() {
  const classes = useStyles();
  const notice = useNotice();
  const state = useAsync(NoticeResource.query);
  const history = useHistory();

  return (
    <Container disableGutters maxWidth="sm">
      <Fallback
        state={state}
        render={({ data }) => {
          return (
            <List>
              {data.map((item) => {
                const avatar = item.sender ? (
                  <Avatar src={item.sender.avatar} />
                ) : (
                  <Avatar>
                    <Notifications />
                  </Avatar>
                );
                return (
                  <ListItem
                    key={item.id}
                    button
                    onClick={async () => {
                      await readNotice(item.id);
                      notice.retry();
                      if (
                        item.type === 'remind_lesson_complete' || item.type === 'lesson_practiceTime_gt_eq'   &&
                        item.userLesson
                      ) {
                        history.push(
                          `/assistant/students/${item.senderID}/courses/${item.userLesson.userCourseID}`
                        );
                      }
                      if (item.type === 'lesson_completed' && item.userLesson) {
                        history.push(
                          `/course/syllabus/${item.userLesson.userCourseID}/6`
                        );
                      }


                      if (item.type === 'lesson_practiceTime_commond' ) {
                        history.push(
                        `/assistant/students/${item.senderID}/practices/${item.userLesson.courseID}`
                        );
                      }



                    }}
                  >
                    <ListItemAvatar>
                      {item.marked ? (
                        <Badge
                          classes={{ badge: classes.badge }}
                          color="secondary"
                          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                          variant="dot"
                        >
                          {avatar}
                        </Badge>
                      ) : (
                        avatar
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.message}
                      secondary={<DateTime value={item.createdAt} />}
                    />
                    <Box>{item.marked ? '未读' : '已读'}</Box>
                    <ListItemSecondaryAction>
                      <NavigateNext />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          );
        }}
      />
    </Container>
  );
}
