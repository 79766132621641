import Fallback from '@/components/Fallback';

import {CommLeave} from '@/services/user';

import {Box, Container} from '@material-ui/core';
import {Card, Col, Table} from 'antd';
import React  from 'react';

import useAsync from "@/hooks/useAsync";
import DateTime from "@/components/DateTime";
import {Link} from "react-router-dom";


const UserChange: React.FC = () => {

    const ContactprofilesState1 = useAsync(() => CommLeave(6), [6]);
    const aaa = ContactprofilesState1.value

    const content1 = aaa?(aaa.Content):(``)


    return (
        <>
            <Container maxWidth="sm">
                <Box mt={2} mb={2}>
                    <p  style={{background:`#FFFFE0`,fontFamily:'Microsoft JhengHei',marginTop:'-0px',marginBottom:'10px'}}  dangerouslySetInnerHTML={{ __html: content1}}/>
                    <p></p>

                    {/*<Col>请假记录</Col>*/}
                    <Fallback
                        state={ContactprofilesState1}
                        render={(data) => (

                            <Box flex={3}>

                                <Table
                                    rowKey="id"
                                    pagination={false}
                                    size="middle"
                                    bordered
                                    // style={{ minWidth: '50%' }}
                                    title={() => <div>请假记录</div>}
                                    dataSource={data.leaves}
                                    columns={[
                                        // {
                                        //     dataIndex: 'id',
                                        //     title: '#',
                                        // },
                                        {
                                            dataIndex: 'days',
                                            width:'14%',
                                            title: '天数',
                                            render: (value) => (
                                                value+'天'
                                            ),
                                        },
                                        {
                                            dataIndex: 'content',
                                            title: '事由',
                                        },
                                        {
                                            dataIndex: 'starttime',
                                            title: '请假时间',
                                        },
                                    ]}
                                />
                            </Box>
                        )}
                    />
                </Box>
            </Container>

        </>
    );
};

export default  UserChange;
