import Axios from 'axios';
import { api } from './api';

const axios = Axios.create({ baseURL: '/api' });
interface AssistantStaticsData {
  allStudentCount: number;
  normalStudentCount: number;
  suspendedStudentCount: number;
  stateBannedCount: number;
  classCount: number;
  allPracticesCount: number;
  viewPracticesCount: number;
  unviewPracticesCount: number;
}
export const queryAssistantStatics = api<void, AssistantStaticsData>(() =>
  axios.get('/assistant/statics')
);
