import React from 'react';


//练习视频
const GenseeVideo1: React.FC<{ videoID: string; videoToken?: string ;userID?: number;practiceID?: number;userLessonID?: number}> = ({
  videoID,
  videoToken,
                                                                                                                              userID,practiceID,userLessonID
}) => {


  const src = React.useMemo(() => {
    if (process.env.NODE_ENV === 'production') {
      const params = new URLSearchParams();
      params.set('videoID', videoID);
    }

    const html = `<html xmlns="http://www.w3.org/1999/xhtml">
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
    <title>H5 Demo1</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0"/>

<!--    视频边框黑边去除-->
    <style>

        div .ccH5Poster {
        background-color:white;
        }
        
        
        div .ccH5playerBox video{
        background-color:white;
        }

    </style>


<script src="https://cdn.bootcss.com/jquery/3.3.1/jquery.min.js"></script>





</head>
<body>
    <div id="test"></div>
<script>


 //点击开始建 开始计数
        var count = 0
        var timer = null //timer变量记录定时器setInterval的返回值


    window.onload = function() {
        video = document.querySelector('video');
        if (video) {
           video.setAttribute("controlsList", "nodownload");
        }
    };


    function onCCH5PlayerLoaded() {
        createPlayer();
      
    }

    function nextPlayer() {
        window.cc_js_Player.destroy();
        createPlayer();
          
    }
    
    
    //点击开始
     function on_CCH5player_play() {
       
        
            timer = setInterval(function() {
                count++;
                console.log(count)


                //观看超过15秒就记录到观看表
                if (count >= 15){
                    console.log('时间到')
                    
                    // $.get('http://localhost:3000/api/practiceviews', {'userID':${userID}, 'practiceID':${practiceID},'userLessonID':${userLessonID}})
                    
                    clearInterval(timer)
                    //数据清零 总秒数清零
                    count = 0
                    
                    
                    $.ajax(
                            {
                                url: "https://xsqxy.com/api/practiceviews",
                                dataType: "jsonp",

                                type: "get",
                                 data: {'userID':${userID}, 'practiceID':${practiceID},'userLessonID':${userLessonID}},
                               xhrFields: {withCredentials: true},
                               crossDomain: true,
                                success: function (result) {
                                },
                                error: function (data) {
                                }
                            }
                        )
                }

            }, 1000)
        
    }
    
    //点击暂停
    function on_CCH5player_pause() {
        //取消定时器
            clearInterval(timer)
    }

    function createPlayer() {
        window.createCCH5Player({
            'vid': '${videoID}',
            'siteid': '3FBC2289488A1532',
            'mediatype':1,
            'playtype': 1,
            'autoStart':false,
            'width': "100%",
            'height': "100%",
            'isShare':'false',
            
           
              
            'parentNode': document.getElementById('test')
        });
    }

    function on_CCH5player_ready(obj) {
        console.log('CCH5player_ready', obj);
        
        /* 调用播放器api样例_设置音量*/
        window.cc_js_Player.setVolume(0.5);
    }

    /* 回调样例_监听音量改变*/
    function on_player_volumechange(volume, vid) {
        console.log('音量改变回调: ', volume, vid)
    }
</script>
<script src="https://p.bokecc.com/player?siteid=3FBC2289488A1532&newversion=true"></script>
</body>
</html>`;
    const blob = new Blob([html], {
      type: 'text/html',
    });
    return URL.createObjectURL(blob);
  }, [videoID, videoToken]);

  return (
    <iframe
      style={{
        width: '100%',
        height: '56vw',
        maxHeight: '330px',
        border: 'none',
        display: 'block',
      }}
      title="video"
      src={src}
    />
  );
};

export default GenseeVideo1
