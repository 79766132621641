import { Input, Space } from 'antd';
import { InputProps } from 'antd/lib/input';
import React from 'react';
import UploadButton from './UploadButton';
import { upload } from '@/services/user';

// TODO: 某些浏览器不能上传视频
interface UplaodInputProps extends Omit<InputProps, 'onChange'> {
  onChange?: (value: string) => void;
}
const UploadInput: React.FC<UplaodInputProps> = ({ onChange, ...rest }) => {
  return (
    <Space>
      <Input
        {...rest}
        onChange={(event) => onChange && onChange(event.target.value)}
      />
      <UploadButton onUploadSuccess={onChange} upload={upload} />
    </Space>
  );
};

export default UploadInput;
