import { AppTab } from '@/components/AppTabs';
import CategorySelect, { CategoryModule } from '@/components/CategorySelect';
import Fallback from '@/components/Fallback';
import {
    createSubmitFab,
    createSubmitModal,
    createSubmitTrigger,
    TotalLabel,
} from '@/components/ResourceManage';
import SearchSelect from '@/components/SearchSelect';
import useAsyncFn, { AsyncState } from '@/hooks/useAsyncFn';
import {PassNote, ContactSourceResource, CourseResource, Student, PracticeResource} from '@/services/admin';
import {ContactSource, LessonNote} from '@/services/entity';
import {Box, makeStyles, Tab} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {Badge, Button, Card, Col, Form, Input, Popconfirm, Radio, Row, Select, Space, Table} from 'antd';
import { FormProps } from 'antd/lib/form';
import { TableProps } from 'antd/lib/table';
import format from 'date-fns/format';
import React from 'react';
import {comparator} from "ramda";
import {differenceInDays} from "date-fns";
import {Link} from "react-router-dom";
import {totalmem} from "os";
import useAsync from "@/hooks/useAsync";

const resource = PassNote;
type Entity = LessonNote;

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

// const RecordForm: React.FC<FormProps> = (props) => {
//     return (
//         <Form size="large" labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }} {...props}>
//             <Form.Item label="类型" name="categoryID" rules={[{ required: true }]}>
//                 <CategorySelect module={CategoryModule.ContactSource} />
//             </Form.Item>
//             <Form.Item label="渠道" name="channel" rules={[{ required: true }]}>
//                 <Input />
//             </Form.Item>
//         </Form>
//     );
// };

// const SubmitModal = createSubmitModal<Entity>(RecordForm);
// const SubmitTrigger = createSubmitTrigger(SubmitModal);
// const SubmitFab = createSubmitFab(SubmitTrigger);

interface DataTableProps extends TableProps<Entity> {
    onCreate?: () => void;
}




const DataTable: React.FC<DataTableProps> = ({ onCreate, ...rest }) => {

    // const state = useAsync(() => {
    //     return resource.query({
    //
    //     });
    // }, []);

    return (

        <Table
            {...rest}
            rowKey="id"
            bordered
            size="small"
            columns={[
                {
                    dataIndex: 'lesson',
                    title: '课程',
                    render: (value) => value.name,
                },
                {
                    dataIndex: 'author',
                    title: '姓名',
                    render: (value) => value.realname,
                },
                {
                    dataIndex: 'content',
                    title: '内容',
                },
                {
                    dataIndex: 'pass',
                    title: '状态',
                    render: (value) => (
                        value ===0 ? (
                            `待审核`
                        ) : (
                            ''
                        )
                    ),
                },
                {
                    title: '事件',
                    dataIndex: 'id',
                    render: (_, record) =>
                        <Popconfirm
                            title="审核通过?"
                            onConfirm={async () => {
                                await PassNote.delete(record.id);
                                window.location.reload();
                            }}
                        >
                            <Button type="link">审核</Button>
                        </Popconfirm>
                },
            ]}
        />
    );
};

const TabPanel1: React.FC<{
    state: AsyncState<{ total: number; data: Entity[] }>;
    fetch: typeof resource.query;
}> = ({ state, fetch }) => {
    const [form] = Form.useForm();
    const aaa =state.value;
    return (
        <div>
            {/*<Card style={{ marginBottom: 8 }}>*/}
            {/*    <Form*/}
            {/*        form={form}*/}
            {/*        layout="vertical"*/}
            {/*        onFinish={(values) => fetch({ filter: values })}*/}
            {/*    >*/}


            {/*        <Form.Item label="状态" name="state">*/}
            {/*            <Select placeholder="全部状态" allowClear>*/}
            {/*                <Select.Option value="pass">通过</Select.Option>*/}
            {/*                <Select.Option value="wait">待审核</Select.Option>*/}
            {/*            </Select>*/}
            {/*        </Form.Item>*/}



            {/*        <Form.Item style={{ textAlign: 'right' }}>*/}
            {/*            <Space>*/}
            {/*                <Button htmlType="reset" onClick={() => form.resetFields()}>*/}
            {/*                    重置*/}
            {/*                </Button>*/}
            {/*                <Button type="primary" htmlType="submit" disabled={state.loading}>*/}
            {/*                    查询*/}-
            {/*                </Button>*/}
            {/*            </Space>*/}
            {/*        </Form.Item>*/}
            {/*    </Form>*/}

            {/*</Card>*/}
            <Fallback
                state={state}
                initial={<DataTable loading />}
                render={({ data }) => {

                    return (

                        <DataTable
                            dataSource={data}
                            loading={state.loading}
                            onCreate={state.retry}
                        />

                    );
                }}
            />
        </div>
    );
};

function useProfileTab(): AppTab {
    const [state, fetch] = useAsyncFn(resource.query, []);
    React.useEffect(() => {
        fetch({});
    }, [fetch]);

    return {
        path: `students`,
        tab: <Tab label={<TotalLabel state={state}>笔记</TotalLabel>} />,
        // fab: (
        //     <SubmitFab
        //     >
        //     </SubmitFab>
        // ),
        panel: <TabPanel1  state={state} fetch={fetch} />,
    };
}

export default useProfileTab;
