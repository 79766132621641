import Fallback from '@/components/Fallback';
import useAsync from '@/hooks/useAsync';
import { PracticeResource } from '@/services/admin';
import React from 'react';
import { useParams } from 'react-router-dom';
import Table from '@/components/Table';
import Duration from '@/components/Duration';
import DateTime from '@/components/DateTime';
import StarOutlined from '@material-ui/icons/StarOutlined';
import { Button, Popconfirm } from 'antd';

const RecordList: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const userID = typeof id === 'string' ? parseInt(id) : undefined;

  const state = useAsync(() => {
    return PracticeResource.query({
      filter: {
        userID,
      },
    });
  }, [userID]);

  return (
    <Fallback
      state={state}
      render={(items, loading) => (
        <Table
          loading={loading}
          columns={[
            { dataIndex: 'id', title: '#' },
            { dataIndex: 'title', title: '标题' },
            {
              dataIndex: 'createdAt',
              title: '开始时间',
              render: (value) => <DateTime value={value} />,
            },
            {
              dataIndex: 'duration',
              title: '时长',
              render: (value) => <Duration value={value} />,
            },
            {
              dataIndex: 'isRecommend',
              title: '收藏',
              render: (value) => (value ? <StarOutlined /> : null),
            },
            {
              title: '操作',
              render: (_, record) => (
                <Popconfirm
                  title="确认删除？"
                  onConfirm={async () => {
                    await PracticeResource.delete(record.id);
                    state.retry();
                  }}
                >
                  <Button type="link" size="small">
                    删除
                  </Button>
                </Popconfirm>
              ),
            },
          ]}
          dataSource={items.data}
        />
      )}
    />
  );
};

export default RecordList;
