import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import AssistantOutlinedIcon from '@material-ui/icons/AssistantOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import React from 'react';
import { Link } from 'react-router-dom';

export function TeacherMenuItem() {
  return (
    <>
      <Divider />
      <List
        component="div"
        disablePadding
        subheader={
          <ListSubheader disableSticky component="div">
            客服
          </ListSubheader>
        }
      >
        <ListItem button component={Link} to="/teacher">
          <ListItemIcon>
            <DashboardOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="总览" />
        </ListItem>
        <ListItem button component={Link} to="/teacher/classes">
          <ListItemIcon>
            <SupervisedUserCircleOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="班级" />
        </ListItem>
        <ListItem button component={Link} to="/teacher/students">
          <ListItemIcon>
            <SchoolOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="学生" />
        </ListItem>
        <ListItem button component={Link} to="/teacher/assistants">
          <ListItemIcon>
            <AssistantOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="琴师" />
        </ListItem>
        <ListItem button component={Link} to="/teacher/tickets">
          <ListItemIcon>
            <NotificationsOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="提醒" />
        </ListItem>
      </List>
    </>
  );
}
