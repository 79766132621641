import AppTabs from '@/components/AppTabs';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
// import LecturePlay from './LecturePlay';
import useCourseTab from './useCourseTab';
import userKindTab from './useKindTab';
import useOrderTab from './useOrderTab';

const Course: React.FC = () => {
  const match = useRouteMatch();

  const kindTab = userKindTab();
  const courseTab = useCourseTab();
  const orderTab = useOrderTab();

  return (
    <Switch>
      {/*<Route path={`${match.path}/lectures/:id`}>*/}
      {/*  <LecturePlay />*/}
      {/*</Route>*/}
      <Route path={match.path}>
        <AppTabs tabs={[kindTab,courseTab,orderTab]} />
      </Route>
    </Switch>
  );
};

export default Course;
