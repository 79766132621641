import React from 'react';
import { notification } from 'antd';
// import { ErrorMessage } from '@/services/api';

function useCatchError<T extends (...args: any[]) => any>(
  fn: T,
  deps: React.DependencyList = []
): T {
  return React.useCallback<any>(
    async (...args: any[]) => {
      try {
        return await fn(...args);
      } catch (error) {
        notification.error({
          message: error.name,
          description: error.message,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fn, ...deps]
  );
}

export default useCatchError;
