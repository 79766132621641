import CategorySelect, { CategoryModule } from '@/components/CategorySelect';
import DateTime from '@/components/DateTime';
import Fallback from '@/components/Fallback';
import {
    createSubmitFab,
    createSubmitModal,
    createSubmitTrigger,
} from '@/components/ResourceManage';
import SearchSelect from '@/components/SearchSelect';
import useAsyncFn from '@/hooks/useAsyncFn';
import { UserEvent } from '@/services/entity';
import {CategoryResource, EventResource, studentEvent} from '@/services/admin';
import { Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {Button, Card, Col, Divider, Form, Input, Popconfirm, Space, Table} from 'antd';
import { FormProps } from 'antd/lib/form';
import React from 'react';
import { useParams } from 'react-router-dom';




type Entity = UserEvent;

const RecordForm: React.FC<FormProps> = (props) => {
    return (
        <Form labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }} {...props}>
            <Form.Item label="类型" name="categoryID" rules={[{ required: true }]}>
                <CategorySelect module={CategoryModule.UserEvent} resource={CategoryResource} />
            </Form.Item>
            <Form.Item label="事件简述" name="name" rules={[{ required: true }]}>
                <Input.TextArea />
            </Form.Item>
        </Form>
    );
};

const SubmitModal = createSubmitModal<Entity>(RecordForm);
const SubmitTrigger = createSubmitTrigger(SubmitModal);
const SubmitFab = createSubmitFab(SubmitTrigger);


export default function StudentEventPage() {


    const resource1 = EventResource;
    const { id } = useParams<{ id: string }>();
    const resource = React.useMemo(() => studentEvent(parseInt(id)), [id]);
    const [form] = Form.useForm();
    const [state, fetch] = useAsyncFn(resource.query, [id]);
    React.useEffect(() => {
        fetch({});
    }, [fetch]);

    return (
        <Fallback
            state={state}
            render={({ data, total, meta }) => (
                <>
                    <Col >{data.length > 0?(<Col>{data[0].user.realname}的事件</Col>):(``)}</Col>
                    <Card style={{ marginBottom: 8 }}>

                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={(values) => fetch({ filter: values })}
                        >
                            <Form.Item label="事件类型" name="categoryID">
                                <SearchSelect
                                    placeholder="全部类型"
                                    query={() =>
                                        CategoryResource.query({
                                            filter: { module: CategoryModule.UserEvent },
                                        })
                                    }
                                    map={({ name, id }) => ({ value: id, label: name })}
                                />
                            </Form.Item>
                            <Form.Item style={{ textAlign: 'right' }}>
                                <Space>
                                    <Button htmlType="reset" onClick={() => form.resetFields()}>
                                        重置
                                    </Button>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        disabled={state.loading}
                                    >
                                        查询
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </Card>


                    <Table
                        rowKey="id"
                        title={() => (
                            <Box display="flex" justifyContent="space-between">
                                <div>共计 {total} 条</div>
                            </Box>
                        )}
                        bordered
                        size="small"
                        style={{ minWidth: '100%' }}
                        dataSource={data}
                        columns={[
                            {
                                dataIndex: 'id',
                                title: '#',
                            },
                            {
                                dataIndex: 'name',
                                title: '事件',
                            },
                            {
                                dataIndex: 'category',
                                title: '类型',
                                render: (value) => value.name,
                            },
                            {
                                dataIndex: 'author',
                                title: '记录',
                                render: (value) => value.realname,
                            },
                            {
                                dataIndex: 'updatedAt',
                                title: '时间',
                                render: (value) => <DateTime value={value} />,
                            },

                            {
                                title: '操作',
                                render: (_, record) => {
                                    return (
                                        <>


                                            <SubmitTrigger
                                                ModalProps={{
                                                    title: '编辑用户',
                                                    centered: true,
                                                    submit: resource.update(record.id),
                                                    onSubmitted: state.retry,
                                                    FormProps: { initialValues: record },
                                                }}
                                                render={({ open }) => (
                                                    <Button style={{ padding: 0 }} type="link" onClick={open}>
                                                        编辑
                                                    </Button>
                                                )}
                                            />

                                            <Divider type="vertical" />

                                            <Popconfirm
                                                title="确认删除?"
                                                onConfirm={async () => {
                                                    await resource1.delete(record.id);
                                                    window.location.reload();
                                                }}
                                            >
                                                <Button type="link">删除</Button>
                                            </Popconfirm>

                                        </>
                                    );
                                },
                            },

                        ]}
                    />
                    <SubmitFab
                        ModalProps={{
                            title: '创建事件',
                            centered: true,
                            submit: resource.create,
                            onSubmitted: state.retry,
                        }}
                    >
                        <AddIcon />
                    </SubmitFab>
                </>
            )}
        />
    );
}
