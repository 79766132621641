import React from 'react';
import { useParams } from 'react-router-dom';
import useAsync from '@/hooks/useAsync';
import { studentProfile, UserResource } from '@/services/teacher';
import ProfilePanel from '@/components/ProfilePanel';
import Fallback from '@/components/Fallback';

export interface ProfileProps {}

const StudentProfilePage: React.FC<ProfileProps> = () => {
  const { id } = useParams<{ id: string }>();
  const studentID = React.useMemo(() => parseInt(id), [id]);
  const api = React.useMemo(() => {
    return studentProfile(studentID);
  }, [studentID]);
  const state = useAsync(api.fetch, [api.fetch]);
  return (
    <Fallback
      state={state}
      render={(profile) => (
        <ProfilePanel
          profile={profile}
          updateAvatar={async (avatar) => {
            await api.update.avatar(avatar);
            state.retry();
          }}
          updateCourseEndTime={async (values) => {
            await UserResource.update(studentID)(values);
            state.retry();
          }}
        />
      )}
    />
  );
};

export default StudentProfilePage;
