import Fallback from '@/components/Fallback';
import useAsyncFn from '@/hooks/useAsyncFn';
import { queryAssistantSuspendedStudents } from '@/services/assistant';
import { User } from '@/services/entity';
import {Badge, Button, Card, Divider, Form, Input, Space, Table} from 'antd';
import { TableProps } from 'antd/lib/table';
import qs from 'qs';
import React from 'react';
import { Link } from 'react-router-dom';

interface DataTableProps extends TableProps<User> {
  onCreate?: () => void;
}

const renderState = (value: string) => {
    switch (value) {
        case 'normal':
            return <Badge status="success" text="正常" />;
        case 'suspended':
            return <Badge status="default" text="暂停" color="red"/>;
        case 'banned':
            return <Badge status="error" text="禁用" color="red"/>;
        default:
            return null;
    }
};

const DataTable: React.FC<DataTableProps> = ({ onCreate, ...rest }) => {
  return (
    <Table
      rowKey="id"
      {...rest}
      bordered
      size="small"
      columns={[
        {
          dataIndex: 'id',
          title: '#',
        },
        {
          dataIndex: 'realname',
          title: '姓名',
          // render: (_, record) => (
          //     <>
          //     <Link to={`/teacher/students/${record.id}/profile`}>
          //     {record.realname} ({record.username})
          //   </Link>
          //   <br />
          //         {renderState(record.state)}
          //     </>
          // ),
        },
        {
          title: '操作',
          render: (_, record) => {
            return (
              <>
                <Link  to={`/assistant/students/${record.id}/courses`}>

                  课程
                </Link>
                <Divider type="vertical" />
                <Link to={`/assistant/students/${record.id}/practices`}>练习</Link>
                <Divider type="vertical" />
                <Link
                  // to={`/assistant/distribute-points?${qs.stringify({
                  //   recipientID: record.id,
                  //   classID: record.classID,
                  // })}`}
                    to={`/assistant/students/${record.id}/points`}
                >
                  积分
                </Link>
              </>
            );
          },
        },
      ]}
    />
  );
};

const StudentSuspended: React.FC = () => {
  const [state, fetch] = useAsyncFn(queryAssistantSuspendedStudents);
  const [form] = Form.useForm();
  React.useEffect(() => {
    fetch({});
  }, [fetch]);
  return (
    <>
      <Card style={{ marginBottom: 8 }}>
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => fetch({ filter: values })}
        >
          <Form.Item label="姓名" name="$search">
            <Input placeholder="请输入学号或姓名" />
          </Form.Item>
          <Form.Item style={{ textAlign: 'right' }}>
            <Space>
              <Button htmlType="reset" onClick={() => form.resetFields()}>
                重置
              </Button>
              <Button type="primary" htmlType="submit" disabled={state.loading}>
                查询
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>


      <Fallback
        state={state}
        initial={<DataTable loading />}
        render={({ data }) => {
          return (
            <DataTable
              dataSource={data}
              loading={state.loading}
              onCreate={state.retry}
            />
          );
        }}
      />
    </>
  );
};

export default StudentSuspended;
