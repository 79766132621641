import React from 'react';
import { useParams } from 'react-router-dom';
import useAsync from '@/hooks/useAsync';
import { getUserProfile, updateUserAvatar } from '@/services/admin';
import ProfilePanel from '@/components/ProfilePanel';
import Fallback from '@/components/Fallback';

export interface ProfileProps {}

const Profile: React.FC<ProfileProps> = () => {
  const { id } = useParams<{ id: string }>();
  const userID = parseInt(id);
  const state = useAsync(() => getUserProfile(userID), [userID]);
  return (
    <Fallback
      state={state}
      render={(profile) => (
        <ProfilePanel
          profile={profile}
          updateAvatar={async (avatar) => {
            await updateUserAvatar({ avatar, userID });
            state.retry();
          }}
        />
      )}
    />
  );
};

export default Profile;
