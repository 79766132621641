import Fallback from '@/components/Fallback';

import { invitationCode } from '@/services/admin';

import { Select, InputBase, MenuItem, Box } from '@material-ui/core';
import { Table, Divider, Card, Form, Input, Space, Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import useAsyncFn from '@/hooks/useAsyncFn';
import qs from 'qs';
import FormatedDatePicker from "@/components/FormatedDatePicker";

import Editor from "@/components/Editor/Editor"


//import UE from '/src/web/public/ueditor/ueditor.all';




const InvitationCode: React.FC = () => {



    const [form] = Form.useForm();
    const [state, fetch] = useAsyncFn(invitationCode);
    const [selected, setSelected] = React.useState<number>(0);



           const setContent =(state:any)=>{
                console.log(111111111111)
                console.log(state)
                form.setFieldsValue({explain:state})
            }


    const resetFields =(state:any)=>{
               console.log(666666666666)
        form.resetFields()
    }


    React.useEffect(() => {
        fetch({});
    }, [fetch]);
    return (
        <>
            <Fallback
                state={state}
                render={({ data }) => {
                    return (
                        <>

                            <Card title={"邀请码后台设置"} style={{ marginBottom: 8 }}>


                                <Form
                                    form={form}
                                    layout="vertical"
                                    onFinish={(values) => fetch({ filter: values })}
                                >

                                                  <Form.Item name="explain">
                                                      <Input  type="hidden"/>
                                                  </Form.Item>


                                    <Form.Item label="活动名称" name="name">
                                        <Input  />
                                    </Form.Item>
                                    <Form.Item  label="开始日期" name="starttime">
                                        <FormatedDatePicker />
                                    </Form.Item>

                                    <Form.Item  label="结束日期" name="endtime">
                                        <FormatedDatePicker />
                                    </Form.Item>

                                    <Form.Item  label="生成次数" name="times">
                                        <Input  size="large"/>
                                    </Form.Item>

                                    <Form.Item  label="使用说明1">
                                        <Editor id={"text"}  resetFields={resetFields}  setContent={setContent}/>
                                    </Form.Item>

                                    {/*<Form.Item style={{ textAlign: 'right' }}>*/}
                                    {/*    <Space>*/}
                                    {/*        <Button*/}
                                    {/*            htmlType="reset"*/}
                                    {/*            onClick={() => form.resetFields()}*/}
                                    {/*        >*/}
                                    {/*            重置*/}
                                    {/*        </Button>*/}
                                    {/*        <Button*/}
                                    {/*            type="primary"*/}
                                    {/*            //htmlType="submit"*/}
                                    {/*            //disabled={state.loading}*/}

                                    {/*        >*/}
                                    {/*            确定*/}
                                    {/*        </Button>*/}
                                    {/*    </Space>*/}
                                    {/*</Form.Item>*/}
                                </Form>
                            </Card>
                            {data.map((item, index) => {
                                return (
                                    <Table
                                        rowKey="id"
                                        bordered
                                        size="small"
                                        style={{ minWidth: '100%' }}
                                        columns={[
                                            {
                                                title: '结果:',
                                                dataIndex: 'result',
                                            },
                                        ]}
                                        dataSource={item.students}
                                    />
                                );
                            })}
                        </>
                    );
                }}
            />
        </>
    );
};




export default InvitationCode;
