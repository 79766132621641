import Fallback from '@/components/Fallback';
import LearnStateChip from '@/components/LearnStateChip';
import LinearProgressWithLabel from '@/components/LinearProgressWithLabel';
import useAsync from '@/hooks/useAsync';
import {startUserCourse, UserCourseResource, completeUserCourse, KindResource} from '@/services/admin';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import {useParams, Link, useHistory} from 'react-router-dom';
import { SubmitTrigger } from './RecordForm';
// import {Col, Row} from "antd";


import * as Antdd from 'antd';
import format from "date-fns/format";




const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
}));

interface DeleteConrifmProps {
  onConfirm: () => void;
}
function DeleteConrifm({ onConfirm }: DeleteConrifmProps) {
  const [open, setOpen] = React.useState(false);



  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button size="small" color="primary" onClick={handleClickOpen}>
        删除
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>确认删除该学生课程？</DialogTitle>
        <DialogContent>
          <DialogContentText>
            学生课程删除后会丢失该课程下的所有学习进度，不可恢复。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            取消
          </Button>
          <Button
            onClick={() => {
              handleClose();
              onConfirm();
            }}
            color="primary"
            autoFocus
          >
            确认
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export interface UserCoursePageProps {}

const UserCoursePage: React.FC<UserCoursePageProps> = () => {
  const classes = useStyles();
  const params = useParams<{ id: string }>();
  const userID = parseInt(params.id);
  const state = useAsync(
    () => UserCourseResource.query({ filter: { userID } }),
    [userID]
  );

  return (
    <Fallback
      state={state}
      initial={null}
      render={(items) => {
        return (
          <Box flex={1}>
            <Box
              mb={2}
              bgcolor="white"
              display="flex"
              justifyContent="flex-end"
            >
              <SubmitTrigger
                ModalProps={{
                  title: '添加课程',
                  onSubmitted: state.retry,
                  submit: (values) =>
                    UserCourseResource.create({ ...values, userID }),
                }}
                render={({ open }) => (
                  <Button onClick={open} color="primary">
                    添加课程3
                  </Button>
                )}
              />


            </Box>
              {items.data.map((item,i)=>{
                  if (i==0){
                      return(
                          // <Button size="small" color="primary">{item.username}的课程</Button>
                          <Antdd.Row>
                              <Antdd.Col xs={8}>{item.username}的课程</Antdd.Col>
                          </Antdd.Row>
                      )
                  }
              })}



            {items.data.map((item) => (
              <Card key={item.id} className={classes.card} variant="outlined">
                <CardHeader
                  avatar={<LearnStateChip type={item.state} />}
                  title={item.course.name}
                />
                <Box ml={2} mr={2}>
                  <LinearProgressWithLabel
                    current={item.completed}
                    total={item.total}
                  />
                </Box>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    component={Link}
                    to={`/admin/student/students/${params.id}/courses/${item.id}`}
                  >
                    查看
                  </Button>


                  <DeleteConrifm
                    onConfirm={async () => {
                      await UserCourseResource.delete(item.id);
                      state.retry();
                    }}
                  />
                  {item.state === 'locked' && (
                    <Button
                      size="small"
                      color="primary"
                      onClick={async () => {
                        await startUserCourse(item.id);
                        state.retry();
                      }}
                    >
                      开课
                    </Button>
                  )}
                  {item.state !== 'locked' && (
                    <Button
                      size="small"
                      color="primary"
                      disabled={item.state !== 'waiting'}
                      onClick={async () => {
                        await completeUserCourse(item.id);
                        state.retry();
                      }}
                    >
                      完成
                    </Button>


                  )}

                    {/*<Button*/}
                    {/*    size="small"*/}
                    {/*    color="primary"*/}
                    {/*    component={Link}*/}
                    {/*    to={`/admin/student/students/${params.id}/courses/${item.id}`}*/}
                    {/*>*/}
                    {/*    编辑课程*/}
                    {/*</Button>*/}
                    {/*<SubmitTrigger*/}
                    {/*    ModalProps={{*/}
                    {/*        title: '编辑课程',*/}
                    {/*        centered: true,*/}
                    {/*        //提交完成后刷新*/}
                    {/*        onSubmitted: state.retry,*/}
                    {/*        submit: UserCourseResource.update(userID),*/}
                    {/*        FormProps: { initialValues: item },*/}
                    {/*    }}*/}
                    {/*    render={({ open }) => (*/}
                    {/*        <Antdd.Button onClick={open} type="link" color="primary">*/}
                    {/*            编辑课程*/}
                    {/*        </Antdd.Button>*/}
                    {/*    )}*/}
                    {/*/>*/}

                    {item.state =='completed'  ? format(new Date(item.updatedAt), 'yyyy-MM-dd HH:mm:ss'):''}

                    <SubmitTrigger
                        ModalProps={{
                            title: '编辑课程',
                            onSubmitted: state.retry,
                            submit: UserCourseResource.update(userID),
                            FormProps: { initialValues: item },
                        }}
                        render={({ open }) => (
                            <Button onClick={open} color="primary">
                                编辑课程
                            </Button>
                        )}
                    />
                    {item.courseState == 'zanting' && (
                        <Button
                            size="small"
                            color="secondary"
                        >
                            停课
                        </Button>
                    )}
                </CardActions>

              </Card>
            ))}
          </Box>
        );
      }}
    />
  );
};

export default UserCoursePage;
