import Fallback from '@/components/Fallback';
import SearchSelect from '@/components/SearchSelect';
import useAsyncFn from '@/hooks/useAsyncFn';
import useAsyncTap from '@/hooks/useAsyncTap';
import useCatchError from '@/hooks/useCatchError';
import { applyFilter } from '@/services/api';
import {
  classRemainPoint,
  PointResource,
  UserResource,
} from '@/services/assistant';


import {AllChangeResource, UserCourseResource} from '@/services/admin';

import { AllChangeData } from '@/services/entity';
import { Box } from '@material-ui/core';
import {Button, Card, Col, Form, Input, InputNumber, message, Table} from 'antd';
import { TableProps } from 'antd/lib/table';
import format from 'date-fns/format';
import React, { useEffect, useMemo } from 'react';
import {Link, useParams} from 'react-router-dom';

import useAsync from "@/hooks/useAsync";
import { MenuOutlined } from '@ant-design/icons';




const DragHandle = (() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const DataTable: React.FC<TableProps<AllChangeData>> = (props) => (

  <Table
    {...props}
    rowKey="id"
    bordered
    size="small"
    title={() => <div><a href="https://wxaurl.cn/XedSLuSTPvp">转介记录</a></div>}
    style={{ minWidth: '100%' }}
    columns={[
        {
            title: '#',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
      {
        dataIndex: 'name',
        title: '姓名',
      },
      {
        dataIndex: 'count',
        title: '次数',
          // render: (value) => (
          //     <span><span  style={{ color: `#FF0000`}} >{value}</span></span>
          // ),
          render: (_, record) => (
              <Link
                  to={`/admin/student/students/change/${record.id}`}
              >
                    {record.count}
                </Link>
          ),
      },




      {
        dataIndex: 'total',
        title: '剩余点',
      },
    ]}
  />
);

const AllChange: React.FC = () => {


    const pointListState = useAsync(
        () => AllChangeResource.query({ filter: 6 }),
        [6]
    );

    const aaa =pointListState.value;

  return (
    <Box>
        {/*<Col>{aaa?.total ? ( <span style={{ color: `#FF0000`}}>共{aaa?.total.toString().substring(0,1)}次,剩余{aaa?.total.toString().substring(2,5)}点</span>):(``)}</Col>*/}

        <Col> 共<span style={{ color: `#FF0000`}}>{aaa?.total.toString().split('.',1)}</span>人,剩余<span style={{ color: `#FF0000`}}>{aaa?.total.toString().split('.',2).pop()}</span>点</Col>
      <Fallback
        state={pointListState}
        initial={<DataTable loading />}
        render={({ data }) => <DataTable dataSource={data} />}
      />



    </Box>




  );
};

export default AllChange;
