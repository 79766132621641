import Fallback from '@/components/Fallback';
import SearchSelect from '@/components/SearchSelect';
import useAsyncFn from '@/hooks/useAsyncFn';
import useAsyncTap from '@/hooks/useAsyncTap';
import useCatchError from '@/hooks/useCatchError';
import { applyFilter } from '@/services/api';
import {
    classRemainPoint,
    PointResource,
    UserResource,
} from '@/services/assistant';


import {UserChangeResource,ContactprofilesState} from '@/services/user';

import {AllChangeData, Change, ContactProfile} from '@/services/entity';
import {Box, Container} from '@material-ui/core';
import {Button, Card, Col, Form, Input, InputNumber, message, Table} from 'antd';
import { TableProps } from 'antd/lib/table';
import format from 'date-fns/format';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useAsync from "@/hooks/useAsync";


const DataTable: React.FC<TableProps<Change>> = (props) => (

    <Table
        {...props}
        rowKey="id"
        bordered
        size="small"
        title={() => <div>奖学金</div>}
        columns={[
            {
                dataIndex: 'quantity',
                title: '数额',
                render: (value) => (value > 0 ? `+${value}` : value),
            },
            {
                dataIndex: 'reason',
                title: '原因',
            },
            {
                dataIndex: 'createdAt',
                title: '日期',
                render: (value) => format(new Date(value), 'yyyy-MM-dd HH:mm:ss'),
            },
        ]}
    />
);



const DataTable1: React.FC<TableProps<ContactProfile>> = (props) => (

    <Table
        {...props}
        rowKey="id"
        bordered
        size="small"
        title={() => <div>已填表</div>}
        columns={[
            {
                dataIndex: 'realname',
                title: '姓名',
            },
            {
                dataIndex: 'createdAt',
                title: '日期',
                render: (value) => format(new Date(value), 'yyyy-MM-dd HH:mm:ss'),
            },
            {
                dataIndex: 'signup',
                title: '报名',
                render: (value) => (
                    value === "1" ? (
                        <Button type="link" danger>已报名</Button>
                    ) : (
                        value === "0" ? (
                            <Button type="text" >待联络</Button>
                        ) : (
                            <Button  type="text">未报名</Button>
                        )
                    )
                ),
            },
        ]}
    />
);

const UserChange: React.FC = () => {


    const pointListState = useAsync(
        () => UserChangeResource.query({ filter: 6 }),
        [6]
    );


    const ContactprofilesState1 = useAsync(
        () => ContactprofilesState.query({ filter: 6 }),
        [6]
    );

    const aaa =pointListState.value;

    return (
        <Container maxWidth="sm" >

            <Box  mt={2}>
                <Fallback
                    state={ContactprofilesState1}
                    initial={<DataTable1 loading />}
                    render={({ data }) => <DataTable1 dataSource={data} />}
                />
            </Box>


        <Box  mt={2}>
                {aaa?.data.length ? (<Col> 共<span style={{ color: `#FF0000`}}>{aaa?.total.toString().split('.',1)}</span>人,剩余<span style={{ color: `#FF0000`}}>{aaa?.total.toString().split('.',2).pop()}</span>点</Col>):(``)}
            {/*<Col>{aaa?.total ? ( <span style={{ color: `#FF0000`}}>共{aaa?.total.toString().split('.',1)}人,剩余{aaa?.total.toString().split('.',2)}点</span>):(`您尚未介绍新同学`)}</Col>*/}

                {/*<Col> 共<span style={{ color: `#FF0000`}}>{aaa?.total.toString().split('.',1)}</span>人,剩余<span style={{ color: `#FF0000`}}>{aaa?.total.toString().split('.',2).pop()}</span>点</Col>*/}
                <Fallback
                    state={pointListState}
                    initial={<DataTable loading />}
                    render={({ data }) => <DataTable dataSource={data} />}
                />
        </Box>


        </Container>



    );
};

export default  UserChange;
