import { AppTab } from '@/components/AppTabs';
import CategorySelect, { CategoryModule } from '@/components/CategorySelect';
import Fallback from '@/components/Fallback';
import {
    createSubmitFab,
    createSubmitModal,
    createSubmitTrigger,
    TotalLabel,
} from '@/components/ResourceManage';
import SearchSelect from '@/components/SearchSelect';
import useAsyncFn, { AsyncState } from '@/hooks/useAsyncFn';
import {CategoryResource, ContactSourceResource, JieXinResource,JieXinResource1} from '@/services/admin';
import { ContactSource } from '@/services/entity';
import { makeStyles, Tab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {Button, Card, Col, Divider, Form, Input,InputNumber, message, Popconfirm, Row, Space, Table,Tooltip, Upload} from 'antd';
import { FormProps } from 'antd/lib/form';
import { TableProps } from 'antd/lib/table';
import format from 'date-fns/format';
import React from 'react';
import {UploadOutlined} from "@ant-design/icons";

const resource = ContactSourceResource;
type Entity = ContactSource;



//-----------

const onChange = ( info:any ) => {
    if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
        message.success(`${info.file.name},图片上传成功`);

        setTimeout(function () {
            //内容1秒后,刷新
            window.location.reload();
        }, 1000);

    } else if (info.file.status === 'error') {
        message.error(`${info.file.name},图片上传失败`);
    }
};


const beforeUpload = ( file:any ) => {
    if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg') {
        return  true
    }else {
        message.error(`${file.name} is not a png file,or jpg,or jpeg`);
    }
    return false
};
//---------------------



const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

const { TextArea } = Input;

const RecordForm: React.FC<FormProps> = (props) => {
    //  const  aaa = props.initialValues
    //
    // const ddd = time?.valueOf()
    // console.log(ddd)

    return (



        <Form size="large" labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }} {...props}>
            <Form.Item label="类型1" name="categoryID" rules={[{ required: true }]}>
                <CategorySelect module={CategoryModule.ContactSource} />
            </Form.Item>
            <Form.Item label="渠道" name="channel" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item label="链接" name="url" >
                <Input />
            </Form.Item>
            {/*<Form.Item label="类型" name="types" >*/}
            {/*    <Input />*/}
            {/*</Form.Item>*/}
            <Form.Item label="x坐标" name="x" >
                <InputNumber />
            </Form.Item>
            <Form.Item label="y坐标" name="y" >
                <InputNumber />
            </Form.Item>
            <Form.Item label="二维码大小" name="size" >
                <InputNumber />
            </Form.Item>


            {/*<Form.Item label="图片" name="pic" >*/}
            {/*    <img style={{*/}
            {/*        height: 400,*/}
            {/*        width: 250,*/}
            {/*    }}*/}

            {/*         />*/}
            {/*</Form.Item>*/}
            {/*<Form.Item label="标题" name="title" >*/}
            {/*    <TextArea rows={4} />*/}
            {/*</Form.Item>*/}

            {/*<Form.Item label="推荐语" name="content" >*/}
            {/*    <TextArea rows={4} />*/}
            {/*</Form.Item>*/}
        </Form>
    );
};

const SubmitModal = createSubmitModal<Entity>(RecordForm);
const SubmitTrigger = createSubmitTrigger(SubmitModal);
const SubmitFab = createSubmitFab(SubmitTrigger);

interface DataTableProps extends TableProps<Entity> {
    onCreate?: () => void;
}
const DataTable: React.FC<DataTableProps> = ({ onCreate, ...rest }) => {
    return (
        <Table
            {...rest}
            rowKey="id"
            bordered
            style={{ minWidth: '100%' }}
            size="small"
            columns={[
                {
                    dataIndex: 'id',
                    title: '#',
                },
                {
                    dataIndex: 'category',
                    title: '类别',
                    // render: (category) => category.name,
                },
                { dataIndex: 'channel', title: '渠道' },
                {
                    dataIndex: 'createdAt',
                    title: '时间',
                    render: (createdAt) =>
                        format(new Date(createdAt), 'yyyy-MM-dd HH:mm:ss'),
                },
                {
                    dataIndex: 'url',
                    title: '链接',
                    // render: (category) => category.name,
                },
                {
                    dataIndex: 'title',
                    title: '标题',
                    ellipsis: {
                        showTitle: false,
                    },
                    render: title => (
                        <Tooltip placement="topLeft" title={title}>
                            {title}
                        </Tooltip>
                    ),
                },
                {
                    dataIndex: 'content',
                    title: '推荐语',
                },
                {
                    title: '背景图片',
                    align: 'center',

                    render: (value, record) => (

                        record.x === 0 ? (
                            <img style={{
                                height: 250,
                                width: 250,
                            }}
                                 src={record.pic1} />
                        ) : (
                            <img style={{
                                height: 400,
                                width: 250,
                            }}
                                 src={record.pic1} />
                            )
                    ),

                },


                {
                    title: '添加图片',
                    align: 'center',

                    render: (_, record) => (

                        record.x === 0 ? (
                           ''
                        ) : (
                            <>
                                <Form
                                    layout="vertical"
                                    // onFinish={(values) => fetch({ filter: values })}
                                >
                                    <Space >
                                        <Row>
                                            <Col xs={8} >
                                                <Upload name={'file'}
                                                        action={'/api/profile/jiexing/'+record.id}
                                                        onChange={onChange}
                                                        beforeUpload={beforeUpload}>
                                                    <Button  icon={<UploadOutlined />} >选择图片</Button>
                                                </Upload>
                                            </Col>
                                        </Row>
                                    </Space>

                                </Form>
                            </>
                        )
                    ),
                },


                {
                    title: '操作',
                    render: (value, record) => (


                        record.status === 1 ? (
                            <>


                            <SubmitTrigger
                                                    ModalProps={{
                                                        title: '编辑来源',
                                                        centered: true,
                                                        submit: resource.update(record.id),
                                                        onSubmitted: onCreate,
                                                        FormProps: { initialValues: record },
                                                    }}
                                                    render={({ open }) => (
                                                        <Button type="link" onClick={open}>
                                                            编辑
                                                        </Button>
                                                    )}
                                                />

                                <Divider type="vertical" />

                                <Popconfirm
                                    title="确认暂停?"
                                    onConfirm={async () => {
                                        await JieXinResource.update(record.id)({
                                        });


                                        window.location.reload();
                                        // setTimeout(function () {
                                        //     window.location.reload();
                                        // }, 1000);
                                    }}
                                >
                                    <Button type="link">暂停</Button>
                                </Popconfirm>



                                {/*<Divider type="vertical" />*/}

                                {/*<Popconfirm*/}
                                {/*    title="确认生成二维码?"*/}
                                {/*    onConfirm={async () => {*/}
                                {/*        await JieXinResource1.update(record.id)({*/}
                                {/*        });*/}
                                {/*        window.location.reload();*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    <Button type="link">二维码</Button>*/}
                                {/*</Popconfirm>*/}





                            </>

                        ) : (

                            record.x === 0 ? (
                                <>

                                    <SubmitTrigger
                                        ModalProps={{
                                            title: '编辑来源',
                                            centered: true,
                                            submit: resource.update(record.id),
                                            onSubmitted: onCreate,
                                            FormProps: { initialValues: record },
                                        }}
                                        render={({ open }) => (
                                            <Button type="link" onClick={open}>
                                                编辑
                                            </Button>
                                        )}
                                    />

                                </>
                            ) : (
                                <>

                                    <SubmitTrigger
                                        ModalProps={{
                                            title: '编辑来源',
                                            centered: true,
                                            submit: resource.update(record.id),
                                            onSubmitted: onCreate,
                                            FormProps: { initialValues: record },
                                        }}
                                        render={({ open }) => (
                                            <Button type="link" onClick={open}>
                                                编辑
                                            </Button>
                                        )}
                                    />



                                    <Divider type="vertical" />
                                    <Popconfirm
                                        title="确认启用?"
                                        onConfirm={async () => {
                                            await JieXinResource.find(record.id);
                                            window.location.reload();
                                        }}
                                    >
                                        <Button type="link">启用</Button>
                                    </Popconfirm>


                                </>
                                )



                        )

                    ),
                },
            ]}
        />
    );
};

const TabPanel: React.FC<{
    state: AsyncState<{ total: number; data: Entity[] }>;
    fetch: typeof resource.query;
}> = ({ state, fetch }) => {
    const [form] = Form.useForm();
    return (
        <div>
            <Card style={{ marginBottom: 8 }}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={(values) => fetch({ filter: values })}
                >
                    <Form.Item label="类别" name="categoryID">
                        <SearchSelect
                            placeholder="全部类别"
                            query={() =>
                                CategoryResource.query({
                                    filter: { module: 'contact_source' },
                                })
                            }
                            map={({ name, id }) => ({ value: id, label: name })}
                        />
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'right' }}>
                        <Space>
                            <Button htmlType="reset" onClick={() => form.resetFields()}>
                                重置
                            </Button>
                            <Button type="primary" htmlType="submit" disabled={state.loading}>
                                查询
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Card>
            <Fallback
                state={state}
                initial={<DataTable loading />}
                render={({ data }) => {
                    return (
                        <DataTable
                            dataSource={data}
                            loading={state.loading}
                            onCreate={state.retry}
                        />
                    );
                }}
            />
        </div>




    );
};

function useProfileTab(): AppTab {
    const classes = useStyles();
    const [state, fetch] = useAsyncFn(resource.query, []);
    React.useEffect(() => {
        fetch({});
    }, [fetch]);

    return {
        path: `sources`,
        tab: <Tab label={<TotalLabel state={state}>来源管理</TotalLabel>} />,
        fab: (
            <SubmitFab
                ModalProps={{
                    title: '创建来源',
                    centered: true,
                    submit: resource.create,
                    onSubmitted: state.retry,
                }}
                className={classes.fab}
            >
                <AddIcon />
            </SubmitFab>
        ),
        panel: <TabPanel state={state} fetch={fetch} />,



    };
}

export default useProfileTab;
