import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { AppBarTabs } from '@/slots';
import Tabs from '@material-ui/core/Tabs';
import { TabProps } from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabPanel from '@material-ui/lab/TabPanel';
import {
  matchPath,
  useLocation,
  useHistory,
  Route,
  useRouteMatch,
  Redirect,
} from 'react-router-dom';
import { Zoom } from '@material-ui/core';

type TabElement = React.ReactElement<TabProps>;

export interface AppTab {
  tab: TabElement;
  panel: React.ReactNode;
  path: string;
  fab?: React.ReactElement;
}
export interface AppTabsProps {
  tabs: AppTab[];
}

const AppTabs: React.FC<AppTabsProps> = ({ tabs }) => {
  const theme = useTheme();
  // const [activeIndex, setActiveIndex] = React.useState(0);
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  if (tabs.length === 0) {
    return null;
  }

  const activeIndex = tabs.findIndex((tab) =>
    matchPath(location.pathname, { path: `${match.path}/${tab.path}` })
  );

  const setActiveIndex = (index: number) => {
    history.push(`${match.url}/${tabs[index].path}`);
  };

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  if (activeIndex < 0) {
    return <Redirect to={`${match.url}/${tabs[0].path}`} />;
  }

  return (
    <>
      <AppBarTabs.Fill>
        <Tabs

            // orientation="vertical"
          value={activeIndex}
          onChange={(_, index) => setActiveIndex(index)}
          // variant="fullWidth"
        >
          {tabs.map(({ tab }, index) =>
            React.cloneElement(tab, { key: index })
          )}
        </Tabs>
      </AppBarTabs.Fill>
      <TabContext value={activeIndex.toString()}>
        {/* <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeIndex}
          onChangeIndex={setActiveIndex}
        > */}
        {tabs.map(({ path, panel }, index) => (
          <Route key={index} path={`${match.path}/${path}`}>
            <TabPanel style={{ padding: 0 }} value={index.toString()}>
              {panel}
            </TabPanel>
          </Route>
        ))}
        {/* </SwipeableViews> */}
      </TabContext>
      {tabs.map(
        (tab, index) =>
          tab.fab && (
            <Zoom
              key={index}
              in={activeIndex === index}
              timeout={transitionDuration}
              style={{
                transitionDelay: `${
                  activeIndex === index ? transitionDuration.exit : 0
                }ms`,
              }}
              unmountOnExit
            >
              {tab.fab}
            </Zoom>
          )
      )}
    </>
  );
};

export default AppTabs;
