import Fallback from '@/components/Fallback';
import SearchSelect from '@/components/SearchSelect';
import useAsyncFn from '@/hooks/useAsyncFn';
import useAsyncTap from '@/hooks/useAsyncTap';
import useCatchError from '@/hooks/useCatchError';
import { applyFilter } from '@/services/api';
import {
  classRemainPoint,
  PointResource,
  UserResource,
} from '@/services/assistant';


import {ChangeResource, UserCourseResource} from '@/services/admin';

import { Change } from '@/services/entity';
import { Box } from '@material-ui/core';
import {Button, Card, Col, Form, Input, InputNumber, message, Table} from 'antd';
import { TableProps } from 'antd/lib/table';
import format from 'date-fns/format';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useAsync from "@/hooks/useAsync";


const DataTable: React.FC<TableProps<Change>> = (props) => (

  <Table
    {...props}
    rowKey="id"
    bordered
    size="small"
    title={() => <div>转介记录</div>}
    style={{ minWidth: '100%' }}
    columns={[
        {
            dataIndex: 'id',
            title: '#',
        },
      {
        dataIndex: 'quantity',
        title: '数额',
        render: (value) => (value > 0 ? `+${value}` : value),
      },
      {
        dataIndex: 'reason',
        title: '原因',
      },
      {
        dataIndex: 'createdAt',
        title: '日期',
        render: (value) => format(new Date(value), 'yyyy-MM-dd HH:mm:ss'),
      },
    ]}
  />
);

const Changes: React.FC = () => {
  // const params = useParams<{classID: string}>();
  // const classID = useMemo(() => parseInt(params.classID), [params.classID]);


    const params = useParams<{ id: string }>();
    const userID = parseInt(params.id);


  const [form] = Form.useForm();
  // const [remainState, refreshRemain] = useAsyncFn(classRemainPoint);

  // const [pointListState, queryPointList] = useAsyncFn(ChangeResource.query);
  // useEffect(() => {
  //   queryPointList({});
  // }, [queryPointList]);



    const pointListState = useAsync(
        () => ChangeResource.query({ filter: { userID } }),
        [userID]
    );


  const [, createPoint] = useAsyncFn(

    useAsyncTap(ChangeResource.create, () => {
      message.success('转介成功');
      form.resetFields();
      // queryPointList({});
        window.location.reload();
    })
  );

  const catchedCreatePoint = useCatchError(createPoint);
    const aaa =pointListState.value;
    const b = {userID}
    form.setFieldsValue({ userID: b.userID });

    const time = aaa?.total.toString().split('.',1);
    const total = aaa?.total.toString().split('.',2).pop();
    const name = aaa?.total.toString().split('.',3).pop();
    return (
    <Box>

      <Card style={{ marginBottom: 16 }} title="新增转介">
        <Form
          form={form}
          onFinish={catchedCreatePoint as any}
        >


            <Form.Item  name="userID"  >
                <Input />
            </Form.Item>


            {/*<Form.Item  name="userID" hidden={true} >*/}
            {/*    <Input />*/}
            {/*</Form.Item>*/}

          <Form.Item
            label="数额"
            name="quantity"

          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label="原因" name="reason" >
            <Input />
          </Form.Item>
          <Box display="flex" justifyContent="flex-end">
            <Button htmlType="submit" type="primary">
              确定
            </Button>
          </Box>
        </Form>
      </Card>
        {/*<Col> 共<span style={{ color: `#FF0000`}}>{aaa?.total.toString().substring(0,1)}</span>次,剩余<span style={{ color: `#FF0000`}}>{aaa?.total.toString().substring(2,5)}</span>点</Col>*/}
        <Col> <span style={{ color: `#FF0000`}}>{name}</span>转介<span style={{ color: `#FF0000`}}>{time}</span>人,剩余<span style={{ color: `#FF0000`}}>{total}</span>点</Col>

        <Fallback
        state={pointListState}
        initial={<DataTable loading />}
        render={({ data }) => <DataTable dataSource={data} />}
      />
    </Box>
  );
};

export default Changes;
