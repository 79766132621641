import AppTabs from '@/components/AppTabs';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Tab,
} from '@material-ui/core';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import ExpandMoreOutlined from '@material-ui/icons/ExpandMoreOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import React from 'react';
import video from './video.mp4';

export interface ForumProps {}

function Select() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleClick} endIcon={<ExpandMoreOutlined />}>
        全部
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>全部</MenuItem>
        <MenuItem onClick={handleClose}>精华</MenuItem>
        <MenuItem onClick={handleClose}>同班</MenuItem>
        <MenuItem onClick={handleClose}>我的</MenuItem>
      </Menu>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 0,
    border: 'none',
    borderTop: `1px solid ${theme.palette.divider}`,
    '&:first-child': {
      borderTop: 'none',
    },
  },
  actions: {
    padding: '0 8px',
    justifyContent: 'flex-end',
  },
  thumbUpAvatarGroup: {
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
    },
  },
  commentList: {
    '& .MuiAvatar-root': {
      border: '2px solid #fafafa',
      width: 32,
      height: 32,
    },
    '& .MuiListItemAvatar-root': {
      minWidth: 40,
    },
  },
}));

const Forum: React.FC<ForumProps> = () => {
  const classes = useStyles();
  return (
    <AppTabs
      tabs={[
        {
          path: 'text',
          tab: <Tab label="日志"></Tab>,
          panel: (
            <Box>
              <Box margin={1}>
                <Select />
              </Box>
              <Box>
                <Card variant="outlined" className={classes.card}>
                  <CardHeader
                    avatar={<Avatar />}
                    title={
                      <Box display="flex" alignItems="center">
                        <Box color="primary.main" mr={1}>
                          琴大田
                        </Box>
                        <Chip label="Lv.3" size="small" color="primary" />
                      </Box>
                    }
                  />
                  <CardContent>
                    <div>
                      琴道就象金字塔的塔尖，塔基的坚实度决定了塔尖的高度。古琴就是文化，它是通过音乐这一艺术形式来展现，通过古琴这个载道之器来外化自己的境界。
                    </div>
                    <div>
                      听琴是一种享受，弹琴就是一场修为。跟随纪老师学琴，不只是学习琴艺，更重要的是体道悟道。
                    </div>
                  </CardContent>
                  <CardActions className={classes.actions}>
                    <IconButton color="primary">
                      <FavoriteBorderOutlinedIcon />
                    </IconButton>
                    <IconButton color="primary">
                      <ChatOutlinedIcon />
                    </IconButton>
                  </CardActions>
                  <CardContent>
                    <Box mb={1} display="flex" alignItems="center">
                      <Box mr={1} clone>
                        <FavoriteBorderOutlinedIcon
                          fontSize="small"
                          color="primary"
                        />
                      </Box>
                      <AvatarGroup
                        className={classes.thumbUpAvatarGroup}
                        max={10}
                      >
                        <Avatar alt="Remy Sharp" />
                        <Avatar alt="Travis Howard" />
                        <Avatar alt="Cindy Baker" />
                        <Avatar alt="Agnes Walker" />
                        <Avatar alt="Trevor Henderson" />
                      </AvatarGroup>
                    </Box>
                    <Box display="flex">
                      <Box
                        height={32}
                        mr={1}
                        marginTop="12px"
                        flexShrink={0}
                        display="flex"
                        alignItems="center"
                      >
                        <ChatOutlinedIcon fontSize="small" color="primary" />
                      </Box>
                      <List
                        disablePadding
                        dense
                        className={classes.commentList}
                      >
                        <ListItem disableGutters dense alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Box
                                color="primary.main"
                                display="flex"
                                justifyContent="space-between"
                              >
                                刘小武
                                <Box color="text.secondary">
                                  2020-01-02 15:00:02
                                </Box>
                              </Box>
                            }
                            secondary={
                              <Box color="text.primary">
                                琴道就象金字塔的塔尖，塔基的坚实度决定了塔尖的高度。古琴就是文化，它是通过音乐这一艺术形式来展现，通过古琴这个载道之器来外化自己的境界。
                              </Box>
                            }
                          />
                        </ListItem>
                        <ListItem disableGutters dense alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Box
                                color="primary.main"
                                display="flex"
                                justifyContent="space-between"
                              >
                                刘小武
                                <Box color="text.secondary">
                                  2020-01-02 15:00:02
                                </Box>
                              </Box>
                            }
                            secondary={
                              <Box color="text.primary">
                                琴道就象金字塔的塔尖，塔基的坚实度决定了塔尖的高度。古琴就是文化，它是通过音乐这一艺术形式来展现，通过古琴这个载道之器来外化自己的境界。
                              </Box>
                            }
                          />
                        </ListItem>
                      </List>
                    </Box>
                  </CardContent>
                </Card>
                <Card variant="outlined" className={classes.card}>
                  <CardHeader
                    avatar={<Avatar />}
                    title={
                      <Box display="flex" alignItems="center">
                        <Box color="primary.main" mr={1}>
                          琴大田
                        </Box>
                        <Chip label="Lv.3" size="small" color="primary" />
                      </Box>
                    }
                  />
                  <CardContent>
                    <div>
                      琴道就象金字塔的塔尖，塔基的坚实度决定了塔尖的高度。古琴就是文化，它是通过音乐这一艺术形式来展现，通过古琴这个载道之器来外化自己的境界。
                    </div>
                    <div>
                      听琴是一种享受，弹琴就是一场修为。跟随纪老师学琴，不只是学习琴艺，更重要的是体道悟道。
                    </div>
                  </CardContent>
                  <CardActions className={classes.actions}>
                    <IconButton color="primary">
                      <FavoriteBorderOutlinedIcon />
                    </IconButton>
                    <IconButton color="primary">
                      <ChatOutlinedIcon />
                    </IconButton>
                  </CardActions>
                  <CardContent>
                    <Box mb={1} display="flex" alignItems="center">
                      <Box mr={1} clone>
                        <FavoriteBorderOutlinedIcon
                          fontSize="small"
                          color="primary"
                        />
                      </Box>
                      <AvatarGroup
                        className={classes.thumbUpAvatarGroup}
                        max={10}
                      >
                        <Avatar alt="Remy Sharp" />
                        <Avatar alt="Travis Howard" />
                        <Avatar alt="Cindy Baker" />
                        <Avatar alt="Agnes Walker" />
                        <Avatar alt="Trevor Henderson" />
                      </AvatarGroup>
                    </Box>
                    <Box display="flex">
                      <Box
                        height={32}
                        mr={1}
                        marginTop="12px"
                        flexShrink={0}
                        display="flex"
                        alignItems="center"
                      >
                        <ChatOutlinedIcon fontSize="small" color="primary" />
                      </Box>
                      <List
                        disablePadding
                        dense
                        className={classes.commentList}
                      >
                        <ListItem disableGutters dense alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Box
                                color="primary.main"
                                display="flex"
                                justifyContent="space-between"
                              >
                                刘小武
                                <Box color="text.secondary">
                                  2020-01-02 15:00:02
                                </Box>
                              </Box>
                            }
                            secondary={
                              <Box color="text.primary">
                                琴道就象金字塔的塔尖，塔基的坚实度决定了塔尖的高度。古琴就是文化，它是通过音乐这一艺术形式来展现，通过古琴这个载道之器来外化自己的境界。
                              </Box>
                            }
                          />
                        </ListItem>
                        <ListItem disableGutters dense alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Box
                                color="primary.main"
                                display="flex"
                                justifyContent="space-between"
                              >
                                刘小武
                                <Box color="text.secondary">
                                  2020-01-02 15:00:02
                                </Box>
                              </Box>
                            }
                            secondary={
                              <Box color="text.primary">
                                琴道就象金字塔的塔尖，塔基的坚实度决定了塔尖的高度。古琴就是文化，它是通过音乐这一艺术形式来展现，通过古琴这个载道之器来外化自己的境界。
                              </Box>
                            }
                          />
                        </ListItem>
                      </List>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Box>
          ),
        },
        {
          path: 'video',
          tab: <Tab label="视频"></Tab>,
          panel: (
            <Box>
              <Box>
                <Card variant="outlined" className={classes.card}>
                  <CardHeader
                    avatar={<Avatar />}
                    title={
                      <Box display="flex" alignItems="center">
                        <Box color="primary.main" mr={1}>
                          琴大田
                        </Box>
                        <Chip label="Lv.3" size="small" color="primary" />
                      </Box>
                    }
                  />
                  <Box width="100%" clone>
                    <video src={video} controls />
                  </Box>
                  <CardActions className={classes.actions}>
                    <IconButton color="primary">
                      <FavoriteBorderOutlinedIcon />
                    </IconButton>
                    <IconButton color="primary">
                      <ChatOutlinedIcon />
                    </IconButton>
                  </CardActions>
                  <CardContent>
                    <Box mb={1} display="flex" alignItems="center">
                      <Box mr={1} clone>
                        <FavoriteBorderOutlinedIcon
                          fontSize="small"
                          color="primary"
                        />
                      </Box>
                      <AvatarGroup
                        className={classes.thumbUpAvatarGroup}
                        max={10}
                      >
                        <Avatar alt="Remy Sharp" />
                        <Avatar alt="Travis Howard" />
                        <Avatar alt="Cindy Baker" />
                        <Avatar alt="Agnes Walker" />
                        <Avatar alt="Trevor Henderson" />
                      </AvatarGroup>
                    </Box>
                    <Box display="flex">
                      <Box
                        height={32}
                        mr={1}
                        marginTop="12px"
                        flexShrink={0}
                        display="flex"
                        alignItems="center"
                      >
                        <ChatOutlinedIcon fontSize="small" color="primary" />
                      </Box>
                      <List
                        disablePadding
                        dense
                        className={classes.commentList}
                      >
                        <ListItem disableGutters dense alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Box
                                color="primary.main"
                                display="flex"
                                justifyContent="space-between"
                              >
                                刘小武
                                <Box color="text.secondary">
                                  2020-01-02 15:00:02
                                </Box>
                              </Box>
                            }
                            secondary={
                              <Box color="text.primary">
                                琴道就象金字塔的塔尖，塔基的坚实度决定了塔尖的高度。古琴就是文化，它是通过音乐这一艺术形式来展现，通过古琴这个载道之器来外化自己的境界。
                              </Box>
                            }
                          />
                        </ListItem>
                        <ListItem disableGutters dense alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Box
                                color="primary.main"
                                display="flex"
                                justifyContent="space-between"
                              >
                                刘小武
                                <Box color="text.secondary">
                                  2020-01-02 15:00:02
                                </Box>
                              </Box>
                            }
                            secondary={
                              <Box color="text.primary">
                                琴道就象金字塔的塔尖，塔基的坚实度决定了塔尖的高度。古琴就是文化，它是通过音乐这一艺术形式来展现，通过古琴这个载道之器来外化自己的境界。
                              </Box>
                            }
                          />
                        </ListItem>
                      </List>
                    </Box>
                  </CardContent>
                </Card>
                <Card variant="outlined" className={classes.card}>
                  <CardHeader
                    avatar={<Avatar />}
                    title={
                      <Box display="flex" alignItems="center">
                        <Box color="primary.main" mr={1}>
                          琴大田
                        </Box>
                        <Chip label="Lv.3" size="small" color="primary" />
                      </Box>
                    }
                  />
                  <Box width="100%" clone>
                    <video src={video} controls />
                  </Box>
                  <CardActions className={classes.actions}>
                    <IconButton color="primary">
                      <FavoriteBorderOutlinedIcon />
                    </IconButton>
                    <IconButton color="primary">
                      <ChatOutlinedIcon />
                    </IconButton>
                  </CardActions>
                  <CardContent>
                    <Box mb={1} display="flex" alignItems="center">
                      <Box mr={1} clone>
                        <FavoriteBorderOutlinedIcon
                          fontSize="small"
                          color="primary"
                        />
                      </Box>
                      <AvatarGroup
                        className={classes.thumbUpAvatarGroup}
                        max={10}
                      >
                        <Avatar alt="Remy Sharp" />
                        <Avatar alt="Travis Howard" />
                        <Avatar alt="Cindy Baker" />
                        <Avatar alt="Agnes Walker" />
                        <Avatar alt="Trevor Henderson" />
                      </AvatarGroup>
                    </Box>
                    <Box display="flex">
                      <Box
                        height={32}
                        mr={1}
                        marginTop="12px"
                        flexShrink={0}
                        display="flex"
                        alignItems="center"
                      >
                        <ChatOutlinedIcon fontSize="small" color="primary" />
                      </Box>
                      <List
                        disablePadding
                        dense
                        className={classes.commentList}
                      >
                        <ListItem disableGutters dense alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Box
                                color="primary.main"
                                display="flex"
                                justifyContent="space-between"
                              >
                                刘小武
                                <Box color="text.secondary">
                                  2020-01-02 15:00:02
                                </Box>
                              </Box>
                            }
                            secondary={
                              <Box color="text.primary">
                                琴道就象金字塔的塔尖，塔基的坚实度决定了塔尖的高度。古琴就是文化，它是通过音乐这一艺术形式来展现，通过古琴这个载道之器来外化自己的境界。
                              </Box>
                            }
                          />
                        </ListItem>
                        <ListItem disableGutters dense alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Box
                                color="primary.main"
                                display="flex"
                                justifyContent="space-between"
                              >
                                刘小武
                                <Box color="text.secondary">
                                  2020-01-02 15:00:02
                                </Box>
                              </Box>
                            }
                            secondary={
                              <Box color="text.primary">
                                琴道就象金字塔的塔尖，塔基的坚实度决定了塔尖的高度。古琴就是文化，它是通过音乐这一艺术形式来展现，通过古琴这个载道之器来外化自己的境界。
                              </Box>
                            }
                          />
                        </ListItem>
                      </List>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Box>
          ),
        },
      ]}
    />
  );
};

export default Forum;
