import { CategoryModule } from '@/components/CategorySelect';
import Fallback from '@/components/Fallback';
import SearchSelect from '@/components/SearchSelect';
import useAsyncFn from '@/hooks/useAsyncFn';
import { encodeQueryParams, useQueryParams } from '@/services/api';
import {
  QuerySeeCode,
} from '@/services/admin';
import {Form, Input, Table} from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import {Button, CardActions} from "@material-ui/core";
import {FieldData} from "rc-field-form/es/interface";
import format from "date-fns/format";

export default function SeeCode() {
  const [form] = Form.useForm();
  // const params = useQueryParams();
  const [state, fetch] = useAsyncFn(QuerySeeCode);


  React.useEffect(() => {
    fetch({});
  }, [fetch]);
  return (
      <>
    <Fallback
      state={state}
      // render={({ data }) => (
          render={(data) => (

              <Form
                  form={form}
                  layout="vertical"
                  onFinish={(values) => fetch({ filter: {values} })}
              >

                  <Form.Item name="yid">
                      <Input  type="hidden"/>
                  </Form.Item>

          <Table
            rowKey="id"
            size="small"
            bordered
            style={{ minWidth: '100%' }}
            dataSource={data}
            columns={[
              {
                dataIndex: 'id',
                title: '#',
              },
              {
                dataIndex: 'cid',
                title: '活动id',
              },
              {
                dataIndex: 'uid',
                title: '学号',
              },
              {
                dataIndex: 'username',
                title: '姓名',
              },
              {
                dataIndex: 'code',
                title: '生成记录',
              },
                {
                    dataIndex: 'createdAt',
                    title: '生成时间',
                    render: (createdAt) =>
                        format(new Date(createdAt), 'yyyy-MM-dd HH:mm:ss'),
                },
              {
                dataIndex: 'state',
                title: '状态',

                  render: (value,record) => (
                      // <Link to={`/admin/seeCode`}>
                      //
                      //     {value === 0 ? (
                      //         `未使用`
                      //
                      //     ) : (
                      //         '已使用'
                      //     )}
                      //
                      // </Link>
                      value === 0 ? (
                          <Button
                              size="small"
                              color="primary"
                              // onMouseMove={() =>form.setFieldsValue(record)}
                              onMouseMove={() =>form.setFieldsValue({yid:record.id})}
                              onClick={() =>form.submit()}
                          >
                              未使用
                          </Button>
                      ) : (
                          <Button
                              size="small"
                              color="primary"
                              disabled
                          >
                        已使用
                        </Button>
                      )


                // <Button
                //     size="small"
                //     color="primary"
                //     // onMouseMove={() =>form.setFieldsValue(record)}
                //     onMouseMove={() =>form.setFieldsValue({name:record.id})}
                //     onClick={() =>form.submit()}
                // >
                //     {value === 0 ? (
                //         `未使用`
                //     ) : (
                //         '已使用'
                //     )}
                // </Button>

                  ),

              },
            ]}
          />
              </Form>
      )}
    />
      </>
  );

}
