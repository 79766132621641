import {
  createSubmitModal,
  createSubmitTrigger,
} from '@/components/ResourceManage';
import SearchSelect from '@/components/SearchSelect';
import {ClassResource, CourseResource} from '@/services/admin';
import { UserCourse } from '@/services/entity';
import {Affix, Button, Form, Radio, Tag} from 'antd';
import { FormProps } from 'antd/lib/form';
import React from 'react';
import { SmileOutlined } from '@ant-design/icons';
import FormatedDatePicker from '@/components/FormatedDatePicker';

const RecordForm: React.FC<FormProps> = (props) => {
  return (
    <Form labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }} {...props}>
      <Form.Item label="课表6" name="courseID" rules={[{ required: true }]}>
        <SearchSelect
          query={CourseResource.query}
          map={({ id, name }) => ({ value: id, label: name })}
        />
      </Form.Item>
          <Form.Item label="开课时间" name="courseStartTime" rules={[]}>
              <FormatedDatePicker />
          </Form.Item>

        <Form.Item label="提示">
            <Tag color="red">学琴入门、一阶、二阶课程,结课时间无需填写</Tag>
        </Form.Item>

        <Form.Item label="结课时间:" name="courseEndTime" rules={[]} >
                <FormatedDatePicker/>
        </Form.Item>


        <Form.Item label="班级" name="classID" rules={[]}>
            <SearchSelect
                allowEmptyValue
                query={ClassResource.query}
                map={({ id, name, teacher, assistant }) => ({
                    value: id,
                    label: `${name} 琴师:${assistant?.realname}, 客服:${teacher?.realname}`,
                })}
            />
        </Form.Item>



        <Form.Item
            label="状态"
            name="courseState"
            initialValue="zaixue"
            rules={[{ required: true }]}
        >
            <Radio.Group>
                <Radio.Button value="zaixue">学习</Radio.Button>
                <Radio.Button value="zanting">停课</Radio.Button>
                <Radio.Button value="yankai">延开</Radio.Button>
                <Radio.Button value="dongjie">冻结</Radio.Button>
                <Radio.Button value="tiqian">到期-续课在学</Radio.Button>
                <Radio.Button value="yanxue">待开-前课未完</Radio.Button>
                <Radio.Button value="jinjie">已进阶</Radio.Button>
                <Radio.Button value="yanqi">延期</Radio.Button>
            </Radio.Group>
        </Form.Item>
    </Form>
  );
};

export const SubmitModal = createSubmitModal<UserCourse>(RecordForm);
export const SubmitTrigger = createSubmitTrigger(SubmitModal);

export default RecordForm;
