import { AppTab } from '@/components/AppTabs';
import Fallback from '@/components/Fallback';
import {
  createSubmitFab,
  createSubmitModal,
  createSubmitTrigger,
  TotalLabel,
} from '@/components/ResourceManage';
import useAsyncFn, { AsyncState } from '@/hooks/useAsyncFn';
import {ContactProfileResource, DelUserResource} from '@/services/admin';
import { ContactProfile } from '@/services/entity';
import { makeStyles, Tab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
    Button,
    Card,
    Form,
    Input,
    InputNumber, Popconfirm,
    Radio,
    Select,
    Space,
    Table,
} from 'antd';
import { FormProps } from 'antd/lib/form';
import { TableProps } from 'antd/lib/table';
import format from 'date-fns/format';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import SourceSelect from './SourceSelect';
import {comparator} from "ramda";

type Entity = ContactProfile;
const resource = ContactProfileResource;

const { TextArea } = Input;

const RecordForm: React.FC<FormProps> = (props) => {
  return (
    <Form labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }} {...props}>
      <Form.Item label="姓名" name="realname" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="性别" name="gender">
        <Radio.Group>
          <Radio.Button value="male">&nbsp;男&nbsp;</Radio.Button>
          <Radio.Button value="female">&nbsp;女&nbsp;</Radio.Button>
        </Radio.Group>
      </Form.Item>

        <Form.Item label="报名" name="signup" >
            <Radio.Group>
                <Radio.Button value="0">待联络</Radio.Button>
                <Radio.Button value="2">未报名</Radio.Button>
                <Radio.Button value="1">已报名</Radio.Button>
                <Radio.Button value="3">联系中</Radio.Button>
                <Radio.Button value="4">无效</Radio.Button>
            </Radio.Group>
        </Form.Item>
      <Form.Item label="来源" name="sourceID">
        <SourceSelect />
      </Form.Item>
      <Form.Item label="电话" name="mobile">
        <Input />
      </Form.Item>
      <Form.Item label="微信" name="wechat">
        <Input />
      </Form.Item>
      <Form.Item label="地址" name="address1">
        <Input />
      </Form.Item>
      <Form.Item label="年龄" name="age">
        {/*<InputNumber />*/}
          <Input />
      </Form.Item>
      {/*<Form.Item label="职业" name="occupation">*/}
      {/*  <Input />*/}
      {/*</Form.Item>*/}
      {/*<Form.Item label="爱好" name="hobby">*/}
      {/*  <Input />*/}
      {/*</Form.Item>*/}
      <Form.Item label="基础" name="state">
        <Input />
      </Form.Item>

        <Form.Item label="备注" name="remark">
            <TextArea rows={4} />
        </Form.Item>
    </Form>
  );
};

interface DataTableProps extends TableProps<Entity> {
  onCreate?: () => void;
}
const DataTable: React.FC<DataTableProps> = ({ onCreate, ...rest }) => {
  const match = useRouteMatch();

  return (
    <Table
      {...rest}
      rowKey="id"
      bordered
      size="small"
      columns={[
        {
          dataIndex: 'id',
          title: '#',
        },
        {
          dataIndex: 'realname',
          title: '姓名',
          render: (text, record) => (
            <Link to={`${match.path}/${record.id}`}>{text}</Link>
          ),
        },
        { dataIndex: 'mobile', title: '电话' },
        {
          dataIndex: 'address',
          title: '来源',
        },


          {
              dataIndex: 'state',
              title: '基础',
              // render: (value) => (
              //     value === 'yes' ? (
              //         `零`
              //     ) : (
              //         '有'
              //     )
              // ),
          },

          {
              dataIndex: 'signup',
              title: '报名',
              sorter: comparator((a, b) => a.signup < b.signup),
              render: (value) => (
                  value === "1" ? (
                      <Button type="link" danger>已报名</Button>
                  ) : (
                      value === "0" ? (
                          <Button type="text" >待联络</Button>
                      ) : (
                                  value === "2" ? (
                                  <Button type="text" >未报名</Button>
                              ) : (
                                      value === "3" ? (
                                          <Button type="text" >联系中</Button>
                                      ) : (
                                          <Button  type="text">无效</Button>
                                      )
                              )
                      )
                  )
              ),
          },
        {
          dataIndex: 'createdAt',
          title: '时间',
          render: (createdAt) =>
            format(new Date(createdAt), 'yyyy-MM-dd HH:mm:ss'),
        },
        {
          title: '操作',
          render: (_, record) => {
            return (
              <SubmitTrigger
                ModalProps={{
                  title: '编辑表单',
                  centered: true,
                  submit: resource.update(record.id),
                  onSubmitted: onCreate,
                  FormProps: { initialValues: record },
                }}
                render={({ open }) => (
                  <Button type="link" onClick={open}>
                    编辑
                  </Button>
                )}
              />
            );
          },
        },

          {
              title: '删除',
              dataIndex: 'id',
              render: (_, record) =>
                  <Popconfirm
                      title="注意:删除,将不可恢复?"
                      onConfirm={async () => {
                          await resource.delete(record.id);
                          window.location.reload();
                      }}
                  >
                      <Button type="link" danger>删除</Button>
                  </Popconfirm>
          },
      ]}
    />
  );
};

const TabPanel: React.FC<{
  state: AsyncState<{ total: number; data: Entity[] }>;
  fetch: typeof resource.query;
}> = ({ state, fetch }) => {
  const [form] = Form.useForm();
  return (
    <div>
      <Card style={{ marginBottom: 8 }}>
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            fetch({ filter: values });
          }}
        >
          <Form.Item label="状态" name="state">
            <Select
              placeholder="待联系"
              options={[
                { value: 'uncontacted', label: '待联系' },
                { value: 'paid', label: '已缴费' },
              ]}
              allowClear
            />
          </Form.Item>
          <Form.Item label="姓名" name="$search">
            <Input placeholder="请输入姓名" />
          </Form.Item>
          <Form.Item style={{ textAlign: 'right' }}>
            <Space>
              <Button htmlType="reset" onClick={() => form.resetFields()}>
                重置
              </Button>
              <Button type="primary" htmlType="submit" disabled={state.loading}>
                查询
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
      <Fallback
        state={state}
        initial={<DataTable loading />}
        render={({ data }, loading) => {
          return (
            <DataTable
              dataSource={data}
              loading={loading}
              onCreate={state.retry}
            />
          );
        }}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const SubmitModal = createSubmitModal<Entity>(RecordForm);
export const SubmitTrigger = createSubmitTrigger(SubmitModal);
const SubmitFab = createSubmitFab(SubmitTrigger);

function useProfileTab(): AppTab {
  const classes = useStyles();
  const [state, fetch] = useAsyncFn(resource.query, []);
  React.useEffect(() => {
    fetch({});
  }, [fetch]);

  return {
    path: `profiles`,
    tab: <Tab label={<TotalLabel state={state}>表单管理</TotalLabel>} />,
    fab: (
      <SubmitFab
        ModalProps={{
          title: '创建表单',
          centered: true,
          submit: ContactProfileResource.create,
          onSubmitted: state.retry,
        }}
        className={classes.fab}
      >
        <AddIcon />
      </SubmitFab>
    ),
    panel: <TabPanel state={state} fetch={fetch} />,
  };
}

export default useProfileTab;
