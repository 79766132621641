import { makeStyles } from '@material-ui/core';
import BaseBottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined';
import FormatListNumberedOutlinedIcon from '@material-ui/icons/FormatListNumberedOutlined';
import TextsmsOutlinedIcon from '@material-ui/icons/TextsmsOutlined';
import ShoppingCart from '@material-ui/icons/ShoppingCart';

import React from 'react';
import { useHistory } from 'react-router-dom';

interface BottomNavigationProps {
  value?: number;
}

const useStyles = makeStyles((theme) =>({
  root: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    zIndex: theme.zIndex.modal - 1,
  }
}))

const BottomNavigation: React.FC<BottomNavigationProps> = ({ value }) => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <BaseBottomNavigation className={classes.root} value={value} showLabels>
      <BottomNavigationAction
        onClick={() => history.push('/')}
        label="课程"
        icon={<ClassOutlinedIcon />}
      />

        <BottomNavigationAction
            onClick={() => history.push('/shoppingmall')}
            label="商城"
            icon={<ShoppingCart />}
        />

      <BottomNavigationAction
        onClick={() => history.push('/leaderboard')}
        label="榜单"
        icon={<FormatListNumberedOutlinedIcon />}
      />

        {/*<BottomNavigationAction*/}
        {/*    onClick={() => history.push('/forum')}*/}
        {/*    label="社区"*/}
        {/*    icon={<TextsmsOutlinedIcon />}*/}
        {/*/>*/}





    </BaseBottomNavigation>


  );
};

export default BottomNavigation;
