import { AppTab } from '@/components/AppTabs';
import DateTime from '@/components/DateTime';
import Fallback from '@/components/Fallback';
import {
    createSubmitFab,
    createSubmitModal,
    createSubmitTrigger,
    TotalLabel,
} from '@/components/ResourceManage';
import SearchSelect from '@/components/SearchSelect';
import useAsyncFn, { AsyncState } from '@/hooks/useAsyncFn';
import {SearchHuman, CourseResource, Student, UserResource} from '@/services/admin';
import {Box, makeStyles, Tab} from '@material-ui/core';
import {Badge, Button, Card, Col, Form, Input, Radio, Row, Select, Space, Table, Modal, message} from 'antd';
import { FormProps } from 'antd/lib/form';
import { TableProps } from 'antd/lib/table';
import format from 'date-fns/format';
import React from 'react';
import {comparator} from "ramda";
import {differenceInDays} from "date-fns";
import {Link} from "react-router-dom";
import { TicketResource } from '@/services/teacher';
import {updateContent} from "@/services/user";
import {Role, TiXin, User} from "@/services/entity";
import {QueryFn} from "@/services/api";

const resource = TicketResource;
type Entity = TiXin;

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

// const RecordForm: React.FC<FormProps> = (props) => {
//     return (
//         <Form size="large" labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }} {...props}>
//             <Form.Item label="类型" name="categoryID" rules={[{ required: true }]}>
//                 <CategorySelect module={CategoryModule.ContactSource} />
//             </Form.Item>
//             <Form.Item label="渠道" name="channel" rules={[{ required: true }]}>
//                 <Input />
//             </Form.Item>
//         </Form>
//     );
// };

// const SubmitModal = createSubmitModal<Entity>(RecordForm);
// const SubmitTrigger = createSubmitTrigger(SubmitModal);
// const SubmitFab = createSubmitFab(SubmitTrigger);

interface DataTableProps extends TableProps<Entity> {
    onCreate?: () => void;
}


const renderState = (value: string) => {
    switch (value) {
        case 'yankai':
            return <Badge status="processing" text="延开" color="orange"/>;
        case 'zaixue':
            return <Badge status="success" text="学习" color="red"/>;
        case 'dongjie':
            return <Badge status="error" text="冻结" color="cyan"/>;

        case 'zanting':
            return <Badge status="default" text="停课" color="green"/>;
        case 'yanxue':
            return <Badge status="warning" text="待开-前课未完" color="blue"/>;
        case 'tiqian':
            return <Badge status="warning" text="到期-续课在学" color="pink"/>;
        case 'jinjie':
            return <Badge status="warning" text="已进阶" color="purple"/>;
        case 'yanqi':
            return <Badge status="warning" text="延期" color="gold"/>;
        default:
            return null;
    }
};


//

interface Values {
    id: string;
    description: string;
}

interface CollectionCreateFormProps {
    visible: boolean;
    onCreate: () => void;
    onCancel: () => void;
}


const DataTable: React.FC<DataTableProps> = ({ ...rest }) => {

    const [visible, setVisible] = React.useState(false);

    const onCreate = () => {
        setVisible(false);
    };


    return (

        <Table
            rowKey="id"
            bordered
            size="small"
            style={{ minWidth: '100%' }}
            {...rest}
            columns={[
                {
                    dataIndex: 'realname',
                    title: '姓名',
                    sorter: comparator((a, b) => a.realname < b.realname),
                    render: (value, record) => (
                        <Link
                            to={`/admin/student/students?filter={"%24search"%3A"${value}"}`}
                        >
                            {value}
                        </Link>
                    ),
                },
                {
                    dataIndex: 'courseState',
                    title: '状态',
                    sorter: comparator((a, b) => a.courseState < b.courseState),
                    render: (_, record) => (
                        <>
                            <br />
                            {renderState(record.courseState)}
                        </>
                    ),
                    // render: (category) => category.name,
                },
                {
                    dataIndex: 'name',
                    title: '班级',
                    sorter: comparator((a, b) => a.name < b.name),
                },
                {
                    dataIndex: 'assistantName',
                    title: '琴师',
                    sorter: comparator((a, b) => a.assistantName < b.assistantName),
                },
                {
                    dataIndex: 'lessonName',
                    title: '课程',
                },

                {
                    dataIndex: 'firstAt',
                    title: '完成时间',
                    render: (value) => value.toString() === '0001-01-01T00:00:00Z' ? (``):(<DateTime value={value} />),
                },
                {
                    dataIndex: 'updatedAt',
                    title: '提醒时间',
                    render: (value) => value.toString() === '0001-01-01T00:00:00Z' ? (``):(<DateTime value={value} />),
                },

                {
                    dataIndex: 'state',
                    title: '提醒',
                    sorter: comparator((a, b) => a.state < b.state),
                    render: (value, record) =>
                        value === 'pending' ? (
                            // <Link to={`/teacher/students/courses/`}>待处理</Link>

                            <Link to={`/teacher/students/${record.authorID}/courses/${record.userCourseID}`}>待处理</Link>
                        ) : (


                    value === 'completed' ? ('已处理') : (``)

                        ),
                },
            ]}
        />




    );
};

const TabPanel1: React.FC<{
    state: AsyncState<{ total: number; data: Entity[] }>;
    fetch: typeof resource.query;
}> = ({ state, fetch }) => {
    const [form] = Form.useForm();
    const aaa =state.value;
    return (
        <div>
            <Card style={{ marginBottom: 8 }}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={(values) => fetch({ filter: values })}
                >
                    <Form.Item label="时间" name="timelimit">
                        <Select placeholder="全部状态" allowClear>
                            <Select.Option value="<24H">&lt;24H</Select.Option>
                            <Select.Option value="≥24H">≥24H</Select.Option>
                        </Select>
                    </Form.Item>


                    <Form.Item label="状态" name="state">
                        <Select placeholder="全部状态" allowClear>
                            <Select.Option value="1">有提醒</Select.Option>
                            <Select.Option value="0">无提醒</Select.Option>
                        </Select>
                    </Form.Item>


                    <Form.Item label="琴师" name="assistantID">
                        <SearchSelect
                            query={
                                (({ filter, ...rest }) =>
                                    UserResource.query({
                                        ...rest,
                                        filter: { ...filter, role: Role.Assistant },
                                    })) as QueryFn<User>
                            }
                            map={({ id, realname }) => ({ value: id, label: realname })}
                            placeholder="全部琴师"
                            allowClear
                        />
                    </Form.Item>

                    <Form.Item style={{ textAlign: 'right' }}>
                        <Space>
                            <Button htmlType="reset" onClick={() => form.resetFields()}>
                                重置
                            </Button>
                            <Button type="primary" htmlType="submit" disabled={state.loading}>
                                查询
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>

            </Card>
            <Col>{aaa?.total ? ( <Col>{aaa?.total}人</Col>):(``)}</Col>
            <Fallback
                state={state}
                initial={<DataTable loading />}
                render={({ data }) => {

                    return (

                        <DataTable
                            dataSource={data}
                            loading={state.loading}
                            onCreate={state.retry}
                        />

                    );
                }}
            />
        </div>
    );
};

function useProfileTab(): AppTab {
    const [state, fetch] = useAsyncFn(resource.query, []);
    React.useEffect(() => {
        fetch({});
    }, [fetch]);

    return {
        path: ``,
        tab: <Tab label={<TotalLabel state={state}>提醒</TotalLabel>} />,
        // fab: (
        //     <SubmitFab
        //     >
        //     </SubmitFab>
        // ),
        panel: <TabPanel1  state={state} fetch={fetch} />,
    };
}

export default useProfileTab;
