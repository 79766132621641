import { api } from './api';
import Axios from 'axios';

const axios = Axios.create({ baseURL: '/api/auth' });

export const login = api<{ username: string; password: string }, void>(
  (params) => axios.post('/login', params)
);

export const logout = api<void, void>(
  () => axios.post('/logout')
);
