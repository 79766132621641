import { ListSubheader } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined';
import ContactsOutlinedIcon from '@material-ui/icons/ContactsOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';

import TextureIcon from '@material-ui/icons/Texture';

import EventNote from '@material-ui/icons/EventNote';


import SYNC  from '@material-ui/icons/Sync';
import Reorder from '@material-ui/icons/Reorder';

import Visibility from '@material-ui/icons/Visibility';
import List1 from '@material-ui/icons/List';

import FindInPage from '@material-ui/icons/FindInPage';


import TripOrigin from '@material-ui/icons/TripOrigin'

import MoneyOff from '@material-ui/icons/MoneyOff'


import TodayIcon from '@material-ui/icons/Today';
import React from 'react';
import { Link } from 'react-router-dom';
import { useProfile } from './pages/Profile/context';
import { TeacherMenuItem } from './pages/Teacher/MenuItem';
import { Role } from './services/entity';
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import {MenuBook} from "@material-ui/icons";

function AdminListItem() {
  return (
    <>
      <Divider />
      <List
        component="div"
        disablePadding
        subheader={
          <ListSubheader disableSticky component="div">
            管理
          </ListSubheader>
        }
      >
        <ListItem button component={Link} to="/admin">
          <ListItemIcon>
            <DashboardOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="总览" />
        </ListItem>
        <ListItem button component={Link} to="/admin/contact">
          <ListItemIcon>
            <ContactsOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="接新" />
        </ListItem>

        <ListItem button component={Link} to="/admin/source">
          <ListItemIcon>
            <TripOrigin />
          </ListItemIcon>
          <ListItemText primary="来源管理" />
        </ListItem>

        <ListItem button component={Link} to="/admin/exchange">
          <ListItemIcon>
            <MoneyOff />
          </ListItemIcon>
          <ListItemText primary="琴贝兑换" />
        </ListItem>

        <ListItem button component={Link} to="/admin/student">
          <ListItemIcon>
            <SchoolOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="学生" />
        </ListItem>
        <ListItem button component={Link} to="/admin/course">
          <ListItemIcon>
            <ClassOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="课程" />
        </ListItem>
        <ListItem button component={Link} to="/admin/practice">
          <ListItemIcon>
            <AccessTimeOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="练习" />
        </ListItem>
        <ListItem button component={Link} to="/teacher/tickets">
          <ListItemIcon>
            <NotificationsOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="待开列表" />
        </ListItem>
        {/*<ListItem button component={Link} to="/admin/announcement">*/}
        {/*  <ListItemIcon>*/}
        {/*    <AnnouncementOutlinedIcon />*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText primary="公告" />*/}
        {/*</ListItem>*/}



        <ListItem button component={Link} to="/admin/mall">
          <ListItemIcon>
            <LocalMallOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="商城" />
        </ListItem>



        {/*<ListItem button component={Link} to="/admin/report">*/}
        {/*  <ListItemIcon>*/}
        {/*    <AssessmentOutlinedIcon />*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText primary="报表" />*/}
        {/*</ListItem>*/}

        {/*<ListItem button component={Link} to="/admin/invitationCode">*/}
        {/*  <ListItemIcon>*/}
        {/*    <TextureIcon />*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText primary="活动发布" />*/}
        {/*</ListItem>*/}


        {/*<ListItem button component={Link} to="/admin/seeCode">*/}
        {/*  <ListItemIcon>*/}
        {/*    <Visibility />*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText primary="查看邀请码" />*/}
        {/*</ListItem>*/}

        {/*<ListItem button component={Link} to="/admin/promotionList">*/}
        {/*  <ListItemIcon>*/}
        {/*    <List1 />*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText primary="历史活动" />*/}
        {/*</ListItem>*/}

        <ListItem button component={Link} to="/admin/searchHuman">
          <ListItemIcon>
            <FindInPage />
          </ListItemIcon>
          <ListItemText primary="搜索筛选" />
        </ListItem>


        <ListItem button component={Link} to="/admin/passNote">
          <ListItemIcon>
            <EventNote />
          </ListItemIcon>
          <ListItemText primary="笔记" />
        </ListItem>


        <ListItem button component={Link} to="/admin/zhuanJie">
          <ListItemIcon>
            <SYNC />
          </ListItemIcon>
          <ListItemText primary="转介" />
        </ListItem>


        <ListItem button component={Link} to="/admin/horselamp">
          <ListItemIcon>
            <Reorder />
          </ListItemIcon>
          <ListItemText primary="通知" />
        </ListItem>

      </List>
    </>
  );
}



function AssistantListItem() {
  return (
    <>
      <Divider />
      <List
        component="div"
        disablePadding
        subheader={
          <ListSubheader disableSticky component="div">
            琴师工作台
          </ListSubheader>
        }
      >




        <ListItem button component={Link} to="/assistant">
          <ListItemIcon>
            <DashboardOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="数据总览" />

        </ListItem>
        <ListItem button component={Link} to="/assistant/classes">
          <ListItemIcon>
            <TodayIcon />
          </ListItemIcon>
          <ListItemText>日常辅导</ListItemText>
        </ListItem>

        {/*<ListItem button component={Link} to="/assistant/students/normal">*/}
        {/*  <ListItemIcon>*/}
        {/*    <SchoolOutlinedIcon />*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText>学生列表</ListItemText>*/}
        {/*</ListItem>*/}


        <ListItem button component={Link} to="/teacher/classes1">
          <ListItemIcon>
            <SupervisedUserCircleOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="班级进度" />
        </ListItem>

        {/*<ListItem button component={Link} to="/assistant/students/suspended">*/}
        {/*  <ListItemIcon>*/}
        {/*    <PauseCircleOutlineIcon />*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText>暂停列表</ListItemText>*/}
        {/*</ListItem>*/}

      </List>
    </>
  );
}

export interface RoleListItemProps {}

const RoleListItem: React.FC<RoleListItemProps> = () => {
  const { profile } = useProfile();
  const roles = React.useMemo(() => {
    return new Set(profile.roles.map(({ name }) => name));
  }, [profile]);

  return (
    <>
      {roles.has(Role.Admin) && <AdminListItem />}
      {roles.has(Role.Teacher) && <TeacherMenuItem />}
      {roles.has(Role.Assistant) && <AssistantListItem />}
    </>
  );
};

export default RoleListItem;
