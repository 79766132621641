import {
  createSubmitModal,
  createSubmitTrigger,
} from '@/components/ResourceManage';
import SearchSelect from '@/components/SearchSelect';
import { UserCourse } from '@/services/entity';
import { CourseResource } from '@/services/teacher';
import { Form } from 'antd';
import { FormProps } from 'antd/lib/form';
import React from 'react';

const RecordForm: React.FC<FormProps> = (props) => {
  return (
    <Form labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }} {...props}>
      <Form.Item label="课表" name="courseID" rules={[{ required: true }]}>
        <SearchSelect
          query={CourseResource.query}
          map={({ id, name }) => ({ value: id, label: name })}
        />
      </Form.Item>
    </Form>
  );
};

export const SubmitModal = createSubmitModal<UserCourse>(RecordForm);
export const SubmitTrigger = createSubmitTrigger(SubmitModal);

export default RecordForm;
