import Fallback from '@/components/Fallback';
import LearnStateChip from '@/components/LearnStateChip';
import LinearProgressWithLabel from '@/components/LinearProgressWithLabel';
import useAsync from '@/hooks/useAsync';
import { startUserCourse, UserCourseResource } from '@/services/assistant';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { SubmitTrigger } from './RecordForm';
import {Badge, Col, Row} from "antd";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
}));

interface DeleteConrifmProps {
  onConfirm: () => void;
}
function DeleteConrifm({ onConfirm }: DeleteConrifmProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button size="small" color="primary" onClick={handleClickOpen}>
        删除
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>确认删除该学生课程？</DialogTitle>
        <DialogContent>
          <DialogContentText>
            学生课程删除后会丢失该课程下的所有学习进度，不可恢复。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            取消
          </Button>
          <Button
            onClick={() => {
              handleClose();
              onConfirm();
            }}
            color="primary"
            autoFocus
          >
            确认
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}


const renderState = (value: string) => {
  switch (value) {
    case 'yankai':
      return <Badge status="processing" text="延开" color="gold"/>;
    case 'zaixue':
      return <Badge status="success" text="学习" color="green"/>;
    case 'dongjie':
      return <Badge status="error" text="冻结" color="cyan"/>;

    case 'zanting':
      return <Badge status="default" text="停课" color="red"/>;
    case 'yanxue':
      return <Badge status="warning" text="待开-前课未完" color="blue"/>;
    case 'tiqian':
      return <Badge status="warning" text="到期-续课在学" color="pink"/>;
    case 'jinjie':
      return <Badge status="warning" text="已进阶" color="purple"/>;
    case 'yanqi':
      return <Badge status="warning" text="延期" color="gold"/>;
    default:
      return null;
  }
};

export interface UserCoursePageProps {}

const UserCoursePage: React.FC<UserCoursePageProps> = () => {
  const classes = useStyles();
  const params = useParams<{ id: string }>();
  const userID = parseInt(params.id);
  const state = useAsync(
    () => UserCourseResource.query({ filter: { userID } }),
    [userID]
  );

  return (
    <Fallback
      state={state}
      initial={null}
      render={(items) => {
        return (




          <Box flex={1}>
            {items.data.map((item,i)=>{
              if (i==0){
                return(
                    // <Button size="small" color="primary">{item.username}的课程</Button>
                <Row>
                  <Col xs={8}>{item.username}的课程</Col>
                </Row>
                )
              }
            })}

            {items.data.map((item) => (

              <Card key={item.id} className={classes.card} variant="outlined">
                <CardHeader
                  avatar={<LearnStateChip type={item.state} />}
                  title={item.course.name}
                />
                <Box ml={2} mr={2}>
                  <LinearProgressWithLabel
                    current={item.completed}
                    total={item.total}
                  />
                </Box>
                <CardActions>
                  <Link to={`/assistant/students/${params.id}/courses/${item.id}`}>
                    <Button size="small" color="primary">
                      查看
                    </Button>
                  </Link>

                  {renderState(item.courseState)}
                </CardActions>
              </Card>
            ))}
          </Box>
        );
      }}
    />
  );
};

export default UserCoursePage;
