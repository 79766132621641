import Fallback from '@/components/Fallback';
import useAsync from '@/hooks/useAsync';
import { assistantClassResource } from '@/services/admin';
import {Box, InputBase, MenuItem, Select} from '@material-ui/core';
import {Badge, Col, Row, Table} from 'antd';
import { differenceInDays } from 'date-fns';
import { differenceInWeeks } from 'date-fns';
import React from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {comparator} from "ramda";
import logo from "@/assets/time.jpg";



const renderState = (value: string) => {
    switch (value) {
        case 'yankai':
            return <Badge status="processing" text="延开" color="gold"/>;
        case 'zaixue':
            return <Badge status="success" text="学习" color="green"/>;
        case 'dongjie':
            return <Badge status="error" text="冻结" color="cyan"/>;

        case 'zanting':
            return <Badge status="default" text="停课" color="red"/>;
        case 'yanxue':
            return <Badge status="warning" text="待开-前课未完" color="blue"/>;
        case 'tiqian':
            return <Badge status="warning" text="到期-续课在学" color="pink"/>;
        case 'jinjie':
            return <Badge status="warning" text="已进阶" color="purple"/>;
        case 'yanqi':
            return <Badge status="warning" text="延期" color="gold"/>;
        default:
            return null;
    }
};


export default function AssistantClass() {
  const history = useHistory();
  const { assistantID, classID } = useParams<{
    assistantID: string;
    classID: string;
  }>();

  const resource = React.useMemo(
    () => assistantClassResource(parseInt(assistantID),parseInt(classID)),
    [assistantID]
  );
  const state = useAsync(resource.query, [assistantID]);
  const selected = React.useMemo(
    () => (classID === undefined ? 0 : parseInt(classID)),
    [classID]
  );

  const setSelected = (classID?: number) => {
    if (classID) {
      history.push(
        `/admin/student/assistants/${assistantID}/classes/${classID}`
      );
    } else {
      history.push(`/admin/student/assistants/${assistantID}/classes`);
    }
  };

  return (
    <>
      <Fallback
        state={state}
        render={({ data }) => {
          return (
            <>
              <Select
                input={<InputBase />}
                value={selected}
                onChange={(e) => {
                  setSelected(e.target.value as number);
                }}
              >
                <MenuItem value={0}>全部</MenuItem>
                {data.map((item) => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
              </Select>

                {data.map((item,i)=>{
                    if (i==0){
                        return(
                            <Row>
                                <Col xs={8}></Col>
                                <Col xs={8}>{item.assistantName}的班级</Col>
                                <Col xs={8}></Col>
                            </Row>
                        )
                    }
                })}

              {data.map((item, index) => {
                if (selected && selected !== item.id) {
                  return null;
                }

                return (
                  <Table
                    rowKey="id"
                    bordered
                    size="small"
                    pagination={ false }
                    style={{ marginTop: 8, minWidth: '100%' }}
                    title={() => `${item.name}`}
                    columns={[
                      {
                        title: '姓名',
                        dataIndex: 'realname',
                        render: (value, record) => (

                            record.courseState === "zanting" ? (
                                <Link
                                    to={`/admin/student/students?filter={"%24search"%3A"${value}"}`}
                                >
                                    {value}<span><span  style={{ color: `#FF0000`}} > (停课)</span></span>
                                </Link>
                            ) : (

                                <Link
                                    to={`/admin/student/students?filter={"%24search"%3A"${value}"}`}
                                >
                                    {value}
                                </Link>
                            )
                        ),
                      },
                        // {
                        //     title: '姓名',
                        //     dataIndex: 'realname',
                        //     render: (value, record) => (
                        //
                        //
                        //         record.courseState === "zanting" ? (
                        //             <Link
                        //                 to={`/admin/student/students?filter={"%24search"%3A"孟玉"}`}
                        //             >
                        //                 <Link
                        //                     to={`/admin/student/students/${record.id}/practices`}
                        //                 >
                        //                 {value}<span><span  style={{ color: `#FF0000`}} > (停课)</span></span>
                        //                 </Link>
                        //             </Link>
                        //         ) : (
                        //
                        //             <Link
                        //                 to={`/admin/student/students?filter={"%24search"%3A"孟玉"}`}
                        //             >
                        //                 {value}
                        //             </Link>
                        //         )
                        //
                        //     ),
                        // },

                        {
                            dataIndex: 'courseState',
                            title: '状态',
                            sorter: comparator((a, b) => a.courseState < b.courseState),
                            render: (_, record) => (
                                <>
                                    <br />
                                    {renderState(record.courseState)}
                                </>
                            ),
                            // render: (category) => category.name,
                        },

                        {
                            title: '进度',
                            sorter: comparator((a, b) => a.lessonCompleted < b.lessonCompleted),
                            render: (_, record) =>
                                record.currentCourseID_ == 999 ? (
                                    <Link
                                        // to={`/teacher/students/${record.id}/courses`}
                                        to={`/teacher/students/${record.id}/courses/${record.currentCourseID}`}
                                    >
                                        完成
                                    </Link>
                                ) : (

                                    record.currentCourseID_ == 666 ? (

                                        <Link
                                            // to={`/teacher/students/${record.id}/courses`}
                                            to={`/teacher/students/${record.id}/courses/${record.currentCourseID}`}
                                        >
                                            待完成
                                        </Link>

                                    ) : ( <Link
                                        // to={`/teacher/students/${record.id}/courses`}
                                        to={`/teacher/students/${record.id}/courses/${record.currentCourseID}`}
                                    >
                                        {record.lessonCompleted}/{record.lessonTotal}
                                    </Link>)





                                ),
                        },
                        {
                            title: '已学',
                            dataIndex: 'startWeek',
                            render: (value, record) =>
                                    <Link
                                        to={`/admin/student/history/${record.id}/${item.courseID}`}
                                    >
                                        {value}
                                    </Link>
                        },

                      {
                        title: '剩余日均',
                        dataIndex: 'durationRemain',
                          sorter: comparator((a, b) => a.durationRemain < b.durationRemain),
                        render: (value) => `${value}分钟`,
                      },
                      {
                        title: '结课',
                        dataIndex: 'courseEndTime',
                          sorter: comparator((a, b) => a.courseEndTime < b.courseEndTime),
                        render: (value) =>
                          value
                            ? `${differenceInDays(
                                new Date(value),
                                new Date()
                              )}天`
                            : '',
                          //N/A
                      },
                        {
                            title: '观看',
                            dataIndex: 'practiceViews',
                            render: (value) => `${value}次`,
                        },
                      {
                        title: '未练习1',
                        dataIndex: 'lastPracticeAt',
                        render: (value) =>
                          value
                            ? `${differenceInDays(
                                new Date(),
                                new Date(value)
                              )}天`
                            : 'N/A',
                      },

                    ]}
                    dataSource={item.students}
                  />
                );

              })}
                    {/*<img*/}
                    {/*    style={{ display: 'block',  margin:'0 auto' ,maxWidth:350 }}*/}
                    {/*    src={logo}*/}
                    {/*    alt="Logo"*/}
                    {/*/>*/}
            </>
          );
        }}
      />
    </>
  );
}
