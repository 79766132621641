import Fallback from '@/components/Fallback';
import LearnStateChip from '@/components/LearnStateChip';
import LinearProgressWithLabel from '@/components/LinearProgressWithLabel';
import { useConsecutiveSnackbars } from '@/components/Snackbar';
import useAsync from '@/hooks/useAsync';
import { CourseResource, remindUnlockLesson } from '@/services/user';
import { UserCourse } from '@/services/entity';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Container,
  InputBase,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  success: {
    background: theme.palette.success.main,
  },
}));

const List: React.FC<{ items: UserCourse[] }> = ({ items }) => {
  const classes = useStyles();
  const history = useHistory();
  const snackbar = useConsecutiveSnackbars();
  const params = useParams<{ id: string,idd:string }>();
  const selectedID = React.useMemo(() => parseInt(params.id), [params.id]);



  const setSelectedID = React.useCallback(
    (selectedID: number) => {
      history.replace(`/course/syllabus/${selectedID}`);
    },
    [history],
  );



  const state = useAsync(() => CourseResource.find(selectedID), [selectedID]);

  const selected = React.useMemo(
    () => items.find((item) => item.id === selectedID) as UserCourse,
    [items, selectedID]
  );

  if (!selected) {
    return null;
  }

  return (
    <Fallback
      state={state}
      render={(selected) => (
        <Box flex={1}>
          <Box padding={2}>
            <Box
              mb={2}
              bgcolor="white"
              display="flex"
              justifyContent="space-between"
            >
              {/*<Select*/}
              {/*  input={<InputBase/>}*/}
              {/*  value={selectedID}*/}
              {/*  onChange={(e) => {*/}
              {/*    setSelectedID(e.target.value as number);*/}
              {/*    // 强制刷新*/}
              {/*    history.go(0)*/}
              {/*  }}*/}
              {/*>*/}
              {/*  {items.map((item) => (*/}
              {/*    <MenuItem key={item.id} value={item.id}>*/}
              {/*      {item.course.name}222*/}
              {/*    </MenuItem>*/}
              {/*  ))}*/}
              {/*</Select>*/}


                {/*{items.map((item, index) => (*/}

                {/*    index == 0?(  <MenuItem key={item.id} value={item.id}>*/}
                {/*        {item.course.name}*/}
                {/*    </MenuItem>):('')*/}
                {/*))}*/}
            </Box>

            {/*<div  dangerouslySetInnerHTML={{ __html: `\u7ba1\u7406\u8bfe\u7a0b`}}/>*/}

            <p ></p>
            <p  style={{background:`#FFFFE0`,fontFamily:'Microsoft JhengHei',marginTop:'-15px',marginBottom:'10px'}}  dangerouslySetInnerHTML={{ __html: selected.content}}/>

            {selected.userLessons.map((item, index) => {
              const available =
                item.state === 'learning' &&
                item.practiceDuration >= item.lesson.practiceDuration;
              return (
                <Card
                  key={item.id}
                  className={classes.card}
                  variant="outlined"
                  onClick={() => {}}
                >
                  <CardHeader
                    avatar={<LearnStateChip type={item.state} />}
                    title={`${index + 1}. ${item.lesson.name}`}
                  />
                  <Box ml={2} mr={2}>
                    <LinearProgressWithLabel
                      current={item.practiceDuration}
                      total={item.lesson.practiceDuration}
                    />
                  </Box>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      disabled={item.state === 'locked'}
                      onClick={() => history.push(`/course/learn/${item.id}`)}
                    >
                      查看
                    </Button>
                    <Button
                      size="small"
                      color="primary"
                      onClick={async () => {
                        try {
                            //需要修改,已经修改
                          await remindUnlockLesson(parseInt(params.idd));
                          snackbar.showMessage({
                            children: (
                              <Alert severity="success">发送提醒成功</Alert>
                            ),
                          });
                        } catch (err) {
                          snackbar.showMessage({
                            children: (
                              <Alert severity="error">{err.message}</Alert>
                            ),
                          });
                        }
                      }}
                      disabled={!available}
                    >
                      开课提醒
                    </Button>
                  </CardActions>
                </Card>
              );
            })}
          </Box>
        </Box>
      )}
    />
  );
};

const Syllabus: React.FC = () => {
  const state = useAsync(CourseResource.query);

  return (
    <Box>
      <Container disableGutters maxWidth="sm">
        <Fallback
          state={state}
          render={({ data }) => {
            if (data.length > 0) {
              return <List items={data} />;
            }
            return null;
          }}
        />
      </Container>
    </Box>
  );
};

export default Syllabus;
