import axios from 'axios';
import {api, encodeQueryParams, PaginationData, QueryParams, restful} from './api';
import {
  UserLesson,
  User,
  Token,
  Notice,
  Practice,
  UserCourse,
  PracticeComment,
  PracticeView,
  Order,
  LessonNote,
  Point,
  Course,
  Class as BaseClass,
  Entity,
  Class,
  ContactProfile,
  AllChangeData,
  Change,
  Productkind,
  Product,
  UserLeave,
} from './entity';
import {MyLeave} from "@/services/admin";

export const currentLesson = api<number, UserLesson | undefined>((id) =>
  axios.get(`/api/currentLesson/${id}`)
);


export const remindUnlockLesson = api<number, Notice>((id) =>
    axios.post(`/api/remind/unlock-lesson/${id}`)
);


// export const currentLesson = restful<UserLesson>(axios, `/api/currentLesson/`);



// export const UserCourseResource = restful<UserCourse>(axios, '/user/courses');


export const ContactProfileResource = restful<ContactProfile>(
    axios,
    '/contact/profiles'
);

export const UserCourseResource = restful<UserCourse>(axios, '/user/courses');


export const readNotice = api<number, Notice>((id) =>
    axios.put(`/api/notices/${id}/read`)
);

interface LiveRoom {
  url: string;
}
export const startPractice = api<number, LiveRoom>((id) =>
  axios.get(`/api/startPractice/${id}`)
);
export const userProfile = api<void, User>(() => axios.get('/api/profile'));


// export const remindUnlockLesson = api<void, Notice>(() =>
//   axios.post('/api/remind/unlock-lesson')
// );




export const updateUserAvatar = api<{ userID: number; avatar: File }, void>(
    ({ userID, avatar }, config) => {
      const data = new FormData();
      data.set('avatar', avatar);
      return axios.post(`/users/${userID}/avatar`, data, config);
    }
);




export const PracticeResource = restful<Practice>(axios, '/api/practices');
export const CourseResource = restful<UserCourse>(axios, '/api/courses');
export const LessonResource = restful<UserLesson>(axios, '/api/lessons');
export const NoticeResource = restful<Notice>(axios, '/api/notices');
export const PointResorce = restful<Point>(axios, '/api/points');
export const PracticeCommentResource = restful<PracticeComment>(
  axios,
  '/api/practice/comments'
);

export interface InvitationcodeData extends Entity{
  id: number;
  name: string;
  explain?: string;
  username?: string;
  times?: number;
  leftTimes?: number;
  code?: string;
  state?: number;
  invitationcode?: string;
}

// export const queryBuildCode = api<void, InvitationcodeData>(() => axios.post('/api/buildinvitationcode'));


export const queryBuildCode = api<
    QueryParams,
    InvitationcodeData>((params) =>
    axios.post('/api/buildinvitationcode', { params: encodeQueryParams(params) })
);



export const queryAllCourses = restful<UserCourse>(axios, '/api/allCourses');

export const updateContent = restful<UserCourse>(axios, '/api/updateContent');

// export const ProductKindResource1 = api<void,void>(() =>
//     axios.get('/api/productkinds1')
// );


export const ProductKindResource1 = restful<Productkind>(axios, '/api/productkinds1');


// export const ProductListResource = restful<Product>(axios, '/api/productList');


export const ProductListResource = api<{ kid: number }, Product[]>((params) => {
  return axios.get('/api/productList', { params });
});


export const UserChangeResource = restful<Change>(axios, '/api/userchange');

export const ContactprofilesState = restful<ContactProfile>(axios, '/api/usercontactprofile');


export const QueryLeaveRecord = api<number, MyLeave[]>((id) =>
    axios.get(`/user/leave/${id}`)
);
export const CommLeave = api<number, MyLeave>((id) => axios.get(`/api/leave`));


export const CommPracticeTips = api<number, string>((id) => axios.get(`/api/practiceTips/${id}`));



export const Class3Resource = restful<Class>(axios, '/api/class3');


export const CommJiangXueJin = api<number, User>((id) => axios.get(`/api/commjiangxuejin/${id}`));


export const CommShop = api<void, Token>((id) => axios.get(`/api/commshop`));


export const SubPoints = api<number, Point>((id) => axios.get(`/api/subPoints/${id}`));



export const MyOrderResource = restful<Order>(axios, '/api/myorder');

// export const UserProductResource = restful<Product>(axios, '/api/userproduct');

export const UserProductResource = api<{ id: string },Product>((params) => {return axios.get('/api/userproduct', { params });
});

export const UserProductResource1 = api<{ id: string },Product>((params) => {return axios.get('/api/userproduct1', { params });
});



export const UserProductResource2 = api<{ id: any },Product>((params) => {return axios.get('/api/userproduct1', { params });
});


interface Yaoqinma extends Entity{
  id: number;
  cid: number;
  uid: number;
  username?: string;
  state?: number;
  code?: string;
}


export const invitationCodeList = api<
    QueryParams,
    Yaoqinma[]>((params) =>
    axios.get('/api/invitationCodeList', { params: encodeQueryParams(params) })
);






export const PracticeViewResource = restful<PracticeView>(
  axios,
  '/api/practice/views'
);
export const LessonNoteResource = restful<LessonNote>(
  axios,
  '/api/lesson/notes'
);

export const LessonNoteResourceMy = restful<LessonNote>(
    axios,
    '/api/assistant/student/mynotes'
);

interface LeadboardItem {
  name: string;
  avatar?: string;
  practiceDuration: number;
}

export const LeadboardAll = api<
  { range: string },
  LeadboardItem[]
>((params) => {
  return axios.get('/api/leaderboard/all', { params });
});

export const LeadboardClass = api<
  { range: string },
  LeadboardItem[]
>((params) => {
  return axios.get('/api/leaderboard/class', { params });
});

export const LeadboardClassWithin = api<
  { range: string },
  LeadboardItem[]
>((params) => {
  return axios.get('/api/leaderboard/class-within', { params });
});

export const upload = api<File, { url: string }>((file, config) => {
  const data = new FormData();
  data.set('file', file);
  return axios.post('/api/upload', data, config);
});

export const changeAvatar = api<File, User>((file, config) => {
  const data = new FormData();
  data.set('avatar', file);
  return axios.post('/api/profile/avatar', data, config);
});
