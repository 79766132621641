import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Lesson from './Lesson';
import Course from './Course';

const Student: React.FC = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/:id/courses/:courseID`}>
        <Lesson />
      </Route>
      <Route path={`${match.path}/:id/courses`}>
        <Course />
      </Route>
    </Switch>
  );
};

export default Student;
