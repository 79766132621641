import Fallback from '@/components/Fallback';
import { useConsecutiveSnackbars } from '@/components/Snackbar';
import useAsync from '@/hooks/useAsync';
import { currentLesson, remindUnlockLesson } from '@/services/user';
import { Box, Button, Container, Grid } from '@material-ui/core';
import React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import LearnActionCard from './LearnActionCard';
import NoteList from './NoteList';
import useAsyncFn from '@/hooks/useAsyncFn';
import Alert from '@material-ui/lab/Alert';
import { Empty } from 'antd';
import {QueryHistoryRecord} from "@/services/admin";


const Learning: React.FC = () => {
  const snakbar = useConsecutiveSnackbars();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  // const state = useAsync(currentLesson);


  // const state = useAsync(() => {
  //   return currentLesson.query({
  //     filter: {
  //       selectedID,
  //     },
  //   });
  // }, [selectedID]);



  const state = useAsync(
      () => currentLesson(parseInt(id)),
      [id]
  );



  const [, remind] = useAsyncFn(async () => {
    try {
      await remindUnlockLesson(parseInt(id));
      state.retry();
      snakbar.showMessage({ message: '发送提醒成功' });
    } catch (err) {
      snakbar.showMessage({
        children: <Alert severity="error">{err.message}</Alert>,
      });
    }
  }, [state.retry]);
  return (
    <Box flex={1} >
      <Fallback
        state={state}
        render={(lesson) => {
          if (!lesson) {
            return (
              <Box
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Empty description="暂无可学课程" />
              </Box>
            );
          }
          return (
            <Container disableGutters maxWidth="sm">
              <Box margin={2}>
                <LearnActionCard lesson={lesson} />
              </Box>
              <Box marginBottom={2}>
                <Grid container>
                  <Grid item xs={4}>
                    <Box display="flex" justifyContent="center">
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => history.push(`/course/practice/${id}`)}
                      >
                        练习
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box display="flex" justifyContent="center">
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() =>
                          history.push(
                            `/course/syllabus/${lesson.userCourseID}/${id}/`
                          )
                        }
                      >
                        课表
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box display="flex" justifyContent="center">
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={remind}
                      >
                        提醒
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <NoteList lesson={lesson.lesson} />
            </Container>
          );
        }}
      />
    </Box>
  );
};

export default Learning;
