import {
  IconButton,
  ListItemSecondaryAction,
} from '@material-ui/core';
import StarBorder from '@material-ui/icons/StarBorder';
import StarOutlined from '@material-ui/icons/StarOutlined';
import React from 'react';
import { useHistory } from 'react-router-dom';
import {Student} from "@/services/admin";

export interface SaixuanItemProps {
  value: Student;
  onRecommend?: (value: Student) => void;
}
const Saixuan: React.FC<SaixuanItemProps> = ({ value, onRecommend }) => {
  const history = useHistory();
  console.log(value)

  return (
      <ListItemSecondaryAction>
        <IconButton
          onClick={() => onRecommend && onRecommend(value)}
        >
          {value.is_gao ? <StarOutlined /> : <StarBorder />}
        </IconButton>
      </ListItemSecondaryAction>
  );
};

export default Saixuan;
