import AppTabs from '@/components/AppTabs';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import useProfileTab from './useProfileTab';
import useSourceTab from './useSourceTab';
import ProfileDetail from './ProfileDetail';

const Contact: React.FC = () => {
  const match = useRouteMatch();

  const profileTab = useProfileTab();
  const sourceTab = useSourceTab();



  return (
    <Switch>
      <Route path={`${match.path}/profiles/:id`}>
        <ProfileDetail />
      </Route>
      <Route path={match.path}>
        <AppTabs tabs={[profileTab, sourceTab]} />
      </Route>
    </Switch>
  );
};

export default Contact;
