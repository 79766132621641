import { Avatar, Box } from '@material-ui/core';
import BaseAppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import HomeIcon from '@material-ui/icons/Home';
import LocalFloristIcon from '@material-ui/icons/LocalFlorist';
import React from 'react';
import { useHistory,Link } from 'react-router-dom';
import { NoticeIconButton } from './components/Notice';
import Username from './components/Username';
import MenuDrawer from './MenuDrawer';
import { useProfile } from './pages/Profile/context';
import { AppBarTabs } from './slots';
import {Tag} from "antd";

import image from '@/assets/learn.jpg';
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import Card from "@material-ui/core/Card";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: 250,
    },
    logo: {
      height: 48,
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(0),
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);

interface AppBarProps {
  back?: boolean;
}

const AppBar: React.FC<AppBarProps> = ({ back = false }) => {
  const classes = useStyles();
  const { profile } = useProfile();
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpen(open);
  };
  const history = useHistory();
  return (
    <>
      <BaseAppBar style={{ zIndex: 1000,background: '#105952' }}  position="static">
        <Toolbar variant="dense">
          <Box
            overflow="hidden"
            pl={0}
            pt={1}
            pb={1}
            bgcolor="primary"
            onClick={toggleDrawer(true)}
          >
            <Box display="flex" alignItems="center">
              <Box>
                <Avatar src={profile.avatar} />
              </Box>
              <Box ml="12px" overflow="hidden">
                <Username value={profile} inverse />
                <Box>{profile.points} 琴贝</Box>
              </Box>
            </Box>
          </Box>
          <div className={classes.grow} />

                <Link to={`/jiangxuejin/0`}>
                    <span  style={{marginRight:`0.3cm`, color: `#FFDD74`,fontSize:`15px`}} >奖学金</span>
                    {/*<img style={{width:"50px",height:"16px",marginTop:"-3px",}} src={"https://teach-vod.oss-cn-hangzhou.aliyuncs.com/8f5d0f9f-7f55-40bf-9673-57a48c8884aa.jpg"} alt={"aaa"}/>*/}
                </Link>
          <NoticeIconButton />
          {/*<IconButton color="inherit">*/}
          {/*  <LocalFloristIcon />*/}
          {/*</IconButton>*/}

          <IconButton
            edge="end"
            color="inherit"
            onClick={() => history.push('/')}
          >
            <HomeIcon />
          </IconButton>
          <MenuDrawer open={open} onClose={toggleDrawer(false)} />
        </Toolbar>
        <AppBarTabs.Slot />
      </BaseAppBar>
    </>
  );
};

export default AppBar;
