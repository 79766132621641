import React from 'react';
import { IconButtonProps, IconButton, Badge } from '@material-ui/core';
import Notifications from '@material-ui/icons/Notifications';
import constate from 'constate';
import useAsync from '@/hooks/useAsync';
import { NoticeResource } from '@/services/user';
import Fallback from '../Fallback';
import { useHistory } from 'react-router-dom';

const [NoticeProvider, useNotice] = constate(() => {
  const state = useAsync(() =>
    NoticeResource.query({ filter: { marked: true } })
  );
  return state;
});

export { NoticeProvider, useNotice };

export function NoticeIconButton(props: IconButtonProps) {
  const notice = useNotice();
  const history = useHistory();
  return (
    <Fallback
      state={notice}
      initial={
        <IconButton {...props} color="inherit">
          <Badge color="error">
            <Notifications />
          </Badge>
        </IconButton>
      }
      render={({ total }) => (
        <IconButton
          {...props}
          color="inherit"
          onClick={() => history.push('/notices')}
        >
          <Badge badgeContent={total} color="error">
            <Notifications />
          </Badge>
        </IconButton>
      )}
    />
  );
}
