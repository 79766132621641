import { AppTab } from '@/components/AppTabs';
import Fallback from '@/components/Fallback';
import {
  createSubmitFab,
  createSubmitModal,
  createSubmitTrigger,
  TotalLabel,
} from '@/components/ResourceManage';
import SearchSelect from '@/components/SearchSelect';
import useAsyncFn, { AsyncState } from '@/hooks/useAsyncFn';
import {
    CategoryResource,
    ProductResource, ProductKindResource, HorseResource,
} from '@/services/admin';
import { Product } from '@/services/entity';
import { makeStyles, Tab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
    Button,
    Card, Col,
    Divider,
    Form,
    Input, message,
    Popconfirm, Row,
    Space,Tag,
    Table, Upload,
    InputNumber,
    Radio
} from 'antd';
import { FormProps } from 'antd/lib/form';
import { TableProps } from 'antd/lib/table';
import format from 'date-fns/format';
import React from 'react';
import EntityList from './EntityList';
import {UploadOutlined} from "@ant-design/icons";
import Editor from "@/components/Editor/Editor";
import useAsyncTap from "@/hooks/useAsyncTap";
import {Link} from "react-router-dom";

const resource = ProductResource;
type Entity = Product;

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));


//-----------

const onChange = ( info:any ) => {
    if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
        message.success(`${info.file.name},上传成功`);

        // let fileList = [...info.fileList];
        // fileList = fileList.slice(-1);
        //
        // // 2. Read from response and show file link
        // fileList = fileList.map(file => {
        //     if (file.response) {
        //
        //     }
        //     return file;
        // });
        setTimeout(function () {
            //内容1秒后,刷新
            window.location.reload();
        }, 1000);

    } else if (info.file.status === 'error') {
        message.error(`${info.file.name},上传失败`);
    }
};


const beforeUpload = ( file:any ) => {
    if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg') {
        return  true
    }else {
        message.error(`${file.name} is not a png file,or jpg,or jpeg`);
    }
    return false
};
//---------------------


//
// const RecordForm1: React.FC<FormProps> = (props) => {
//
//     const [form] = Form.useForm()
//
//     const [, createProduct] = useAsyncFn(
//
//         useAsyncTap(resource.create, () => {
//             form.resetFields();
//             window.location.reload();
//
//         })
//     );
//
//
//
//     const resetFields =(state:any)=>{
//         form.setFieldsValue({content:state})
//         form.submit()
//     }
//
//     const setContent =(state:any)=>{
//         form.setFieldsValue({content:state})
//         form.submit()
//     }
//
//     return (
//         <Form   labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }}
//                 form={form}
//                 onFinish={createProduct as any} {...props}>
//             <Form.Item label="名称" name="name" rules={[{ required: true }]}>
//                 <Input />
//             </Form.Item>
//
//             <Form.Item label="类别" name="kindID" rules={[{ required: true }]}>
//                 <SearchSelect
//                     allowEmptyValue
//                     query={ProductKindResource.query}
//                     map={({ id, name}) => ({
//                         value: id,
//                         label: `${name} `,
//                     })}
//                 />
//             </Form.Item>
//
//
//             <Form.Item label="琴贝" >
//
//                 <Space>
//                     <Form.Item
//                         label=""
//                         name="qinbei"
//                     >
//                         <InputNumber   style={{ width: 212 }}/>
//                     </Form.Item>
//                     <Form.Item
//                         label="价格"
//                         name="price"
//                     >
//                         <InputNumber  style={{ width: 212 }} />
//                     </Form.Item>
//                 </Space>
//             </Form.Item>
//
//
//             <Form.Item label="链接" name="link" >
//                 <Input />
//             </Form.Item>
//
//
//             <Form.Item label="状态" name="status" >
//
//                 <Radio.Group>
//                     <Radio.Button value="1">上架</Radio.Button>
//                     <Radio.Button value="0">隐藏</Radio.Button>
//                 </Radio.Group>
//
//
//                 {/*<Switch checkedChildren="上架" unCheckedChildren="隐藏"  />*/}
//             </Form.Item>
//
//
//             <Form.Item  name="content" hidden={true} >
//                 <Input />
//             </Form.Item>
//
//             <Form.Item  label="内容1">
//                 {/*<Input.TextArea />*/}
//                 <Editor id={"text"}  resetFields={resetFields}  setContent={setContent}/>
//             </Form.Item>
//         </Form>
//     );
// };
//-----------------------

const RecordForm: React.FC<FormProps> = (props) => {


  return (
    <Form   labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }} {...props}>
      <Form.Item label="名称" name="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

        <Form.Item label="类别" name="kindID" rules={[{ required: true }]}>
            <SearchSelect
                allowEmptyValue
                query={ProductKindResource.query}
                map={({ id, name}) => ({
                    value: id,
                    label: `${name} `,
                })}
            />
        </Form.Item>


        <Form.Item label="琴贝" >

            <Space>
                    <Form.Item
                        label=""
                        name="qinbei"
                    >
                        <InputNumber   style={{ width: 212 }}/>
                    </Form.Item>
                    <Form.Item
                        label="价格"
                        name="price"
                    >
                        <InputNumber  style={{ width: 212 }} />
                    </Form.Item>
            </Space>
        </Form.Item>


        <Form.Item label="链接" name="link" >
            <Input />
        </Form.Item>


        <Form.Item label="状态" name="status" >

            <Radio.Group>
                <Radio.Button value="1">上架</Radio.Button>
                <Radio.Button value="0">隐藏</Radio.Button>
            </Radio.Group>


            {/*<Switch checkedChildren="上架" unCheckedChildren="隐藏"  />*/}
        </Form.Item>

    </Form>
  );
};

const SubmitModal = createSubmitModal<Entity>(RecordForm);
const SubmitTrigger = createSubmitTrigger(SubmitModal);
const SubmitFab = createSubmitFab(SubmitTrigger);

interface DataTableProps extends TableProps<Entity> {
  onCreate?: () => void;
  onDelete?: () => void;
}
const DataTable: React.FC<DataTableProps> = ({
  onCreate,
  onDelete,
  ...rest
}) => {
  return (
    <Table
      {...rest}
      rowKey="id"
      bordered
      size="small"
      columns={[
        {
          dataIndex: 'id',
          title: '#',
        },
        { dataIndex: 'name', title: '名称',  },

        // {
        //   dataIndex: 'createdAt',
        //   title: '时间',
        //   render: (createdAt) =>
        //     format(new Date(createdAt), 'yyyy-MM-dd HH:mm:ss'),
        // },
          { dataIndex: 'kind',
              title: '类别',
              // render: (value) => value.name,

              render: (value) => (
                  value ? (
                      value.name
                  ) : (
                    ``
                  )
              ),
          },
          {
              dataIndex: 'qinbei',
              title: '琴贝',
          },
          {
              dataIndex: 'price',
              title: '价格',
          },
          {
              dataIndex: 'link',
              title: '链接',
              ellipsis: true,
              render: (value) => (
                      <a href={value}>{value}</a>
              ),
          },
          {
              dataIndex: 'url',
              title: '',
              ellipsis: true,
              align: 'center',

              render: (record) =>
                  <img style={{
                      height: 60,
                      width: 250,
                  }}
                       src={record} />
          },

          {
              title: '添加',
              align: 'center',
              render: (_, record) => {
                  return (
                      <>
                          <Form
                              layout="vertical"
                              // onFinish={(values) => fetch({ filter: values })}
                          >

                              <Space >
                                  <Row>
                                      <Col xs={8} >
                                          <Upload name={'file'}
                                                  action={'/api/profile/producturl/'+record.id}
                                                  onChange={onChange}
                                                  beforeUpload={beforeUpload}>
                                              <Button  icon={<UploadOutlined />} >选择</Button>
                                          </Upload>
                                      </Col>
                                  </Row>
                              </Space>

                          </Form>
                      </>
                  );
              },
          },



          { dataIndex: 'status',
              title: '状态',
              render: (value) => (
                  value === "0" ? (
                      <Tag>隐藏</Tag>

                  ) : (
                      <Tag color={"red"}>上架</Tag>
                  )
              ),
          },
          // {
          //     dataIndex: 'content',
          //     title: '内容',
          // },

          // { dataIndex: '',
          //     title: '设置内容',
          //     render: (_, record) => (
          //         <Link
          //             to={`/admin/student/students/product/${record.id}`}
          //         >
          //             设置内容
          //         </Link>
          //     ),
          // },



          {
              dataIndex: 'content',
              title: '内容',
              ellipsis: true,
              align: 'center',

              render: (record) =>
                  <img style={{
                      height: 60,
                      width: 500,
                  }}
                       src={record} />
          },
          {
              title: '设置内容',
              align: 'center',
              render: (_, record) => {
                  return (
                      <>
                          <Form
                              layout="vertical"
                              // onFinish={(values) => fetch({ filter: values })}
                          >

                              <Space >
                                  <Row>
                                      <Col xs={8} >
                                          <Upload name={'file'}
                                                  action={'/api/profile/producturl1/'+record.id}
                                                  onChange={onChange}
                                                  beforeUpload={beforeUpload}>
                                              <Button  icon={<UploadOutlined />} >选择</Button>
                                          </Upload>
                                      </Col>
                                  </Row>
                              </Space>

                          </Form>
                      </>
                  );
              },
          },

        {
          title: '操作',
          render: (_, record) => {
            return (
              <>
                <Popconfirm
                  title="确认删除?"
                  onConfirm={async () => {
                    await resource.delete(record.id);
                    onDelete && onDelete();
                  }}
                >
                  <Button type="link">删除</Button>
                </Popconfirm>
                <Divider type="vertical" />
                <SubmitTrigger
                  ModalProps={{
                    title: '编辑商品',
                    centered: true,
                    submit: resource.update(record.id),
                    onSubmitted: onCreate,
                    FormProps: { initialValues: record },
                  }}
                  render={({ open }) => (
                    <Button type="link" onClick={open}>
                      编辑
                    </Button>
                  )}
                />
              </>
            );
          },
        },




      ]}
    />
  );
};

const TabPanel: React.FC<{
  state: AsyncState<{ total: number; data: Entity[] }>;
  fetch: typeof resource.query;
}> = ({ state, fetch }) => {
  const [form] = Form.useForm();
  return (
    <div>
      <Card style={{ marginBottom: 8 }}>
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => fetch({ filter: values })}
        >
          <Form.Item label="类别" name="kindID">
            <SearchSelect
              placeholder="全部类别"
              query={() =>
                  ProductKindResource.query({
                  filter: { module: 'contact_source' },
                })
              }
              map={({ name, id }) => ({ value: id, label: name })}
            />
          </Form.Item>
          <Form.Item style={{ textAlign: 'right' }}>
            <Space>
              <Button htmlType="reset" onClick={() => form.resetFields()}>
                重置
              </Button>
              <Button type="primary" htmlType="submit" disabled={state.loading}>
                查询
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
      <Fallback
        state={state}
        initial={<DataTable loading />}
        render={({ data }) => {
          return (
            <DataTable
              dataSource={data}
              loading={state.loading}
              onCreate={state.retry}
              onDelete={state.retry}
            />
          );
        }}
      />
    </div>
  );
};

function useStudentTab(): AppTab {
  const classes = useStyles();
  const [state, fetch] = useAsyncFn(resource.query, []);
  React.useEffect(() => {
    fetch({ filter: {} });
  }, [fetch]);

  return {
    path: `products`,
    tab: <Tab label={<TotalLabel state={state}>商品</TotalLabel>} />,
    fab: (
      <SubmitFab
        ModalProps={{
            title: '新建商品',
            centered: true,
            submit: resource.create,
            onSubmitted: state.retry,
        }}
        className={classes.fab}
      >
        <AddIcon />
      </SubmitFab>
    ),
    panel: <TabPanel state={state} fetch={fetch} />,
  };
}

export default useStudentTab;
