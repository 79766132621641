import {
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  IconButton,
  Slider,
  useTheme,
  DialogProps,
} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { TransitionProps } from '@material-ui/core/transitions';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      display: 'flex',
      flexDirection: 'column',
    },
    appBar: {
      position: 'relative',
    },
    title: {
      color: theme.palette.primary.contrastText,
      marginLeft: theme.spacing(1),
      flex: 1,
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const MAX_AVATAR_SIZE = 600;

const compressAvatar = (image: HTMLCanvasElement) => {
  if (image.width <= MAX_AVATAR_SIZE) {
    return image;
  }

  const compressed = document.createElement('canvas');
  compressed.width = MAX_AVATAR_SIZE;
  compressed.height = MAX_AVATAR_SIZE;
  const ctx = compressed.getContext('2d');
  if (!ctx) {
    throw new Error('get avatar canvas context failure');
  }
  ctx.drawImage(image, 0, 0, MAX_AVATAR_SIZE, MAX_AVATAR_SIZE);
  return compressed;
};

interface AvatarSettingDialogProps extends DialogProps {
  avatar?: File;
  setAvatar: (avatar: File) => Promise<void>;
  onCompleted: () => void;
}
export default function AvatarSettingDialog({
  avatar,
  setAvatar,
  onClose,
  onCompleted,
  ...rest
}: AvatarSettingDialogProps) {
  const classes = useStyles();
  const [scale, setScale] = useState(1);
  const editorRef = useRef<AvatarEditor>(null);

  const theme = useTheme();




  const size = Math.min(theme.breakpoints.values.sm, window.innerWidth);
  return (

    <Dialog
      {...rest}
      onClose={onClose}
      fullScreen
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={(event) => onClose && onClose(event, 'backdropClick')}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            设置个人头像
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={(event) => {
              const editor = editorRef.current;
              if (!editor) {
                return;
              }
              const avatar = compressAvatar(editor.getImage());
              avatar.toBlob(async (blob) => {
                if (!blob) {
                  return;
                }
                const file = new File([blob], 'avatar.png', {
                  type: 'image/png',
                });
                await setAvatar(file);
                onCompleted()
              }, 'image/png');
            }}
          >
            使用
          </Button>
        </Toolbar>
      </AppBar>
      {avatar && (
        <Container disableGutters maxWidth="sm">
          <AvatarEditor
            ref={editorRef}
            width={size - 48}
            height={size - 48}
            image={avatar}
            border={24}
            scale={scale}
          />
          <Box mt={2} mb={2} ml={4} mr={4}>
            <Slider
              step={0.1}
              min={1}
              max={2}
              value={scale}
              onChange={(_, value) => setScale(value as number)}
            />
          </Box>
        </Container>
      )}
    </Dialog>
  );
}


