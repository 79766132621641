import Fallback from '@/components/Fallback';
import useAsync from '@/hooks/useAsync';
import { queryAssistantCounsel } from '@/services/assistant';
import { Select, InputBase, MenuItem, Box } from '@material-ui/core';
import {Button, message, Table} from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import {comparator} from "ramda";
import Clipboard from "clipboard";
import content from "wangeditor/dist/editor/history/data/content";

const Counsel: React.FC = () => {
    const state = useAsync(queryAssistantCounsel);


    const copy = new Clipboard('.btn');
    copy.on('success', e => {
        // message.info(`ok`)
        // copy.destroy()
        // return
    });
    copy.on('error', function (e) {
        // alert('error')
    });


    function click() {

        message.success({
            content: '复制成功',
            style: {
                marginTop: '35vh',
            },
        });
    };

    const [selected, setSelected] = React.useState<number>(0);
    return (
        <>
            <Fallback
                state={state}
                render={({ data }) => {
                    return (
                        <>
                            {/*<Box mb={2}>*/}
                            {/*    <Select*/}
                            {/*        input={<InputBase />}*/}
                            {/*        value={selected}*/}
                            {/*        onChange={(e) => {*/}
                            {/*            setSelected(e.target.value as number);*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        <MenuItem value={0}>全部</MenuItem>*/}
                            {/*        {data.map((item) => (*/}
                            {/*            <MenuItem value={item.id}>{item.className}</MenuItem>*/}
                            {/*        ))}*/}
                            {/*    </Select>*/}
                            {/*</Box>*/}
                            {data.map((item, index) => {
                                if (selected && selected !== item.id) {
                                    return null;
                                }
                                return (
                                    <Table
                                        rowKey="id"
                                        title={() => (
                                            <Box display="flex" justifyContent="space-between">
                                                <div>{item.className}</div>

                                                {item.dayDuration ? (<div>标准{item.dayDuration}分钟</div>) : ('')}


                                                <Link to={`/assistant/classes/${item.id}/points`}>
                                                    本周剩余：{item.remainPoints}积分
                                                </Link>
                                            </Box>
                                        )}
                                        bordered
                                        size="small"
                                        style={{ minWidth: '100%' }}
                                        columns={[
                                            {
                                                title: '练习视频',
                                                dataIndex: 'realname',
                                                render: (value, record) => (
                                                    // <Link
                                                    //   to={`/assistant/students/${record.id}/practices`}
                                                    // >
                                                    //   {value}
                                                    // </Link>


                                                    record.state === "suspended" ? (
                                                        <Link
                                                            to={`/assistant/students/${record.userID}/practices/${record.courseID}`}
                                                        >
                                                            {value}<span><span  style={{ color: `#FF0000`}} > (暂停)</span></span>
                                                        </Link>
                                                    ) : (

                                                        <Link
                                                            to={`/assistant/students/${record.userID}/practices/${record.courseID}`}
                                                        >
                                                            {value}
                                                        </Link>
                                                    )


                                                ),
                                            },
                                            {
                                                title: '当前进度',
                                                dataIndex: 'lessonName',
                                                render: (value, record) => {
                                                    if (!value) {
                                                        return <span>-</span>;
                                                    }
                                                    return (
                                                        <Link
                                                            to={`/course/learn/${record.lessonID}`}
                                                        >
                                                            {value} ({record.practiceDuration}/
                                                            {record.total})
                                                        </Link>
                                                    );
                                                },
                                            },
                                            {
                                                title: '剩余日均',
                                                dataIndex: 'username',
                                                // sorter: comparator((a, b) => a.durationRemain < b.durationRemain),
                                                // render: (value) => `${value}分钟`,
                                                render: (value, record) =>



                                                    record.resultDay === 9999 ? (
                                                        <a  style={{color:"red"}}
                                                                                      data-clipboard-text={record.content}
                                                                                      className="btn"
                                                                                      onClick={click}
                                                        >{value}</a>
                                                    ) : (

                                                        <a
                                                           data-clipboard-text={record.content}
                                                           className="btn"
                                                           onClick={click}
                                                        >{value}</a>
                                                    )


                                                   ,

                                            },

                                            {
                                                title: '今日练习',
                                                dataIndex: 'practiceRecomms',
                                            },
                                            {
                                                title: '今日观看',
                                                dataIndex: 'practiceViews',
                                            },
                                        ]}
                                        dataSource={item.students}
                                    />
                                );
                            })}
                        </>
                    );
                }}
            />
        </>
    );
};

export default Counsel;
