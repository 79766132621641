import React from 'react';
import PointList from '@/components/PointList';
import useAsync from '@/hooks/useAsync';
import { useParams } from 'react-router-dom';
import {ChangePointResource, studentPoint} from '@/services/admin';
import Fallback from '@/components/Fallback';
import {Box} from "@material-ui/core";
import {Button, Card, Form, Input, InputNumber, message} from "antd";
import useAsyncFn from "@/hooks/useAsyncFn";
import useAsyncTap from "@/hooks/useAsyncTap";
import useCatchError from "@/hooks/useCatchError";

export default function StudentPointPage() {

  const params = useParams<{ id: string }>();
  const userID = parseInt(params.id);




  const { id } = useParams<{ id: string }>();

  const b = {userID}
  const [form] = Form.useForm();
  form.setFieldsValue({ userID: b.userID });


  const [, createPoint1] = useAsyncFn(

      useAsyncTap(ChangePointResource.create, () => {
        message.success('转介成功');
        form.resetFields();
        window.location.reload();
      })
  );

  const catchedCreatePoint1 = useCatchError(createPoint1);


  const api = React.useMemo(() => {
    return studentPoint(parseInt(id));
  }, [id]);
  const state = useAsync(api.query, [api.query]);
  return (

      <Box>

        <Card style={{ marginBottom: 16 }} title="积分增减">
          <Form
              form={form}
              onFinish={catchedCreatePoint1 as any}
          >


            <Form.Item  name="userID"  >
              {/*<Input />*/}
              <Input hidden={true}/>
            </Form.Item>


            {/*<Form.Item  name="userID" hidden={true} >*/}
            {/*    <Input />*/}
            {/*</Form.Item>*/}

            <Form.Item
                label="数额"
                name="quantity"

            >
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="原因" name="reason" >
              <Input />
            </Form.Item>
            <Box display="flex" justifyContent="flex-end">
              <Button htmlType="submit" type="primary">
                确定
              </Button>
            </Box>
          </Form>
        </Card>


        <Fallback
            state={state}
            initial={<PointList loading />}
            render={({ data, meta }, loading) => (
                <PointList title={() => `${meta.realname}的总积分：${meta.points}`} dataSource={data} loading={loading} />
            )}
        />

      </Box>


  );
}
