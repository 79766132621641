import { Box, Container } from '@material-ui/core';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Announcement from './Announcement';
import Contact from './Contact';

import ExChange from './Exchange';
import SearchHuman from './SearchHuman'
import Source from './Source'
import PassNote from './PassNote'
import Course from './Course';
import Dashboard from './Dashboard';
import Mall from './Mall';
import Member from './Member';
import Notification from './Notification';
import Order from './Order';
import Practice from './Practice';
import Profile from './Profile';
import Report from './Report';
import Student from './Student';
import AllChange from './AllChange';
import HorseLamp from './HorseLamp'

import InvitationCode from "@/pages/Admin/InvitationCode/index.tsx";

import SeeCode from "@/pages/Admin/SeeCode/index.tsx";

import InvitationCodeList  from "@/pages/Admin/InvitationCodeList/index";


import PromotionList from "@/pages/Admin/PromotionList/index";


export interface AdminProps {}

const Admin: React.FC<AdminProps> = () => {
  const { path } = useRouteMatch();
  return (
    <Box flex={1}>
      <Box clone pt={2} pb={8}>
        <Container>
          <Switch>
            <Route path={path} exact>
              <Dashboard />
            </Route>
            <Route path={`${path}/announcement`}>
              <Announcement />
            </Route>
            <Route path={`${path}/contact`}>
              <Contact />
            </Route>


            <Route path={`${path}/exchange`}>
              <ExChange />
            </Route>

            <Route path={`${path}/searchHuman`}>
              <SearchHuman />
            </Route>


            <Route path={`${path}/source`}>
              <Source />
            </Route>

            <Route path={`${path}/passNote`}>
              <PassNote />
            </Route>

            <Route path={`${path}/zhuanJie`}>
              <AllChange />
            </Route>

            <Route path={`${path}/horselamp`}>
              <HorseLamp />
            </Route>

            <Route path={`${path}/course`}>
              <Course />
            </Route>
            <Route path={`${path}/mall`}>
              <Mall />
            </Route>
            <Route path={`${path}/member`}>
              <Member />
            </Route>
            <Route path={`${path}/notification`}>
              <Notification />
            </Route>
            <Route path={`${path}/order`}>
              <Order />
            </Route>
            <Route path={`${path}/practice/:id?`}>
              <Practice />
            </Route>
            <Route path={`${path}/profile/:id`}>
              <Profile />
            </Route>
            <Route path={`${path}/report`}>
              <Report />
            </Route>
            <Route path={`${path}/student`}>
              <Student />
            </Route>

            <Route path={`${path}/invitationCode`}>
              <InvitationCode />
            </Route>





            <Route path={`${path}/seeCode`}>
              <SeeCode />
            </Route>

            <Route path={`${path}/promotionList`}>
              <PromotionList />
            </Route>



          </Switch>
        </Container>
      </Box>
    </Box>
  );
};

export default Admin;
