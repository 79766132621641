import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import image1 from '@/assets/1.jpg';
import image2 from '@/assets/2.jpg';
import image3 from '@/assets/3.jpg';
import { useHistory } from 'react-router-dom';
import {UserCourse} from "@/services/entity";
import GenseeVideo from "@/components/GenseeVideo";
import {Box} from "@material-ui/core";
import useAsyncFn from "@/hooks/useAsyncFn";
import * as course from "@/services/user";
import {message,Carousel } from "antd";
import {differenceInDays} from "date-fns";
import format from "date-fns/format";


const useStyles = makeStyles({
  root: {
    marginBottom: 16,
  },
  media: {
    height: 140,
  },
});

interface ActionCardProps {}

interface SysProps{
  courses: UserCourse;
  i:number;
}



const ActionCard:React.FC<SysProps> = (props)=>{
  const classes = useStyles();
  const history = useHistory();

  const {courses,i} = props

  // const aaa = [image1,image2,image3]

  console.log(courses)

  function createMarkup(str:any) {
    return {__html: str};
  };



  const [, startPractice] = useAsyncFn(async () => {
    // if (courses.state =='locked'){
    //   message.warning('该课程尚未解锁',1)
    // }else if(courses.courseState =='zanting'){
    //   message.warning('该课程已到期,请联系客服',1)
    // }else {
    //   window.location.href = `/course1/${courses.course.id}`
    // }

    if (courses.state =='locked'){
      message.warning('该课程尚未解锁',1)
    }else {
      window.location.href = `/course1/${courses.course.id}`
    }
  });






  return (

      <>

        {courses.content &&  i===0? (
            <Box>
              <Carousel >
                <div >
                  {/*<h3 style={{background:`#FFFFE0`, textAlign: 'left',fontSize: '14px'}}>dangerouslySetInnerHTML={createMarkup(courses.content)}</h3>*/}
                  <p style={{background:`#FFFFE0`, textAlign: 'left',fontSize: '14px',color:'#767676',fontFamily:'Microsoft JhengHei'}} dangerouslySetInnerHTML={createMarkup(courses.content)}/>
                </div>
              </Carousel>
            </Box>):(``)}


        {courses.course.kind.picurl ? (
            <Card
                className={classes.root}
                onClick={startPractice}
            >


              <CardActionArea>
                <CardMedia
                    className={classes.media}
                    image={courses.course.kind.picurl}
                />


                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {courses.course.kind? (courses.course.kind.name):(``)}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
        ):(``)}


      </>
  );
}

export default ActionCard

// export default function ActionCard() {
//   const classes = useStyles();
//   const history = useHistory();
//   return (
//     <Card
//       className={classes.root}
//       // variant="outlined"
//       onClick={() => history.push('/course1')}
//     >
//       <CardActionArea>
//         <CardMedia
//           className={classes.media}
//           image={image}
//         />
//         <CardContent>
//           <Typography gutterBottom variant="h5" component="h2">
//             系统课
//           </Typography>
//         </CardContent>
//       </CardActionArea>
//     </Card>
//   );
// }
