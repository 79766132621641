import Fallback from '@/components/Fallback';

import {
    QueryHistoryRecord, UserCourseResource,
} from '@/services/admin';

import {Col, Form, Row, Table} from 'antd';
import React from 'react';
import {useHistory, useParams} from "react-router-dom";
import useAsync from "@/hooks/useAsync";

export default function HistoryRecord() {

    const [form] = Form.useForm();
    const history = useHistory();
    const params = useParams<{ id: string,idd:string}>();

    const userID = parseInt(params.id);
    const courseID = parseInt(params.idd);

    // const listState = useAsync(
    //     () => QueryHistoryRecord(userID),
    //     [userID]
    // );


    const listState = useAsync(
        () => QueryHistoryRecord({ filter: { userID,courseID } }),
        [userID]
    );

    // const listState = React.useCallback(
    //     (courseID: number) =>
    //         history.push(`/user/historyRecord/${params.id}/${courseID}`),
    //     [history, params.id]
    // );


    return (
        <Fallback
            state={listState}
            render={( data) => (
                <>
                    <div  style={{'fontSize': '20px','fontWeight':'bold'}}>
                    {data.map((item,i)=>{
                        if (i==0){
                            return(
                                <Row>
                                    <Col xs={16}>{item.userName}:练习统计</Col>
                                </Row>
                            )
                        }
                    })}
                    </div>

                    <Table
                        title={() => (
                                <div  style={{'fontSize': '15px'}}>
                                    {data.map((item,i)=>{
                                        if (i==0){
                                            return(
                                                <Row >
                                                    开课日:<Col style={{ color: `#FF0000`}} xs={16}>{item.startTime}</Col>
                                                </Row>
                                            )
                                        }
                                    })}
                                </div>
                        )}
                        rowKey="id"
                        bordered
                        size="small"
                        style={{ minWidth: '100%' }}
                        dataSource={data}
                        pagination={ false }
                        columns={[
                            {
                                dataIndex: 'weekName',
                                title: '#',
                            },
                            {
                                dataIndex: 'weekTime_',
                                title: '周统计',
                                render: (value) => (
                                    value === -1 ? (
                                        `进行中`
                                    ) : (
                                        <span><span  style={{ color: `#FF0000`}} >{value}</span><span>分钟</span></span>
                                    )
                                ),
                            },
                            {
                                dataIndex: 'monthTime_',
                                title: '月统计',
                                render: (value) => (
                                    value <= 0 ? (
                                        ''
                                    ) : (
                                        <span><span  style={{ color: `#FF0000`}} >{value}</span><span>分钟</span></span>
                                    )
                                ),
                            },
                            {
                                dataIndex: 'weekLimit',
                                title: '周期',
                            },

                        ]}
                    />
                </>
            )}
        />
    );
}

