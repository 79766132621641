import React from 'react';

const GenseeVideo: React.FC<{ videoID: string;userID:number; videoToken?: string }> = ({
                                                                                           videoID,
                                                                                           videoToken,
                                                                                           userID,
                                                                                       }) => {


    const src = React.useMemo(() => {
        if (process.env.NODE_ENV === 'production') {
            const params = new URLSearchParams();
            params.set('videoID', videoID);
        }

        const html = `<html xmlns="http://www.w3.org/1999/xhtml">
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
    <title>H5 Demo1</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0"/>
    
    
<!--    视频边框黑边去除-->
    <style>

        div .ccH5Poster {
        background-color:white;
        }
        
        
        div .ccH5playerBox video{
        background-color:white;
        }

    </style>






</head>
<body>
<!--style="width: 101.5%;height: 100%"-->
    <div id="test"></div>
<script>


    window.onload = function() {
        video = document.querySelector('video');
        if (video) {
           video.setAttribute("controlsList", "nodownload");
        }
    };


    function onCCH5PlayerLoaded() {
        createPlayer();
    }

    function nextPlayer() {
        window.cc_js_Player.destroy();
        createPlayer();
    }

    
     // 'background-color': '#fffefe',
    
    function createPlayer() {
        window.createCCH5Player({
            'vid': '${videoID}',
            'siteid': '3FBC2289488A1532',
            'mediatype':1,
            'playtype': 1,
            'autoStart':false,
            'width': "100%",
            'height': "100%",
            'isShare':'false',
            'showDownload':'false',
            'background-color': '#1863d9',
            
            
           
              
            'parentNode': document.getElementById('test')
        });
    }

    function on_CCH5player_ready(obj) {
        console.log('CCH5player_ready', obj);
        
        /* 调用播放器api样例_设置音量*/
        window.cc_js_Player.setVolume(0.5);
    }

    /* 回调样例_监听音量改变*/
    function on_player_volumechange(volume, vid) {
        console.log('音量改变回调: ', volume, vid)
    }
</script>
<script src="https://p.bokecc.com/player?siteid=3FBC2289488A1532&newversion=true"></script>
</body>
</html>`;
        const blob = new Blob([html], {
            type: 'text/html',
        });
        return URL.createObjectURL(blob);
    }, [videoID, videoToken]);

    return (
        <iframe
            style={{
                width: '100%',
                height: '56vw',
                maxHeight: '335px',
                border: 'none',
                display: 'block',
            }}
            title="video"
            src={src}
        />
    );
};

export default GenseeVideo
