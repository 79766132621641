import Fallback from '@/components/Fallback';
import useAsync from '@/hooks/useAsync';
import { queryStatics } from '@/services/teacher';
import { Card, Col, Row, Space } from 'antd';
import qs from 'qs';
import React from 'react';
import { Link } from 'react-router-dom';

const TeacherDashboard: React.FC = () => {
  const state = useAsync(queryStatics);
  return (
    <Fallback
      state={state}
      render={(data) => (
        <div>
          <Space style={{ width: '100%' }} size="large" direction="vertical">
            <Card title="学生人数">
              <Row>
                <Col xs={8}>
                  <Link to="/teacher/students">
                    全部: {data.allStudentCount}
                  </Link>
                </Col>
                <Col xs={8}>
                  <Link
                    to={{
                      pathname: '/teacher/students',
                      search: qs.stringify({
                        filter: JSON.stringify({ state: 'normal' }),
                      }),
                    }}
                  >
                    正常: {data.normalStudentCount}
                  </Link>
                </Col>
                <Col xs={8}>
                  <Link
                    to={{
                      pathname: '/teacher/students',
                      search: qs.stringify({
                        filter: JSON.stringify({ state: 'suspended' }),
                      }),
                    }}
                  >
                    禁用: {data.suspendedStudentCount}
                  </Link>
                </Col>
              </Row>
            </Card>
            <Card title="我的琴师">
              <Row>
                <Col xs={24}>
                  <Link to="/teacher/assistants">
                    进行中: {data.allAssistantCount}位琴师
                  </Link>
                </Col>
              </Row>
            </Card>
            <Card title="我的班级">
              <Row>
                <Col xs={12}>
                  <Link
                    to={{
                      pathname: '/teacher/classes',
                      search: qs.stringify({
                        filter: JSON.stringify({ state: 'normal' }),
                      }),
                    }}
                  >
                    进行中: {data.normalClassCount}个班级
                  </Link>
                </Col>
                <Col xs={12}>
                  <Link
                    to={{
                      pathname: '/teacher/classes',
                      search: qs.stringify({
                        filter: JSON.stringify({ state: 'closed' }),
                      }),
                    }}
                  >
                    已结班: {data.closedClassCount}个班级
                  </Link>
                </Col>
              </Row>
            </Card>
            <Card title="学生提醒">
              <Row>
                <Col xs={8}>
                  <Link to="/teacher/tickets">
                    未处理: {data.pendingTicketCount}条
                  </Link>
                </Col>
              </Row>
            </Card>
          </Space>
        </div>
      )}
    />
  );
};

export interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
  return <TeacherDashboard />;
};

export default Dashboard;
