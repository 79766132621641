import { AsyncState } from '@/hooks/useAsyncFn';

type Element = React.ReactElement | null;

export function fallback<T>(
  state: AsyncState<T>,
  success: (value: T, loading: boolean) => Element,
  fallback: {
    loading?: () => Element;
    failure?: (error: Error) => Element;
  } = {}
): Element {
  const render = () => {
    if (state.error) {
      return fallback.failure ? fallback.failure(state.error) : null;
    }
    if (!state.initialized) {
      return fallback.loading ? fallback.loading() : null;
    }
    return success(state.value as T, state.loading);
  };
  return render();
}

interface FallbackProps<T> {
  state: AsyncState<T>;
  render: (value: T, loading: boolean) => Element;
  initial?: Element;
  error?: (error: Error) => Element;
}
function Fallback<T>({ state, render, initial, error }: FallbackProps<T>) {
  return fallback<T>(state, render, {
    ...(initial ? { loading: () => initial } : {}),
    ...(error ? { failure: error } : {}),
  });
}

export default Fallback;
