import DateTime from '@/components/DateTime';
import MultilineText from '@/components/MultilineText';
import Username1 from '@/components/Username1';
import { Lesson } from '@/services/entity';
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Typography,
} from '@material-ui/core';
import React from 'react';

const NoteList: React.FC<{ lesson: Lesson }> = ({ lesson }) => {
  return (
    <List
      subheader={
        <ListSubheader style={{ backgroundColor: '#fff' }}>
          <Box display="flex" justifyContent="space-between">
            <Box>本课笔记</Box>
          </Box>
        </ListSubheader>
      }
    >
      {lesson.notes.map((note, index) => (
        <React.Fragment key={note.id}>
          {index > 0 && <Divider variant="middle" component="li" />}
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar src={note.author.avatar} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Box display="flex" justifyContent="space-between">
                  <Username1 value={note.author} />
                </Box>
              }
              secondary={
                <Box mt={1}>
                  <MultilineText color="text.primary" value={note.content} />
                  <Box mt={1} textAlign="right">
                    <Typography variant="caption">
                      <DateTime value={note.createdAt} />
                    </Typography>
                  </Box>
                </Box>
              }
            />
          </ListItem>
        </React.Fragment>
      ))}
    </List>
  );
};

export default NoteList;
