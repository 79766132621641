import React from 'react';
import ProfilePanel from '@/components/ProfilePanel';
import { useProfile } from './context';
import { changeAvatar } from '@/services/user';
export interface ProfileProps {}

const Profile: React.FC<ProfileProps> = () => {
  const { profile, logout, refresh } = useProfile();
  return (
    <ProfilePanel
      profile={profile}
      logout={logout}
      updateAvatar={async (avatar: File) => {
        await changeAvatar(avatar);
        refresh();
      }}
    />
  );
};

export default Profile;
