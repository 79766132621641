import React from 'react';
import { User } from '@/services/entity';
import { Box, makeStyles, Chip } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    '& $chip': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  inverse: {
    color: theme.palette.primary.contrastText,
    '& $chip': {
      color: theme.palette.primary.main,
      background: theme.palette.primary.contrastText,
    },
  },
  chip: {
    height: 16,
    '& .MuiChip-label': {
      paddingLeft: 6,
      paddingRight: 6,
    },
  },
}));

export interface UesrnameProps1 {
  value: User;
  inverse?: boolean;
}
export default function Username1({ value, inverse }: UesrnameProps1) {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.root, inverse && classes.inverse)}>
      <Box
        mr="6px"
        maxWidth="80px"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        {value.realname}
      </Box>
    </Box>
  );
}
