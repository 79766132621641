import Fallback from '@/components/Fallback';
import { createSubmitModal, createSubmitTrigger } from '@/components/ResourceManage';
import useAsyncFn from '@/hooks/useAsyncFn';
import { ContactLogResource } from '@/services/admin';
import { Interface, PickMuteable } from '@/services/api';
import { ContactLog } from '@/services/entity';
import { Button, Form, Input, Table } from 'antd';
import { FormProps } from 'antd/lib/form';
import { TableProps } from 'antd/lib/table';
import format from 'date-fns/format';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

type Entity = ContactLog;
const resource = ContactLogResource;

const RecordForm: React.FC<FormProps> = (props) => {
  return (
    <Form size="large" labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }} {...props}>
      <Form.Item label="备注" name="remark">
        <Input />
      </Form.Item>
    </Form>
  );
};

type EntityMutationFn = Interface<PickMuteable<Entity>, Entity>;

const SubmitModal = createSubmitModal<Entity>(RecordForm);
const SubmitTrigger = createSubmitTrigger(SubmitModal);

interface LogTableProps extends TableProps<Entity> {}
const LogTable: React.FC<LogTableProps> = () => {
  const match = useRouteMatch<{ id: string }>();

  const [state, query] = useAsyncFn(resource.query);
  React.useEffect(() => {
    query({});
  }, [query]);

  const assocProfileID: (fn: EntityMutationFn) => EntityMutationFn = (fn) => {
    return (params, config) => {
      return fn({ ...params, profileID: parseInt(match.params.id) }, config);
    };
  };

  const props: TableProps<Entity> = {
    rowKey: 'id',
    style: {
      marginBottom: 8,
    },
    title: () => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span>联络详情</span>
        <SubmitTrigger
          ModalProps={{
            title: '添加联络',
            centered: true,
            onSubmitted: state.retry,
            submit: assocProfileID(resource.create),
          }}
          render={({ open }) => (
            <Button type="link" onClick={open}>
              添加
            </Button>
          )}
        />
      </div>
    ),
    bordered: true,
    size: 'small',
    columns: [
      {
        dataIndex: 'id',
        title: '#',
      },
      {
        dataIndex: 'promoter',
        title: '联络人',
        render: (promoter) => promoter.username,
      },
      {
        dataIndex: 'remark',
        title: '备注',
      },
      {
        dataIndex: 'createdAt',
        title: '时间',
        render: (createdAt) => format(new Date(createdAt), 'yyyy-MM-dd'),
      },
      {
        title: '操作',
        render: (_, record) => {
          return (
            <>
              <SubmitTrigger
                ModalProps={{
                  title: '编辑联络',
                  centered: true,
                  submit: assocProfileID(resource.update(record.id)),
                  onSubmitted: state.retry,
                  FormProps: { initialValues: record },
                }}
                render={({ open }) => (
                  <Button type="link" onClick={open}>
                    编辑
                  </Button>
                )}
              />
            </>
          );
        },
      },
    ],
  };

  return (
    <Fallback
      state={state}
      initial={<Table {...props} />}
      render={(data, loading) => (
        <Table {...props} dataSource={data.data} loading={loading} />
      )}
    />
  );
};

export default LogTable;
