import AppTabs from '@/components/AppTabs';
import Fallback from '@/components/Fallback';
import useAsync from '@/hooks/useAsync';
import { ContactProfileResource } from '@/services/admin';
import { ContactProfile } from '@/services/entity';
import { Tab } from '@material-ui/core';
import { Button, Card, Descriptions } from 'antd';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import AgreementTable from './AgreementTable';
import ApplyTable from './ApplyTable';
import LogTable from './LogTable';
import PaymentTable from './PaymentTable';
import { SubmitTrigger } from './useProfileTab';

function Gender({ value }: { value?: string }) {
  if (value === 'male') {
    return <span>男</span>;
  }
  if (value === 'female') {
    return <span>女</span>;
  }
  return null;
}

const ProfileDetail: React.FC = () => {
  const match = useRouteMatch<{ id: string }>();
  const state = useAsync(() =>
    ContactProfileResource.find(parseInt(match.params.id))
  );

  const field = (render: (profile: ContactProfile) => React.ReactNode) => (
    <Fallback
      state={state}
      initial={<span>-</span>}
      render={(profile) => <>{render(profile)}</>}
    />
  );

  return (
    <AppTabs
      tabs={[
        {
          path: `logs`,
          tab: <Tab label="表单信息" />,
          panel: (
            <div>
              <Card
                style={{ marginBottom: 8 }}
                title="基本信息"
                extra={
                  <Fallback
                    state={state}
                    render={(record) => {
                      return (
                        <SubmitTrigger
                          ModalProps={{
                            title: '编辑表单',
                            centered: true,
                            submit: ContactProfileResource.update(record.id),
                            onSubmitted: state.retry,
                            FormProps: { initialValues: record },
                          }}
                          render={({ open }) => (
                            <Button type="link" onClick={open}>
                              编辑
                            </Button>
                          )}
                        />
                      );
                    }}
                  ></Fallback>
                }
              >
                <Descriptions>
                  <Descriptions.Item label="姓名">
                    {field((profile) => profile.realname)}
                  </Descriptions.Item>
                  <Descriptions.Item label="性别">
                    {field((profile) => (
                      <Gender value={profile.gender} />
                    ))}
                  </Descriptions.Item>
                  <Descriptions.Item label="电话">
                    {field((profile) => profile.mobile)}
                  </Descriptions.Item>
                  <Descriptions.Item label="微信">
                    {field((profile) => profile.wechat)}
                  </Descriptions.Item>
                  <Descriptions.Item label="地址">
                    {field((profile) => profile.address)}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
              <LogTable />
              <Card style={{ marginBottom: 8 }} title="其他信息">
                <Descriptions>
                  <Descriptions.Item label="年龄">
                    {field((profile) => profile.age)}
                  </Descriptions.Item>
                  <Descriptions.Item label="职业">
                    {field((profile) => profile.occupation)}
                  </Descriptions.Item>
                  <Descriptions.Item label="爱好">
                    {field((profile) => profile.hobby)}
                  </Descriptions.Item>
                  <Descriptions.Item label="基础">
                    {field((profile) => profile.foundation)}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </div>
          ),
        },
        {
          path: `payments`,
          tab: <Tab label="缴费申请" />,
          panel: (
            <div>
              <PaymentTable />
              <AgreementTable />
              <ApplyTable />
            </div>
          ),
        },
      ]}
    />
  );
};

export default ProfileDetail;
