import React from 'react';
import useAsync from '@/hooks/useAsync';
import { LectureResource } from '@/services/admin';
import { useParams } from 'react-router-dom';
import Fallback from '@/components/Fallback';
import GenseeVideo1 from '@/components/GenseeVideo1';
import { Box, Typography, Container } from '@material-ui/core';

const LecturePlay: React.FC = () => {
  const params = useParams<{ id: string }>();
  const lectureID = React.useMemo(() => parseInt(params.id), [params.id]);
  const state = useAsync(() => LectureResource.find(lectureID), [lectureID]);

  return (
    <Fallback
      state={state}
      render={(lecture) => (
        <Container maxWidth="sm">
          <Box mb={2}>
            <Box mb={1}>
              {/*{lecture.genseeCourseware ? (*/}
              {/*  <GenseeVideo videoID={lecture.videoID} />*/}
              {/*) : (*/}
              {/*  <video*/}
              {/*    style={{ width: '100%', maxWidth: '100%' }}*/}
              {/*    src={lecture.url}*/}
              {/*    controls*/}
              {/*  />*/}
              {/*)}*/}
                <GenseeVideo1 videoID={lecture.videoID} />
            </Box>
            <Typography variant="h6" style={{ textAlign: 'center' }}>
              {lecture.name}
            </Typography>
          </Box>
        </Container>
      )}
    />
  );
};

export default LecturePlay;
