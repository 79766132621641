import Fallback from '@/components/Fallback';
import SearchSelect from '@/components/SearchSelect';
import useAsyncFn from '@/hooks/useAsyncFn';
import useAsyncTap from '@/hooks/useAsyncTap';
import useCatchError from '@/hooks/useCatchError';
import { applyFilter } from '@/services/api';
import {
  classRemainPoint,
  PointResource,
  UserResource,
} from '@/services/assistant';


import {SourceResource} from '@/services/admin';

import { Horse} from '@/services/entity';
import { Box } from '@material-ui/core';
import {Button, Card, Col, Divider, Form, Input, InputNumber, message, Popconfirm, Table} from 'antd';
import { TableProps } from 'antd/lib/table';
import format from 'date-fns/format';
import React, { useEffect, useMemo } from 'react';
import {Link, useParams} from 'react-router-dom';
import useAsync from "@/hooks/useAsync";

import Editor1 from "@/components/Editor1/Editor1"





const Source: React.FC = () => {
  // const params = useParams<{classID: string}>();
  // const classID = useMemo(() => parseInt(params.classID), [params.classID]);


    const params = useParams<{ id: string }>();
    const userID = parseInt(params.id);


  const [form] = Form.useForm();


  const [, createPoint] = useAsyncFn(

    useAsyncTap(SourceResource.create, () => {
      // message.success('新增成功');
      form.resetFields();
      //   window.location.reload();

        window.location.href="/";

        setTimeout(function () {
            window.location.reload();
        }, 1000);



        // setTimeout(function () {
        //     window.location.href="http://localhost:3000/admin/horselamp";
        // }, 2000);


    })
  );

  const catchedCreatePoint = useCatchError(createPoint);

    const { TextArea } = Input;


    const sleep = (milliseconds:any) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }


    const resetFields =(state:any)=>{
        form.setFieldsValue({content:state})
        form.submit()
    }


    const setShop =(state:any)=>{
        form.setFieldsValue({ is_publish: 2 });
        form.setFieldsValue({content:state})
        form.submit()
    }

    const setContent =(state:any)=>{
        form.setFieldsValue({ is_publish: 1 });
        form.setFieldsValue({content:state})
        form.submit()
    }

    function formProcess() {
        form.setFieldsValue({ is_publish: 1 });
        form.submit()

    };

    return (
        <div>
            <div>
                <Card style={{ marginBottom: 16 }} title="修改">
                    <Form
                        form={form}
                        onFinish={catchedCreatePoint as any}
                    >

                        {/*<Form.Item  name="is_publish" hidden={true} >*/}
                        {/*    <Input />*/}
                        {/*</Form.Item>*/}

                        <Form.Item  name="is_publish"  >
                            <Input />
                        </Form.Item>

                        <Form.Item label="内容" name="content" >
                            {/*<TextArea  maxLength={100} style={{ height: 120 }}/>*/}
                            <Editor1 id={"text"}  resetFields={resetFields}  setContent={setContent}  setShop={setShop}/>
                        </Form.Item>
                        {/*<Box display="flex" justifyContent="flex-end">*/}
                        {/*    <Button htmlType="submit" type="primary">*/}
                        {/*      确定*/}
                        {/*    </Button>*/}
                        {/*      <br />*/}
                        {/*      <Button danger type="primary"  onClick={() => formProcess()}>*/}
                        {/*          发布*/}
                        {/*      </Button>*/}
                        {/*</Box>*/}


                    </Form>
                </Card>
            </div>


        </div>

  );
};

export default Source;
