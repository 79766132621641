import Fallback from '@/components/Fallback';
import useAsyncFn from '@/hooks/useAsyncFn';
import * as auth from '@/services/auth';
import { userProfile } from '@/services/user';
import { User } from '@/services/entity';
import React, { createContext, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AuthorizeRedirect from './AuthorizeRedirect';

const ProfileContext = createContext<
  | {
      profile: User;
      logout: () => Promise<void>;
      refresh: () => void;
    }
  | undefined
>(undefined);

export const ProfileProvider: React.FC = ({ children }) => {
  const [state, fetch] = useAsyncFn(userProfile);
  useEffect(() => {
    fetch();
  }, [fetch]);
  const history = useHistory();
  const [, logout] = useAsyncFn(async () => {
    await auth.logout();
    history.push('/');
    fetch();
  });

  return (
    <Fallback
      state={state}
      render={(profile) => (
        <ProfileContext.Provider
          value={{ profile, logout, refresh: state.retry }}
        >
          {children}
        </ProfileContext.Provider>
      )}
    />
  );
};

export const AuthorizeProvider: React.FC = ({ children }) => {
  return (
    <AuthorizeRedirect>
      <ProfileProvider>{children}</ProfileProvider>
    </AuthorizeRedirect>
  );
};

export function useProfile() {
  const context = useContext(ProfileContext);
  if (!context) {
    throw new Error(
      'Invariant failed: You should not use useProfile outside a <ProfileContext>'
    );
  }
  return context;
}


export function useInfo() {
    const context = useContext(ProfileContext);
    if (!context) {
        throw new Error(
            'Invariant failed: You should not use useProfile outside a <ProfileContext>'
        );
    }
    return context;
}



export default ProfileContext;
