import React from 'react';
import { matchPath, Route, RouteProps, useLocation } from 'react-router-dom';

import BottomNavigation from './BottomNavigation';
import Course from '../Course';
import Kind from '../Kind';
import Forum from '../Forum';
import Leaderboard from '../Leaderboard';
import ShoppingMall from '../ShoppingMall';
import { Box, Container } from '@material-ui/core';




//首页
const Home: React.FC = () => {
  const location = useLocation();




  const routes: RouteProps[] = [
    // { path: '/', exact: true, component: Course },
    { path: '/', exact: true, component: Kind },
    { path: '/forum', component: Forum },
    { path: '/leaderboard', component: Leaderboard },
    { path: '/shoppingmall', component: ShoppingMall },
  ];
  const index = routes.findIndex((route) =>
    matchPath(location.pathname, route)
  );
  return (
    <>
      <Box flex={1}>
        <Container style={{ padding: 0, paddingBottom: 62 }} maxWidth="sm">
          {routes.map((route, index) => (
            <Route key={index} {...route} />
          ))}
        </Container>
      </Box>
      <BottomNavigation value={index} />
    </>
  );
};

export default Home;
