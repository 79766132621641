

import {CommShop,SubPoints} from '@/services/user';
import {Box, Button} from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';


import useAsync from "@/hooks/useAsync";
import {message, Popconfirm} from "antd";
import {ContactProfileResource} from "@/services/admin";
import Clipboard from 'clipboard';
import {useHistory, useParams} from "react-router-dom";

interface MallProps{
    mainUrl: string;
    oneUrl: string;
    twoUrl: string;
    p1: string;
    p2: string;
    p3: string;
    points:number;
    shopcontent: string;
}



const p1: React.CSSProperties = {
    // boxSizing: "border-box",
    // marginTop: "4px",
    // marginBottom: "4px",
    // color: "rgba(0, 0, 0, 0.65)",
    // fontSize: "14px",
    // whiteSpace: "normal",
    // backgroundColor: "rgb(255, 255, 255)",
    // // textAlign: "justify"
    //
    //
    // bottom: -30,
    //
    //
    // right: "11%",
    //
    // position: "relative",
    // margin: "0 auto",
    // // maxWidth: "337px",
    // textAlign:"center"



    bottom: -30,
    position: "relative",
    margin: "0 auto",
    maxWidth: "342px",
    textAlign:"left"



}


const p2: React.CSSProperties = {
    // boxSizing: "border-box",
    // marginTop: "4px",
    // marginBottom: "4px",
    // color: "rgba(0, 0, 0, 0.65)",
    // fontSize: "14px",
    // whiteSpace: "normal",
    // backgroundColor: "rgb(255, 255, 255)",
    //
    //
    // bottom: -30,
    //
    //
    // right: "6%",
    //
    // position: "relative",
    // margin: "0 auto",
    // textAlign:"center"

    bottom: -30,
    position: "relative",
    margin: "0 auto",
    maxWidth: "280px",
    textAlign:"left"
}



const p3: React.CSSProperties = {
    boxSizing: "border-box",
    marginTop: "4px",
    marginBottom: "4px",
    color: "rgba(0, 0, 0, 0.65)",
    fontSize: "14px",
    whiteSpace: "normal",
    backgroundColor: "rgb(255, 255, 255)",
    // textAlign: "justify"


    bottom: -30,


    right: "4.2%",

    position: "relative",
    margin: "0 auto",
    // maxWidth: "337px",
    textAlign:"center"
}


const p4: React.CSSProperties = {
    // boxSizing: "border-box",
    // marginTop: "4px",
    // marginBottom: "4px",
    // color: "rgba(0, 0, 0, 0.65)",
    // fontSize: "14px",
    // whiteSpace: "normal",
    // backgroundColor: "rgb(255, 255, 255)",
    // bottom: -30,
    // right: "3.9%",
    // position: "relative",
    // margin: "0 auto",
    // textAlign:"center"

    bottom: -30,
    position: "relative",
    margin: "0 auto",
    maxWidth: "400px",
    textAlign:"left"
}



const p5: React.CSSProperties = {
    // boxSizing: "border-box",
    // marginTop: "4px",
    // marginBottom: "4px",
    // color: "rgba(0, 0, 0, 0.65)",
    // fontSize: "14px",
    // whiteSpace: "normal",
    // backgroundColor: "rgb(255, 255, 255)",
    // bottom: -30,
    // right: "3.9%",
    // position: "relative",
    // margin: "0 auto",
    // textAlign:"center"

    bottom: -47,
    position: "relative",
    margin: "0 auto",
    maxWidth: "400px",
    textAlign:"left",
    fontSize: "15px"
}

const p6: React.CSSProperties = {

    bottom: -41,
    position: "relative",
    margin: "0 auto",
    maxWidth: "335px",
    textAlign:"left",
    fontSize: "15px"
}


const p62: React.CSSProperties = {

    bottom: -43,
    position: "relative",
    margin: "0 auto",
    maxWidth: "400px",
    textAlign:"left",
}


const p61: React.CSSProperties = {

    bottom: -30,
    position: "relative",
    margin: "0 auto",
    maxWidth: "280px",
    textAlign:"left",
}


const p7: React.CSSProperties = {

    bottom: -40,
    position: "relative",
    margin: "0 auto",
    maxWidth: "335px",
    textAlign:"left",
}


const p71: React.CSSProperties = {

    bottom: -35,
    position: "relative",
    margin: "0 auto",
    maxWidth: "280px",
    textAlign:"left",
}

const img8: React.CSSProperties = {

    //自适应
    display: "inline-block",
    // width: "auto",
    maxWidth: "100%",
    height: "auto",


}




const span1: React.CSSProperties = {
    boxSizing: "border-box",
    fontFamily: "Microsoft JhengHei",
    color: "rgb(227, 108, 9)",
    letterSpacing: "0px",
    fontSize: "15px",
    backgroundImage: "initial",
    backgroundPosition: "initial",
    backgroundSize: "initial",
    backgroundRepeat: "initial",
    backgroundAttachment: "initial",
    backgroundOrigin: "initial",
    backgroundClip: "initial"
}


const span2: React.CSSProperties = {
    boxSizing: "border-box",
    fontFamily: "Microsoft JhengHei",
    color: "rgb(124, 124, 124)",
    letterSpacing: "0px",
    fontSize: "15px",
    backgroundImage: "initial",
    backgroundPosition: "initial",
    backgroundSize: "initial",
    backgroundRepeat: "initial",
    backgroundAttachment: "initial",
    backgroundOrigin: "initial",
    backgroundClip: "initial"
}



const Ios:React.FC<MallProps> = (props)=>{

    const {mainUrl,oneUrl,twoUrl,points,shopcontent,p1,p2,p3} = props

    function createMarkup(str:any) {
        return {__html: str};
    };


    function oneClick(urls:string){

        window.location.href =urls

    }


    function oneClick1(url:string,p:number,types:number) {

        if (types == 1){
            if (p < 10000){

                message.error({
                    content: '您的琴贝不足~',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                })

            }else{
                SubPoints(-10000)
                window.location.href =url
            }
        }

        if (types == 2){
            if (p < 20000){

                message.error({
                    content: '您的琴贝不足~',
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                })

            }else{
                SubPoints(-20000)
                window.location.href =url
            }
        }
    };






    var userAgent = navigator.userAgent;

    //苹果
    if (userAgent.indexOf("iPhone") > -1) {
        return (
            <Box >


                <div style={ant_col_iosa}   dangerouslySetInnerHTML={createMarkup(shopcontent)}/>

                <div
                    style={ant_col_ios}
                >

                    <a href={mainUrl}>
                    <img style={img}
                         src={p1}
                    />
                    </a>
                </div>

                <div
                    style={ant_col_ios11}
                >


                        <a href={twoUrl}>
                            <img style={img}
                                 src={p3}
                            />
                        </a>

                </div>


                <div
                    style={ant_col_ios22}
                >


                        <a href={oneUrl}>
                            <img style={img}

                                 src={p2}
                            />
                        </a>

                </div>

                {/*<div*/}
                {/*    style={ant_col_ios2}*/}
                {/*>*/}
                {/*   长按保存图片*/}
                {/*</div>*/}



                {/*<p style={p1}>*/}
                {/*        <span style={span1}>*/}
                {/*            推荐导语︱*/}
                {/*        </span>*/}
                {/*    <span style={span2}>*/}
                {/*            可直接复制，也可自己撰写*/}
                {/*        </span>*/}
                {/*</p>*/}


                {/*<div style={ant_col_ios3}  dangerouslySetInnerHTML={createMarkup(content)}/>*/}


                {/*<p style={p6}>*/}
                {/*    <a*/}
                {/*        data-clipboard-text={trimcontent}*/}
                {/*        className="btn"*/}
                {/*        onClick={click}*/}
                {/*    >点击一键复制</a>*/}
                {/*</p>*/}
                {/*<p style={p7}>*/}
                {/*    <img style={img1}*/}
                {/*         src={`https://teach-vod.oss-cn-hangzhou.aliyuncs.com/t.gif`}*/}
                {/*    />*/}
                {/*</p>*/}


                {/*<Button  onClick={() => {*/}
                {/*    reset(index)*/}
                {/*}}  >换一张</Button>*/}

            </Box>
        )
    }else{
        return (
            <Box >

                <div style={ant_col_androida}   dangerouslySetInnerHTML={createMarkup(shopcontent)}/>

                <div
                    style={ant_col_android}
                >



                        {/*<a  onClick={() =>oneClick(mainUrl)}>*/}
                    <a href={mainUrl}>
                    <img style={img}
                         src={p1}
                    />
                    </a>
                </div>

                <div
                    style={ant_col_android1}
                >


                        <a href={twoUrl}>
                            <img style={img}
                                 src={p3}
                            />
                        </a>

                </div>

                <div
                    style={ant_col_android2}
                >

                        <a href={oneUrl}>
                            <img style={img}
                                 src={p2}
                            />
                        </a>

                </div>

                {/*<div*/}
                {/*    style={ant_col_android2}*/}
                {/*>*/}
                {/*    长按保存图片*/}
                {/*</div>*/}


                {/*<p style={p2}>*/}
                {/*        <span style={span1}>*/}
                {/*            推荐导语︱*/}
                {/*        </span>*/}
                {/*    <span style={span2}>*/}
                {/*            可直接复制，也可自己撰写*/}
                {/*        </span>*/}
                {/*</p>*/}

                {/*<div style={ant_col_android3}  dangerouslySetInnerHTML={createMarkup(content)}/>*/}



                {/*<p style={p61}>*/}
                {/*    <a*/}
                {/*        data-clipboard-text={trimcontent}*/}
                {/*        className="btn"*/}
                {/*        onClick={click}*/}
                {/*    >点击一键复制</a>*/}
                {/*</p>*/}
                {/*<p style={p71}>*/}
                {/*    <img style={img1}*/}
                {/*         src={`https://teach-vod.oss-cn-hangzhou.aliyuncs.com/t.gif`}*/}
                {/*    />*/}
                {/*</p>*/}

                {/*<Button  onClick={() => {*/}
                {/*    reset(index)*/}
                {/*}}  >换一张</Button>*/}

            </Box>
        )
    }

}

//

const ShoppingMall: React.FC = () => {


    const product = useAsync(() =>
        CommShop()
    );


    function winShow() {

        message.error({
            content: '请您在【手机】上使用！',
            className: 'custom-class',
            style: {
                marginTop: '20vh',
            },
        })
    }


    // function oneClick(url:string,p:number,types:number) {
    //
    //     if (types == 1){
    //         if (p < 10000){
    //
    //             message.error({
    //                 content: '您的琴贝不足~',
    //                 className: 'custom-class',
    //                 style: {
    //                     marginTop: '20vh',
    //                 },
    //             })
    //
    //         }else{
    //
    //             const point = useAsync(() =>
    //                 SubPoints(-10000)
    //             );
    //             const bbb =point.value;
    //
    //             const quantity = bbb?(bbb.quantity):(0)
    //
    //             if (quantity < -10000){
    //                 message.error({
    //                     content: '您的琴贝不足~',
    //                     className: 'custom-class',
    //                     style: {
    //                         marginTop: '20vh',
    //                     },
    //                 })
    //             }else{
    //                 window.location.href =url
    //             }
    //         }
    //     }
    //
    //     if (types == 2){
    //         if (p < 20000){
    //
    //             message.error({
    //                 content: '您的琴贝不足~',
    //                 className: 'custom-class',
    //                 style: {
    //                     marginTop: '20vh',
    //                 },
    //             })
    //
    //         }else{
    //             const point = useAsync(() =>
    //                 SubPoints(-20000)
    //             );
    //             const bbb =point.value;
    //
    //             const quantity = bbb?(bbb.quantity):(0)
    //
    //             if (quantity < -20000){
    //                 message.error({
    //                     content: '您的琴贝不足~',
    //                     className: 'custom-class',
    //                     style: {
    //                         marginTop: '20vh',
    //                     },
    //                 })
    //             }else{
    //                 window.location.href =url
    //             }
    //
    //
    //         }
    //     }
    // };


    function createMarkup(str:any) {
        return {__html: str};
    };


    const aaa =product.value;

    console.log(product)

    const mainUrl = aaa?(aaa.mainUrl):(``)
    const oneUrl = aaa?(aaa.oneUrl):(``)
    const twoUrl = aaa?(aaa.twoUrl):(``)
    const points = aaa?(aaa.points):(0)

    const p1 = aaa?(aaa.p1):(``)
    const p2 = aaa?(aaa.p2):(``)
    const p3 = aaa?(aaa.p3):(``)

    const shopcontent = aaa?(aaa.shopcontent):(``)


    var userAgent = navigator.userAgent;



    //桌面
    if (userAgent.indexOf("Windows") > -1) {


        return (
            <Box >

                <div style={ant_col_windowsa}   dangerouslySetInnerHTML={createMarkup(shopcontent)}/>

                <div
                    style={ant_col_windows}
                >
                    <a href={mainUrl}>
                    <img style={img}
                         src={p1}
                    />
                    </a>
                </div>

                <div
                    style={ant_col_windows11}
                >

                    <a href={twoUrl}>
                    <img style={img}
                           src={p3}
                    />
                        </a>
                </div>

                <div
                    style={ant_col_windows2}
                >
                    <a href={oneUrl}>
                    <img style={img}
                         src={p2}
                    />
                    </a>
                </div>


            </Box>
        )

    }else{
        //安卓,ios
        return (
            <Ios  mainUrl={mainUrl}  oneUrl={oneUrl} twoUrl={twoUrl} shopcontent={shopcontent} p1={p1} p2={p2} p3={p3}  points={points}/>
        )
    }
};



const bt_android: React.CSSProperties = {
    width: "50px",
    position: "fixed",
    // bottom: "20px",
    right: "80%",
    fontSize: 0,
    lineHeight: 0,
    zIndex: 100,

    bottom: -10
}


const bt: React.CSSProperties = {
    // position: "sticky",
    // height: "40px",
    // width: "40px",`
    // bottom: "90px",
    // right: "50px",
    // backgroundPosition: "0 -62px"


    width: "50px",
    position: "fixed",
    bottom: "20px",
    right: "70%",
    fontSize: 0,
    lineHeight: 0,
    zIndex: 100,

}

const bt_windows: React.CSSProperties = {
    width: "170px",
    position: "fixed",
    // bottom: "20px",
    right: "54.8%",
    fontSize: 0,
    lineHeight: 0,
    zIndex: 100,

    bottom: -5

}


const a: React.CSSProperties = {
    width: "350px",
    height: "50px",
    display: "inline-block",
    backgroundColor: "#cc0000",
    marginBottom: "2px",
    fontSize:18,
    color:"#fff",
    // backgroundPosition: "0 -266px"
}

const b: React.CSSProperties = {
    width: "160px",
    height: "50px",
    display: "inline-block",
    backgroundColor: "#F7F7F7",
    marginBottom: "2px",
    fontSize:18,
    color:"#202124",
}


const bt_ios: React.CSSProperties = {
    width: "50px",
    position: "fixed",
    // bottom: "20px",
    right: "82%",
    fontSize: 0,
    lineHeight: 0,
    zIndex: 100,

    bottom: -10
}

const a_ios: React.CSSProperties = {
    width: "195px",
    height: "45px",
    display: "inline-block",
    backgroundColor: "#cc0000",
    marginBottom: "2px",
    fontSize:18,
    color:"#fff",
    // backgroundPosition: "0 -266px"
}

const b_ios: React.CSSProperties = {
    width: "125px",
    height: "45px",
    display: "inline-block",
    backgroundColor: "#F7F7F7",
    marginBottom: "2px",
    fontSize:18,
    color:"#202124",
}



const a_android: React.CSSProperties = {
    width: "170px",
    height: "45px",
    display: "inline-block",
    backgroundColor: "#cc0000",
    marginBottom: "2px",
    fontSize:18,
    color:"#fff",
    // backgroundPosition: "0 -266px"
}

const b_android: React.CSSProperties = {
    width: "100px",
    height: "45px",
    display: "inline-block",
    backgroundColor: "#F7F7F7",
    marginBottom: "2px",
    fontSize:18,
    color:"#202124",
}


const img: React.CSSProperties = {
    // maxWidth: "128px",
    // maxHeight: "128px",
    // height:"auto",
    // zoom:"expression( function(e) {if(e.width>e.height) {if (e.width>128) { e.height = e.height*(128 /e.width); e.width=128; }}else {if (e.height>128) { e.width = e.width*(128 /e.height); e.height=128; }}e.style.zoom = '1';     }(this));",
    // overflow:"hidden"

    //自适应
    display: "inline-block",
    width: "auto",
    maxWidth: "100%",
    height: "auto"
}

const img1: React.CSSProperties = {

    //自适应
    display: "inline-block",
    // width: "auto",
    maxWidth: "101%",
    height: "auto",
    // marginLeft: "0.5cm",
    // marginTop: "-19px"
}

const img6: React.CSSProperties = {

    //自适应
    display: "inline-block",
    // width: "auto",
    maxWidth: "23%",
    height: "auto",
    marginLeft: "18cm",
    marginTop: "-19px"
}


const img7: React.CSSProperties = {

    //自适应
    display: "inline-block",
    // width: "auto",
    maxWidth: "21%",
    height: "auto",
    marginLeft: "20cm",
    marginTop: "-19px"
}


const img2: React.CSSProperties = {
    // display: "inline-block",
    // width: "auto",
    position: "relative",
    maxWidth: "90%",
    // height: "90%"
}


const ant_col_windowsa: React.CSSProperties = {
    background:`#FFFFE0`,
    bottom: -20,
    position: "relative",
    margin: "0 auto",
    marginLeft:"18px",
    marginRight:"18px",
    // maxWidth: "500px"
}

const ant_col_windows: React.CSSProperties = {
    bottom: -20,
    position: "relative",
    margin: "0 auto",
    // maxWidth: "500px"
    marginLeft:"18px",
    marginRight:"18px",
}

const ant_col_windows11: React.CSSProperties = {
    bottom: -50,
    position: "relative",
    margin: "0 auto",
    // maxWidth: "500px"
    marginLeft:"18px",
    marginRight:"18px",
}

const ant_col_windows2: React.CSSProperties = {
    bottom: -80,
    position: "relative",
    margin: "0 auto",
    // maxWidth: "500px"
    marginLeft:"18px",
    marginRight:"18px",
    marginBottom:"80px",
}

const ant_col_windows1: React.CSSProperties = {
    bottom: -10,
    position: "relative",
    margin: "0 auto",
    maxWidth: "400px",
    textAlign:"center"
}


const ant_col_windows3: React.CSSProperties = {
    bottom: -40,
    position: "relative",
    margin: "0 auto",
    maxWidth: "400px",
    textAlign:"center"

}


const ant_col_androida: React.CSSProperties = {
    background:`#FFFFE0`,
    bottom: -10,
    position: "relative",
    margin: "0 auto",
    // maxWidth: "285px"
    marginLeft:"18px",
    marginRight:"18px",
}


const ant_col_android: React.CSSProperties = {
    bottom: -5,
    position: "relative",
    margin: "0 auto",
    // maxWidth: "285px"
    marginLeft:"18px",
    marginRight:"18px",
}

const ant_col_android1: React.CSSProperties = {
    bottom: -15,
    position: "relative",
    margin: "0 auto",
    // maxWidth: "285px"
    marginLeft:"18px",
    marginRight:"18px",
}

const ant_col_android2: React.CSSProperties = {
    bottom: -25,
    position: "relative",
    margin: "0 auto",
    // maxWidth: "285px"
    marginLeft:"18px",
    marginRight:"18px",
    marginBottom:"20px",
}


// const ant_col_android1: React.CSSProperties = {
//     bottom: -10,
//     position: "relative",
//     margin: "0 auto",
//     maxWidth: "280px",
//     textAlign:"center"
// }


// const ant_col_android2: React.CSSProperties = {
//     bottom: -18,
//
//     position: "relative",
//     margin: "0 auto",
//     maxWidth: "280px",
//     textAlign:"center"
//
// }


const ant_col_android3: React.CSSProperties = {
    bottom: -30,

    position: "relative",
    margin: "0 auto",
    maxWidth: "280px",
    textAlign:"center"

}


const ant_col_iosa: React.CSSProperties = {
    background:`#FFFFE0`,
    bottom: -10,

    position: "relative",
    margin: "0 auto",
    // maxWidth: "340px"

    marginLeft:"18px",
    marginRight:"18px",
}


const ant_col_ios: React.CSSProperties = {
    bottom: -5,

    position: "relative",
    margin: "0 auto",
    // maxWidth: "340px"

    marginLeft:"18px",
    marginRight:"18px",
}


const ant_col_ios11: React.CSSProperties = {
    bottom: -15,

    position: "relative",
    margin: "0 auto",
    // maxWidth: "340px"

    marginLeft:"18px",
    marginRight:"18px",
}

const ant_col_ios22: React.CSSProperties = {
    bottom: -25,

    position: "relative",
    margin: "0 auto",
    // maxWidth: "340px"

    marginLeft:"18px",
    marginRight:"18px",
    marginBottom:"20px",
}



// const ant_col_ios1: React.CSSProperties = {
//     bottom: -20,
//     position: "relative",
//     margin: "0 auto",
//     maxWidth: "337px",
//     textAlign:"center"
// }
//
//
// const ant_col_ios2: React.CSSProperties = {
//     bottom: -20,
//
//     position: "relative",
//     margin: "0 auto",
//     maxWidth: "300px",
//     textAlign:"center"
// }



const ant_col_ios3: React.CSSProperties = {
    bottom: -35,

    position: "relative",
    margin: "0 auto",
    maxWidth: "337px",
    textAlign:"center"
}


export default  ShoppingMall;
