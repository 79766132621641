import AppTabs from '@/components/AppTabs';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import useExchange from './useExchangeTab';

const Exchange: React.FC = () => {
    const match = useRouteMatch();

    const exchangeTab = useExchange();

    return (
        <Switch>
            <Route path={match.path}>
                <AppTabs tabs={[ exchangeTab]} />
            </Route>
        </Switch>
    );
};

export default Exchange;

