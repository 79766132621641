import Fallback from '@/components/Fallback';
import PointList from '@/components/PointList';
import useAsync from '@/hooks/useAsync';
import { studentPoint } from '@/services/assistant';
import React from 'react';
import { useParams } from 'react-router-dom';
export default function StudentPointPage() {
  const params = useParams<{ id: string }>();
  const api = React.useMemo(() => studentPoint(parseInt(params.id)), [
    params.id,
  ]);
  const state = useAsync(api.query, [api.query]);
  return (
    <Fallback
      state={state}
      initial={<PointList loading />}
      render={({ data, meta }, loading) => (
        <PointList
          title={() => `${meta.realname}的总积分：${meta.points}`}
          dataSource={data}
          loading={loading}
        />
      )}
    />
  );
}
