import Fallback from '@/components/Fallback';
import PracticeItem from '@/components/PracticeItem';
import useAsync from '@/hooks/useAsync';
import {
  Box,
  Button, Checkbox,
  Container,
  Divider, FormControlLabel,
  List,
  ListSubheader,
} from '@material-ui/core';
import React, { useState } from 'react';
import RangeSelect, { Range } from './RangeSelect';
import { startOfDay, endOfDay, subDays } from 'date-fns';
import { PracticeResource } from '@/services/user';
import {useParams} from "react-router-dom";




const RecordList: React.FC = () => {

    const  idd  = useParams<{ id: string }>();

  const [range, setRange] = useState(Range.All);
  const [currentLesson, setCurrentLesson] = React.useState(false);
  const state = useAsync(() => {
    const getRange = () => {
      switch (range) {
        case Range.All:
          return undefined;
        case Range.Today:
          return {
            begin: startOfDay(new Date()),
            end: endOfDay(new Date()),
          };
        case Range.Yesterday:
          return {
            begin: subDays(startOfDay(new Date()), 1),
            end: subDays(endOfDay(new Date()), 1),
          };
          case Range.Week:
              return {
                  // begin: subDays(startOfDay(new Date()), 5),
                  // end: endOfDay(new Date()),
                  times:"week"
              };
          case Range.LastWeek:
              return {
                  // begin: subDays(startOfDay(new Date()), 12),
                  // end: subDays(startOfDay(new Date()), 6),
                  times:"lastweek"
              };
      }
    };
    return PracticeResource.query({
      filter: {
        range: getRange(),
        currentLesson,
          idd,
      },
    });
  }, [range, currentLesson]);

  return (
      <Fallback
          state={state}
          render={(items) => (
              <Box flex={1}>
                <Container maxWidth="sm">
                  <List
                      subheader={
                        <ListSubheader disableGutters>
                          <Box
                              bgcolor="white"
                              display="flex"
                              justifyContent="space-between"
                          >
                            <RangeSelect value={range} onChange={setRange} />
                            <FormControlLabel
                                control={
                                  <Checkbox
                                      checked={currentLesson}
                                      onChange={(event) =>
                                          setCurrentLesson(event.target.checked)
                                      }
                                  />
                                }
                                label="标星练习6"
                            />
                          </Box>
                        </ListSubheader>
                      }
                  >
                    {items.data.map((item, index) => (
                        <React.Fragment key={item.id}>
                          {index > 0 && <Divider component="li" />}
                          <PracticeItem
                              value={item}
                              onRecommend={async () => {
                                await PracticeResource.update(item.id)({
                                  isRecommend: !item.isRecommend,
                                });
                                state.retry();
                              }}
                          />
                        </React.Fragment>
                    ))}
                  </List>
                </Container>
              </Box>
          )}
      />
  );
};

export default RecordList;
