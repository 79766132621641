import Fallback from '@/components/Fallback';
import useAsync from '@/hooks/useAsync';
import { queryAssistantStatics } from '@/services/statics';
import { Card, Col, Row, Space } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const AssistantDashboard = () => {
  const state = useAsync(queryAssistantStatics);
  return (
      <Fallback
          state={state}
          render={(data) => (
              <div>
                <Space style={{ width: '100%' }} size="large" direction="vertical">
                  <Card title="我的学员">
                    <Row>
                      <Col xs={8}>全部: {data.allStudentCount}</Col>
                      <Col xs={8}>
                        <Link to={`/assistant/students/normal`}>
                          正常: {data.normalStudentCount}
                        </Link>
                      </Col>
                      <Col xs={8}>
                        <Link to={`/assistant/students/suspended`}>
                          暂停: {data.suspendedStudentCount}
                        </Link>
                      </Col>
                      <Col xs={8}>
                        <Link to={`/assistant/students/banned`}>
                          禁用: {data.stateBannedCount}
                        </Link>
                      </Col>
                    </Row>
                  </Card>


                  <Card title="今日练习">
                    <Row>
                      <Col xs={8}>

                        全部: {data.allStudentCount}

                      </Col>

                      <Col xs={8}>

                        已练: {data.allPracticesCount}

                      </Col>

                      <Col xs={8}>

                        已看: {data.viewPracticesCount}

                      </Col>

                      <Col xs={8}>

                        待看: {data.unviewPracticesCount}

                      </Col>
                    </Row>
                  </Card>

                  {/*<Card title="">*/}
                  {/*  <Row>*/}
                  {/*    <Col xs={8}>*/}
                  {/*      <Link to={`/assistant/classes`}>*/}
                  {/*        入门: {data.classCount}个班级*/}
                  {/*      </Link>*/}
                  {/*    </Col>*/}
                  {/*  </Row>*/}
                  {/*</Card>*/}

                </Space>
              </div>
          )}
      />
  );
};

export interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
  return <AssistantDashboard />;
};

export default Dashboard;
