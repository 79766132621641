import React, {
  cloneElement,
  createContext,
  Dispatch,
  FC,
  isValidElement,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { useGetSet } from 'react-use';

export interface SlotProps {}

interface SlotContextValue {
  identityPool: IdentityPool;
  filles: [() => FillNodes, Dispatch<SetStateAction<FillNodes>>];
}
const SlotContext = createContext<SlotContextValue | undefined>(undefined);

interface SlotFill {
  Slot: FC;
  Fill: FC;
}

class IdentityPool {
  private index = 0;
  private available: number[] = [];

  reserve() {
    if (this.available.length === 0) {
      return this.index++;
    }
    return this.available.shift() as number;
  }

  release(identity: number) {
    this.available.push(identity);
  }
}

type FillNodes = WeakMap<SlotFill, Map<number, ReactNode>>;

export const SlotFillProvider: React.FC = ({ children }) => {
  const identityPool = React.useMemo(() => new IdentityPool(), []);

  const filles = useGetSet<FillNodes>(() => new WeakMap());

  return (
    <SlotContext.Provider value={{ identityPool, filles }}>
      {children}
    </SlotContext.Provider>
  );
};

export function useSlot(instance: SlotFill): ReactNode[] {
  const context = useContext(SlotContext);
  if (!context) {
    throw new Error('');
  }
  const {
    filles: [getFilles],
  } = context;
  const nodes = getFilles().get(instance);
  if (!nodes) {
    return [];
  }

  const items: ReactNode[] = [];

  nodes.forEach((node, key) =>
    items.push(isValidElement(node) ? cloneElement(node, { key }) : node)
  );

  return items;
}

export const createSlotFill = () => {
  const instance: SlotFill = {
    Slot: () => {
      const items = useSlot(instance);
      return <>{items}</>;
    },
    Fill: ({ children }) => {
      const context = useContext(SlotContext);
      if (!context) {
        throw new Error('');
      }

      const id = useRef(0);

      const {
        identityPool,
        filles: [, setFilles],
      } = context;

      useEffect(() => {
        id.current = identityPool.reserve();
        return () => {
          setFilles((filles) => {
            const nodes = filles.get(instance);
            if (!nodes) {
              return filles;
            }
            nodes.delete(id.current);
            if (nodes.size === 0) {
              filles.delete(instance);
            }
            return filles;
          });
          identityPool.release(id.current);
        };
      }, [identityPool, setFilles]);

      useEffect(() => {
        setFilles((filles) => {
          if (!filles.has(instance)) {
            filles.set(instance, new Map());
          }
          filles.get(instance)?.set(id.current, children);
          return filles;
        });
      }, [children, identityPool, setFilles]);

      return null;
    },
  };
  return instance;
};
