import Fallback from '@/components/Fallback';
import LearnStateChip from '@/components/LearnStateChip';
import LinearProgressWithLabel from '@/components/LinearProgressWithLabel';
import useAsync from '@/hooks/useAsync';
import {queryAllCourses} from '@/services/user';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardHeader, Container,
    makeStyles, Paper,
} from '@material-ui/core';
import React from 'react';
import {useParams, Link, useHistory} from 'react-router-dom';


import * as Antdd from 'antd';


const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
}));



export interface AllCoursePageProps {}

const AllCoursePage: React.FC<AllCoursePageProps> = () => {
  const classes = useStyles();
  const params = useParams<{ id: string }>();
  const userID = parseInt(params.id);
  const state = useAsync(
    () => queryAllCourses.query({ filter: { userID } }),
    [userID]
  );

  return (
    <Fallback
      state={state}
      initial={null}
      render={(items) => {
        return (
            <Container maxWidth="sm" >
          <Box flex={1}>

              {/*{items.data.map((item,i)=>{*/}
              {/*    if (i==0){*/}
              {/*        return(*/}
              {/*            // <Button size="small" color="primary">{item.username}的课程</Button>*/}
              {/*            <Antdd.Row>*/}
              {/*                <Antdd.Col xs={8}>{item.username}的课程</Antdd.Col>*/}
              {/*            </Antdd.Row>*/}
              {/*        )*/}
              {/*    }*/}
              {/*})}*/}

            <Box p={2}></Box>

            {items.data.map((item) => (
              <Card key={item.id} className={classes.card} variant="outlined">
                <CardHeader
                  avatar={<LearnStateChip type={item.state} />}
                  title={item.course.name}
                />
                <Box ml={2} mr={2}>
                  <LinearProgressWithLabel
                    current={item.completed}
                    total={item.total}
                  />
                </Box>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    component={Link}

                    //to={`/admin/student/students/${params.id}/courses/${item.id}`}

                    to={`/course/syllabus/${item.id}/${item.course.id}/`}

                  >
                    查看
                  </Button>

                </CardActions>

              </Card>
            ))}
          </Box>
            </Container>
        );
      }}
    />
  );
};

export default AllCoursePage;
