import { getRoleTitle, User } from '@/services/entity';
import { getZhuangTai } from '@/services/entity';
import chooseFiles from '@/utils/choose-files';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from '@material-ui/core';
import NavigateNext from '@material-ui/icons/NavigateNext';
import { format } from 'date-fns';
import React, { useState } from 'react';
import AvatarSettingDialog from './AvatarSettingDialog';
import Form, { FormProps } from 'antd/lib/form';
import { createSubmitModal, createSubmitTrigger } from '../ResourceManage';
import FormatedDatePicker from '../FormatedDatePicker';
import {Link, Redirect } from "react-router-dom";




export interface ProfileProps {}

const CourseEndTimeForm: React.FC<FormProps> = (props) => {
  return (
    <Form {...props}>
      <Form.Item label="结课时间" name="courseEndTime">
        <FormatedDatePicker />
      </Form.Item>
    </Form>
  );
};

const CourseEndTimeModal = createSubmitModal<{ courseEndTime: string }>(
  CourseEndTimeForm
);

const CourseEndTimeTrigger = createSubmitTrigger(CourseEndTimeModal);

interface ProfilePanelProps {
  profile: User;
  logout?: () => void;
  updateCourseEndTime?: (data: { courseEndTime: string }) => Promise<unknown>;
  updateAvatar: (avatar: File) => Promise<void>;
}




const ProfilePanel: React.FC<ProfilePanelProps> = ({
  profile: user,
  logout,
  updateAvatar,
  updateCourseEndTime,
}) => {
  const [avatar, setAvatar] = useState<File>();

  function Aaa(){
    var userAgent = navigator.userAgent;
    //如果是safari浏览器使用以下组件进行,更换头像
    if (userAgent.indexOf("Safari") > -1) {
      window.location.href="/safariAvatar"
    }else{
      handleAvatarSetting()
    }

  }



  const handleAvatarSetting = () => {
    chooseFiles(
      (files) => {
        if (files.length === 0) {
          return;
        }

        setAvatar(files[0]);
      },
      { accept: 'image/*', multiple: false }

    );

  };





  return (
    <>
      <Box bgcolor="background.default" flex={1}>
        <Container disableGutters maxWidth="sm">
          <Box padding={2}>
            <Box mb={2} clone>
              <Paper variant="outlined">
                <List disablePadding>
                  <ListItem button onClick={Aaa}>
                    <ListItemText primary="头像" />
                    <Avatar src={user.avatar} />
                    <ListItemSecondaryAction>
                      <IconButton
                        size="small"
                        edge="end"
                        disableRipple
                        onClick={Aaa}
                      >
                        <NavigateNext />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primary="姓名" />
                    <div>{user.realname}</div>
                    <ListItemSecondaryAction />
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText primary="学号" />
                    <div>{user.username}</div>
                    <ListItemSecondaryAction />
                  </ListItem>
                </List>
              </Paper>
            </Box>
            <Box mb={2} clone>
              <Paper variant="outlined">
                <List disablePadding>
                  <ListItem button component={Link} to="/assistant/student/changePassword">
                    {/*<ListItemText primary="密码" />*/}
                    <ListItemText>密码</ListItemText>
                    <ListItemSecondaryAction>
                      <IconButton size="small" edge="end">
                        <NavigateNext />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Paper>
            </Box>

            <Box mb={2} clone>
              <Paper variant="outlined">
                <List disablePadding>
                  <ListItem button>
                    <ListItemText primary="等级" />
                    <Chip size="small" color="primary" label="Lv.1" />
                    <ListItemSecondaryAction>
                      <IconButton size="small" edge="end">
                        <NavigateNext />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem button>
                    <ListItemText primary="角色" />
                    <Box display="flex">
                      {user.roles.map((role, index) => (
                        <Box ml={1} key={index}>
                          {getRoleTitle(role.name)}
                        </Box>
                      ))}
                    </Box>
                    <ListItemSecondaryAction />
                  </ListItem>
                </List>
              </Paper>
            </Box>


            {user.courses? (user.courses.map((r, i) => (
                  <Box mb={2} clone>
                    <Paper variant="outlined">
                      <List disablePadding>

                        <ListItem button>
                          <ListItemText primary="班级" />
                          <Box ml={1} key={i}>
                            {r.class ? r.class.name:``}
                          </Box>
                          <ListItemSecondaryAction />
                        </ListItem>
                        <Divider />

                        <ListItem button>
                          <ListItemText primary="种类" />
                          <Box ml={1} key={i}>
                            {r.course.kind ? r.course.kind.name:``}
                          </Box>
                          <ListItemSecondaryAction />
                        </ListItem>
                        <Divider />
                        <ListItem button>
                          <ListItemText primary="课程" />
                          <Box>{r.course.name}</Box>
                          <ListItemSecondaryAction />
                        </ListItem>
                        <Divider />

                        <ListItem button>
                          <ListItemText primary="状态" />
                          <Box ml={1} key={i}>
                            {getZhuangTai(r.courseState)}
                          </Box>
                          <ListItemSecondaryAction />
                        </ListItem>



                        <Divider />
                        <ListItem button>
                          <ListItemText primary="琴师" />
                          {r.class?.assistant ? (
                              <Box>{r.class.assistant.realname}</Box>
                          ) : (
                              <Box color="text.secondary">暂无</Box>
                          )}
                          <ListItemSecondaryAction />
                        </ListItem>
                        <Divider />
                        <ListItem button>
                          <ListItemText primary="客服" />
                          {r.class && r.class.teacher ? (
                              <Box>{r.class.teacher.realname}</Box>
                          ) : (
                              <Box color="text.secondary">暂无</Box>
                          )}
                          <ListItemSecondaryAction />
                        </ListItem>
                        <Divider />

                        <ListItem button>
                          <ListItemText primary="开课" />
                          {r.courseStartTime ? (
                              <Box>
                                {format(
                                    new Date(r.courseStartTime),
                                    'yyyy年MM月dd日'
                                )}
                              </Box>
                          ) : (
                              <Box color="text.secondary">暂无</Box>
                          )}
                          <ListItemSecondaryAction />
                        </ListItem>
                        <Divider />


                        {updateCourseEndTime ? (
                            <CourseEndTimeTrigger
                                ModalProps={{
                                  title: '修改结课时间',
                                  submit: updateCourseEndTime,
                                  FormProps: {
                                    initialValues: { courseEndTime: r.courseEndTime },
                                  },
                                }}
                                render={({ open }) => (
                                    <ListItem button onClick={open}>
                                      <ListItemText primary="结课" />
                                      {r.courseEndTime ? (
                                          <Box>
                                            {format(
                                                new Date(r.courseEndTime),
                                                'yyyy年MM月dd日'
                                            )}
                                          </Box>
                                      ) : (
                                          <Box color="text.secondary">暂无</Box>
                                      )}
                                      <ListItemSecondaryAction />
                                    </ListItem>
                                )}
                            />
                        ) : (
                            <ListItem button>
                              <ListItemText primary="结课" />
                              {r.courseEndTime ? (
                                  <Box>
                                    {format(
                                        new Date(r.courseEndTime),
                                        'yyyy年MM月dd日'
                                    )}
                                  </Box>
                              ) : (
                                  <Box color="text.secondary">暂无</Box>
                              )}
                              <ListItemSecondaryAction />
                            </ListItem>
                        )}
                      </List>
                    </Paper>
                  </Box>
              ))):(``)}










            {/*<Box mb={2} clone>*/}
            {/*  <Paper variant="outlined">*/}
            {/*    <List disablePadding>*/}
            {/*      <ListItem button>*/}
            {/*        <ListItemText primary="班级1" />*/}
            {/*        {user.class ? (*/}
            {/*          <Box>{user.class.name}</Box>*/}
            {/*        ) : (*/}
            {/*          <Box color="text.secondary">暂无</Box>*/}
            {/*        )}*/}
            {/*        <ListItemSecondaryAction />*/}
            {/*      </ListItem>*/}
            {/*      <Divider />*/}
            {/*      <ListItem button>*/}
            {/*        <ListItemText primary="助教" />*/}
            {/*        {user.class?.assistant ? (*/}
            {/*          <Box>{user.class.assistant.realname}</Box>*/}
            {/*        ) : (*/}
            {/*          <Box color="text.secondary">暂无</Box>*/}
            {/*        )}*/}
            {/*        <ListItemSecondaryAction />*/}
            {/*      </ListItem>*/}
            {/*      <Divider />*/}
            {/*      <ListItem button>*/}
            {/*        <ListItemText primary="客服" />*/}
            {/*        {user.class && user.class.teacher ? (*/}
            {/*          <Box>{user.class.teacher.realname}</Box>*/}
            {/*        ) : (*/}
            {/*          <Box color="text.secondary">暂无</Box>*/}
            {/*        )}*/}
            {/*        <ListItemSecondaryAction />*/}
            {/*      </ListItem>*/}
            {/*      <Divider />*/}

            {/*      <ListItem button>*/}
            {/*        <ListItemText primary="开课" />*/}
            {/*        {user.courseStartTime ? (*/}
            {/*            <Box>*/}
            {/*              {format(*/}
            {/*                  new Date(user.courseStartTime),*/}
            {/*                  'yyyy年MM月dd日'*/}
            {/*              )}*/}
            {/*            </Box>*/}
            {/*        ) : (*/}
            {/*            <Box color="text.secondary">暂无</Box>*/}
            {/*        )}*/}
            {/*        <ListItemSecondaryAction />*/}
            {/*      </ListItem>*/}
            {/*      <Divider />*/}


            {/*      {updateCourseEndTime ? (*/}
            {/*        <CourseEndTimeTrigger*/}
            {/*          ModalProps={{*/}
            {/*            title: '修改结课时间',*/}
            {/*            submit: updateCourseEndTime,*/}
            {/*            FormProps: {*/}
            {/*              initialValues: { courseEndTime: user.courseEndTime },*/}
            {/*            },*/}
            {/*          }}*/}
            {/*          render={({ open }) => (*/}
            {/*            <ListItem button onClick={open}>*/}
            {/*              <ListItemText primary="结课" />*/}
            {/*              {user.courseEndTime ? (*/}
            {/*                <Box>*/}
            {/*                  {format(*/}
            {/*                    new Date(user.courseEndTime),*/}
            {/*                    'yyyy年MM月dd日'*/}
            {/*                  )}*/}
            {/*                </Box>*/}
            {/*              ) : (*/}
            {/*                <Box color="text.secondary">暂无</Box>*/}
            {/*              )}*/}
            {/*              <ListItemSecondaryAction />*/}
            {/*            </ListItem>*/}
            {/*          )}*/}
            {/*        />*/}
            {/*      ) : (*/}
            {/*        <ListItem button>*/}
            {/*          <ListItemText primary="结课" />*/}
            {/*          {user.courseEndTime ? (*/}
            {/*            <Box>*/}
            {/*              {format(*/}
            {/*                new Date(user.courseEndTime),*/}
            {/*                'yyyy年MM月dd日'*/}
            {/*              )}*/}
            {/*            </Box>*/}
            {/*          ) : (*/}
            {/*            <Box color="text.secondary">暂无</Box>*/}
            {/*          )}*/}
            {/*          <ListItemSecondaryAction />*/}
            {/*        </ListItem>*/}
            {/*      )}*/}
            {/*    </List>*/}
            {/*  </Paper>*/}






            {/*</Box>*/}








            {logout && (
              <Box mb={2}>
                <Button
                  onClick={logout}
                  variant="outlined"
                  size="large"
                  fullWidth
                  color="default"
                >
                  退出
                </Button>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
      <AvatarSettingDialog
        open={!!avatar}
        avatar={avatar}
        setAvatar={updateAvatar}
        onClose={() => setAvatar(undefined)}
        onCompleted={() => setAvatar(undefined)}
      />
    </>
  );
};

export default ProfilePanel;
