import Fallback from '@/components/Fallback';
import useAsyncFn from '@/hooks/useAsyncFn';
import { QueryFn } from '@/services/api';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import React from 'react';

type ValueType<T> = T extends { value: any; label: infer R } ? R : never;

interface SearchSelectProps<T, R> extends SelectProps<ValueType<R>> {
  query: QueryFn<T>;
  map: (entity: T) => R;
  allowEmptyValue?: boolean;
}

function SearchSelect<T, R extends { value: any; label: any }>({
  onChange,
  query: _query,
  map,
  allowEmptyValue,
  ...rest
}: SearchSelectProps<T, R>) {
  const [state, query] = useAsyncFn(_query);

  React.useEffect(() => {
    query({});
  }, [query]);

  const style: React.CSSProperties = {
    width: '100%',
  };

  return (
    <Fallback
      state={state}
      initial={<Select style={style} {...rest} loading />}
      error={() => <Select style={style} {...rest} disabled />}
      render={(categories, loading) => (
        <Select
          style={style}
          {...rest}
          onChange={onChange}
          loading={loading}
          options={
            allowEmptyValue
              ? [{ label: '[置空]', value: 0 }, ...categories.data.map(map)]
              : categories.data.map(map)
          }
        />
      )}
    />
  );
}

export default SearchSelect;
