import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    close: {
      padding: theme.spacing(0.5),
    },
  })
);

export interface SnackbarMessage {
  message: SnackbarProps;
  key: number;
}

export interface State {
  open: boolean;
  snackPack: SnackbarMessage[];
  messageInfo?: SnackbarMessage;
}

interface ConsecutiveSnackbarsValue {
  showMessage: (message: SnackbarProps) => void;
}

const ConsecutiveSnackbarsContext = React.createContext<ConsecutiveSnackbarsValue | undefined>(undefined)

export function useConsecutiveSnackbars() {
  const context = React.useContext(ConsecutiveSnackbarsContext);
  if (!context) {
    throw new Error('You should not call useConsecutiveSnackbars outside a <ConsecutiveSnackbarsProvider>')
  }
  return context
}

interface ConsecutiveSnackbarsProviderProps {
  children: React.ReactNode;
}
export function ConsecutiveSnackbarsProvider({
  children,
}: ConsecutiveSnackbarsProviderProps) {
  const [snackPack, setSnackPack] = React.useState<SnackbarMessage[]>([]);
  const [open, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState<
    SnackbarMessage | undefined
  >(undefined);

  React.useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);



  const handleClose = (
    event: React.SyntheticEvent | MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const value = React.useMemo(
    () => {
      return ({
        showMessage: (message: SnackbarProps) => {
          setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
        },
      })
    },
    []
  )

  const classes = useStyles();
  return (
    <ConsecutiveSnackbarsContext.Provider value={value}>
      {children}
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        onExited={handleExited}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
        {...(messageInfo ? messageInfo.message : {})}
      />
    </ConsecutiveSnackbarsContext.Provider>
  );
}
