import { AppTab } from '@/components/AppTabs';
import CategorySelect, { CategoryModule } from '@/components/CategorySelect';
import Fallback from '@/components/Fallback';
import {
    createSubmitFab,
    createSubmitModal,
    createSubmitTrigger,
    TotalLabel,
} from '@/components/ResourceManage';
import {Popconfirm, Switch} from 'antd';
import SearchSelect from '@/components/SearchSelect';
import useAsyncFn, { AsyncState } from '@/hooks/useAsyncFn';
import {
    SearchHuman,
    CourseResource,
    Student,
    studentPractice,
    UserCourseResource,
    DelUserResource
} from '@/services/admin';
import {Box, IconButton, ListItemSecondaryAction, makeStyles, Tab} from '@material-ui/core';
import {Badge, Button, Card, Col, Form, Input, Radio, Row, Select, Space, Table, Modal, message} from 'antd';
import { FormProps } from 'antd/lib/form';
import { TableProps } from 'antd/lib/table';
import format from 'date-fns/format';
import React from 'react';
import {comparator} from "ramda";
import {differenceInDays} from "date-fns";
import {Link, useHistory} from "react-router-dom";
import {updateContent} from "@/services/user";
import Saixuan from "@/components/Saixuan";


import {
    QueryIconRecord,QueryIconRecord1
} from '@/services/admin';
import Saixuan1 from "@/components/Saixuan1";
import DateTime from "@/components/DateTime";
import {SubmitTrigger} from "@/pages/Admin/Student/Course/RecordForm";

const resource = SearchHuman;
type Entity = Student;

// const [state, fetch] = useAsyncFn(resource.query, []);

// const [state, query] = useAsyncFn(resource.query);

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

// const RecordForm: React.FC<FormProps> = (props) => {
//     return (
//         <Form size="large" labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }} {...props}>
//             <Form.Item label="类型" name="categoryID" rules={[{ required: true }]}>
//                 <CategorySelect module={CategoryModule.ContactSource} />
//             </Form.Item>
//             <Form.Item label="渠道" name="channel" rules={[{ required: true }]}>
//                 <Input />
//             </Form.Item>
//         </Form>
//     );
// };

// const SubmitModal = createSubmitModal<Entity>(RecordForm);
// const SubmitTrigger = createSubmitTrigger(SubmitModal);
// const SubmitFab = createSubmitFab(SubmitTrigger);

interface DataTableProps extends TableProps<Entity> {
    onCreate?: () => void;
}


const renderState = (value: string) => {
    switch (value) {
        case 'yankai':
            return <Badge status="processing" text="延开" color="orange"/>;
        case 'zaixue':
            return <Badge status="success" text="学习" color="red"/>;
        case 'dongjie':
            return <Badge status="error" text="冻结" color="cyan"/>;

        case 'zanting':
            return <Badge status="default" text="停课" color="green"/>;
        case 'yanxue':
            return <Badge status="warning" text="待开-前课未完" color="blue"/>;
        case 'tiqian':
            return <Badge status="warning" text="到期-续课在学" color="pink"/>;
        case 'jinjie':
            return <Badge status="warning" text="已进阶" color="purple"/>;
        case 'yanqi':
            return <Badge status="warning" text="延期" color="gold"/>;
        default:
            return null;
    }
};


//

interface Values {
    id: string;
    description: string;
}

interface CollectionCreateFormProps {
    visible: boolean;
    onCreate: () => void;
    onCancel: () => void;
}

const { TextArea } = Input;
let idd:string


const CollectionCreateForm: React.FC<CollectionCreateFormProps> = ({
                                                                       visible,
                                                                       onCreate,
                                                                       onCancel,
                                                                   }) => {
    const [form] = Form.useForm();
    return (
        <Modal
            visible={visible}
            title="修改古琴"
            okText="确定"
            cancelText="取消"
            onCancel={onCancel}
            onOk={() => {
                form
                    .validateFields()
                    .then(values => {
                        const  content = values.description.toString()

                        message.success(`修改成功`);

                        form.resetFields();
                        // UserProductResource2({id})
                        // filter: {"cid":data.id,"times":data.times,"code":data.code} })}
                        updateContent.query({ filter: { "content":content,id: idd} })


                        onCreate();
                        // setTimeout(function () {
                        //
                        //     window.location.reload()
                        // }, 1000);
                    })
                    .catch(info => {
                        console.log('Validate Failed:', info);
                    });
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{ modifier: 'public' }}
            >
                <Form.Item name="description" label="古琴">
                    <TextArea rows={4} />
                </Form.Item>
            </Form>
        </Modal>
    );
};



const TabPanel1: React.FC<{
    state: AsyncState<{ total: number; data: Entity[] }>;
    fetch: typeof resource.query;
}> = ({ state, fetch }) => {
    const [form] = Form.useForm();
    const aaa =state.value;


    const [visible, setVisible] = React.useState(false);


    const onCreate = () => {
        setVisible(false);
    };

    form.setFieldsValue({del:0})

    function aaa1(id:any){
        idd = id
    }

    // const PracticeResource = React.useMemo((id:number) => QueryIconRecord(id), [
    //     id,
    // ]);



    function PracticeResource(id:number){
        QueryIconRecord(id)
    }

    function PracticeResource1(id:number){
        QueryIconRecord1(id)
    }


    return (
        <div>
            <Card style={{ marginBottom: 8 }}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={(values) => fetch({ filter: values })}
                >
                    <Form.Item label="课程状态" name="state">
                        <Select placeholder="全部状态" allowClear>
                            <Select.Option value="yankai">延开</Select.Option>
                            <Select.Option value="zaixue">学习</Select.Option>
                            <Select.Option value="dongjie">冻结</Select.Option>
                            <Select.Option value="zanting">停课</Select.Option>
                            <Select.Option value="tiqian">到期-续课在学</Select.Option>
                            <Select.Option value="yanxue">待开-前课未完</Select.Option>
                            <Select.Option value="jinjie">已进阶</Select.Option>
                            <Select.Option value="yanqi">延期</Select.Option>
                        </Select>
                    </Form.Item>


                    <Form.Item label="到期时间" name="endtime">
                        <Select placeholder="全部状态" allowClear>
                            <Select.Option value="≤7">≤7天</Select.Option>
                            <Select.Option value=">7">&#62;7天</Select.Option>
                        </Select>
                    </Form.Item>


                    <Form.Item label="课表" name="courseID">
                        <SearchSelect
                            query={CourseResource.query}
                            map={({ id, name }) => ({ value: id, label: name })}
                            placeholder="全部课表"
                            allowClear
                        />
                    </Form.Item>

                    <Form.Item  name="del"  >
                        <Input hidden={true}/>
                    </Form.Item>

                    <Form.Item style={{ textAlign: 'right' }}>
                        <Space>
                            <Button htmlType="reset" onClick={() => form.resetFields()}>
                                重置
                            </Button>
                            <Button type="primary" htmlType="submit" disabled={state.loading}>
                                查询
                            </Button>



                            <Popconfirm
                                title="注意:清空标新,将不可恢复?"
                                onConfirm={async () => {
                                    form.setFieldsValue({del:1})
                                    form.submit()


                                    setTimeout(function () {
                                        state.retry();
                                        state.retry();
                                    }, 1000);
                                }}

                            >
                                <Button type="primary"  disabled={state.loading}>
                                    清空标新
                                </Button>
                            </Popconfirm>

                        </Space>
                    </Form.Item>
                </Form>

            </Card>

            <Col>{aaa?.total ? ( <Col>{aaa?.total}人</Col>):(``)}</Col>
            <Fallback
                state={state}
                render={({ data }) => {

                    return (

                        // <DataTable
                        //     dataSource={data}
                        //     loading={state.loading}
                        //     onCreate={state.retry}
                        // />

                        <Table
                            dataSource={data}
                            rowKey="id"
                            bordered
                            size="small"
                            columns={[
                                {
                                    dataIndex: 'username',
                                    title: '学号',
                                },
                                {
                                    dataIndex: 'realname',
                                    title: '姓名',
                                    // render: (category) => category.name,
                                    render: (value, record) => (
                                        <Link
                                            to={`/admin/student/students?filter={"%24search"%3A"${value}"}`}
                                        >
                                            {value}
                                        </Link>
                                    ),
                                },
                                {
                                    dataIndex: 'courseState',
                                    title: '状态',
                                    sorter: comparator((a, b) => a.courseState < b.courseState),
                                    render: (_, record) => (
                                        <>
                                            <br />
                                            {renderState(record.courseState)}
                                        </>
                                    ),
                                    // render: (category) => category.name,
                                },
                                // {
                                //     dataIndex: 'courseEndTime',
                                //     title: '结课日期',
                                //     render: (courseEndTime) =>
                                //         format(new Date(courseEndTime), 'yyyy-MM-dd HH:mm:ss'),
                                // },


                                {
                                    dataIndex: 'courseEndTime',
                                    title: '结课日期',
                                    // render: (courseEndTime) =>
                                    //     format(new Date(courseEndTime), 'yyyy-MM-dd HH:mm:ss'),

                                    render: (value) => value ? (format(new Date(value), 'yyyy-MM-dd HH:mm:ss')):(``),

                                },

                                {
                                    title: '结课',
                                    dataIndex: 'courseEndTime',
                                    sorter: comparator((a, b) => differenceInDays( new Date(a.courseEndTime),new Date()) > differenceInDays( new Date(b.courseEndTime),new Date())),
                                    render: (value) =>
                                        value
                                            ? `${differenceInDays(
                                            new Date(value),
                                            new Date()
                                            )}天`
                                            : '',

                                    //N/A
                                },

                                // {
                                //     title: '结课1',
                                //     dataIndex: 'courseEndTime',
                                //     sorter: comparator((a, b) => a.days >= b.days),
                                //     render: (value) =>
                                //         value
                                //             ? `${differenceInDays(
                                //             new Date(value),
                                //             new Date()
                                //             )}天`
                                //             : '',
                                //
                                //     //N/A
                                // },


                                {
                                    title: '剩余日均',
                                    dataIndex: 'durationRemain',
                                    sorter: comparator((a, b) => a.durationRemain < b.durationRemain),
                                    render: (value) => `${value}分钟`,
                                },
                                {
                                    title: '未练习',
                                    dataIndex: 'lastPracticeAt',
                                    sorter: comparator((a, b) => a.lastPracticeAt < b.lastPracticeAt),
                                    // render: (value) =>
                                    //     value
                                    //         ? `${differenceInDays(
                                    //         new Date(),
                                    //         new Date(value)
                                    //         )}天`
                                    //         : '',


                                    render: (_, value) => value.lastPracticeAt.toString() === '0001-01-01T00:00:00Z' ? (value.courseEndTime?('特(8.30)'):('')):(`${differenceInDays(
                                        new Date(),
                                        new Date(value.lastPracticeAt)
                                    )}天`),

                                },


                                {
                                    title: '请假',
                                    dataIndex: 'day',
                                    sorter: comparator((a, b) => a.day < b.day),
                                    render: (value) =>
                                        value ? (value == 999 ? (`未开始`):(`${value}天中`))
                                            : (''),
                                },

                                {
                                    dataIndex: '',
                                    title: '备忘',
                                    sorter: comparator((a, b) => a.is_gao < b.is_gao),
                                    render: (_, record) => {
                                        return (
                                            <Saixuan
                                                value={record}
                                                onRecommend={async () => {
                                                    await PracticeResource(record.currentCourseID);
                                                    state.retry();
                                                    state.retry();
                                                }}
                                            />
                                        );
                                    },
                                },

                                {
                                    dataIndex: '',
                                    title: '续课',
                                    sorter: comparator((a, b) => a.is_luo < b.is_luo),
                                    render: (_, record) => {
                                        return (
                                            <Saixuan1
                                                value={record}
                                                onRecommend={async () => {
                                                    await PracticeResource1(record.currentCourseID);
                                                    state.retry();
                                                    state.retry();
                                                }}
                                            />
                                        );
                                    },
                                },

                                {
                                    title: '事件',
                                    dataIndex: 'startWeek',
                                    render: (value, record) =>
                                        // <Link
                                        //     to={`/teacher/students/${record.id}/events`}
                                        // >
                                        <Link to={`/admin/student/students/${record.id}/events`}>
                                            事件
                                        </Link>
                                },
                                {
                                    title: '古琴',
                                    dataIndex: 'content',
                                    sorter: comparator((a, b) => a.content < b.content),

                                },
                                {
                                    dataIndex: '',
                                    title: '修改古琴',
                                    render: (_, record) => {
                                        return (
                                            // <button onClick={aaa}>123</button>

                                            <div>
                                                <Button
                                                    type="primary"
                                                    onClick={() => {
                                                        aaa1(record.id)
                                                        setVisible(true);
                                                        state.retry();
                                                    }}
                                                >
                                                    添加内容
                                                </Button>
                                                <CollectionCreateForm
                                                    visible={visible}
                                                    onCreate={onCreate}
                                                    onCancel={() => {
                                                        setVisible(false);
                                                    }}
                                                />
                                            </div>
                                        );
                                    },
                                },
                            ]}
                        />

                    );
                }}
            />
        </div>
    );
};

function useProfileTab(): AppTab {
    const [state, fetch] = useAsyncFn(resource.query, []);
    React.useEffect(() => {
        fetch({});
    }, [fetch]);

    return {
        path: `students`,
        tab: <Tab label={<TotalLabel state={state}>搜索筛选</TotalLabel>} />,
        // fab: (
        //     <SubmitFab
        //     >
        //     </SubmitFab>
        // ),
        panel: <TabPanel1  state={state} fetch={fetch} />,
    };
}

export default useProfileTab;
