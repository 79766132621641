import AppTabs from '@/components/AppTabs';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Course from './Course';
import StudentEventPage from './Event';
import StudentLeavePage from './Leave';
import Lesson from './Lesson';
import StudentPointPage from './Point';
import StudentPracticePage from './Practice';
import useClassTab from './useClassTab';
import useStudentTab from './useStudentTab';
import AssistantClass from './Assistant/Class';
import ClassRecordPage from './Record';
import ClassOne from './ClassOne/index';

import Change from '../Change';
import Product from '../Product'

import HistoryRecord from './Record/index';


const Student: React.FC = () => {
  const match = useRouteMatch();

  const studentTab = useStudentTab();
  const classTab = useClassTab();

  return (
    <Switch>
        <Route path={`${match.path}/history/:id/:idd`}>
            <HistoryRecord />
        </Route>
      <Route path={`${match.path}/assistants/:assistantID/classes/:classID?`}>
        <AssistantClass />
      </Route>
      <Route path={`${match.path}/students/:id/events`}>
        <StudentEventPage />
      </Route>

        <Route path={`${match.path}/students/:id/leave`}>
            <StudentLeavePage />
        </Route>


      <Route path={`${match.path}/students/:id/points`}>
        <StudentPointPage />
      </Route>
      <Route path={`${match.path}/students/:id/practices`}>
        <StudentPracticePage />
      </Route>
      <Route path={`${match.path}/students/:id/courses/:courseID`}>
        <Lesson />
      </Route>
      <Route path={`${match.path}/students/:id/courses`}>
        <Course />
      </Route>

        <Route path={`${match.path}/students/change/:id`}>
            <Change />`
        </Route>


        <Route path={`${match.path}/students/classone/:id`}>
            <ClassOne />
        </Route>


        <Route path={`${match.path}/students/product/:id`}>
            <Product />
        </Route>

      <Route path={`${match.path}/classes/:classID/records`}>
        <ClassRecordPage />
      </Route>
      <Route path={match.path}>
        <AppTabs tabs={[studentTab, classTab]} />
      </Route>



    </Switch>
  );
};

export default Student;
