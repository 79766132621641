import React from 'react';
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Container,
  InputAdornment,
  Button,
  makeStyles,
} from '@material-ui/core';
import { Alert } from 'antd';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import * as auth from '@/services/auth';
import useAsyncFn from '@/hooks/useAsyncFn';
import { useHistory } from 'react-router-dom';
import logo from '@/assets/logo.png';
import {differenceInDays} from "date-fns";
import format from "date-fns/format";
import LearnCard from "@/pages/Course/LearnCard";
import ExerciseCard from "@/pages/Course/ExerciseCard";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  icon: {
    color: theme.palette.text.disabled,
  },
  title: {
    color: theme.palette.primary.contrastText,
    flex: 1,
    textAlign: 'center',
  },
  field: {
    marginBottom: theme.spacing(4),
  },
  button: {
    height: 40,
  },
}));

const Login: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [state, login] = useAsyncFn(async (values: any) => {
    await auth.login(values);
    history.push('/');
  }, []);

  // console.log(11111)
  // console.log(state)
  // console.log(login)

  return (

    <Box>
      <AppBar className={classes.appBar} style={{background: '#105952' }}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            谿山琴学院
          </Typography>
        </Toolbar>
      </AppBar>
      {state.error && (
        <Box margin={2}>
          <Alert
            style={{ marginBottom: 8 }}
            type="error"
            showIcon
            message={state.error.message}
          />
        </Box>
      )}
      <Container disableGutters maxWidth="sm">
        <Box ml={4} mr={4} mt={4} mb={2}>
          <Box mb={6}>
            <img
              style={{ display: 'block', margin: '0 auto',maxWidth:50 }}
              src={logo}
              alt="Logo"
            />
          </Box>
          <Formik
            initialValues={{}}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                await login(values);
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {() => (
              <Form>
                <Field
                  className={classes.field}
                  component={TextField}
                  name="username"
                  size="small"
                  fullWidth
                  placeholder="学号"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleOutlinedIcon className={classes.icon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Field
                  className={classes.field}
                  component={TextField}
                  name="password"
                  size="small"
                  fullWidth
                  placeholder="密码"
                  variant="outlined"
                  type="password"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlinedIcon className={classes.icon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  type="submit"
                  className={classes.button}
                  fullWidth
                  color="primary"
                  variant="contained"
                  disableElevation
                >
                  进入
                </Button>
              </Form>
            )}
          </Formik>
          <Box color="grey.700" lineHeight={2.2} textAlign="right" mt={6}>
            听琴是一种享受
            <br />
            弹琴是一场修为
            <br />
            谿山琴学院
            <br />
            让古琴回归人文
            <br />
            回归生活
          </Box>



        </Box>

      </Container>

      <div style={{ textAlign: 'center'}}  >
        <a href="https://beian.miit.gov.cn/">鄂ICP备17005093号-2</a>
      </div>
    </Box>

  );
};



export default Login;


// 2021-04-03在新浪微博上,看到账户昵称为"朱荣--盈链资本"的人,推荐"趣跟单"app,可以在上面自主进行虚拟数字货币交易,也可以和此app推荐的交易员进行跟单交易.从2021-04-03到2021-04-16号,先后3次,充值了3300元人民币,到此app进行交易.其间有赚有赔.2021-04-16当天我自己进行虚拟数字货币交易.盈利了.本金和盈利所得,共计9077元.2021-04-17 00-14-21.趣跟单通知我,说我交易次数太多,属于违规交易,要没收我得账户所有资金,并且关闭了我趣跟单账户得,资金提现功能.我马上发现可能遭遇了诈骗,所以向网络违法举报平台进行举报,附件里面有我3次转账记录,及和趣跟单平台,邮件和微信沟通得记录.