import React from 'react';
import { TableProps } from 'antd/lib/table';
import { Point } from '@/services/entity';
import Table from '../Table';
import DateTime from '../DateTime';

export default function PointList(props: TableProps<Point>) {
  return (
    <Table
      {...props}
      rowKey="id"
      columns={[
        {
          dataIndex: 'quantity',
          title: '记录',
          render: (value) => (value > 0 ? `+${value}` : value),
        },
        {
          dataIndex: 'title',
          title: '来源',
        },
        {
          dataIndex: 'issuer',
          title: '发放人',
          render: (value) => value.realname,
        },
        {
          dataIndex: 'createdAt',
          title: '日期',
          render: (value) => <DateTime value={value} />
        }
      ]}
    />
  );
}
