import AppTabs from '@/components/AppTabs';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import useSourceTab from './useSourceTab';

const Contact: React.FC = () => {
  const match = useRouteMatch();

  const sourceTab = useSourceTab();

  return (
    <Switch>
      <Route path={match.path}>
        <AppTabs tabs={[ sourceTab]} />
      </Route>
    </Switch>
  );
};

export default Contact;
