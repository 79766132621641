import { DependencyList, useEffect } from 'react'
import useAsyncFn from './useAsyncFn'


export default function useAsync<T extends (...args: any[]) => Promise<any>>(
  fn: T,
  deps: DependencyList = []
) {
  const [state, callback] = useAsyncFn(fn, deps)

  useEffect(() => {
    callback()
  }, [callback])

  return state
}
