import React from 'react';
import {
  Box,
  Card,
  CardMedia,
  CardActionArea,
  makeStyles,
  Typography,
  Chip,
  Container,
  Button,
} from '@material-ui/core';
import ChevronRightOutlined from '@material-ui/icons/ChevronRightOutlined';
import image from './image.webp';

export interface MemberProps {}

const useStyles = makeStyles({
  card: {
    marginBottom: 16,
    '& .MuiCardActionArea-root': {
      display: 'flex',
      height: 142,
    },
  },
  cover: {
    width: 140,
    height: 140,
    flexShrink: 0,
  },
});

const Member: React.FC<MemberProps> = () => {
  const classes = useStyles();

  return (
    <Box padding={2}>
      <Container style={{ padding: 0 }} maxWidth="sm">
        <Box mb={1}>
          <Button color="primary">
            <Box mr={1} clone>
              <Typography variant="h6" color="textPrimary">
                琴贝
              </Typography>
            </Box>
            <Typography variant="h6" color="primary">
              123346
            </Typography>
            <ChevronRightOutlined />
          </Button>
        </Box>
        <Card variant="outlined" className={classes.card}>
          <CardActionArea>
            <CardMedia className={classes.cover} image={image}></CardMedia>
            <Box
              padding={2}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              flex={1}
              height={140}
            >
              <Box clone lineHeight={1.36} height={42} overflow="hidden">
                <Typography variant="subtitle1">
                  纪老师《大还阁琴谱乐诠》CD光盘
                </Typography>
              </Box>
              <Box>
                <Chip size="small" label="实物" />
              </Box>
              <Box fontSize={18} color="primary.main">
                <Box clone fontWeight="bold">
                  <span>3000</span>
                </Box>
                <Box ml={0.5} fontSize={12} clone>
                  <span>琴贝</span>
                </Box>
              </Box>
            </Box>
          </CardActionArea>
        </Card>
        <Card variant="outlined" className={classes.card}>
          <CardActionArea>
            <CardMedia className={classes.cover} image={image}></CardMedia>
            <Box
              padding={2}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              flex={1}
              height={140}
            >
              <Box clone lineHeight={1.36} height={42} overflow="hidden">
                <Typography variant="subtitle1">
                  纪老师《大还阁琴谱乐诠》CD光盘
                </Typography>
              </Box>
              <Box>
                <Chip size="small" label="实物" />
              </Box>
              <Box fontSize={18} color="primary.main">
                <Box clone fontWeight="bold">
                  <span>3000</span>
                </Box>
                <Box ml={0.5} fontSize={12} clone>
                  <span>琴贝</span>
                </Box>
              </Box>
            </Box>
          </CardActionArea>
        </Card>
        <Card variant="outlined" className={classes.card}>
          <CardActionArea>
            <CardMedia className={classes.cover} image={image}></CardMedia>
            <Box
              padding={2}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              flex={1}
              height={140}
            >
              <Box clone lineHeight={1.36} height={42} overflow="hidden">
                <Typography variant="subtitle1">
                  纪老师《大还阁琴谱乐诠》CD光盘
                </Typography>
              </Box>
              <Box>
                <Chip size="small" label="实物" />
              </Box>
              <Box fontSize={18} color="primary.main">
                <Box clone fontWeight="bold">
                  <span>3000</span>
                </Box>
                <Box ml={0.5} fontSize={12} clone>
                  <span>琴贝</span>
                </Box>
              </Box>
            </Box>
          </CardActionArea>
        </Card>
        <Card variant="outlined" className={classes.card}>
          <CardActionArea>
            <CardMedia className={classes.cover} image={image}></CardMedia>
            <Box
              padding={2}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              flex={1}
              height={140}
            >
              <Box clone lineHeight={1.36} height={42} overflow="hidden">
                <Typography variant="subtitle1">
                  纪老师《大还阁琴谱乐诠》CD光盘
                </Typography>
              </Box>
              <Box>
                <Chip size="small" label="实物" />
              </Box>
              <Box fontSize={18} color="primary.main">
                <Box clone fontWeight="bold">
                  <span>3000</span>
                </Box>
                <Box ml={0.5} fontSize={12} clone>
                  <span>琴贝</span>
                </Box>
              </Box>
            </Box>
          </CardActionArea>
        </Card>
      </Container>
    </Box>
  );
};

export default Member;
