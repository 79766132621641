import Axios from 'axios';
import {api, encodeQueryParams, PaginationData, QueryParams, restful} from './api';
import { InvitationcodeData} from './user';
import {
  Category,
  Class,
  ContactAgreement,
  ContactApply,
  ContactLog,
  ContactPayment,
  ContactProfile,
  ContactSource,
  Course,
  Lecture,
  Product,
  Lesson,
  Kind,
  Point,
  Change,
  Horse,
  Practice,
  User,
  Productkind,
  UserCourse,
  Order,
  UserEvent,
  ClassRecord, Entity, Class as BaseClass, LessonNote, AllChangeData, UserLeave, UserLesson,
} from './entity';
import {Link} from "react-router-dom";
import React from "react";

const axios = Axios.create({ baseURL: `/api/admin` });

const axios1 = Axios.create({ baseURL: `/admin` });

export const ChangeResource = restful<Change>(axios, '/user/change');

export const ChangePointResource = restful<Change>(axios, '/user/changePoint');

export const HorseResource = restful<Horse>(axios, '/user/horse');

export const SourceResource = restful<Horse>(axios, '/user/source');

export const DelUserResource = restful<Horse>(axios, '/user/deluser');

export const DelClassResource = restful<Horse>(axios, '/user/delclass');

export const JieXinResource = restful<Horse>(axios, '/user/jiexin');

export const JieXinResource1 = restful<Horse>(axios, '/user/jiexin1');

export const Product1Resource = restful<Horse>(axios, '/user/product');

export const AllChangeResource = restful<AllChangeData>(axios, '/user/allchange');

export const ExchangeResource = restful<Point>(axios, '/user/exchange');




export const ContactProfileResource = restful<ContactProfile>(
  axios,
  '/contact/profiles'
);

export const ContactSourceResource = restful<ContactSource>(
  axios,
  '/contact/sources'
);

export const ContactBusinessResource = restful<ContactSource>(
    axios,
    '/contact/business'
);

// export const SearchHuman = restful<ContactSource>
export const SearchHuman = restful<Student>(
    axios,
    '/searchHuman'
);

export const PassNote = restful<LessonNote>(
    axios,
    '/passNote'
);


export const CategoryResource = restful<Category>(axios, '/categories');

export const ContactPaymentResource = restful<ContactPayment>(
  axios,
  '/contact/payments'
);

export const ContactAgreementResource = restful<ContactAgreement>(
  axios,
  '/contact/agreements'
);

export const ContactApplyResource = restful<ContactApply>(
  axios,
  '/contact/applies'
);


export const invitationCode = api<
    QueryParams,
    PaginationData<Class>
    >((params) =>
    axios.post('/user/invitationCode', { params: encodeQueryParams(params) })
);



export const mallHomePage = api<
    QueryParams,
    PaginationData<Class>
    >((params) =>
    axios.post('/user/addHomePage', { params: encodeQueryParams(params) })
);


export interface MyLeave  {
  leaves: UserLeave[];
  UserCourse:UserCourse;
  Content: string;
}


interface Yaoqinma extends Entity{
  id: number;
  cid: number;
  uid: number;
  username?: string;
  state?: number;
  code?: string;
}



export interface PracticeData extends Entity{
  startTime: string;
  weekName: string;
  userName: string;
  weekTime_: number;
  weekLimit?: string;
  monthTime_?: number;
}


// export const QuerySeeCode = restful<Yaoqinma>(
//     axios,
//     '/user/seecode'
// );



export const QueryLeaveRecord = api<number, MyLeave[]>((id) =>
    axios.get(`/user/leave/${id}`)
);


export const QueryIconRecord = api<number,Student>((id) =>
    axios.get(`/user/icon/${id}`)
);


export const QueryIconRecord1 = api<number,Student>((id) =>
    axios.get(`/user/icon1/${id}`)
);

export const QueryLeaveCourseRecord = api<number, Course[]>((id) =>
    axios.get(`/user/leaveCourse/${id}`)
);

export const QuerySeeCode = api<
    QueryParams,
    Yaoqinma[]>((params) =>
    axios.post('/user/seecode', { params: encodeQueryParams(params) })
);


export const QueryPromotionList = restful<InvitationcodeData>(
    axios,
    '/user/promotionList'
);


// export const QueryHistoryRecord = restful<any,{}>(axios, '/user/historyRecord');

// export const QueryHistoryRecord = api<number, PracticeData[]>((id) =>
//     axios.get(`/user/historyRecord/${id}`)
// );


// export const QueryHistoryRecord = restful<PracticeData>(axios, '/user/historyRecord1');


export const QueryHistoryRecord = api<
    QueryParams,
    PracticeData[]>((params) =>
    axios.get('/user/historyRecord1', { params: encodeQueryParams(params) })
);



// export const QueryHistoryRecord = restful<InvitationcodeData>(
//     axios,
//     '/user/promotionList'
// );


// export const QueryHistoryRecord = api<number, User>((userID) =>
//     axios.get(`/user/historyRecord/${userID}`)
// );


export const ContactLogResource = restful<ContactLog>(axios, '/contact/logs');

export const UserResource = restful<User>(axios, '/users');

export const LectureResource = restful<Lecture>(axios, '/lectures');

export const LessonResource = restful<Lesson>(axios, '/lessons');

export const KindResource = restful<Lesson>(axios, '/kinds');


export const ProductKindResource = restful<Productkind>(axios, '/productkinds');


export const CourseResource = restful<Course>(axios, '/courses');

export const LeaveCourseResource = restful<Course>(axios, '/leavecourses');



export const ProductResource = restful<Product>(axios, '/products');

export const EventResource = restful<Course>(axios, '/event');

export const ClassResource = restful<Class>(axios, '/classes');

export const ClassResource7 = restful<Class>(axios, '/user/class2');

export const ClassRecordResource = restful<ClassRecord>(
  axios,
  '/class/records'
);


export const  UserCourseResource = restful<UserCourse>(axios, '/user/courses');




export const startUserCourse = api<number, void>((id) =>
  axios.put(`/user/courses/${id}/start`)
);

export const completeUserCourse = api<number, void>((id) =>
  axios.put(`/user/courses/${id}/complete`)
);

export const completeUserLesson = api<number, void>((id) =>
  axios.put(`/user/lessons/${id}/complete`)
);

export const passUserLesson = api<number, void>((id) =>
    axios.put(`/user/lessons/${id}/complete`)
);



export const PracticeResource = restful<Practice>(axios, '/practices');

export const getUserProfile = api<number, User>((userID) =>
  axios.get(`/users/${userID}/profile`)
);

export const updateUserAvatar = api<{ userID: number; avatar: File }, void>(
  ({ userID, avatar }, config) => {
    const data = new FormData();
    data.set('avatar', avatar);
    return axios.post(`/users/${userID}/avatar`, data, config);
  }
);

interface StaticsData {
  allStudentCount: number;
  normalStudentCount: number;
  suspendedStudentCount: number;
  courses: (Course & { totalStudents: number })[];
  allPracticeCount: number;
  viewedPracticeCount: number;
  unViewedPracticeCount: number;
}

export const queryStatics = api<void, StaticsData>(() => axios.get('/statics'));


export const queryMall = api<void, void>(() => axios1.get('/mall'));

export const studentEvent = (studentID: number) =>
  restful<UserEvent>(axios, `/students/${studentID}/events`);


export const studentLeave = (studentID: number) =>
    restful<UserLeave>(axios, `/students/${studentID}/leave`);

export const studentPoint = (studentID: number) =>
  restful<Point, { points: number; realname: string }>(
    axios,
    `/students/${studentID}/points`
  );

export const studentPractice = (studentID: number) =>
  restful<Practice>(axios, `/students/${studentID}/practices`);


  export interface Student extends User {
  readonly lessonCompleted: number;
  readonly lessonTotal: number;
  readonly durationRemain: number;
  readonly lastPracticeAt: string;
  readonly currentCourseID: number;
  readonly currentCourseID_: number;
  readonly isFinish: number;
  readonly day: number;
  readonly is_gao:boolean;
  readonly is_luo:boolean;

}

interface ClassWithProgress extends Class {
  readonly students: Student[];
}


export const assistantClassResource = (assistantID: number,classID: number) =>
  restful<ClassWithProgress>(axios, `/assistants/${assistantID}/classes/${classID}`);





