import { AppTab } from '@/components/AppTabs';
import Fallback from '@/components/Fallback';
import FormatedDatePicker from '@/components/FormatedDatePicker';
import {
  createSubmitFab,
  createSubmitModal,
  createSubmitTrigger,
  TotalLabel,
} from '@/components/ResourceManage';
import SearchSelect from '@/components/SearchSelect';
import useAsyncFn, { AsyncState } from '@/hooks/useAsyncFn';
import {ClassResource, CourseResource, PassNote, UserResource} from '@/services/admin';
import { PickMuteable, QueryFn } from '@/services/api';
import { getRoleTitle, Role, User, UserRole } from '@/services/entity';
import { makeStyles, Tab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
    Badge,
    Button,
    Card, Col,
    Form,
    Input, Popconfirm,
    Radio,
    Select,
    Space,
    Table,
    Tag,
} from 'antd';
import { FormProps } from 'antd/lib/form';
import { SelectProps } from 'antd/lib/select';
import { TableProps } from 'antd/lib/table';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {DelUserResource} from '@/services/admin';

const resource = UserResource;
type Entity = User;

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

interface RoleMutipleSelectProps {
  value?: UserRole[];
  onChange?: (value: PickMuteable<UserRole>[]) => void;
}
const RoleMutipleSelect: React.FC<RoleMutipleSelectProps> = ({
  value,
  onChange,
}) => {
  return (
    <Select
      value={value?.map(({ name }) => name)}
      mode="multiple"
      options={[
        { value: Role.Student, label: getRoleTitle(Role.Student) },
        { value: Role.Assistant, label: getRoleTitle(Role.Assistant) },
        { value: Role.Teacher, label: getRoleTitle(Role.Teacher) },
        { value: Role.Admin, label: getRoleTitle(Role.Admin) },
      ]}
      onChange={(value: Role[]) => {
        onChange && onChange(value.map((name) => ({ name })));
      }}
    />
  );
};

interface RoleSelectProps
  extends Omit<SelectProps<Role>, 'value' | 'onChange'> {
  value?: Role;
  onChange?: (value: Role) => void;
}
const RoleSelect: React.FC<RoleSelectProps> = (props) => {
  return (
    <Select
      {...props}
      options={[
        { value: Role.Student, label: getRoleTitle(Role.Student) },
        { value: Role.Assistant, label: getRoleTitle(Role.Assistant) },
        { value: Role.Teacher, label: getRoleTitle(Role.Teacher) },
        { value: Role.Admin, label: getRoleTitle(Role.Admin) },
      ]}
    />
  );
};

const { TextArea } = Input;

const RecordForm: React.FC<FormProps> = (props) => {
  const { initialValues } = props;

  return (
    <Form labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }} {...props}>
      <Form.Item
        label="角色"
        name="roles"
        initialValue={[{ name: Role.Student }]}
        rules={[{ required: true }]}
      >
        <RoleMutipleSelect />
      </Form.Item>
      <Form.Item label="学号" name="username" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="密码"
        name="password"
        // rules={[{ required: !initialValues?.id }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item label="姓名" name="realname" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

        <Form.Item label="古琴" name="content" >
            <TextArea rows={4} />
        </Form.Item>

      {/*<Form.Item label="班级" name="classID" rules={[]}>*/}
      {/*  <SearchSelect*/}
      {/*    allowEmptyValue*/}
      {/*    query={ClassResource.query}*/}
      {/*    map={({ id, name, teacher, assistant }) => ({*/}
      {/*      value: id,*/}
      {/*      label: `${name} 助教:${assistant?.realname}, 客服:${teacher?.realname}`,*/}
      {/*    })}*/}
      {/*  />*/}
      {/*</Form.Item>*/}

      {/*  <Form.Item label="开课时间1" name="courseStartTime" rules={[]}>*/}
      {/*      <FormatedDatePicker />*/}
      {/*  </Form.Item>*/}
      {/*<Form.Item label="结课时间" name="courseEndTime" rules={[]}>*/}
      {/*  <FormatedDatePicker />*/}
      {/*</Form.Item>*/}
      <Form.Item
        label="状态"
        name="state"
        initialValue="normal"
        rules={[{ required: true }]}
      >
        <Radio.Group>
          <Radio.Button value="normal">正常</Radio.Button>
          <Radio.Button value="suspended">暂停</Radio.Button>
          <Radio.Button value="banned">禁用</Radio.Button>
        </Radio.Group>
      </Form.Item>
    </Form>
  );
};

const SubmitModal = createSubmitModal<Entity>(RecordForm);
const SubmitTrigger = createSubmitTrigger(SubmitModal);
const SubmitFab = createSubmitFab(SubmitTrigger);

interface DataTableProps extends TableProps<Entity> {
  onCreate?: () => void;
}

const renderState = (value: string) => {
  switch (value) {
    case 'normal':
      return <Badge status="success" text="正常" />;
    case 'suspended':
      return <Badge status="default" text="暂停" color="red"/>;
    case 'banned':
      return <Badge status="error" text="禁用" color="red"/>;
    default:
      return null;
  }
};

const DataTable: React.FC<DataTableProps> = ({ onCreate, ...rest }) => {
  return (
    <Table
      rowKey="id"
      {...rest}
      bordered
      size="small"
      style={{ width: '100%' }}
      columns={[
        {
          dataIndex: 'id',
          title: '#',
        },
        {
          dataIndex: 'realname',
          title: '姓名2',
          render: (_, record) => {
            return (
              <>
                <Link to={`/admin/profile/${record.id}`}>
                  {record.realname} ({record.username})
                </Link>
                <br />
                {renderState(record.state)}
              </>
            );
          },
        },
        {
          dataIndex: 'roles',
          title: '角色',
          render: (value, record) => {
            return record.roles.map((r, i) => (
              <Tag key={i}>{getRoleTitle(r.name)}</Tag>
            ));
          },
        },
        // {
        //   dataIndex: 'createdAt',
        //   title: '时间',
        //   render: (createdAt) =>
        //     format(new Date(createdAt), 'yyyy-MM-dd HH:mm:ss'),
        // },
          {
              dataIndex: 'content',
              title: '古琴',
          },

        {
          title: '操作',
          render: (_, record) => {
            return (
              <Space>
                <SubmitTrigger
                  ModalProps={{
                    title: '编辑用户',
                    centered: true,
                    submit: resource.update(record.id),
                    onSubmitted: onCreate,
                    FormProps: { initialValues: record },
                  }}
                  render={({ open }) => (
                    <Button style={{ padding: 0 }} type="link" onClick={open}>
                      编辑
                    </Button>
                  )}
                />
                <Link to={`/admin/student/students/${record.id}/courses`}>
                  课程
                </Link>
                <Link to={`/admin/student/students/${record.id}/practices`}>
                  练习
                </Link>
                <Link to={`/admin/student/students/${record.id}/points`}>
                  积分
                </Link>
                <Link to={`/admin/student/students/${record.id}/events`}>
                  事件
                </Link>
                {record.roles.some(({ name }) => name === Role.Assistant) && (
                  // <Link to={`/admin/student/assistants/${record.id}/classes/${record.classID}`}>
                  //   班级
                  // </Link>
                    <Link to={`/admin/student/students/classone/${record.id}`}>
                        班级
                    </Link>
                )}
                  <Link to={`/admin/student/students/change/${record.id}`}>
                      转介
                  </Link>

                  <Link to={`/admin/student/students/${record.id}/leave`}>
                      {/*<Link to={`/admin/student/students/change/${record.id}`}>*/}
                      请假
                  </Link>


              </Space>
            );
          },
        },
          {
              title: '删除账号',
              dataIndex: 'id',
              render: (_, record) =>
                  <Popconfirm
                      title="注意:删除账号,将不可恢复?"
                      onConfirm={async () => {
                          await DelUserResource.delete(record.id);
                          window.location.reload();
                      }}
                  >
                      <Button type="link" danger>删除</Button>
                  </Popconfirm>
          },


          // {
          //     dataIndex: '',
          //     title: '添加',
          //     render: (_, record) => {
          //         return (
          //             <SubmitTrigger
          //                 ModalProps={{
          //                     title: '新增内容',
          //                     centered: true,
          //                     submit: resource.update(record.id),
          //                     onSubmitted: onCreate,
          //                     FormProps: { initialValues: record },
          //                 }}
          //                 render={({ open }) => (
          //                     <Button style={{ padding: 0 }} type="link" onClick={open}>
          //                         编辑
          //                     </Button>
          //                 )}
          //             />
          //             );
          //     },
          // },
      ]}
    />
  );
};

const TabPanel: React.FC<{
  state: AsyncState<{ total: number; data: Entity[] }>;
  fetch: typeof resource.query;
  filter?: {};
}> = ({ state, fetch, filter }) => {
  const [form] = Form.useForm();
    const aaa =state.value;

  return (
    <div>
      <Card style={{ marginBottom: 8 }}>
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => fetch({ filter: values })}
          initialValues={filter}
        >
          <Form.Item label="角色" name="role">
            <RoleSelect placeholder="全部角色" />
          </Form.Item>
          <Form.Item label="状态" name="state">
            <Select placeholder="全部状态" allowClear>
              <Select.Option value="normal">正常</Select.Option>
              <Select.Option value="suspended">暂停</Select.Option>
              <Select.Option value="banned">禁用</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="课表" name="courseID">
            <SearchSelect
              query={CourseResource.query}
              map={({ id, name }) => ({ value: id, label: name })}
              placeholder="全部课表"
              allowClear
            />
          </Form.Item>
          <Form.Item label="琴师" name="assistantID">
            <SearchSelect
              query={
                (({ filter, ...rest }) =>
                  UserResource.query({
                    ...rest,
                    filter: { ...filter, role: Role.Assistant },
                  })) as QueryFn<User>
              }
              map={({ id, realname }) => ({ value: id, label: realname })}
              placeholder="全部琴师"
              allowClear
            />
          </Form.Item>
          <Form.Item label="客服" name="teacherID">
            <SearchSelect
              query={
                (({ filter, ...rest }) =>
                  UserResource.query({
                    ...rest,
                    filter: { ...filter, role: Role.Teacher },
                  })) as QueryFn<User>
              }
              map={({ id, realname }) => ({ value: id, label: realname })}
              placeholder="全部客服"
              allowClear
            />
          </Form.Item>
          <Form.Item label="姓名" name="$search">
            <Input placeholder="请输入学号或姓名" style={{ fontSize: '1.8vh' }}/>
          </Form.Item>
          <Form.Item style={{ textAlign: 'right' }}>
            <Space>
              <Button htmlType="reset" onClick={() => form.resetFields()}>
                重置
              </Button>
              <Button type="primary" htmlType="submit" disabled={state.loading}>
                查询
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
        <Col>{aaa?.total ? ( <Col>{aaa?.total}人</Col>):(``)}</Col>
      <Fallback
        state={state}
        initial={<DataTable loading />}
        render={({ data }) => {
          return (
            <DataTable
              dataSource={data}
              loading={state.loading}
              onCreate={state.retry}
            />
          );
        }}
      />
    </div>
  );
};

function useFilterParam() {
  const { search } = useLocation();
  return React.useMemo(() => {
    const params = new URLSearchParams(search);
    if (!params.has('filter')) {
      return {};
    }
    try {
      return JSON.parse(params.get('filter') as string);
    } catch {
      return {};
    }
  }, [search]);
}
function useStudentTab(): AppTab {
  const classes = useStyles();
  const filter = useFilterParam();
  const [state, fetch] = useAsyncFn(resource.query, []);
  React.useEffect(() => {
    fetch({ filter });
  }, [fetch, filter]);

  return {
    path: `students`,
    tab: <Tab label={<TotalLabel state={state}>用户</TotalLabel>} />,
    fab: (
      <SubmitFab
        ModalProps={{
          title: '创建用户',
          centered: true,
          submit: resource.create,
          onSubmitted: state.retry,
        }}
        className={classes.fab}
      >
        <AddIcon />
      </SubmitFab>
    ),
    panel: <TabPanel state={state} fetch={fetch} filter={filter} />,
  };
}

export default useStudentTab;
