import Fallback from '@/components/Fallback';
import useAsyncFn, { AsyncState } from '@/hooks/useAsyncFn';
import useAsyncTap from '@/hooks/useAsyncTap';
import { Fab, FabProps, makeStyles } from '@material-ui/core';
import { Alert, Form } from 'antd';
import { FormProps } from 'antd/lib/form';
import { ModalProps } from 'antd/lib/modal';
import React from 'react';
import FullScreenDialog from './FullScreenDialog';
import { PickMuteable } from '@/services/api';
import clsx from 'clsx';

export interface SubmitModalProps<T> extends ModalProps {
  submit?: (values: T) => Promise<unknown>;
  onSubmitted?: () => void;
  onError?: (reason: any) => void;
  FormProps?: FormProps;
}

export function createSubmitModal<T>(
  RecordForm: React.FC<FormProps>
): React.FC<SubmitModalProps<PickMuteable<T>>> {
  return ({
    submit = () => Promise.resolve(),
    onSubmitted,
    onError,
    FormProps,
    ...rest
  }) => {
    const [form] = Form.useForm();
    const [state, doSubmit] = useAsyncFn(submit);
    const tappedSubmit = useAsyncTap(doSubmit, onSubmitted, onError);
    return (
      <FullScreenDialog
        {...rest}
        onOk={async () => {
          const values = await form.validateFields();
          tappedSubmit(values as any);
        }}
        afterClose={() => {
          form.resetFields();
        }}
        confirmLoading={state.loading}
      >
        {state.error && (
          <Alert
            style={{ marginBottom: 8 }}
            showIcon
            type="error"
            message={state.error.message}
          />
        )}
        <RecordForm {...FormProps} form={form} />
      </FullScreenDialog>
    );
  };
}

export interface TriggerOptions {
  open: () => void;
}

export interface SubmitTriggerProps<T> {
  ModalProps: SubmitModalProps<T>;
  render: (options: TriggerOptions) => React.ReactElement;
}

export function createSubmitTrigger<T>(
  SubmitModal: React.FC<SubmitModalProps<T>>
): React.FC<SubmitTriggerProps<T>> {
  return ({ ModalProps: { onSubmitted, ...ModalProps } = {}, render }) => {
    const [visible, setVisible] = React.useState(false);
    return (
      <>
        {render({ open: () => setVisible(true) })}
        <SubmitModal
          {...ModalProps}
          visible={visible}
          onCancel={() => setVisible(false)}
          onSubmitted={() => {
            onSubmitted && onSubmitted();
            setVisible(false);
          }}
        />
      </>
    );
  };
}

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));
export interface FabSubmitProps<T> extends FabProps {
  ModalProps?: SubmitModalProps<T>;
}
export function createSubmitFab<T>(
  SubmitTrigger: React.FC<SubmitTriggerProps<T>>
): React.FC<FabSubmitProps<T>> {
  return ({ ModalProps = {}, children, className, ...rest }) => {
    const classes = useStyles();
    return (
      <SubmitTrigger
        ModalProps={ModalProps}
        render={({ open }) => (
          <Fab
            {...rest}
            className={clsx(className, classes.fab)}
            color="primary"
            onClick={open}
          >
            {children}
          </Fab>
        )}
      />
    );
  };
}

export const TotalLabel: React.FC<{
  state: AsyncState<{ total: number }>;
}> = ({ state, children }) => {
  return (
    <Fallback
      state={state}
      render={({ total }) => (
        <span>
          {children} ({total})
        </span>
      )}
      initial={<span>{children} (0)</span>}
      error={() => <span>{children} (0)</span>}
    />
  );
};
