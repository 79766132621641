import SearchSelect from '@/components/SearchSelect';
import useAsyncFn from '@/hooks/useAsyncFn';
import { useQueryParams } from '@/services/api';
import {
  AssistantResource,
  CourseResource,
} from '@/services/teacher';
import { Button, Card, Form, Input, Select, Space } from 'antd';
import React from 'react';
import Fallback from '@/components/Fallback';
import Table, { TableProps } from 'antd/lib/table';
import { User } from '@/services/entity';
import { Link } from 'react-router-dom';

const DataTable: React.FC<TableProps<User>> = (props) => {
  return (
    <Table
      {...props}
      rowKey="id"
      bordered
      size="small"
      style={{ minWidth: '100%' }}
      columns={[
        { dataIndex: 'id', title: '#' },
        {
          dataIndex: 'username',
          title: '学号',
          render: (value, record) => (
            <Link to={`/teacher/students/${record.id}/profile`}>
              {record.realname} ({record.username})
            </Link>
          ),
        },
        {
          title: '操作',
          render: (_, record) => (
            <Space>
              <Link
                to={`/teacher/students/${record.id}/courses`}
              >
                课程
              </Link>
              <Link
                to={`/teacher/students/${record.id}/practices`}
              >
                练习
              </Link>
              <Link
                to={`/teacher/students/${record.id}/points`}
              >
                积分
              </Link>
              <Link
                to={`/teacher/students/${record.id}/events`}
              >
                事件
              </Link>
              <Link
                to={`/teacher/assistants/${record.id}/classes`}
              >
                班级
              </Link>
            </Space>
          ),
        },
      ]}
    />
  );
};

export default function AssistantPage() {
  const [form] = Form.useForm();
  const params = useQueryParams();
  const [state, fetch] = useAsyncFn(AssistantResource.query);
  React.useEffect(() => {
    fetch(params);
  }, [fetch, params]);
  return (
    <>
      <Card style={{ marginBottom: 8 }}>
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => fetch({ filter: values })}
          initialValues={params.filter}
        >
          <Form.Item label="状态" name="state">
            <Select placeholder="全部状态" allowClear>
              <Select.Option value="normal">正常</Select.Option>
              {/*<Select.Option value="suspended">暂停</Select.Option>*/}
              <Select.Option value="banned">禁用</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="课表" name="courseID">
            <SearchSelect
              query={CourseResource.query}
              map={({ id, name }) => ({ value: id, label: name })}
              placeholder="全部课表"
              allowClear
            />
          </Form.Item>
          <Form.Item label="姓名" name="$search">
            <Input placeholder="请输入学号或姓名" />
          </Form.Item>
          <Form.Item style={{ textAlign: 'right' }}>
            <Space>
              <Button
                onClick={() => form.resetFields()}
              >
                重置
              </Button>
              <Button
                type="primary"
                htmlType="submit"
              >
                查询
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
      <Fallback
        state={state}
        initial={<DataTable loading />}
        render={({ data }, loading) => (
          <DataTable loading={loading} dataSource={data} />
        )}
      />
    </>
  );
}
