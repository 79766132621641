import Fallback from '@/components/Fallback';
import SearchSelect from '@/components/SearchSelect';
import useAsyncFn from '@/hooks/useAsyncFn';
import useAsyncTap from '@/hooks/useAsyncTap';
import useCatchError from '@/hooks/useCatchError';
import { applyFilter } from '@/services/api';
import {
  classRemainPoint,
  PointResource,
  UserResource,
} from '@/services/assistant';
import { Point } from '@/services/entity';
import { Box } from '@material-ui/core';
import { Button, Card, Form, Input, InputNumber, message, Table } from 'antd';
import { TableProps } from 'antd/lib/table';
import format from 'date-fns/format';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';


const DataTable: React.FC<TableProps<Point>> = (props) => (
  <Table
    {...props}
    rowKey="id"
    bordered
    size="small"
    title={() => <div>发放记录</div>}
    style={{ minWidth: '100%' }}
    columns={[
      {
        dataIndex: 'recipient',
        title: '学生',
        render: (value) => value.realname,
      },
      {
        dataIndex: 'quantity',
        title: '数额',
        render: (value) => (value > 0 ? `+${value}` : value),
      },
      {
        dataIndex: 'issuer',
        title: '发放人',
        render: (value) => value.realname,
      },
      {
        dataIndex: 'createdAt',
        title: '日期',
        render: (value) => format(new Date(value), 'yyyy-MM-dd HH:mm:ss'),
      },
    ]}
  />
);

const StudentPoint: React.FC = () => {
  const params = useParams<{classID: string}>();
  const classID = useMemo(() => parseInt(params.classID), [params.classID]);

  const [form] = Form.useForm();
  const [remainState, refreshRemain] = useAsyncFn(classRemainPoint);

  const [pointListState, queryPointList] = useAsyncFn(PointResource.query);



  useEffect(() => {
    queryPointList({});
  }, [queryPointList]);

  useEffect(() => {
    refreshRemain(classID as number);
  }, [refreshRemain, classID]);

  const [, createPoint] = useAsyncFn(
    useAsyncTap(PointResource.create, () => {
      message.success('积分发放成功');
      form.resetFields();
      queryPointList({});
      refreshRemain(classID as number);
    })
  );

  const catchedCreatePoint = useCatchError(createPoint);

  return (
    <Box>
      <Fallback
        state={remainState}
        render={(remainQuantity) => (
          <Box mb={2}>本周剩余：{remainQuantity}积分</Box>
        )}
      />

      <Card style={{ marginBottom: 16 }} title="积分发放">
        <Form
          form={form}
          onFinish={catchedCreatePoint as any}
        >
          <Form.Item
            label="对象"
            name="recipientID"
            rules={[{ required: true }]}
          >
            <SearchSelect
              query={applyFilter({ classID }, UserResource.query)}
              map={({ id, realname }) => ({ value: id, label: realname })}
            />
          </Form.Item>
          <Form.Item
            label="数额"
            name="quantity"

          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label="原因" name="title" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Box display="flex" justifyContent="flex-end">
            <Button htmlType="submit" type="primary">
              确定
            </Button>
          </Box>
        </Form>
      </Card>
      <Fallback
        state={pointListState}
        initial={<DataTable loading />}
        render={({ data }) => <DataTable dataSource={data} />}
      />
    </Box>
  );
};

export default StudentPoint;
