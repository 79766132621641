import { AppTab } from '@/components/AppTabs';
import Fallback from '@/components/Fallback';
import {
  createSubmitFab,
  createSubmitModal,
  createSubmitTrigger,
  TotalLabel,
} from '@/components/ResourceManage';
import UploadInput from '@/components/UploadInput';
import useAsyncFn, { AsyncState } from '@/hooks/useAsyncFn';
import { LectureResource, LessonResource } from '@/services/admin';
import { Lesson } from '@/services/entity';
import { makeStyles, Tab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Space,
  Table,
} from 'antd';
import { FormProps } from 'antd/lib/form';
import { InputNumberProps } from 'antd/lib/input-number';
import { TableProps } from 'antd/lib/table';
import format from 'date-fns/format';
import React from 'react';
import EntityList from './EntityList';

const resource = LessonResource;
type Entity = Lesson;

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));



const DurationInput1: React.FC<InputNumberProps> = (props) => {
    return (
        <>
            <InputNumber {...props} />
            <span style={{ marginLeft: 8 }}>天</span>
        </>
    );
};


const DurationInput: React.FC<InputNumberProps> = (props) => {
  return (
    <>
      <InputNumber {...props} />
      <span style={{ marginLeft: 8 }}>分钟</span>
    </>
  );
};



const RecordForm: React.FC<FormProps> = (props) => {
  return (
    <Form labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }} {...props}>
      <Form.Item label="课件名称" name="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="练习时间"
        name="practiceDuration"
        rules={[{ required: true }]}
      >
        <DurationInput />
      </Form.Item>

        <Form.Item
            label="标准时间"
            name="standardDuration"
        >
            <DurationInput1 />
        </Form.Item>
      <Form.Item label="讲义选择" name="handout">
        <UploadInput />
      </Form.Item>
      <Form.Item label="视频选择" name="lectures">
        <EntityList
          query={LectureResource.query}
          map={({ id, name }) => ({ value: id, label: name })}
        />
      </Form.Item>
    </Form>
  );
};

const SubmitModal = createSubmitModal<Entity>(RecordForm);
const SubmitTrigger = createSubmitTrigger(SubmitModal);
const SubmitFab = createSubmitFab(SubmitTrigger);

interface DataTableProps extends TableProps<Entity> {
  onCreate?: () => void;
  onDelete?: () => void;
}
const DataTable: React.FC<DataTableProps> = ({
  onCreate,
  onDelete,
  ...rest
}) => {
  return (
    <Table
      {...rest}
      rowKey="id"
      bordered
      size="small"
      columns={[
        {
          dataIndex: 'id',
          title: '#',
        },
        {
          dataIndex: 'name',
          title: '名称',
        },
        {
          dataIndex: 'createdAt',
          title: '时间',
          render: (createdAt) =>
            format(new Date(createdAt), 'yyyy-MM-dd HH:mm:ss'),
        },
        {
          title: '操作',
          render: (_, record) => {
            return (
              <>
                <Popconfirm
                  title="确认删除?"
                  onConfirm={async () => {
                    await resource.delete(record.id);
                    onDelete && onDelete();
                  }}
                >
                  <Button type="link">删除</Button>
                </Popconfirm>
                <Divider type="vertical" />
                <SubmitTrigger
                  ModalProps={{
                    title: '编辑课件',
                    centered: true,
                    submit: resource.update(record.id),
                    onSubmitted: onCreate,
                    FormProps: { initialValues: record },
                  }}
                  render={({ open }) => (
                    <Button type="link" onClick={open}>
                      编辑
                    </Button>
                  )}
                />
              </>
            );
          },
        },
      ]}
    />
  );
};

const TabPanel: React.FC<{
  state: AsyncState<{ total: number; data: Entity[] }>;
  fetch: typeof resource.query;
}> = ({ state, fetch }) => {
  const [form] = Form.useForm();
  return (
    <div>
      <Card style={{ marginBottom: 8 }}>
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => fetch({ filter: values })}
        >
          <Form.Item label="课件名称" name="$search">
            <Input />
          </Form.Item>
          <Form.Item style={{ textAlign: 'right' }}>
            <Space>
              <Button htmlType="reset" onClick={() => form.resetFields()}>
                重置
              </Button>
              <Button type="primary" htmlType="submit" disabled={state.loading}>
                查询
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
      <Fallback
        state={state}
        initial={<DataTable loading />}
        render={({ data }) => {
          return (
            <DataTable
              dataSource={data}
              loading={state.loading}
              onCreate={state.retry}
              onDelete={state.retry}
            />
          );
        }}
      />
    </div>
  );
};

function useClassTab(): AppTab {
  const classes = useStyles();
  const [state, fetch] = useAsyncFn(resource.query, []);
  React.useEffect(() => {
    fetch({});
  }, [fetch]);

  return {
    path: `lessons`,
    tab: <Tab label={<TotalLabel state={state}>课件管理</TotalLabel>} />,
    fab: (
      <SubmitFab
        ModalProps={{
          title: '创建课件',
          centered: true,
          submit: resource.create,
          onSubmitted: state.retry,
        }}
        className={classes.fab}
      >
        <AddIcon />
      </SubmitFab>
    ),
    panel: <TabPanel state={state} fetch={fetch} />,
  };
}

export default useClassTab;
