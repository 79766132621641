/** Prompt the user to select files.
 *
 *  @example
 *	  chooseFiles( files => {
 *		  // example: print the file names
 *		  console.log(files.map( file => file.name));
 *	  });
 */

interface FileOptions {
  accept?: string;
  multiple?: boolean;
}
export default function chooseFiles(
  callback: (files: File[]) => void,
  options = {} as FileOptions
) {
  let input = document.createElement('input');
  input.type = 'file';
  input.multiple = true;
  if (options.accept) {
    input.accept = options.accept
  }
  if (typeof options.multiple !== 'undefined') {
    input.multiple = options.multiple
  }
  let done = () => {
    if (!callback) return;
    callback([].slice.call(input.files || []));
  };

  input.addEventListener('change', done);

  input.click();
}
