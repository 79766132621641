import { red } from '@material-ui/core/colors';
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AppBar from './AppBar';
import { NoticeProvider } from './components/Notice';
import { SlotFillProvider } from './components/Slot';
import { ConsecutiveSnackbarsProvider } from './components/Snackbar';
import Admin from './pages/Admin';
import Assistant from './pages/Assistant';
import Learning from './pages/Course/Learning';
import Lesson from './pages/Course/Lesson';
import Practice from './pages/Course/Practice';
import RecordList from './pages/Course/Practice/RecordList';
import Replay from './pages/Course/Practice/Replay';
import Syllabus from './pages/Course/Syllabus';
import Course from './pages/Course';
import Home from './pages/Home';
import Login from './pages/Login';
import Member from './pages/Member';
import NoticePage from './pages/Notice';
import Order from './pages/Order';
import UserPointPage from './pages/Point';
import JiangXueJin from './pages/Jiangxuejin'
import Profile from './pages/Profile';
import { AuthorizeProvider } from './pages/Profile/context';
import Teacher from './pages/Teacher';


import AllCourse from './pages/Admin/AllCourse';
import UserChange from './pages/Admin/UserChange';
import Leave from './pages/Admin/Leave';
import UserProduct from './pages/Admin/UserProduct';
import MyOrder from './pages/Admin/MyOrder';

import BuildInvitationCode from './pages/Admin/BuildInvitationCode';
import SafariAvatar from './pages/Admin/SafariAvatar';
import InvitationCodeList from "@/pages/Admin/InvitationCodeList";




const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#7d4709',
    },
    secondary: red,
  },
  zIndex: {
    modal: 1000,
  },
  overrides: {
    MuiListItem: {
      root: {
        '&.Mui-selected': {
          backgroundColor: '#7d4709',
          color: '#fff',
          '& svg': {
            color: '#fff',
          },
          '&:hover': {
            backgroundColor: '#95550b',
          },
        },
      },
    },
    MuiFab: {
      root: {
        width: 40,
        height: 40,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 48,
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: '#eee',
      },
    },
  },
});

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  container: {
    flexGrow: 1,
  },
});

function App() {
  const classes = useStyles();

  return (
    <ConsecutiveSnackbarsProvider>
      <SlotFillProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Switch>
              <Route path="/login" exact>
                <Login />
              </Route>
              <AuthorizeProvider>
                <NoticeProvider>
                  <div className={classes.root}>
                    <AppBar />
                    <Switch>
                      <Route path="/points">
                        <UserPointPage />
                      </Route>

                      <Route path="/jiangxuejin/:id">
                        <JiangXueJin />
                      </Route>
                      <Route path="/member">
                        <Member />
                      </Route>
                      <Route path="/order">
                        <Order />
                      </Route>
                      <Route path="/profile">
                        <Profile />
                      </Route>
                      <Route path="/notices">
                        <NoticePage />
                      </Route>
                      <Route path="/course/learn/:id">
                        <Lesson />
                      </Route>


                      <Route path="/invitationCode">
                        <BuildInvitationCode />
                      </Route>


                      <Route path="/allCourses">
                        <AllCourse />
                      </Route>

                      <Route path="/userChange">
                        <UserChange />
                      </Route>


                      <Route path="/leave">
                        <Leave />
                      </Route>

                      <Route path="/myOrder">
                        <MyOrder />
                      </Route>

                      <Route path="/userProduct/:id">
                        <UserProduct />
                      </Route>


                      <Route path="/safariAvatar">
                        <SafariAvatar />
                      </Route>

                      <Route path="/invitationCodeList">
                        <InvitationCodeList />
                      </Route>


                      <Route path="/course1/:id">
                        <Course />
                      </Route>

                      <Route path="/course/learn1/:id">
                        <Learning />
                      </Route>
                      <Route path="/course/practice/records/:id">
                        <Replay />
                      </Route>
                      <Route path="/course/practice/records1/:id">
                        <RecordList />
                      </Route>
                      <Route path="/course/practice/:id">
                        <Practice />
                      </Route>
                      <Route path="/course/syllabus/:id/:idd">
                        <Syllabus />
                      </Route>
                      <Route path="/admin">
                        <Admin />
                      </Route>
                      <Route path="/assistant">
                        <Assistant />
                      </Route>
                      <Route path="/teacher">
                        <Teacher />
                      </Route>
                      <Route path="/">
                        <Home />
                      </Route>
                    </Switch>
                  </div>
                </NoticeProvider>
              </AuthorizeProvider>
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      </SlotFillProvider>
    </ConsecutiveSnackbarsProvider>
  );
}

export default App;
