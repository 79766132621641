import { Box, Container } from '@material-ui/core';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Counsel from './Counsel';
import Practice from './Practice';
import StudentPointPage from './Student/Point';
import Dashboard from './Dashboard';
import StudentNormal from './StudentNormal';
import StudentPoint from './StudentPoint';
import StudentSuspended from './StudentSuspended';
import Student from './Student';
import NoteList from "@/pages/Course/Lesson/NoteList";
import NoteListMy from "@/pages/Course/Lesson/NoteList_my";
import StudentChangePwd from "@/pages/Assistant/StudentNormal/index1.tsx";
import StudentBanned from "@/pages/Assistant/StudentBanned";

export interface AssistantProps {}

const Assistant: React.FC<AssistantProps> = () => {
  const { path } = useRouteMatch();
  return (
    <Box flex={1}>
      <Box clone pt={2} pb={8}>
        <Container>
          <Switch>
            <Route path={path} exact>
              <Dashboard />
            </Route>
            <Route path={`${path}/classes/:classID/points`}>
              <StudentPoint />
            </Route>
            <Route path={`${path}/classes`}>
              <Counsel />
            </Route>
            <Route path={`${path}/students/normal`}>
              <StudentNormal />
            </Route>
            {/*上课 -> 课表 ->查看 -> 我的*/}
            <Route path={`${path}/student/mynotes/:id`}>
              <NoteListMy lessonID={1}/>
            </Route>
            {/* 个人信息 -> 密码*/}
            <Route path={`${path}/student/changePassword`}>
              <StudentChangePwd/>
            </Route>



            <Route path={`${path}/students/banned`}>
              <StudentBanned />
            </Route>


            <Route path={`${path}/students/suspended`}>
              <StudentSuspended />
            </Route>
            {/*练习*/}
            <Route path={`${path}/students/:id/practices/:idd`}>
              <Practice />
            </Route>
            <Route path={`${path}/students/:id/points`}>
              <StudentPointPage />
            </Route>
            <Route path={`${path}/students`}>
              <Student />
            </Route>
          </Switch>
        </Container>
      </Box>
    </Box>
  );
};

export default Assistant;
