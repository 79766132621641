import { Box, Container } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { TransitionProps } from '@material-ui/core/transitions';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { ModalProps } from 'antd/lib/modal';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      display: 'flex',
      flexDirection: 'column',
    },
    appBar: {
      position: 'relative',
    },
    title: {
      color: theme.palette.primary.contrastText,
      marginLeft: theme.spacing(1),
      flex: 1,
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const FullScreenDialog: React.FC<ModalProps> = ({
  visible = false,
  title,
  onOk,
  onCancel,
  afterClose,
  confirmLoading,
  children,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.dialog}
      fullScreen
      open={visible}
      onClose={onCancel}
      onExited={afterClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onCancel}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <Button autoFocus color="inherit" onClick={onOk}>
            保存
          </Button>
        </Toolbar>
      </AppBar>
      <Box flex={1} overflow="auto">
        <Container disableGutters maxWidth="sm">
          <Box padding={2}>{children}</Box>
        </Container>
      </Box>
    </Dialog>
  );
};

export default FullScreenDialog;
