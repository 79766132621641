import Fallback from '@/components/Fallback';
import PointList from '@/components/PointList';
import useAsync from '@/hooks/useAsync';
import { PointResorce } from '@/services/user';
import { Box, Container } from '@material-ui/core';
import React from 'react';

export default function UserPointPage() {
  const api = PointResorce;
  const state = useAsync(api.query, [api.query]);
  return (
    <Container maxWidth="sm">
      <Box mt={2} mb={2}>
        <Fallback
          state={state}
          initial={<PointList loading />}
          render={({ data }, loading) => (
            <PointList dataSource={data} loading={loading} />
          )}
        />
      </Box>
    </Container>
  );
}
