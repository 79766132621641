import React from 'react';
import { ContactSourceResource } from '@/services/admin';
import useAsyncFn from '@/hooks/useAsyncFn';
import { Select } from 'antd';
import Fallback from '@/components/Fallback';
import { SelectProps } from 'antd/lib/select';

interface SourceSelectProps extends SelectProps<number> {}
const SourceSelect: React.FC<SourceSelectProps> = ({ onChange, ...rest }) => {
  const [state, query] = useAsyncFn(ContactSourceResource.query);

  React.useEffect(() => {
    query({});
  }, [query]);

  const style: React.CSSProperties = {
    width: '100%',
  };

  return (
    <Fallback
      state={state}
      initial={<Select style={style} {...rest} loading />}
      error={() => <Select style={style} {...rest} disabled />}
      render={(categories, loading) => (
        <Select
          style={style}
          {...rest}
          onChange={onChange}
          loading={loading}
          options={categories.data.map(({ id, channel }) => ({
            value: id,
            label: channel,
          }))}
        />
      )}
    />
  );
};

export default SourceSelect;
