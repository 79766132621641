import Fallback from '@/components/Fallback';
import useAsyncFn from '@/hooks/useAsyncFn';
import { QueryFn, QueryParams } from '@/services/api';
import { Button, List, Select } from 'antd';
import { ascend, prop, zipObj } from 'ramda';
import React from 'react';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  NotDraggingStyle,
} from 'react-beautiful-dnd';

// a little function to help us with reordering the result
function reorder<T>(list: T[], startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

const getItemStyle = (
  isDragging: boolean,
  draggableStyle?: DraggingStyle | NotDraggingStyle
): React.CSSProperties => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  // background: isDraggingOver ? 'lightblue' : 'white',
  // padding: grid,
  // width: 250,
});

interface OptionItem {
  value: string | number;
  label: string;
}

interface EntityListProps<T> {
  query: QueryFn<T>;
  map: (value: T) => OptionItem;
  value?: T[];
  onChange?: (value: T[]) => void;
}

interface Orderable {
  order: number;
}

function EntityList<T extends Orderable>({
  query,
  map,
  value = [],
  onChange,
}: EntityListProps<T>) {
  const [state, search] = useAsyncFn(async (params: QueryParams) => {
    const lectures = await query(params);
    const options = lectures.data.map(map);
    return {
      lookup: zipObj(
        options.map(({ value }) => value.toString()),
        lectures.data
      ),
      options,
    };
  });
  React.useEffect(() => {
    search({});
  }, [search]);

  const [selected, setSelected] = React.useState<string | number>();

  const setLectures = (lectures: T[]) => {
    onChange &&
      onChange(
        lectures.map((lecture, index) => ({ ...lecture, order: index }))
      );
  };
  return (
    <>
      <div style={{ display: 'flex' }}>
        <Fallback
          state={state}
          render={({ options }) => (
            <Select
              value={selected}
              onChange={setSelected}
              style={{ flex: 1 }}
              options={options}
            />
          )}
        />
        <Fallback
          state={state}
          render={({ lookup }) => (
            <Button
              style={{ marginLeft: 16 }}
              type="primary"
              onClick={() => {
                if (selected) {
                  if (value.some((item) => map(item).value === selected)) {
                    return;
                  }
                  setLectures([...value, lookup[selected.toString()]]);
                }
              }}
            >
              添加
            </Button>
          )}
        />
      </div>
      <List style={{ marginTop: 8 }} bordered>
        <DragDropContext
          onDragEnd={(result) => {
            // dropped outside the list
            if (!result.destination) {
              return;
            }

            setLectures(
              reorder(value, result.source.index, result.destination.index)
            );
          }}
        >
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {value.sort(ascend(prop('order'))).map((lecture, index) => (
                  <Draggable
                    key={map(lecture).value}
                    draggableId={map(lecture).value.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <List.Item
                          actions={[
                            <Button
                              type="link"
                              danger
                              onClick={() => {
                                onChange &&
                                  onChange(
                                    value.filter((item) => item !== lecture)
                                  );
                              }}
                            >
                              删除
                            </Button>,
                          ]}
                        >
                          <b>{index + 1}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {map(lecture).label}
                        </List.Item>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </List>
    </>
  );
}

export default EntityList;
