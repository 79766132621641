import React,{createContext} from 'react';
import LearnCard from './LearnCard';
import ExerciseCard from './ExerciseCard';

import {Box, Container, Paper} from '@material-ui/core';
import {useProfile} from "@/pages/Profile/context";
import {differenceInDays} from "date-fns";
import format from "date-fns/format";
import {Route, useParams} from 'react-router-dom';
import Replay from "@/pages/Course/Practice/Replay";
import {UserCourse} from "@/services/entity";
import System1 from "@/pages/Kind/System";
import {Col} from "antd";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import {DashboardProps1} from "@/pages/Admin/BuildInvitationCode";
import Learning from "@/pages/Course/Learning";
import Card from "@material-ui/core/Card";
import useAsync from "@/hooks/useAsync";
import {CommPracticeTips} from "@/services/user";


export const Context = createContext(0)


export interface ChildCourseProps1 {Change:Function}




// const Course:React.FC<ChildCourseProps1> = () => {
// const Course: React.FC<ChildCourseProps> = () => {
const Course: React.FC = () => {

// export default function Course() {

    const { id } = useParams<{ id: string }>();
    console.log( id )
    const  {profile}  = useProfile();


    const practicetips = useAsync(() => CommPracticeTips(parseInt(id)), [id]).value;
    const content1 = practicetips?(practicetips.toString()):(``)

    // const {ddd} = parseInt(id)

    // r.course.id == id ? ( r.course.kind.name):(``)

    return (


            <Box>


                <Box flex={1} pt={2}>
                    <Container maxWidth="sm" style={{paddingLeft:'15px',paddingRight:'15px'}}>
                    {/*{profile.courses? (profile.courses.map((r, i) => (*/}
                    {/*            r.course.id === parseInt(id) ? (  <p  style={{background:`#FFFFE0`}}>小提示：<span  style={{ color: `#FF0000`}} >{r.course.name}</span>共有<span  style={{ color: `#FF0000`}} >{r.course.duration}</span>分钟的练习，平均每天练习<span  style={{ color: `#FF0000`}} >{r.course.dayDuration}</span>分钟即可按时完成！如果没有稳定的练习，后面每天就需要更多练习的时间来补足。</p> ):(``)*/}
                    {/*        ))):(``)}*/}

                        <p  style={{background:`#FFFFE0`,fontFamily:'Microsoft JhengHei',marginTop:'-0px',marginBottom:'10px' }}  dangerouslySetInnerHTML={{ __html: content1}}/>
                    </Container>
                </Box>



            <Learning />



            </Box>



  );
}


export default Course;
