import CategorySelect, { CategoryModule } from '@/components/CategorySelect';
import Fallback from '@/components/Fallback';
import {
  createSubmitModal,
  createSubmitTrigger,
} from '@/components/ResourceManage';
import useAsyncFn from '@/hooks/useAsyncFn';
import { ContactApplyResource } from '@/services/admin';
import { Interface, PickMuteable } from '@/services/api';
import { ContactApply } from '@/services/entity';
import { Button, Form, Input, Table } from 'antd';
import { FormProps } from 'antd/lib/form';
import { TableProps } from 'antd/lib/table';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

type Entity = ContactApply;
const resource = ContactApplyResource;

const RecordForm: React.FC<FormProps> = (props) => {
  return (
    <Form labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }} {...props}>
      <Form.Item label="类型" name="categoryID" rules={[{ required: true }]}>
        <CategorySelect module={CategoryModule.ContactApply} />
      </Form.Item>
      <Form.Item label="链接" name="url" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
    </Form>
  );
};

type EntityMutationFn = Interface<PickMuteable<Entity>, Entity>;

const SubmitModal = createSubmitModal<Entity>(RecordForm);
const SubmitTrigger = createSubmitTrigger(SubmitModal);

interface ApplyTableProps extends TableProps<Entity> {}
const ApplyTable: React.FC<ApplyTableProps> = () => {
  const match = useRouteMatch<{ id: string }>();

  const [state, query] = useAsyncFn(resource.query);
  React.useEffect(() => {
    query({});
  }, [query]);

  const assocProfileID: (fn: EntityMutationFn) => EntityMutationFn = (fn) => {
    return (params, config) => {
      return fn({ ...params, profileID: parseInt(match.params.id) }, config);
    };
  };

  const props: TableProps<Entity> = {
    rowKey: 'id',
    style: {
      marginBottom: 8,
    },
    title: () => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span>申请</span>
        <SubmitTrigger
          ModalProps={{
            title: '添加申请',
            centered: true,
            onSubmitted: state.retry,
            submit: assocProfileID(resource.create),
          }}
          render={({ open }) => (
            <Button type="link" onClick={open}>
              添加申请
            </Button>
          )}
        />
      </div>
    ),
    bordered: true,
    size: 'small',
    columns: [
      {
        dataIndex: 'id',
        title: '#',
      },
      {
        dataIndex: 'category',
        title: '类型',
        render: (value) => value.name,
      },
      {
        dataIndex: 'url',
        title: '链接',
        render: (url) => <a href={url}>链接</a>,
      },
      {
        title: '操作',
        render: (_, record) => {
          return (
            <>
              <SubmitTrigger
                ModalProps={{
                  title: '编辑申请',
                  centered: true,
                  submit: assocProfileID(resource.update(record.id)),
                  onSubmitted: state.retry,
                  FormProps: { initialValues: record },
                }}
                render={({ open }) => (
                  <Button type="link" onClick={open}>
                    编辑
                  </Button>
                )}
              />
            </>
          );
        },
      },
    ],
  };

  return (
    <Fallback
      state={state}
      initial={<Table {...props} />}
      render={(data, loading) => (
        <Table {...props} dataSource={data.data} loading={loading} />
      )}
    />
  );
};

export default ApplyTable;
