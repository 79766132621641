import Fallback from '@/components/Fallback';
import GenseeVideo from '@/components/GenseeVideo';
import useAsync from '@/hooks/useAsync';
import { LessonResource } from '@/services/user';
import { Box, Button, Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import {
    Link,
    Route,
    Switch,
    useHistory,
    useParams,
    useRouteMatch,
} from 'react-router-dom';
import NoteList from './NoteList';

//import PDF from '@/components/pdf-viewer/pdfViewer';
import PDF from '@/components/pdfh5/pdf.js';

const Lesson: React.FC = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const { id ,idd} = useParams<{ id: string,idd:string }>();
  const state = useAsync(() => LessonResource.find(parseInt(id)), []);
  return (



      <Box>
        <Container disableGutters maxWidth="sm">
          <Fallback
              state={state}
              render={({ lesson,userID,userCourse,roleName }) => (
                  <Switch>
                    <Route path={`${match.path}`} exact>
                      <Box flex={1} >
                        <Box padding={2} >
                          <Box
                              mb={4}
                              display="flex"
                              alignItems="center"
                              justifyContent="space-between"

                              style={{marginBottom: "12px"}}
                          >
                            <Typography  variant="subtitle1" style={{ fontFamily:"Microsoft JhengHei",color: "#247fa7"}}>{lesson.name}</Typography>
                            {/*<Button*/}
                            {/*    variant="contained"*/}
                            {/*    size="small"*/}
                            {/*    color="primary"*/}
                            {/*    onClick={() => history.push(`${match.url}/handout`)}*/}
                            {/*>*/}
                            {/*  讲义*/}
                            {/*</Button>*/}


                              <Button
                                  variant="contained"
                                  size="small"
                                  // color="primary"
                                  style={{backgroundColor: "#105952",color:"white" }}
                                  // onClick={() => history.push(`/teacher/students/${userID}/courses/${userCourse.id}`)}
                                  onClick={() => history.push(`${roleName}`)}




                                  // to={`/teacher/students/${record.id}/courses/${record.currentCourseID}`}

                                  // to={`/course/syllabus/${item.id}/${item.course.id}/`}

                              >
                                  课表
                              </Button>
                          </Box>

                          {lesson.lectures.map((lecture) => (
                              <Box mb={2} >
                                <Box
                                    alignItems="center"
                                    style={{ marginLeft:"-8px",marginRight:"-8px"}}
                                    // style={{ backgroundColor: "white"}}
                                >
                                  {/*{lecture.genseeCourseware ? (*/}
                                  {/*  <GenseeVideo*/}
                                  {/*    videoID={lecture.videoID}*/}
                                  {/*  />*/}
                                  {/*) : (*/}
                                  {/*  <video*/}
                                  {/*    style={{ width: '100%', maxWidth: '100%' }}*/}
                                  {/*    src={lecture.url}*/}
                                  {/*    controls*/}
                                  {/*    controlsList="nodownload"*/}
                                  {/*  />*/}
                                  {/*)}*/}
                                    <GenseeVideo
                                        videoID={lecture.videoID}
                                        userID = {userID}
                                    />
                                </Box>


                                <Typography
                                    variant="h2"
                                    style={{ textAlign: 'center' ,fontFamily:"Microsoft JhengHei",fontSize:"16px",color: "#0070a3"}}
                                >
                                  {lecture.name}
                                </Typography>
                              </Box>
                          ))}
                        </Box>
                        <NoteList lessonID={lesson.id} />
                      </Box>
                    </Route>
                    <Route path={`${match.path}/handout`}>
                      <Box flex={1}>
                        {lesson.handout.endsWith('.pdf') ? (
                            <PDF src={lesson.handout}></PDF>
                        ) : (
                            <img
                                src={lesson.handout}
                                style={{ width: '100%', maxWidth: '100%' }}
                                alt="handout"
                            />
                        )}
                      </Box>
                    </Route>
                  </Switch>
              )}
          />
        </Container>
      </Box>
  );
};

export default Lesson;
