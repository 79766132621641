
import { UploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { ButtonProps } from 'antd/lib/button';

interface UploadButtonProps extends ButtonProps {
  upload: (file: File) => Promise<any>;
  onUploadSuccess?: (data: any) => void;
}
const UploadButton: React.FC<UploadButtonProps> = ({
  onUploadSuccess,
  upload,
  ...rest
}) => {
  const [uploading, setUploading] = React.useState(false);
  // const [progress, setProgress] = React.useState(0);

  return (
    <Button
      {...rest}
      loading={uploading}
      icon={<UploadOutlined />}
      onClick={() => {
        const input = document.createElement('input');
        input.type = 'file';
        input.click();
        input.addEventListener('change', async () => {
          if (!input.files) {
            return;
          }
          const file = input.files.item(0);
          if (!file) {
            return;
          }

          try {
            setUploading(true);
            const { url } = await upload(file);
            onUploadSuccess && onUploadSuccess(url);
          } finally {
            setUploading(false);
          }
        });
      }}
    >
      上传
    </Button>
  );
};

export default UploadButton