import React from 'react';

const Duration: React.FC<{ value: number; text?: boolean }> = ({
  value,
  text,
}) => {
  const content = React.useMemo(() => {
    if (text) {
      return <>{Math.floor(value / 1000000000 / 60)}分钟</>;
    }
    const h = Math.floor(value / 1000000000 / 60 / 60);
    const m = Math.floor(value / 1000000000 / 60) % 60;
    const s = Math.floor(value / 1000000000) % 60;
    return [h, m, s].map((n) => n.toString().padStart(2, '0')).join(':');
  }, [text, value]);
  return <>{content}</>;
};

export default Duration;
