import SearchSelect from '@/components/SearchSelect';
import useAsyncFn from '@/hooks/useAsyncFn';
import {PickMuteable, useQueryParams} from '@/services/api';
import {
  AssistantResource,
  CourseResource,
  UserResource,
} from '@/services/teacher';
import {Badge, Button, Card, Form, Input, Radio, Select, Space} from 'antd';
import React from 'react';
import Fallback from '@/components/Fallback';
import Table, { TableProps } from 'antd/lib/table';
import {getRoleTitle, Role, User, UserRole} from '@/services/entity';
import { Link } from 'react-router-dom';
import {createSubmitFab, createSubmitModal, createSubmitTrigger} from "@/components/ResourceManage";
import {FormProps} from "antd/lib/form";
import {ClassResource} from "@/services/admin";
import FormatedDatePicker from "@/components/FormatedDatePicker";

type Entity = User;
const resource = UserResource;


interface DataTableProps extends TableProps<Entity> {
    onCreate?: () => void;
}

interface RoleMutipleSelectProps {
    value?: UserRole[];
    onChange?: (value: PickMuteable<UserRole>[]) => void;
}


const renderState = (value: string) => {
    switch (value) {
        case 'normal':
            return <Badge status="success" text="正常" />;
        // case 'suspended':
        //     return <Badge status="default" text="暂停" color="red"/>;
        case 'banned':
            return <Badge status="error" text="禁用" />;
        default:
            return null;
    }
};


const RoleMutipleSelect: React.FC<RoleMutipleSelectProps> = ({
                                                                 value,
                                                                 onChange,
                                                             }) => {
    return (
        <Select
            value={value?.map(({ name }) => name)}
            mode="multiple"
            options={[
                { value: Role.Student, label: getRoleTitle(Role.Student) },
                { value: Role.Assistant, label: getRoleTitle(Role.Assistant) },
                { value: Role.Teacher, label: getRoleTitle(Role.Teacher) },
                { value: Role.Admin, label: getRoleTitle(Role.Admin) },
            ]}
            onChange={(value: Role[]) => {
                onChange && onChange(value.map((name) => ({ name })));
            }}
        />
    );
};

const RecordForm: React.FC<FormProps> = (props) => {
    const { initialValues } = props;

    return (
        <Form labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }} {...props}>
            <Form.Item
                label="角色"
                name="roles"
                initialValue={[{ name: Role.Student }]}
                rules={[{ required: true }]}
            >
                <RoleMutipleSelect />
            </Form.Item>
            <Form.Item label="学号" name="username" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item
                label="密码"
                name="password"
                rules={[{ required: !initialValues?.id }]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item label="姓名" name="realname" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item label="班级" name="classID" rules={[]}>
                <SearchSelect
                    allowEmptyValue
                    query={ClassResource.query}
                    map={({ id, name, teacher, assistant }) => ({
                        value: id,
                        label: `${name} 琴师:${assistant?.realname}, 客服:${teacher?.realname}`,
                    })}
                />
            </Form.Item>
            <Form.Item label="开课时间" name="courseStartTime" rules={[]}>
                <FormatedDatePicker />
            </Form.Item>
            <Form.Item label="结课时间" name="courseEndTime" rules={[]}>
                <FormatedDatePicker />
            </Form.Item>
            <Form.Item
                label="状态"
                name="state"
                initialValue="normal"
                rules={[{ required: true }]}
            >
                <Radio.Group>
                    <Radio.Button value="normal">在学</Radio.Button>
                    <Radio.Button value="suspended">暂停</Radio.Button>
                    <Radio.Button value="banned">禁用</Radio.Button>
                </Radio.Group>
            </Form.Item>
        </Form>
    );
};


const SubmitModal = createSubmitModal<Entity>(RecordForm);
const SubmitTrigger = createSubmitTrigger(SubmitModal);
const SubmitFab = createSubmitFab(SubmitTrigger);



const DataTable: React.FC<DataTableProps> = ({ onCreate, ...props }) => {
// const DataTable: React.FC<TableProps<User>> = (props) => {
  return (
    <Table
      {...props}
      rowKey="id"
      bordered
      size="small"
      style={{ minWidth: '100%' }}
      columns={[
        { dataIndex: 'id', title: '#' },
        {
          dataIndex: 'username',
          title: '学号',
          // render: (value, record) => (
          //   <Link to={`/teacher/students/${record.id}/profile`}>
          //     {record.realname} ({record.username})
          //   </Link>
          //
          // ),

            render: (value, record) => {
                return (
                    <>
                        <Link to={`/teacher/students/${record.id}/profile`}>
                            {record.realname} ({record.username})
                        </Link>
                        <br />
                        {renderState(record.state)}
                    </>
                );
            },
        },
        {
          title: '操作',
          render: (_, record) => (
            <Space>
                <SubmitTrigger
                    ModalProps={{
                        title: '编辑用户',
                        centered: true,
                        submit: resource.update(record.id),
                        onSubmitted: onCreate,
                        FormProps: { initialValues: record },
                    }}
                    render={({ open }) => (
                        <Button style={{ padding: 0 }} type="link" onClick={open}>
                            编辑
                        </Button>
                    )}
                />
              <Link
                to={`/teacher/students/${record.id}/courses`}
              >
                课程
              </Link>
              <Link
                to={`/teacher/students/${record.id}/practices`}
              >
                练习
              </Link>
              <Link
                to={`/teacher/students/${record.id}/points`}
              >
                积分
              </Link>
              <Link
                to={`/teacher/students/${record.id}/events`}
              >
                事件
              </Link>
            </Space>
          ),
        },
      ]}
    />
  );
};

export default function StudentPage() {
  const [form] = Form.useForm();
  const params = useQueryParams();
  const [state, fetch] = useAsyncFn(UserResource.query);
  React.useEffect(() => {
    fetch(params);
  }, [fetch, params]);
  return (
    <>
      <Card style={{ marginBottom: 8 }}>
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => fetch({ filter: values })}
          initialValues={params.filter}
        >
          <Form.Item label="状态" name="state">
            <Select placeholder="全部状态" allowClear>
              <Select.Option value="normal">在学</Select.Option>
              <Select.Option value="suspended">暂停</Select.Option>
              <Select.Option value="banned">禁用</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="课表" name="courseID">
            <SearchSelect
              query={CourseResource.query}
              map={({ id, name }) => ({ value: id, label: name })}
              placeholder="全部课表"
              allowClear
            />
          </Form.Item>
          <Form.Item label="琴师" name="assistantID">
            <SearchSelect
              query={AssistantResource.query}
              map={({ id, realname }) => ({ value: id, label: realname })}
              placeholder="全部琴师"
              allowClear
            />
          </Form.Item>
          <Form.Item label="姓名" name="$search">
            <Input placeholder="请输入学号或姓名" />
          </Form.Item>
          <Form.Item style={{ textAlign: 'right' }}>
            <Space>
              <Button
                onClick={() => form.resetFields()}
              >
                重置
              </Button>
              <Button
                type="primary"
                htmlType="submit"
              >
                查询
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
      <Fallback
        state={state}
        initial={<DataTable loading />}
        render={({ data }, loading) => (
          <DataTable loading={loading} dataSource={data} />
        )}
      />
    </>
  );
}
