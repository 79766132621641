import { CategoryModule } from '@/components/CategorySelect';
import Fallback from '@/components/Fallback';
import SearchSelect from '@/components/SearchSelect';
import useAsyncFn from '@/hooks/useAsyncFn';
import { encodeQueryParams, useQueryParams } from '@/services/api';
import {
  AssistantResource,
  CategoryResource,
  ClassResource,
} from '@/services/teacher';


import {Class3Resource} from '@/services/user';

import { Box } from '@material-ui/core';
import { Button, Card, Form, Space, Table, Select } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { comparator } from 'ramda';


export default function Class() {
  const [form] = Form.useForm();
  const params = useQueryParams();
  const [state, fetch] = useAsyncFn(Class3Resource.query);
  React.useEffect(() => {
    fetch(params);
  }, [fetch, params]);
  return (
    <Fallback
      state={state}
      render={({ data, total, meta }) => (
        <>
          <Card style={{ marginBottom: 8 }}>
            <Form
              form={form}
              layout="vertical"
              onFinish={(values) => fetch({ filter: values })}
              initialValues={params.filter}
            >
              <Form.Item label="状态" name="state">
                <Select placeholder="全部状态" allowClear>
                  <Select.Option value="normal">进行中</Select.Option>
                  <Select.Option value="closed">已结班</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item label="琴师" name="assistantID">
                <SearchSelect
                  query={AssistantResource.query}
                  map={({ id, realname }) => ({ value: id, label: realname })}
                  allowClear
                  placeholder="全部琴师"
                />
              </Form.Item>

              <Form.Item label="班级类型" name="categoryID">
                <SearchSelect
                  placeholder="全部类型"
                  query={() =>
                    CategoryResource.query({
                      filter: { module: CategoryModule.Class },
                    })
                  }
                  map={({ name, id }) => ({ value: id, label: name })}
                />
              </Form.Item>
              <Form.Item style={{ textAlign: 'right' }}>
                <Space>
                  <Button htmlType="reset" onClick={() => form.resetFields()}>
                    重置
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={state.loading}
                  >
                    查询
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Card>
          <Table
            rowKey="id"
            title={() => (
              <Box display="flex" justifyContent="space-between">
                <div>
                  {/*共计 {total} 个班级 {meta.students}人*/}
                </div>
              </Box>
            )}
            bordered
            size="small"
            style={{ minWidth: '100%' }}
            dataSource={data}
            columns={[
              {
                dataIndex: 'id',
                title: '#',
              },
              {
                dataIndex: 'name',
                title: '班级',
                render: (value, record) => (
                  <Link to={`/teacher/classes/${record.id}/records`}>
                    {value}
                  </Link>
                ),
              },
              // {
              //   dataIndex: 'assistant',
              //   title: '助教',
              //   render: (assistant) => (
              //     <Link
              //       to={`/teacher/assistants?${qs.stringify(
              //         encodeQueryParams({
              //           filter: { id: assistant.id },
              //         })
              //       )}`}
              //     >
              //       {assistant.realname}
              //     </Link>
              //   ),
              // },

              {
                dataIndex: 'assistant',
                title: '琴师',
                render: (assistant) => {
                  if (assistant) {
                    return (
                        <Link
                            to={`/teacher/assistants?${qs.stringify(
                                encodeQueryParams({
                                  filter: { id: assistant.id },
                                })
                            )}`}
                        >
                          {assistant.realname}
                        </Link>
                    );
                  }
                  return '-';
                },
              },
              {
                dataIndex: 'students',
                title: '人数',
                sorter: comparator((a, b) => a.students.length < b.students.length),
                render: (students) => (students?(students.length):('')),
              },
              {
                title: '进度',
                render: (_, record) => (
                  <Link
                    to={`/teacher/assistants/${record.assistantID}/classes/${record.id}`}
                  >
                    查看
                  </Link>
                ),
              },
            ]}
          />
        </>
      )}
    />
  );
}
