import React from 'react';
import AppTabs from '@/components/AppTabs';
import { Tab } from '@material-ui/core';

export interface NotificationProps {}

const Notification: React.FC<NotificationProps> = () => {
  return (
    <AppTabs
      tabs={[
        { path: 'today', tab: <Tab label="今日" />, panel: '今日' },
        { path: 'yesterday', tab: <Tab label="昨日" />, panel: '昨日' },
        { path: 'week', tab: <Tab label="每周" />, panel: '每周' },
        { path: 'history', tab: <Tab label="历史" />, panel: '历史' },
      ]}
    />
  );
};

export default Notification;
