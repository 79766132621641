import Fallback from '@/components/Fallback';
import SearchSelect from '@/components/SearchSelect';
import useAsyncFn from '@/hooks/useAsyncFn';
import useAsyncTap from '@/hooks/useAsyncTap';
import useCatchError from '@/hooks/useCatchError';
import { applyFilter } from '@/services/api';
import {
  classRemainPoint,
  PointResource,
  UserResource,
} from '@/services/assistant';


import {HorseResource} from '@/services/admin';

import { Horse} from '@/services/entity';
import { Box } from '@material-ui/core';
import {Button, Card, Col, Divider, Form, Input, InputNumber, message, Popconfirm, Table} from 'antd';
import { TableProps } from 'antd/lib/table';
import format from 'date-fns/format';
import React, { useEffect, useMemo } from 'react';
import {Link, useParams} from 'react-router-dom';
import useAsync from "@/hooks/useAsync";

import Editor from "@/components/Editor/Editor"



const DataTable: React.FC<TableProps<Horse>> = (props) => (

  <Table
    {...props}
    rowKey="id"
    bordered
    size="small"
    //title={() => <div>内容</div>}
    style={{ minWidth: '100%' }}
    columns={[
        {
            dataIndex: 'id',
            title: '#',
        },
      {
        dataIndex: 'content',
        title: '内容',
      },
      {
        dataIndex: 'createdAt',
        title: '日期',
        render: (value) => format(new Date(value), 'yyyy-MM-dd HH:mm:ss'),
      },
        {
            dataIndex: 'is_publish',
            title: '状态',
            render: (value, record) => (


                record.is_publish === 1 ? (
                        <span  style={{ color: `#FF0000`}} >发布中</span>
                ) : (
                    <span>待发布</span>
                )

            ),
        },

        {
            title: '操作',
            render: (value, record) => (


                record.is_publish === 1 ? (
                    <>
                        <Popconfirm
                            title="确认收回?"
                            onConfirm={async () => {
                                await HorseResource.update(record.id)({
                                });
                                window.location.href="/";

                                setTimeout(function () {
                                    window.location.reload();
                                }, 1000);
                            }}
                        >
                            <Button type="link">收回</Button>
                        </Popconfirm>

                        <Divider type="vertical" />

                    <Popconfirm
                        title="确认删除?"
                        onConfirm={async () => {
                            await HorseResource.delete(record.id);

                            window.location.href="/";

                            setTimeout(function () {
                                window.location.reload();
                            }, 1000);

                        }}
                    >
                        <Button type="link">删除</Button>
                    </Popconfirm>

                    </>

                ) : (
                    <>
                        <Popconfirm
                            title="确认发布?"
                            onConfirm={async () => {
                                await HorseResource.find(record.id);
                                // window.location.reload();


                                window.location.href="/";

                                setTimeout(function () {
                                    window.location.reload();
                                }, 1000);
                            }}
                        >
                            <Button type="link">发布</Button>
                        </Popconfirm>

                        <Divider type="vertical" />

                        <Popconfirm
                            title="确认删除?"
                            onConfirm={async () => {
                                await HorseResource.delete(record.id);
                                window.location.reload();
                            }}
                        >
                            <Button type="link">删除</Button>
                        </Popconfirm>
                    </>
                )

            ),
        },
    ]}
  />
);

const Changes: React.FC = () => {
  // const params = useParams<{classID: string}>();
  // const classID = useMemo(() => parseInt(params.classID), [params.classID]);


    const params = useParams<{ id: string }>();
    const userID = parseInt(params.id);


  const [form] = Form.useForm();
  // const [remainState, refreshRemain] = useAsyncFn(classRemainPoint);

  // const [pointListState, queryPointList] = useAsyncFn(ChangeResource.query);
  // useEffect(() => {
  //   queryPointList({});
  // }, [queryPointList]);



    const pointListState = useAsync(
        () => HorseResource.query({ filter: { userID } }),
        [userID]
    );


  const [, createPoint] = useAsyncFn(

    useAsyncTap(HorseResource.create, () => {
      // message.success('新增成功');
      form.resetFields();
      //   window.location.reload();

        window.location.href="/";

        setTimeout(function () {
            window.location.reload();
        }, 1000);



        // setTimeout(function () {
        //     window.location.href="http://localhost:3000/admin/horselamp";
        // }, 2000);


    })
  );

  const catchedCreatePoint = useCatchError(createPoint);

    const { TextArea } = Input;


    const sleep = (milliseconds:any) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }


    const resetFields =(state:any)=>{
        form.setFieldsValue({content:state})
        form.submit()




        // window.location.reload();

        /*
        sleep(2000);
        window.history.back();
        sleep(2000);
        window.history. forward();


        setTimeout(function () {
            //内容1秒后,刷新
            // window.location.href="/admin/horselamp";
            // alert(2)
            // window.history. forward();
            window.history.back();
        }, 3000);

        setTimeout(function () {

            window.history. forward();
        }, 3000);

        */


    }

    const setContent =(state:any)=>{
        form.setFieldsValue({ is_publish: 1 });
        form.setFieldsValue({content:state})
        form.submit()
    }

    function formProcess() {
        form.setFieldsValue({ is_publish: 1 });
        form.submit()

    };

    return (
    <Box>

      <Card style={{ marginBottom: 16 }} title="新增通知">
        <Form
          form={form}
          onFinish={catchedCreatePoint as any}
        >

            {/*<Form.Item  name="is_publish" hidden={true} >*/}
            {/*    <Input />*/}
            {/*</Form.Item>*/}


            <Form.Item  name="is_publish"  >
                <Input />
            </Form.Item>

          <Form.Item label="内容" name="content" >
              {/*<TextArea  maxLength={100} style={{ height: 120 }}/>*/}
              <Editor id={"text"}  resetFields={resetFields}  setContent={setContent}/>
          </Form.Item>
          <Box display="flex" justifyContent="flex-end">
            {/*<Button htmlType="submit" type="primary">*/}
            {/*  确定*/}
            {/*</Button>*/}
            {/*  <br />*/}
            {/*  <Button danger type="primary"  onClick={() => formProcess()}>*/}
            {/*      发布*/}
            {/*  </Button>*/}
          </Box>


        </Form>
      </Card>

        <Fallback
        state={pointListState}
        initial={<DataTable loading />}
        render={({ data }) => <DataTable dataSource={data} />}
      />
    </Box>
  );
};

export default Changes;
