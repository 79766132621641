import { AsyncFnProvider } from '@/hooks/useAsyncFn';
import { StatusError } from '@/services/api';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface AuthorizeRedirectProps {
}
const AuthorizeRedirect: React.FC<AuthorizeRedirectProps> = ({
  children,
}) => {
  const history = useHistory();
  return (
    <AsyncFnProvider
      value={{
        onError: (err) => {
          if (err instanceof StatusError && err.response.status === 401) {
            history.push('/login')
            return;
          }
          throw err;
        },
      }}
    >
      {children}
    </AsyncFnProvider>
  );
};

export default AuthorizeRedirect;
