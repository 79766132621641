import React from 'react';
import PropTypes from 'prop-types'
import {Button,Space,Form } from 'antd';

 class Editor extends React.Component {

    static propTypes = {
        setContent:PropTypes.func.isRequired,
        resetFields:PropTypes.func.isRequired
    }


  constructor(props){
    super(props);

    this.state={
      id:this.props.id?this.props.id:null,
      editor1 :null,
      content: null

    }

    //this.handleSubmitForm = this.handleSubmitForm.bind(this)
  }
  componentDidMount(){
    let UE = window.UE;
    let {id} = this.state;
    if(id){
      try {
        /*
            加载之前先执行删除操作，否则如果存在页面切换，
            再切回带编辑器页面重新加载时不刷新无法渲染出编辑器
        */
        UE.delEditor(id);
      }catch (e) {}
        let UE = window.UE;
      let  editor = UE.getEditor(id, {
        autoHeightEnabled: true,
        autoFloatEnabled: true
      });

      this.setState({editor1:editor});


//        editor.addListener("blur",function(){
//              const con = editor.getContent()
//              console.log(con)
//
//              this.props.setContent(con)
//
//              debugger
//          });
    }
  }


    search =()=>{
        const cont = this.textarea.value.trim()
//        alert(this.state.editor1.getContent())
//         alert(cont)

         this.props.setContent(cont)
    }


     // reset =()=>{
     //     this.state.editor1.execCommand( "cleardoc" );
     //     this.props.resetFields()
     // }

     reset =()=>{
         const cont = this.textarea.value.trim()
         this.props.resetFields(cont)
     }


     // reset =()=>{
     //     this.state.editor1.execCommand( "cleardoc" );
     //     this.props.resetFields()
     // }


  render(){
    let {id} = this.state;
    let form_data = this.state.editor;
    return (
      <div>
        <textarea id={id}    ref={textarea=> this.textarea = textarea}  />

        <Form.Item style={{ textAlign: 'right' }}>
        <Space>
        <Button  type="primary"  onClick={this.reset}>确定</Button>
        <Button  type="primary"   onClick={this.search}>发布</Button>
        </Space>
         </Form.Item>
      </div>
    );
  }
}




export default Editor