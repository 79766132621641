import React from 'react';
import { Chip } from '@material-ui/core';

interface LearnStateChipProps {
  type: string;
}
function LearnStateChip({ type }: LearnStateChipProps) {
  if (type === 'locked') {
    return <Chip size="small" label="未解锁" />;
  }
  if (type === 'waiting') {
    return <Chip size="small" label="待完成" />;
  }
  if (type === 'learning') {
    return <Chip size="small" color="primary" label="学习中" />;
  }
  if (type === 'completed') {
    return <Chip size="small" label="已完成" />;
  }
  return null;
}

export default LearnStateChip;
