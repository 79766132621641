import {
  DependencyList,
  useCallback,
  useState,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react';
import { useMountedState } from 'react-use';

export interface AsyncState<T> {
  initialized: boolean;
  loading: boolean;
  error?: any;
  value?: T;
  reset: () => void;
  retry: () => void;
}

export type PromiseFn<T> = (...args: any[]) => Promise<T>;

export type Result<T> = T extends PromiseFn<infer R> ? R : never;

export type AsyncFn<T extends PromiseFn<any>> = (
  fn: T,
  deps?: DependencyList,
  initialState?: Partial<AsyncState<Result<T>>>
) => [AsyncState<Result<T>>, T];

interface AsyncFnContextValue {
  onError?: (reason: any, retry: () => Promise<void>) => void;
}
const AsyncFnContext = createContext<AsyncFnContextValue>({});

export const AsyncFnProvider = AsyncFnContext.Provider;

export default function useAsyncFn<T extends (...args: any[]) => Promise<any>>(
  fn: T,
  deps: DependencyList = [],
  initialState: Partial<AsyncState<Result<T>>> = {
    loading: false,
    initialized: false,
  }
): [AsyncState<Result<T>>, T] {
  const context = useContext(AsyncFnContext);
  const onErrorRef = useRef(context.onError);
  onErrorRef.current = context.onError;

  const reset = useCallback(
    (set: Dispatch<SetStateAction<AsyncState<Result<T>>>>) => {
      set((state) => ({
        ...state,
        retry: () => {},
        initialized: false,
        loading: false,
        ...initialState,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [state, set] = useState<AsyncState<Result<T>>>(() => ({
    reset: () => reset(set),
    retry: () => {},
    initialized: false,
    loading: false,
    ...initialState,
  }));

  const isMounted = useMountedState();

  const callback = useCallback<any>((...args: any[]) => {
    const action = () => {
      set((state) => ({
        ...state,
        loading: true,
        retry: () => {},
      }));

      return fn(...args).then(
        (value) => {
          isMounted() &&
            set((state) => ({
              ...state,
              value,
              error: undefined,
              loading: false,
              initialized: true,
              retry: action,
            }));
          return value;
        },
        (error) => {
          isMounted() &&
            set((state) => ({
              ...state,
              error,
              loading: false,
              retry: action,
            }));

          if (onErrorRef.current) {
            onErrorRef.current(error, action);
            return;
          }

          return error;
        }
      );
    };

    return action();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return [state, callback];
}
