import { CategoryModule } from '@/components/CategorySelect';
import Fallback from '@/components/Fallback';
import SearchSelect from '@/components/SearchSelect';
import useAsyncFn from '@/hooks/useAsyncFn';
import { encodeQueryParams, useQueryParams } from '@/services/api';
import {
  AssistantResource,
  CategoryResource,
  ClassResource1,
} from '@/services/teacher';
import { Box } from '@material-ui/core';
import { Button, Card, Form, Space, Table, Select } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { comparator } from 'ramda';

export default function Class1() {
  const [form] = Form.useForm();
  const params = useQueryParams();
  const [state, fetch] = useAsyncFn(ClassResource1.query);
  React.useEffect(() => {
    fetch(params);
  }, [fetch, params]);
  return (
    <Fallback
      state={state}
      render={({ data, total, meta }) => (
        <>

          <Table
            rowKey="id"
            title={() => (
              <Box display="flex" justifyContent="space-between">
                <div>
                  共计 {total} 个班级 {meta.students}人
                </div>
              </Box>
            )}
            bordered
            size="small"
            style={{ minWidth: '100%' }}
            dataSource={data}
            columns={[
              {
                dataIndex: 'id',
                title: '#',
              },
              {
                dataIndex: 'name',
                title: '班级',
                render: (value, record) => (
                  <Link to={`/teacher/classes/${record.id}/records`}>
                    {value}
                  </Link>
                ),
              },
              {
                dataIndex: 'assistant',
                title: '琴师',
                render: (value) => (
                  <Link
                    to={`/teacher/assistants?${qs.stringify(
                      encodeQueryParams({
                        filter: { id: value.id },
                      })
                    )}`}
                  >
                    {value.realname}
                  </Link>
                ),
              },
              {
                dataIndex: 'students',
                title: '人数',
                sorter: comparator(
                  (a, b) => a.students.length < b.students.length
                ),
                render: (value) => value.length,
              },
              {
                title: '进度',
                render: (_, record) => (
                  <Link
                    to={`/teacher/assistants/${record.assistantID}/classes/${record.id}`}
                  >
                    查看
                  </Link>
                ),
              },
            ]}
          />
        </>
      )}
    />
  );
}
