import image from '@/assets/4.jpg';
import useAsyncFn from '@/hooks/useAsyncFn';
import { UserLesson } from '@/services/entity';
import { Box, Chip } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useHistory } from 'react-router-dom';
import {message} from "antd";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  media: {
    height: 140,
  },
}));

interface LearnActionCardPrpos {
  lesson: UserLesson;
}
export default function LearnActionCard({
  lesson: value,
}: LearnActionCardPrpos) {
  const classes = useStyles();
  const history = useHistory();


  const [, startLearn] = useAsyncFn(async () => {
    history.push(`/course/learn/${value.id}`);
  });
  return (
    <Card className={classes.root} variant="outlined" onClick={startLearn}>
      <CardActionArea>
        <CardMedia className={classes.media} image={value.userCourse.course.picurl}>
          {/* <Box left={0} top={0} ml={1} mt={1} position="absolute">
            <Chip size="small" label={``} />
          </Box> */}
        </CardMedia>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">

          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body2" color="textSecondary" component="p">
              {/*,fontSize:16*/}
              <span  style={{ color: `#FF0000`}} >当前进度：{value.lesson.name}</span>
            </Typography>
            <Box ml={2}>
              <Chip
                size="small"
                label={
                  <>
                    {value.practiceDuration} / {value.lesson.practiceDuration}
                  </>
                }
              />
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
