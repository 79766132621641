import useAsyncFn from '@/hooks/useAsyncFn';
import useAsyncTap from '@/hooks/useAsyncTap';
import useCatchError from '@/hooks/useCatchError';


import { Product1Resource} from '@/services/admin';

import { Box } from '@material-ui/core';
import {Button, Card, Col, Form, Input, InputNumber, message, Table} from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Editor from "@/components/Editor/Editor";


const Product: React.FC = () => {


    const params = useParams<{ id: string }>();
    const userID = parseInt(params.id);



    const [form] = Form.useForm();




    const [, createPoint] = useAsyncFn(

        useAsyncTap(Product1Resource.create, () => {
            form.resetFields();
            window.location.href="/admin/mall/products";
        })
    );

    const catchedCreatePoint = useCatchError(createPoint);





    const resetFields =(state:any)=>{
        form.setFieldsValue({content:state})
        form.setFieldsValue({ id: userID });
        form.submit()
    }

    const setContent =(state:any)=>{
        form.setFieldsValue({content:state})
        form.submit()
    }


    return (
        <Box>

            <Card style={{ marginBottom: 16 }} title="修改商品内容">
                <Form
                    form={form}
                    onFinish={catchedCreatePoint as any}
                >

                    {/*<Form.Item  name="id" hidden={true} >*/}
                    {/*    <Input />*/}
                    {/*</Form.Item>*/}

                    <Form.Item  name="id" >
                        <Input />
                    </Form.Item>

                    <Form.Item label="内容" name="content" >
                        <Editor id={"text"}  resetFields={resetFields}  setContent={setContent}/>
                    </Form.Item>
                    <Box display="flex" justifyContent="flex-end">
                    </Box>


                </Form>
            </Card>

        </Box>
    );
};

export default Product;
