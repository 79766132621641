


import {LeadboardAll, UserProductResource} from '@/services/user';

import {Box} from '@material-ui/core';
import {Button, Card, Col, Form, Input, Popconfirm, message, Modal} from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';


import useAsync from "@/hooks/useAsync";
import {UserProductResource1}   from '@/services/user';


interface MallProps{
    id:string;
    idd:number;
    // price: number;
    qinbei: string;
    content: string;
    is_price:number;
}



const Ios:React.FC<MallProps> = (props)=>{

    const {id,idd,qinbei,content,is_price} = props

    function formProcess(str:any) {


        if (is_price > 0){
            message.warning({
                content: '您的琴贝余额不足!',
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            })
        }else {



            Modal.info({
                content: '购买成功,请联系客服',
            });


            setTimeout(function () {

                //直接调用跳转到后台
                UserProductResource1({ id})
                window.location.href ="/shoppingmall"
            }, 1500);
        }
    }



    var userAgent = navigator.userAgent;

    //苹果
    if (userAgent.indexOf("iPhone") > -1) {
        return (
            <Box >
                <div
                    style={ant_col_windows}
                >

                    <img style={img}
                         src={content}
                    />
                </div>

                <div style={bt_ios}>


                    <div style={{ display:"table-cell"}}>
                        <Popconfirm
                            title={qinbei}
                            okText="确认"
                            cancelText="取消"
                            onConfirm={() => formProcess(idd)}
                        >
                            <Button style={a_ios} > 立 即 购 买</Button>
                        </Popconfirm>
                    </div>

                    <div style={{ display:"table-cell",padding:"20px"}}>
                        <Popconfirm
                            title={qinbei}
                            okText="确认"
                            cancelText="取消"
                            onConfirm={() => formProcess(idd)}
                        >
                            <Button style={b_ios} >赚琴贝</Button>
                        </Popconfirm>
                    </div>
                </div>
            </Box>
        )
    }else{
        return (
            <Box >
                <div
                    style={ant_col_windows}
                >

                    <img style={img}
                         src={content}
                    />
                </div>

                <div style={bt_android}>


                    <div style={{ display:"table-cell"}}>
                        <Popconfirm
                            title={qinbei}
                            okText="确认"
                            cancelText="取消"
                            onConfirm={() => formProcess(idd)}
                        >
                            <Button style={a_android} > 立 即 购 买</Button>
                        </Popconfirm>
                    </div>

                    <div style={{ display:"table-cell",padding:"20px"}}>
                        <Popconfirm
                            title={qinbei}
                            okText="确认"
                            cancelText="取消"
                            onConfirm={() => formProcess(idd)}
                        >
                            <Button style={b_android} >赚琴贝</Button>
                        </Popconfirm>
                    </div>
                </div>
            </Box>
        )
    }


}


const UserProduct: React.FC = () => {


    const { id } = useParams<{ id: string }>();


    const product = useAsync(() => UserProductResource({ id}), [id]);

    const aaa =product.value;

    const bbb = aaa?(aaa.content):(``)

    const idd = aaa?(aaa.id):(0)

    const is_price = aaa?(aaa.is_price):(0)

    const qinbei = aaa?("您将消耗"+aaa.qinbei+"琴贝"):('')

    function formProcess(str:any) {


        if (is_price > 0){
            message.warning({
                content: '您的琴贝余额不足!',
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            })
        }else {

            //内容1秒后,刷新
            // message.success({
            //     content: '购买成功,请联系客服',
            //     className: 'custom-class',
            //     style: {
            //         marginTop: '20vh',
            //     },
            // })


            Modal.info({
                content: '购买成功,请联系客服',
            });


            setTimeout(function () {

                //直接调用跳转到后台
                UserProductResource1({ id})
                window.location.href ="/shoppingmall"
            }, 1500);
        }
    }

    var userAgent = navigator.userAgent;

    //桌面
    if (userAgent.indexOf("Windows") > -1) {
        return (
            <Box >
                <div
                    style={ant_col_windows}
                >
                    <img style={img}
                         src={bbb}
                    />
                </div>

                <div style={bt_windows}>
                    {/*<a href="#" >*/}
                    {/*</a>*/}

                    <div style={{ display:"table-cell"}}>
                        <Popconfirm
                            title={qinbei}
                            okText="确认"
                            cancelText="取消"
                            onConfirm={() => formProcess(idd)}
                        >
                            <Button style={a} > 立 即 购 买</Button>
                        </Popconfirm>
                    </div>

                    <div style={{ display:"table-cell",padding:"15px"}}>
                        <Popconfirm
                            title={qinbei}
                            okText="确认"
                            cancelText="取消"
                            onConfirm={() => formProcess(idd)}
                        >
                            <Button style={b} >赚琴贝</Button>
                        </Popconfirm>
                    </div>

                </div>
            </Box>
        )
    }else{

        //安卓,ios
        return (
            <Ios idd={idd} is_price={is_price} qinbei={qinbei} id={id} content={bbb}/>
        )
    }


};



const bt_android: React.CSSProperties = {
    width: "50px",
    position: "fixed",
    // bottom: "20px",
    right: "80%",
    fontSize: 0,
    lineHeight: 0,
    zIndex: 100,

    bottom: -10
}


const bt: React.CSSProperties = {
    // position: "sticky",
    // height: "40px",
    // width: "40px",
    // bottom: "90px",
    // right: "50px",
    // backgroundPosition: "0 -62px"


    width: "50px",
    position: "fixed",
    bottom: "20px",
    right: "70%",
    fontSize: 0,
    lineHeight: 0,
    zIndex: 100,

}

const bt_windows: React.CSSProperties = {
    width: "170px",
    position: "fixed",
    // bottom: "20px",
    right: "54.8%",
    fontSize: 0,
    lineHeight: 0,
    zIndex: 100,

    bottom: -5

}


const a: React.CSSProperties = {
    width: "350px",
    height: "50px",
    display: "inline-block",
    backgroundColor: "#cc0000",
    marginBottom: "2px",
    fontSize:18,
    color:"#fff",
    // backgroundPosition: "0 -266px"
}

const b: React.CSSProperties = {
    width: "160px",
    height: "50px",
    display: "inline-block",
    backgroundColor: "#F7F7F7",
    marginBottom: "2px",
    fontSize:18,
    color:"#202124",
}


const bt_ios: React.CSSProperties = {
    width: "50px",
    position: "fixed",
    // bottom: "20px",
    right: "82%",
    fontSize: 0,
    lineHeight: 0,
    zIndex: 100,

    bottom: -10
}

const a_ios: React.CSSProperties = {
    width: "195px",
    height: "45px",
    display: "inline-block",
    backgroundColor: "#cc0000",
    marginBottom: "2px",
    fontSize:18,
    color:"#fff",
    // backgroundPosition: "0 -266px"
}

const b_ios: React.CSSProperties = {
    width: "125px",
    height: "45px",
    display: "inline-block",
    backgroundColor: "#F7F7F7",
    marginBottom: "2px",
    fontSize:18,
    color:"#202124",
}



const a_android: React.CSSProperties = {
    width: "170px",
    height: "45px",
    display: "inline-block",
    backgroundColor: "#cc0000",
    marginBottom: "2px",
    fontSize:18,
    color:"#fff",
    // backgroundPosition: "0 -266px"
}

const b_android: React.CSSProperties = {
    width: "100px",
    height: "45px",
    display: "inline-block",
    backgroundColor: "#F7F7F7",
    marginBottom: "2px",
    fontSize:18,
    color:"#202124",
}


const img: React.CSSProperties = {
    // maxWidth: "128px",
    // maxHeight: "128px",
    // height:"auto",
    // zoom:"expression( function(e) {if(e.width>e.height) {if (e.width>128) { e.height = e.height*(128 /e.width); e.width=128; }}else {if (e.height>128) { e.width = e.width*(128 /e.height); e.height=128; }}e.style.zoom = '1';     }(this));",
    // overflow:"hidden"

    //自适应
    display: "inline-block",
    width: "auto",
    maxWidth: "100%",
    height: "auto"
}


const img1: React.CSSProperties = {
    // display: "inline-block",
    // width: "auto",
    position: "relative",
    maxWidth: "90%",
    // height: "90%"
}

const ant_col_windows: React.CSSProperties = {
    // paddingLeft: "12px",
    // paddingRight: "12px",
    //
    // boxSizing:"border-box",
    // display: "block",
    // width: "auto",
    //
    // float: "left",
    // position: "relative",
    // minHeight: ".0625rem"


    // position: "absolute",
    // left: 0,
    // top: 0,
    // width: "100%",
    // overflow: "hidden",
    // zIndex: -100

    position: "relative",
    margin: "0 auto",
    maxWidth: "540px"

}

export default  UserProduct;
