import AppTabs from '@/components/AppTabs';
import Duration from '@/components/Duration';
import Fallback from '@/components/Fallback';
import useAsync from '@/hooks/useAsync';
import { LeadboardAll, LeadboardClass, LeadboardClassWithin } from '@/services/user';
import {
  Avatar,
  Box,
  Divider,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  SelectProps,
  Tab,
  Typography,
} from '@material-ui/core';
import { Empty } from 'antd';
import React from 'react';

export interface LeaderboardProps {}

function RangeSelect(props: SelectProps) {
  return (
    <Select {...props} input={<InputBase />}>
      <MenuItem value="today">今日</MenuItem>
      <MenuItem value="yesterday">昨日</MenuItem>
      <MenuItem value="week">本周</MenuItem>
      <MenuItem value="lastWeek">上周</MenuItem>
      <MenuItem value="history">历史</MenuItem>
    </Select>
  );
}

const RankingListAll = () => {
  const [range, setRange] = React.useState('today');
  const state = useAsync(() => LeadboardAll({ range }), [range]);
  return (
    <>
      <Box padding={2} pt={0}>
        <List
          subheader={
            <ListSubheader style={{ backgroundColor: '#fff' }}>
              <RangeSelect
                value={range}
                onChange={(event) => setRange(event.target.value as string)}
              />
            </ListSubheader>
          }
        >
          <Fallback
            state={state}
            render={(data) => {
              if (data.length === 0) {
                return (
                  <Box
                    mt={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Empty description="暂无榜单数据" />
                  </Box>
                );
              }
              return (
                <>
                  {data.map((item, i) => {
                    return (
                      <React.Fragment key={i}>
                        <ListItem>
                          <Box
                            color="primary.main"
                            fontWeight="bold"
                            textAlign="center"
                            minWidth={24}
                            mr={2}
                            clone
                          >
                            <Typography variant="subtitle1">{i + 1}</Typography>
                          </Box>
                          <ListItemAvatar>
                            <Avatar src={item.avatar} />
                          </ListItemAvatar>
                          <ListItemText primary={item.name} />
                          <Box color="warning.dark" fontWeight="normal" clone>
                            <Typography variant="h6">
                              <Duration value={item.practiceDuration} text />
                            </Typography>
                          </Box>
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    );
                  })}
                </>
              );
            }}
          />
        </List>
      </Box>
    </>
  );
};

const RankingListClass = () => {
  const [range, setRange] = React.useState('today');
  const state = useAsync(() => LeadboardClass({ range }), [range]);
  return (
    <>
      <Box padding={2} pt={0}>
        <List
          subheader={
            <ListSubheader style={{ backgroundColor: '#fff' }}>
              <RangeSelect
                value={range}
                onChange={(event) => setRange(event.target.value as string)}
              />
            </ListSubheader>
          }
        >
          <Fallback
            state={state}
            render={(data) => {
              if (data.length === 0) {
                return (
                  <Box
                    mt={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Empty description="暂无榜单数据" />
                  </Box>
                );
              }
              return (
                <>
                  {data.map((item, i) => {
                    return (
                      <React.Fragment key={i}>
                        <ListItem>
                          <Box
                            color="primary.main"
                            fontWeight="bold"
                            textAlign="center"
                            minWidth={24}
                            mr={2}
                            clone
                          >
                            <Typography variant="subtitle1">{i + 1}</Typography>
                          </Box>
                          <ListItemAvatar>
                            <Avatar src={item.avatar} alt={item.name}>
                              {item.name.substr(0, 1)}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={item.name} />
                          <Box color="warning.dark" fontWeight="normal" clone>
                            <Typography variant="h6">
                              <Duration value={item.practiceDuration} text />
                            </Typography>
                          </Box>
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    );
                  })}
                </>
              );
            }}
          />
        </List>
      </Box>
    </>
  );
};

const RankingListClassWithin = () => {
  const [range, setRange] = React.useState('today');
  const state = useAsync(() => LeadboardClassWithin({ range }), [range]);
  return (
    <>
      <Box padding={2} pt={0}>
        <List
          subheader={
            <ListSubheader style={{ backgroundColor: '#fff' }}>
              <RangeSelect
                value={range}
                onChange={(event) => setRange(event.target.value as string)}
              />
            </ListSubheader>
          }
        >
          <Fallback
            state={state}
            render={(data) => {
              if (data.length === 0) {
                return (
                  <Box
                    mt={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Empty description="暂无榜单数据" />
                  </Box>
                );
              }
              return (
                <>
                  {data.map((item, i) => {
                    return (
                      <React.Fragment key={i}>
                        <ListItem>
                          <Box
                            color="primary.main"
                            fontWeight="bold"
                            textAlign="center"
                            minWidth={24}
                            mr={2}
                            clone
                          >
                            <Typography variant="subtitle1">{i + 1}</Typography>
                          </Box>
                          <ListItemAvatar>


                              <Avatar src={item.avatar} alt={item.name}/>

                          </ListItemAvatar>
                          <ListItemText primary={item.name} />
                          <Box color="warning.dark" fontWeight="normal" clone>
                            <Typography variant="h6">
                              <Duration value={item.practiceDuration} text />
                            </Typography>
                          </Box>
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    );
                  })}
                </>
              );
            }}
          />
        </List>
      </Box>
    </>
  );
};

const Leaderboard: React.FC<LeaderboardProps> = () => {
  return (
    <AppTabs
      tabs={[
        {

          path: 'all',
          tab: <Tab label="总榜" />,
          panel: (
            <div>
              <RankingListAll />
            </div>
          ),
        },
        {
          path: 'class',
          tab: <Tab label="班级" />,
          panel: (
            <div>
              <RankingListClass />
            </div>
          ),
        },
        {
          path: 'class-within',
          tab: <Tab label="班内" />,
          panel: (
            <div>
              <RankingListClassWithin />
            </div>
          ),
        }
      ]}
    />
  );
};

export default Leaderboard;
