import { PromiseFn, Result } from './useAsyncFn';
import { useRef, useCallback } from 'react';

function useAsyncTap<T extends PromiseFn<any>>(
  promiseCreator: T,
  onFulfilled?: (value: Result<T>) => void,
  onRejected?: (reason: any) => void
): T {
  const onFulfilledRef = useRef(onFulfilled);
  onFulfilledRef.current = onFulfilled;

  const onRejectedRef = useRef(onRejected);
  onRejectedRef.current = onRejected;

  return useCallback<any>(
    (...args: any[]) => {
      return promiseCreator(...args).then(
        (value) => {
          if (onFulfilledRef.current) {
            onFulfilledRef.current(value);
          }
        },
        (reason) => {
          if (onRejectedRef.current) {
            onRejectedRef.current(reason);
          }
          throw reason;
        }
      );
    },
    [promiseCreator]
  );
}

export default useAsyncTap;
