import Fallback from '@/components/Fallback';
import useAsync from '@/hooks/useAsync';
import { assistantClassResource } from '@/services/teacher';
import {Box, Button, InputBase, MenuItem, Select, Tab} from '@material-ui/core';
import {Badge, Col, Row, Table} from 'antd';
import { differenceInDays } from 'date-fns';
import React from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {comparator} from "ramda";
import logo from "@/assets/time.jpg";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import BaseBottomNavigation from "@material-ui/core/BottomNavigation";
import {AppTab} from "@/components/AppTabs";
import useAsyncFn from "@/hooks/useAsyncFn";
import {TotalLabel} from "@/components/ResourceManage";
import AddIcon from "@material-ui/icons/Add";



const renderState = (value: string) => {
  switch (value) {
    case 'yankai':
      return <Badge status="processing" text="延开" color="orange"/>;
    case 'zaixue':
      return <Badge status="success" text="学习" color="red"/>;
    case 'dongjie':
      return <Badge status="error" text="冻结" color="cyan"/>;

    case 'zanting':
      return <Badge status="default" text="停课" color="green"/>;
    case 'yanxue':
      return <Badge status="warning" text="待开-前课未完" color="blue"/>;
    case 'tiqian':
      return <Badge status="warning" text="到期-续课在学" color="pink"/>;
    case 'jinjie':
      return <Badge status="warning" text="已进阶" color="purple"/>;
    case 'yanqi':
      return <Badge status="warning" text="延期" color="gold"/>;
    default:
      return null;
  }
};


export default function AssistantClass() {
  const history = useHistory();
  const { assistantID, classID } = useParams<{
    assistantID: string;
    classID: string;
  }>();

  const resource = React.useMemo(
      () => assistantClassResource(parseInt(assistantID),parseInt(classID)),
      [assistantID,classID]
  );
  const state = useAsync(resource.query, [assistantID]);
  const selected = React.useMemo(
      () => (classID === undefined ? 0 : parseInt(classID)),
      [classID]
  );

  const setSelected = (classID?: number) => {
    if (classID) {
      history.push(`/teacher/assistants/${assistantID}/classes/${classID}`);
    } else {
      history.push(`/teacher/assistants/${assistantID}/classes`);
    }
  };

  return (
      <>
        <Fallback
            state={state}
            render={({ data }) => {
              return (
                  <>
                    {/*<Select*/}
                    {/*  input={<InputBase />}*/}
                    {/*  value={selected}*/}
                    {/*  onChange={(e) => {*/}
                    {/*    setSelected(e.target.value as number);*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  <MenuItem value={0}>全部</MenuItem>*/}
                    {/*  {data.map((item) => (*/}
                    {/*    <MenuItem value={item.id}>{item.name}</MenuItem>*/}
                    {/*  ))}*/}
                    {/*</Select>*/}



                    {/*{data.map((item,i)=>{*/}
                    {/*    if (i==0){*/}
                    {/*        return(*/}
                    {/*            <Row>*/}
                    {/*                <Col xs={8}></Col>*/}
                    {/*                <Col xs={8}>{item.assistantName}的班级</Col>*/}
                    {/*                <Col xs={8}></Col>*/}
                    {/*            </Row>*/}
                    {/*        )*/}
                    {/*    }*/}
                    {/*})}*/}



                    {data.map((item, index) => {
                      if (selected && selected !== item.id) {
                        return null;
                      }
                      return (
                          <Table
                              rowKey="id"
                              bordered
                              pagination={ false }
                              size="small"
                              style={{ marginTop: 8, minWidth: '100%' }}
                              title={() => `${item.name}`}
                              columns={[
                                {
                                  title: '姓名',
                                  dataIndex: 'realname',
                                  render: (value, record) => (
                                      record.state === "banned" ? (
                                          <Link
                                              // to={`/teacher/students/${record.id}/practices/${item.courseID}`}
                                              to={`/assistant/students/${record.id}/practices/${item.courseID}`}
                                          >
                                            {value}<span><span  style={{ color: `#FF0000`}} > (禁用)</span></span>
                                          </Link>
                                      ) : (

                                          <Link
                                              // to={`/teacher/students/${record.id}/practices/${item.courseID}`}
                                              to={`/assistant/students/${record.id}/practices/${item.courseID}`}
                                          >
                                            {value}
                                          </Link>
                                      )

                                  ),
                                },
                                // {
                                //   title: '进度',
                                //   render: (_, record) => (
                                //     <Link to={`/teacher/students/${record.id}/courses`} >
                                //       {record.lessonCompleted}/{record.lessonTotal}
                                //     </Link>
                                //   ),
                                // },


                                {
                                  dataIndex: 'courseState',
                                  title: '状态',
                                  sorter: comparator((a, b) => a.courseState < b.courseState),
                                  render: (_, record) => (
                                      <>
                                        <br />
                                        {renderState(record.courseState)}
                                      </>
                                  ),
                                  // render: (category) => category.name,
                                },
                                // {
                                //   title: '进度',
                                //   sorter: comparator((a, b) => a.lessonCompleted < b.lessonCompleted),
                                //   render: (_, record) =>
                                //       record.currentCourseID ? (
                                //           <Link
                                //               // to={`/teacher/students/${record.id}/courses`}
                                //               to={`/teacher/students/${record.id}/courses/${record.currentCourseID}`}
                                //           >
                                //             {record.lessonCompleted}/{record.lessonTotal}
                                //           </Link>
                                //       ) : (
                                //
                                //           <Link
                                //               // to={`/teacher/students/${record.id}/courses`}
                                //               to={`/teacher/students/${record.id}/courses/${record.currentCourseID}`}
                                //           >
                                //             完成
                                //           </Link>
                                //       ),
                                // },
                                {
                                  title: '进度',
                                  sorter: comparator((a, b) => a.lessonCompleted < b.lessonCompleted),
                                  render: (_, record) =>
                                      record.currentCourseID_ == 999 ? (
                                          <Link
                                              // to={`/teacher/students/${record.id}/courses`}
                                              to={`/teacher/students/${record.id}/courses/${record.currentCourseID}`}
                                          >
                                            完成
                                          </Link>
                                      ) : (

                                          record.currentCourseID_ == 666 ? (

                                              <Link
                                                  // to={`/teacher/students/${record.id}/courses`}
                                                  to={`/teacher/students/${record.id}/courses/${record.currentCourseID}`}
                                              >
                                                待完成
                                              </Link>

                                          ) : ( <Link
                                              // to={`/teacher/students/${record.id}/courses`}
                                              to={`/teacher/students/${record.id}/courses/${record.currentCourseID}`}
                                          >
                                            {record.lessonCompleted}/{record.lessonTotal}
                                          </Link>)





                                      ),
                                },

                                {
                                  title: '已学',
                                  dataIndex: 'startWeek',
                                  // render: (value) => `${value}`,
                                  render: (value, record) =>
                                      <Link
                                          to={`/teacher/students/history/${record.id}`}
                                      >
                                        {value}
                                      </Link>
                                },
                                {
                                  title: '剩余日均',
                                  dataIndex: 'durationRemain',
                                  sorter: comparator((a, b) => a.durationRemain < b.durationRemain),
                                  render: (value) => `${value}分钟`,
                                },
                                {
                                  title: '结课',
                                  dataIndex: 'courseEndTime',
                                  render: (value) =>
                                      value
                                          ? `${differenceInDays(
                                          new Date(value),
                                          new Date()
                                          )}天`
                                          : '',
                                  //N/A
                                },
                                // {
                                //     title: '观看',
                                //     dataIndex: 'practiceViews',
                                //     render: (value) => `${value}次`,
                                // },
                                {
                                  title: '未练习',
                                  dataIndex: 'lastPracticeAt',
                                  render: (value) =>
                                      value
                                          ? `${differenceInDays(
                                          new Date(),
                                          new Date(value)
                                          )}天`
                                          : 'N/A',
                                },
                              ]}
                              dataSource={item.students}
                          />
                      );
                    })}
                    {/*<img*/}
                    {/*    style={{ display: 'block',  margin:'0 auto' ,maxWidth:350 }}*/}
                    {/*    src={logo}*/}
                    {/*    alt="Logo"*/}
                    {/*/>*/}
                  </>

              );

            }}

        />


      </>
  );

}







