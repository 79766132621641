import React from 'react';
import { Table as BaseTable } from 'antd';
import { TableProps } from 'antd/lib/table';

export default function Table<T extends {}>(props: TableProps<T>) {
  return (
    <BaseTable
      bordered
      size="small"
      style={{ minWidth: '100%' }}
      rowKey="id"
      {...props}
    />
  );
}
