import { AppTab } from '@/components/AppTabs';
import Fallback from '@/components/Fallback';
import {
    createSubmitFab,
    createSubmitModal,
    createSubmitTrigger,
    TotalLabel,
} from '@/components/ResourceManage';
import UploadInput from '@/components/UploadInput';
import useAsyncFn, { AsyncState } from '@/hooks/useAsyncFn';
import { LessonResource, KindResource } from '@/services/admin';
import { Lesson } from '@/services/entity';
import {Avatar, makeStyles, Tab} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CardMedia from '@material-ui/core/CardMedia';
import {
    Button,
    Card, Col,
    Divider,
    Form,
    Input,
    InputNumber, message,
    Popconfirm, Row,
    Space,
    Table, Upload,
} from 'antd';



import { FormProps } from 'antd/lib/form';
import { InputNumberProps } from 'antd/lib/input-number';
import { TableProps } from 'antd/lib/table';
import format from 'date-fns/format';
import React from 'react';
import EntityList from './EntityList';
import {UploadOutlined} from "@ant-design/icons";
import Editor from "@/components/Editor/Editor";
import {objOf} from "ramda";
import CardActionArea from "@material-ui/core/CardActionArea";

const resource = KindResource;
type Entity = Lesson;

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));


//---------------------
// const props = {
//
//     beforeUpload: (file:any) => {
//         if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg') {
//             return  true
//         }else {
//             message.error(`${file.name} is not a png file,or jpg,or jpeg`);
//         }
//         return false
//     },
//
//
//     name: 'file',
//     action: '/api/profile/mallphone1',
//     headers: {
//         authorization: 'authorization-text',
//     },
//
//
//     onChange(info:any) {
//         if (info.file.status !== 'uploading') {
//             console.log(info.file, info.fileList);
//         }
//         if (info.file.status === 'done') {
//             message.success(`${info.file.name},文件上传成功1`);
//
//             let fileList = [...info.fileList];
//             fileList = fileList.slice(-1);
//
//             // 2. Read from response and show file link
//             fileList = fileList.map(file => {
//                 if (file.response) {
//
//                 }
//                 return file;
//             });
//
//         } else if (info.file.status === 'error') {
//             message.error(`${info.file.name},文件上传失败`);
//         }
//     },
// };

const onChange = ( info:any ) => {
    if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
        message.success(`${info.file.name},图片上传成功`);

        // let fileList = [...info.fileList];
        // fileList = fileList.slice(-1);
        //
        // // 2. Read from response and show file link
        // fileList = fileList.map(file => {
        //     if (file.response) {
        //
        //     }
        //     return file;
        // });
        setTimeout(function () {
            //内容1秒后,刷新
            window.location.reload();
        }, 1000);

    } else if (info.file.status === 'error') {
        message.error(`${info.file.name},图片上传失败`);
    }
};


const beforeUpload = ( file:any ) => {
    if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg') {
        return  true
    }else {
        message.error(`${file.name} is not a png file,or jpg,or jpeg`);
    }
    return false
};
//---------------------



const DurationInput: React.FC<InputNumberProps> = (props) => {
    return (
        <>
            <InputNumber {...props} />
            <span style={{ marginLeft: 8 }}>分钟</span>
        </>
    );
};

const RecordForm: React.FC<FormProps> = (props) => {
    return (
        <Form labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }} {...props}>
            <Form.Item label="类别名称" name="name" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
        </Form>
    );
};

const SubmitModal = createSubmitModal<Entity>(RecordForm);
const SubmitTrigger = createSubmitTrigger(SubmitModal);
const SubmitFab = createSubmitFab(SubmitTrigger);

interface DataTableProps extends TableProps<Entity> {
    onCreate?: () => void;
    onDelete?: () => void;
}
const DataTable: React.FC<DataTableProps> = ({
                                                 onCreate,
                                                 onDelete,
                                                 ...rest
                                             }) => {
    return (
        <Table
            {...rest}
            rowKey="id"
            bordered
            size="small"
            columns={[
                {
                    dataIndex: 'id',
                    title: '#',
                    align: 'center',
                },
                {
                    dataIndex: 'name',
                    title: '名称',
                    align: 'center',
                },
                {
                    dataIndex: 'createdAt',
                    title: '时间',
                    align: 'center',
                    render: (createdAt) =>
                        format(new Date(createdAt), 'yyyy-MM-dd HH:mm:ss'),
                },
                {
                    dataIndex: 'picurl',
                    title: '背景图片',
                    ellipsis: true,
                    align: 'center',

                    render: (record) =>
                        <img style={{
                            height: 60,
                            width: 250,
                        }}
                         src={record} />
                },
                {
                    title: '添加图片',
                    align: 'center',
                    render: (_, record) => {
                        return (
                            <>
                                <Form
                                    layout="vertical"
                                    // onFinish={(values) => fetch({ filter: values })}
                                >

                                    <Space >
                                        <Row>
                                            <Col xs={8} >
                                                <Upload name={'file'}
                                                        action={'/api/profile/mallphone1/'+record.id}
                                                        onChange={onChange}
                                                        beforeUpload={beforeUpload}>
                                                    <Button  icon={<UploadOutlined />} >选择图片</Button>
                                                </Upload>
                                            </Col>
                                        </Row>
                                    </Space>
                                </Form>
                            </>
                        );
                    },
                },

                {
                    title: '操作',
                    align: 'center',
                    render: (_, record) => {
                        return (
                            <>
                                <Popconfirm
                                    title="确认删除?"
                                    onConfirm={async () => {
                                        await resource.delete(record.id);
                                        onDelete && onDelete();
                                    }}
                                >
                                    <Button type="link">删除</Button>
                                </Popconfirm>
                                <Divider type="vertical" />
                                <SubmitTrigger
                                    ModalProps={{
                                        title: '编辑类别',
                                        centered: true,
                                        submit: resource.update(record.id),
                                        // 提交时回调
                                        onSubmitted: onCreate,
                                        FormProps: { initialValues: record },
                                    }}
                                    render={({ open }) => (
                                        <Button type="link" onClick={open}>
                                            编辑
                                        </Button>
                                    )}
                                />
                            </>
                        );
                    },
                },



            ]}
        />
    );
};

const TabPanel: React.FC<{
    state: AsyncState<{ total: number; data: Entity[] }>;
    fetch: typeof resource.query;
}> = ({ state, fetch }) => {
    const [form] = Form.useForm();
    return (
        <div>
            <Card style={{ marginBottom: 8 }}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={(values) => fetch({ filter: values })}
                >
                    <Form.Item label="类别名称" name="$search">
                        <Input />
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'right' }}>
                        <Space>
                            <Button htmlType="reset" onClick={() => form.resetFields()}>
                                重置
                            </Button>
                            <Button type="primary" htmlType="submit" disabled={state.loading}>
                                查询
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Card>
            <Fallback
                state={state}
                initial={<DataTable loading />}
                render={({ data }) => {
                    return (
                        <DataTable
                            dataSource={data}
                            loading={state.loading}
                            onCreate={state.retry}
                            onDelete={state.retry}
                        />
                    );
                }}
            />
        </div>
    );
};

function useKindTab(): AppTab {
    const classes = useStyles();
    const [state, fetch] = useAsyncFn(resource.query, []);
    React.useEffect(() => {
        fetch({});
    }, [fetch]);

    return {
        path: `kinds`,
        tab: <Tab label={<TotalLabel state={state}>类别管理</TotalLabel>} />,
        fab: (
            <SubmitFab
                ModalProps={{
                    title: '创建类别',
                    centered: true,
                    submit: resource.create,
                    onSubmitted: state.retry,
                }}
                className={classes.fab}
            >
                <AddIcon />
            </SubmitFab>
        ),
        panel: <TabPanel state={state} fetch={fetch} />,
    };
}

export default useKindTab;
