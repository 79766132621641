import DateTime from '@/components/DateTime';
import Fallback from '@/components/Fallback';
import {
    createSubmitFab,
    createSubmitModal,
    createSubmitTrigger,
} from '@/components/ResourceManage';
import SearchSelect from '@/components/SearchSelect';
import useAsyncFn from '@/hooks/useAsyncFn';
import { UserLeave} from '@/services/entity';
import {
    LeaveCourseResource,
    studentLeave,
    QueryLeaveRecord,
} from '@/services/admin';
import AddIcon from '@material-ui/icons/Add';
import {Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm,  Table} from 'antd';
import { FormProps } from 'antd/lib/form';
import React from 'react';
import { useParams } from 'react-router-dom';
import {InputNumberProps} from "antd/lib/input-number";
import useAsync from "@/hooks/useAsync";
import {Box} from "@material-ui/core";
import {applyFilter} from "@/services/api";
import FormatedDatePicker from "@/components/FormatedDatePicker";


const DurationInput1: React.FC<InputNumberProps> = (props) => {
    return (
        <>
            <InputNumber {...props} />
            <span style={{ marginLeft: 8 }}>天</span>
        </>
    );
};
//
type Entity = UserLeave;




export default function StudentLeavePage() {


    const { id } = useParams<{ id: string }>();
    const userID = parseInt(id);
    const resource = React.useMemo(() => studentLeave(userID), [userID]);
    const [state, fetch] = useAsyncFn(resource.query, [id]);


    const state1 = useAsync(() => QueryLeaveRecord(userID), [userID]);



    React.useEffect(() => {
        fetch({});
    }, [fetch]);




    return (
        <>
            <Fallback
                state={state1}
                // render={({ data }) => (
                render={(data) => (


                    <Box flex={1}>

                        <Col >{data.length > 0?(<Col>{data[0].UserCourse.username}的请假记录</Col>):(``)}</Col>
                    {data.map((item, index) => {
                        return (


                            item.leaves.length > 0?(
                                <Card title={item.UserCourse.course.name}  extra={<span  style={{ color: `#ff0000`}} >剩余{item.UserCourse.course.leaveDuration-item.UserCourse.resultDay}天</span>}>
                                    <Table
                                        rowKey="id"
                                        pagination={false}
                                        // title={() => (
                                        //     <Box display="flex" justifyContent="space-between">
                                        //         <div>共计 {total} 条</div>
                                        //     </Box>
                                        // )}
                                        // bordered
                                        size="small"
                                        // style={{ minWidth: '500%' }}
                                        dataSource={item.leaves}
                                        columns={[
                                            {
                                                dataIndex: 'days',
                                                title: ' 天数',
                                            },
                                            {
                                                dataIndex: 'content',
                                                title: '事由',
                                            },
                                            {
                                                dataIndex: 'starttime',
                                                title: '请假时间',
                                            },

                                            {
                                                title: '操作',
                                                render: (_, record) => {
                                                    return (
                                                        <>
                                                            <SubmitTrigger
                                                                ModalProps={{
                                                                    title: '编辑用户',
                                                                    centered: true,
                                                                    submit: resource.update(record.id),
                                                                    onSubmitted: state.retry,
                                                                    FormProps: { initialValues: record },
                                                                }}
                                                                render={({ open }) => (
                                                                    <Button style={{ padding: 0 }} type="link" onClick={open}>
                                                                        编辑
                                                                    </Button>
                                                                )}
                                                            />

                                                            <Divider type="vertical" />

                                                            <Popconfirm
                                                                title="确认删除?"
                                                                onConfirm={async () => {
                                                                    await resource.delete(record.id);
                                                                    window.location.reload();
                                                                }}
                                                            >
                                                                <Button type="link">删除</Button>
                                                            </Popconfirm>
                                                        </>
                                                    );
                                                },
                                            },



                                        ]}
                                    />

                                </Card>

                            ):(``)

                        );
                        })}

                        <SubmitFab
                            ModalProps={{
                                title: '创建事件',
                                centered: true,
                                submit: resource.create,
                                onSubmitted: state.retry,
                            }}
                        >
                            <AddIcon />
                        </SubmitFab>

                    </Box>

                )}
            />
        </>
    );
}






const RecordForm: React.FC<FormProps> = (props) => {
    const { id } = useParams<{ id: string }>();
    const userID = parseInt(id);

    return (
        <Form labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }} {...props}>
            <Form.Item label="课程选择" name="courseID" rules={[{ required: true }]}>
                <SearchSelect

                    query={applyFilter({ userID }, LeaveCourseResource.query)}
                    map={({ id, name }) => ({ value: id, label: name })}
                />
            </Form.Item>

            <Form.Item
                label="请假天数"
                name="days"
            >
                <DurationInput1 />
            </Form.Item>

            <Form.Item label="事由记录" name="content">
                <Input.TextArea />
            </Form.Item>
            <Form.Item label="请假时间" name="starttime">
                <Input.TextArea />
            </Form.Item>

            <Form.Item label="请假开始" name="start">
                <FormatedDatePicker />
            </Form.Item>

            <Form.Item label="请假结束" name="end">
                <FormatedDatePicker />
            </Form.Item>


        </Form>
    );
};

const SubmitModal = createSubmitModal<Entity>(RecordForm);
const SubmitTrigger = createSubmitTrigger(SubmitModal);
const SubmitFab = createSubmitFab(SubmitTrigger);
