import Fallback from '@/components/Fallback';
import LearnStateChip from '@/components/LearnStateChip';
import LinearProgressWithLabel from '@/components/LinearProgressWithLabel';
import useAsync from '@/hooks/useAsync';
import useAsyncFn from '@/hooks/useAsyncFn';
import useEventCallback from '@/hooks/useEventCallback';
import { completeUserLesson, UserCourseResource } from '@/services/assistant';
import { UserLesson } from '@/services/entity';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  InputBase,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {Col, Row} from "antd";
import format from "date-fns/format";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
}));

function List() {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<{ id: string; courseID: string }>();

  const userID = parseInt(params.id);

  const selectedID = React.useMemo(() => parseInt(params.courseID), [
    params.courseID,
  ]);

  const state = useAsync(() => UserCourseResource.find(selectedID), [
    selectedID,
  ]);

  const listState = useAsync(
    () => UserCourseResource.query({ filter: { userID } }),
    [userID]
  );

  const refresh = useEventCallback(() => {
    state.reset();
    state.retry();
  }, []);

  const [, complete] = useAsyncFn(async (lesson: UserLesson) => {
    await completeUserLesson(lesson.id);
    refresh();
  });

  const setSelectedID = React.useCallback(
    (courseID: number) =>
      history.push(`/assistant/students/${params.id}/courses/${courseID}`),
    [history, params.id]
  );

  return (
    <Fallback
      state={state}
      render={(selected) => (
        <Box flex={1}>
          <Box
            mb={2}
            bgcolor="white"
            display="flex"
            justifyContent="space-between"
          >
            {/*<Fallback*/}
            {/*  state={listState}*/}
            {/*  render={({ data: items }) => (*/}
            {/*    <Select*/}
            {/*      input={<InputBase />}*/}
            {/*      value={selectedID}*/}
            {/*      onChange={(e) => {*/}
            {/*        setSelectedID(e.target.value as number);*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      {items.map((item) => (*/}
            {/*        <MenuItem key={item.id} value={item.id}>*/}
            {/*          {item.course.name}*/}
            {/*        </MenuItem>*/}
            {/*      ))}*/}
            {/*    </Select>*/}
            {/*  )}*/}
            {/*/>*/}
          </Box>
          <p ></p>
          <p  style={{background:`#FFFFE0`,fontFamily:'Microsoft JhengHei',marginTop:'-15px',marginBottom:'10px'}}  dangerouslySetInnerHTML={{ __html: selected.content}}/>
          <Row>
            <Col style={{marginTop:'0px',marginBottom:'10px'}}>{selected.username}的课程7</Col>
          </Row>
          {selected.userLessons.map((item, index) => {
            const available =
              item.state === 'learning' &&
              item.practiceDuration >= item.lesson.practiceDuration;
            return (
              <Card
                key={item.id}
                className={classes.card}
                variant="outlined"
                onClick={() => {}}
              >
                <CardHeader
                  avatar={<LearnStateChip type={item.state} />}
                  title={`${index + 1}. ${item.lesson.name}`}
                />
                <Box ml={2} mr={2}>
                  <LinearProgressWithLabel
                    current={item.practiceDuration}
                    total={item.lesson.practiceDuration}
                  />
                </Box>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    disabled={item.state === 'locked'}
                    onClick={() => history.push(`/course/learn/${item.id}`)}
                  >
                    查看
                  </Button>
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => complete(item)}
                    disabled={!available}
                  >
                    通过
                  </Button>
                  {item.state =='completed'  ? format(new Date(item.updatedAt), 'yyyy-MM-dd HH:mm:ss'):''}
                </CardActions>
              </Card>
            );
          })}
        </Box>
      )}
    />
  );
}

export interface LessonProps {}

const Lesson: React.FC<LessonProps> = () => {
  return <List />;
};

export default Lesson;
