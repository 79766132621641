import React from 'react';
import { Box, BoxProps } from '@material-ui/core';

interface MultilineText extends BoxProps {
  value?: string;
}

function MultilineText({ value, ...rest }: MultilineText) {
  return (
    <Box {...rest}>
      {(value || '').split('\n').map((line, index) => (
        <div key={index}>{line}</div>
      ))}
    </Box>
  );
}

export default MultilineText;