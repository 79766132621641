import {Button, Card, Col, Form, Row, Space,message} from 'antd';
import React from 'react';
import { queryBuildCode } from '@/services/user';
import Fallback from '@/components/Fallback';
import {Link} from 'react-router-dom';
import useAsyncFn from "@/hooks/useAsyncFn";
import {
    Avatar,
    Box,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper
} from "@material-ui/core";
import NavigateNext from "@material-ui/icons/NavigateNext";

const AdminInvitationCode: React.FC = () => {
    const [form] = Form.useForm();
    const [state, fetch] = useAsyncFn(queryBuildCode);


    //处理html渲染
    function createMarkup(str:any) {
        return {__html: str};
    };


    function formProcess(str:any) {
        if (str == -1){
            message.warning('剩余次数已用完',1)
        }else{
            form.submit()
            if (str == 0){
                message.warning('剩余次数已用完',1)
            }else{
                message.warning('剩余'+str+'次',1)
            }
        }
    };


    // <p><span style="font-size: 12px;">使用方法</span></p>
    // <ol style="list-style-type: decimal;" className=" list-paddingleft-2">
    //     <li><p><span style="font-size: 12px;">邀请码仅在特殊的日子里<br/></span></p></li>
    //     <li><p><span style="font-size: 12px;">点击生成邀请码后</span></p></li>
    //     <li><p><span style="font-size: 12px;">亲友扫码添加客服微信</span></p></li>
    //     <li><p><span style="font-size: 12px;">生成及使用有效期:2021年2月1日~2月26日</span></p></li>
    // </ol>
    // <p style="text-align: center"><img src="https://teach-vod.oss-cn-hangzhou.aliyuncs.com/637290297.jpg" width="100"
    //                                    height="140"/></p>
    // <p><br/></p>

    React.useEffect(() => {
        fetch({});
    }, [fetch]);
    return (
        <Fallback
            state={state}
            render={(data) => (

                <div>


                        <Card  style={{'fontSize': '16px','fontWeight':'bold'}} >
                            <Row>
                                <Col xs={8}>
                                    {data.name}
                                </Col>

                                <Col xs={8}>
                                </Col>



                                <Box display="flex" justifyContent="space-between">
                                    <div>&#8194;&#8194;&#8194;</div>
                                </Box>
                                <Col >
                                    <Link to={`/invitationCodeList`}>
                                        我的邀请
                                    </Link>
                                </Col>
                            </Row>


                        </Card>

                        {/*<Card  style={{'fontSize': '14px'}}>*/}

                            {/*<Row>*/}
                            {/*    <Col>*/}
                            {/*        邀 请 码: {data.code}*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}

                            {/*<Row>*/}
                            {/*    <Col>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}


                            {/*<Row>*/}
                            {/*    <Col xs={8}>*/}
                            {/*        邀 请 人: {data.username}*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}



                            <List disablePadding>
                                <ListItem>
                                    <ListItemText primary="邀 请 码:" />
                                    <div>{data.code}</div>
                                    <ListItemSecondaryAction />
                                </ListItem>
                                <Divider />
                            </List>

                            <List disablePadding>
                                <ListItem>
                                    <ListItemText primary="邀 请 人:" />
                                    <div>{data.username}</div>
                                    <ListItemSecondaryAction />
                                </ListItem>
                                <Divider />
                            </List>


                        {/*</Card>*/}




                    <Card style={{ whiteSpace: 'pre-wrap','fontSize': '14px'}} >
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={(values) => fetch({ filter: {"cid":data.id,"times":data.times,"code":data.code} })}
                        >

                            <Form.Item style={{ textAlign: 'center' }}>
                                <Space>
                                    <Button
                                        type="primary"
                                        // htmlType="submit"
                                        style={{'backgroundColor': '#105952'}}
                                        // disabled={state.loading}


                                        onClick={() => formProcess(data.leftTimes)}
                                    >
                                        点击生成
                                    </Button>

                                </Space>
                            </Form.Item>
                        </Form>

                        <div dangerouslySetInnerHTML={createMarkup(data.explain)} />
                    </Card>

                </div>
            )}
        />
    );
};

export interface DashboardProps1 {}

const BuildInvitationCode: React.FC<DashboardProps1> = () => {
    return <AdminInvitationCode />;
};

export default BuildInvitationCode;






