import Fallback from '@/components/Fallback';
import { queryAssistantStudentsPwd } from '@/services/assistant';
import { Select, InputBase, MenuItem, Box } from '@material-ui/core';
import { Table, Divider, Card, Form, Input, Space, Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import useAsyncFn from '@/hooks/useAsyncFn';
import qs from 'qs';

const StudentChangePwd: React.FC = () => {
    const [form] = Form.useForm();
    const [state, fetch] = useAsyncFn(queryAssistantStudentsPwd);
    const [selected, setSelected] = React.useState<number>(0);
    React.useEffect(() => {
        fetch({});
    }, [fetch]);
    return (
        <>
            <Fallback
                state={state}
                render={({ data }) => {
                    return (
                        <>


                            <Card style={{ marginBottom: 8 }}>
                                <Form
                                    form={form}
                                    layout="vertical"
                                    onFinish={(values) => fetch({ filter: values })}
                                >
                                    <Form.Item label="修改密码" name="password">
                                        <Input placeholder="请输入新密码" />
                                    </Form.Item>
                                    <Form.Item  name="passwordagain">
                                        <Input placeholder="再次输入新密码" />
                                    </Form.Item>
                                    <Form.Item style={{ textAlign: 'right' }}>
                                        <Space>
                                            <Button
                                                htmlType="reset"
                                                onClick={() => form.resetFields()}
                                            >
                                                重置
                                            </Button>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                disabled={state.loading}
                                            >
                                                修改
                                            </Button>
                                        </Space>
                                    </Form.Item>
                                </Form>
                            </Card>
                            {data.map((item, index) => {
                                return (
                                    <Table
                                        rowKey="id"
                                        bordered
                                        size="small"
                                        style={{ minWidth: '100%' }}
                                        columns={[
                                            {
                                                title: '结果:',
                                                dataIndex: 'result',
                                            },

                                        ]}
                                        dataSource={item.students}
                                    />
                                );
                            })}
                        </>
                    );
                }}
            />
        </>
    );
};

export default StudentChangePwd;
