import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import image from '@/assets/exercise.jpg';
import * as course from '@/services/user';
import useAsyncFn from '@/hooks/useAsyncFn';
import {useHistory} from "react-router-dom";
import {useProfile} from "@/pages/Profile/context";
import {message} from "antd";
import {getZhuangTai} from "@/services/entity";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  media: {
    height: 140,
  },
}));


interface SysProps{
  uid: number;
}


const ActionCard:React.FC<SysProps> = (props)=>{

  const {uid} = props
  console.log(props)
// export default function ActionCard() {
  const classes = useStyles();

  // const history = useHistory();
  const  {profile}  = useProfile();

  // function formProcess() {
  //   profile.state == 'suspended' ? message.warning('您的课程已到期,请联系客服',1) : {startPractice})
  // };



  const [, startPractice] = useAsyncFn(async () => {
    const { url } = await course.startPractice(uid)









    // profile.state == 'suspended' ? message.warning('您的课程已到期,请联系客服',1) : window.location.href = url;

    //
    // profile.courses? (profile.courses.map((r, i) => (
    //     // alert(r.courseState)
    //     // r.course.id === uid ? (r.courseState  == ZhuangTai.Zanting() ? message.warning('您的课程已到期,请联系客服',1) : window.location.href = url):(``)
    // ))):(``)

    profile.courses.map((r, i) => {
      if (r.course.id == uid ){





        if (profile.state  == 'suspended'){
          message.warning('您的账户已暂停,请联系教务老师',1)
        }else if (r.courseState  == 'zanting'){
        message.warning('您的课程已停课,请联系客服',1)
      }


        else if (r.courseState  == 'yankai'){
          message.warning('您的课程已延开,请联系客服',1)
        }else if (r.courseState  == 'dongjie'){
          message.warning('您的课程已冻结,请联系客服',1)
        }else if (r.courseState  == 'jinjie'){
          message.warning('您的课程已进阶,请联系客服',1)
        }else{
          window.location.href = url
        }
      }
    })


  });
  return (
    <Card
      className={classes.root}
      variant="outlined"
      onClick={startPractice}
    >
      <CardActionArea>
        <CardMedia className={classes.media} image={image} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            开始练习
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}


export default ActionCard