import Fallback from '@/components/Fallback';
import PracticeItem from '@/components/PracticeItem';
import useAsync from '@/hooks/useAsync';
import {CommPracticeTips, PracticeResource} from '@/services/user';

import {
  Box,
  Container,
  Divider,
  List,
  ListSubheader,
} from '@material-ui/core';
import React from 'react';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import Card from './Card';





const PracticeList: React.FC = () => {
    const { id } = useParams<{ id: string }>();

  const match = useRouteMatch();
  const state = useAsync(() => PracticeResource.query({ limit: 5 ,id:parseInt(id)}));
  return (
    <Fallback
      state={state}
      render={(items) => (
        <List
          subheader={
            <ListSubheader style={{ backgroundColor: '#fff' }}>
              <Box display="flex" justifyContent="space-between">
                <Box>练习视频</Box>
                <Box>
                  <Link to={`/course/practice/records1/${id}`}>更多</Link>
                </Box>
              </Box>
            </ListSubheader>
          }
        >
          {items.data.map((item, index) => (
            <React.Fragment key={item.id}>
              {index > 0 && <Divider component="li" />}
              <PracticeItem
                value={item}
                onRecommend={async () => {
                  await PracticeResource.update(item.id)({
                    isRecommend: !item.isRecommend,
                  });
                  state.retry();
                }}
              />
            </React.Fragment>
          ))}
        </List>
      )}
    />
  );
};


const Practice: React.FC = (props) => {
    const { id } = useParams<{ id: string }>();



    const practicetips = useAsync(() => CommPracticeTips(parseInt(id)), [id]).value;


    const content1 = practicetips?(practicetips.toString()):(``)

  return (
    <Box flex={1} pt={2}>
      <Container maxWidth="sm">
          <p  style={{background:`#FFFFE0`,fontFamily:'Microsoft JhengHei',marginTop:'-0px',marginBottom:'10px'}}  dangerouslySetInnerHTML={{ __html: content1}}/>
        <Card uid={parseInt(id)}/>
        <PracticeList />
      </Container>
    </Box>
  );
};

export default Practice;
