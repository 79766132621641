import Axios from 'axios';
import { api, restful } from './api';
import {
  Category,
  Class ,
  ClassRecord,
  Ticket,
  TiXin,
  User,
  UserEvent,
  Course,
  Point,
  Practice,
  UserCourse, Entity, Order,
} from './entity';

const axios = Axios.create({ baseURL: '/api/teacher' });

interface StaticsData {
  allStudentCount: number;
  normalStudentCount: number;
  suspendedStudentCount: number;
  allClassCount: number;
  normalClassCount: number;
  closedClassCount: number;
  allAssistantCount: number;
  pendingTicketCount: number;
}

export const queryStatics = api<void, StaticsData>(() => axios.get('/statics'));

export const TicketResource = restful<
    TiXin,
  { pending: number; completed: number }
>(axios, '/tickets');

// export const ClassResource = restful<Class, { students: number }>(
//   axios,
//   '/classes'
// );


export const ClassResource = restful<Class>(axios, '/user/class2');

export const ClassResource1 = restful<Class, { students: number }>(
    axios,
    '/classes1'
);



export const AssistantResource = restful<User>(axios, '/assistants');

export const CategoryResource = restful<Category>(axios, '/categories');


export const ProductOrderResource = restful<Order>(axios, '/orders');

export const ClassRecordResource = restful<ClassRecord>(
  axios,
  '/class/records'
);

export const UserResource = restful<User>(axios, '/users');

export const CourseResource = restful<Course>(axios, '/courses');

interface Student extends User {
  readonly lessonCompleted: number;
  readonly lessonTotal    : number;
  readonly durationRemain : number;
  readonly currentCourseID : number;
  readonly lastPracticeAt : string;
  readonly currentCourseID_: number;
}

interface PracticeData extends Entity{
  startTime: string;
  weekName: string;
  userName: string;
  weekTime_: number;
  weekLimit?: string;
  monthTime_?: number;
}

export const QueryStHistoryRecord = api<number, PracticeData[]>((id) =>
    axios.get(`/historyRecord/${id}`)
);



interface ClassWithProgress extends Class {
  readonly students: Student[]
}
export const assistantClassResource = (assistantID: number,classID: number) =>
  restful<ClassWithProgress>(axios, `/assistants/${assistantID}/classes/${classID}`);

export const studentEvent = (studentID: number) =>
  restful<UserEvent>(axios, `/students/${studentID}/events`);

export const studentPoint = (studentID: number) =>
  restful<Point, { points: number; realname: string }>(
    axios,
    `/students/${studentID}/points`
  );

export const studentPractice = (studentID: number) =>
  restful<Practice>(axios, `/students/${studentID}/practices`);

export const updateUserAvatar = api<{ userID: number; avatar: File }, void>(
  ({ userID, avatar }, config) => {
    const data = new FormData();
    data.set('avatar', avatar);
    return axios.post(`/users/${userID}/avatar`, data, config);
  }
);
export const studentProfile = (studentID: number) => ({
  fetch: api<void, User>(() => axios.get(`/students/${studentID}/profile`)),
  update: {
    avatar: api<File, void>((avatar) => {
      const data = new FormData();
      data.set('avatar', avatar);
      return axios.put(`/students/${studentID}/profile/avatar`, data);
    }),
  },
});


export const UserCourseResource = restful<UserCourse>(axios, '/user/courses');

export const startUserCourse = api<number, void>((id) =>
  axios.put(`/user/courses/${id}/start`)
);

export const completeUserCourse = api<number, void>((id) =>
  axios.put(`/user/courses/${id}/complete`)
);

export const completeUserLesson = api<number, void>((id) =>
  axios.put(`/user/lessons/${id}/complete`)
);
