import { Card, Col, Row, Space } from 'antd';
import React from 'react';
import useAsync from '@/hooks/useAsync';
import { queryStatics } from '@/services/admin';
import Fallback from '@/components/Fallback';
import { Link } from 'react-router-dom';
import qs from 'qs';

const AdminDashboard: React.FC = () => {
  const state = useAsync(queryStatics);
  return (
    <Fallback
      state={state}
      render={(data) => (
        <div>
          <Space style={{ width: '100%' }} size="large" direction="vertical">
            <Card title="学生人数">
              <Row>
                <Col xs={8}>全部: {data.allStudentCount}</Col>
                <Col xs={8}>
                  <Link
                    to={{
                      pathname: '/admin/student/students',
                      search: qs.stringify({
                        filter: JSON.stringify({
                          state: 'normal',
                        }),
                      }),
                    }}
                  >
                    正常: {data.normalStudentCount}
                  </Link>
                </Col>
                <Col xs={8}>
                  <Link
                    to={{
                      pathname: '/admin/student/students',
                      search: qs.stringify({
                        filter: JSON.stringify({
                          state: 'suspended',
                        }),
                      }),
                    }}
                  >
                    禁用: {data.suspendedStudentCount}
                  </Link>
                </Col>
              </Row>
            </Card>
            <Card title="在学课程">
              <Row>
                {data.courses.map((course) => {
                  return (
                    <Col xs={8}>
                      <Link
                        to={{
                          pathname: '/admin/student/students',
                          search: qs.stringify({
                            filter: JSON.stringify({
                              courseID: course.id,
                            }),
                          }),
                        }}
                      >
                        {course.name}: {course.totalStudents}
                      </Link>
                    </Col>
                  );
                })}
              </Row>
            </Card>
            <Card title="昨日练习">
              <Row>
                <Col xs={8}>全部: {data.allPracticeCount}</Col>
                <Col xs={8}>已看: {data.viewedPracticeCount}</Col>
                <Col xs={8}>未看: {data.unViewedPracticeCount}</Col>
              </Row>
            </Card>
          </Space>
        </div>
      )}
    />
  );
};

export interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
  return <AdminDashboard />;
};

export default Dashboard;
