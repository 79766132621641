import Axios from 'axios';
import {
  api,
  restful,
  PaginationData,
  QueryParams,
  encodeQueryParams,
} from './api';
import { Point, Class,Class1, User, Course, UserCourse, Practice } from './entity';

const axios = Axios.create({ baseURL: '/api/assistant' });

export const PointResource = restful<Point>(axios, '/points');


export const ClassRecource = restful<Class>(axios, '/classes');

export const CourseResource = restful<Course>(axios, '/courses');

export const UserResource = restful<User>(axios, '/users');

export const UserCourseResource = restful<UserCourse>(axios, '/user/courses');

export const studentPractice = (studentID: number,idd: number) =>
  restful<Practice>(axios, `/students/${studentID}/practices/${idd}`);

export const studentPoint = (studentID: number) =>
  restful<Point, { points: number; realname: string }>(
    axios,
    `/students/${studentID}/points`
  );

export const classRemainPoint = api<number, number>((classID) =>
  axios.get(`/classes/${classID}/remain-points`)
);

export const queryAssistantCounsel = api<
  void,
  PaginationData<Class1 & { remainPoints: number }>
>(() => axios.get('/counsel'));

export const queryAssistantStudentsNormal = api<
  QueryParams,
  PaginationData<Class>
>((params) =>
  axios.get('/student/normal', { params: encodeQueryParams(params) })
);

export const queryAssistantStudentsPwd = api<
    QueryParams,
    PaginationData<Class>
    >((params) =>
    axios.post('/student/changePassword', { params: encodeQueryParams(params) })
);


export const queryAssistantSuspendedStudents = api<
  QueryParams,
  PaginationData<User>
>((params) =>
  axios.get('/student/suspended', {
    params: encodeQueryParams(params),
  })
);


export const queryAssistantBannedStudents = api<
    QueryParams,
    PaginationData<User>
    >((params) =>
    axios.get('/student/banned', {
      params: encodeQueryParams(params),
    })
);


export const startUserCourse = api<number, void>((id) =>
  axios.put(`/user/courses/${id}/start`)
);

export const completeUserLesson = api<number, void>((id) =>
  axios.put(`/user/lessons/${id}/complete`)
);
