import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import Select from '@material-ui/core/Select';

export enum Range {
  All,
  Today,
  Yesterday,
  Week,
  LastWeek,
}
interface RangeSelectProps {
  value: Range;
  onChange: (value: Range) => void;
}
export default function RangeSelect({ value, onChange }: RangeSelectProps) {
  return (
    <Select
      input={<InputBase />}
      value={value}
      onChange={(e) => {
        onChange(e.target.value as Range);
      }}
    >
      <MenuItem value={Range.All}>全部</MenuItem>
      <MenuItem value={Range.Today}>今日</MenuItem>
      <MenuItem value={Range.Yesterday}>昨日</MenuItem>
      <MenuItem value={Range.Week}>本周</MenuItem>
      <MenuItem value={Range.LastWeek}>上周</MenuItem>
    </Select>
  );
}
