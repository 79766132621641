import { AppTab } from '@/components/AppTabs';
import CategorySelect, { CategoryModule } from '@/components/CategorySelect';
import Fallback from '@/components/Fallback';
import {
  createSubmitFab,
  createSubmitModal,
  createSubmitTrigger,
  TotalLabel,
} from '@/components/ResourceManage';
import SearchSelect from '@/components/SearchSelect';
import useAsyncFn, { AsyncState } from '@/hooks/useAsyncFn';
import {
    CategoryResource, ClassResource7,
    ClassResource, CourseResource,
    UserResource, DelClassResource,
} from '@/services/admin';
import { QueryFn, roleFilter } from '@/services/api';
import { Class, Role, User } from '@/services/entity';
import { makeStyles, Tab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {Button, Card, Form, Input, Popconfirm, Radio, Select, Space, Table} from 'antd';
import { FormProps } from 'antd/lib/form';
import { TableProps } from 'antd/lib/table';
import { comparator } from 'ramda';
import React from 'react';
import { Link } from 'react-router-dom';
import qs from 'qs';

const resource = ClassResource;
type Entity = Class;

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const RecordForm: React.FC<FormProps> = (props) => {
  return (
    <Form labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }} {...props}>
      <Form.Item label="类型" name="categoryID" rules={[{ required: true }]}>
        <CategorySelect module={CategoryModule.Class} />
      </Form.Item>
        <Form.Item label="课程" name="courseID" rules={[{ required: true }]}>
            <SearchSelect
                query={CourseResource.query}
                map={({ id, name }) => ({ value: id, label: name })}
            />
        </Form.Item>
      <Form.Item label="班级名称" name="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="琴师" name="assistantID" rules={[{ required: true }]}>
        <SearchSelect
          allowEmptyValue
          query={
            roleFilter(Role.Assistant, UserResource.query) as QueryFn<User>
          }
          map={({ id, realname }) => ({ value: id, label: realname })}
        />
      </Form.Item>
      <Form.Item label="客服" name="teacherID" rules={[{ required: true }]}>
        <SearchSelect
          allowEmptyValue
          query={roleFilter(Role.Teacher, UserResource.query) as QueryFn<User>}
          map={({ id, realname }) => ({ value: id, label: realname })}
        />
      </Form.Item>
      <Form.Item
        label="状态"
        name="state"
        initialValue="normal"
        rules={[{ required: true }]}
      >
        <Radio.Group>
          <Radio.Button value="normal">进行中</Radio.Button>
          <Radio.Button value="closed">已结班</Radio.Button>
        </Radio.Group>
      </Form.Item>
    </Form>
  );
};

const SubmitModal = createSubmitModal<Entity>(RecordForm);
const SubmitTrigger = createSubmitTrigger(SubmitModal);
const SubmitFab = createSubmitFab(SubmitTrigger);

interface DataTableProps extends TableProps<Entity> {
  onCreate?: () => void;
}
const DataTable: React.FC<DataTableProps> = ({ onCreate, ...rest }) => {
  return (
    <Table
      {...rest}
      rowKey="id"
      bordered
      size="small"
      // pagination={false}
      //   pagination={{defaultCurrent:3}}
      columns={[
        {
          dataIndex: 'id',
          title: '#',
        },
        {
          dataIndex: 'name',
          title: '班级',
            sorter: comparator((a, b) => a.name < b.name),
          render: (name, record) => (
            <Link
              to={{ pathname: `/admin/student/classes/${record.id}/records` }}
            >
              {name}
            </Link>
          ),
        },
        {
          dataIndex: 'assistant',
          title: '琴师',
          render: (assistant) => {
            if (assistant) {
              return (
                <Link
                  to={{
                    pathname: '/admin/student/students',
                    search: qs.stringify({
                      filter: JSON.stringify({
                        id: assistant.id,
                      }),
                    }),
                  }}
                >
                  {assistant.realname}
                </Link>
              );
            }
            return '-';
          },
        },
        {
          dataIndex: 'students',
          title: '人数',
          sorter: comparator((a, b) => (a.students?(a.students.length):('')) < (b.students?(b.students.length):(''))),
          render: (students) => (students?(students.length):('')),
        },
        // {
        //   dataIndex: 'createdAt',
        //   title: '时间',
        //   render: (createdAt) =>
        //     format(new Date(createdAt), 'yyyy-MM-dd HH:mm:ss'),
        // },
        {
          title: '操作',
          render: (_, record) => {
            return (
              <Space>
                <SubmitTrigger
                  ModalProps={{
                    title: '编辑班级',
                    centered: true,
                    submit: resource.update(record.id),
                    onSubmitted: onCreate,
                    FormProps: { initialValues: record },
                  }}
                  render={({ open }) => (
                    <Button type="link" onClick={open}>
                      编辑
                    </Button>
                  )}
                />
                {!!record.assistantID && (
                  <Link
                    to={`/admin/student/assistants/${record.assistantID}/classes/${record.id}`}
                  >
                    进度3
                  </Link>
                )}
              </Space>
            );
          },
        },

          {
              title: '删除班级',
              dataIndex: 'id',
              render: (_, record) =>
                  <Popconfirm
                      title="注意:删除班级,将不可恢复?"
                      onConfirm={async () => {
                          await DelClassResource.delete(record.id);
                          window.location.reload();
                      }}
                  >
                      <Button type="link" danger>删除</Button>
                  </Popconfirm>
          },

      ]}
    />
  );
};

const TabPanel: React.FC<{
  state: AsyncState<{ total: number; data: Entity[] }>;
  fetch: typeof resource.query;
}> = ({ state, fetch }) => {
  const [form] = Form.useForm();
  return (
    <div>
      <Card style={{ marginBottom: 8 }}>
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => fetch({ filter: values })}
        >
          <Form.Item label="状态" name="state">
            <Select placeholder="全部状态" >
              <Select.Option value="normal">进行中</Select.Option>
              {/*<Select.Option value="suspended">暂停</Select.Option>*/}
              <Select.Option value="closed">已结班</Select.Option>
          </Select>
          </Form.Item>
          <Form.Item label="琴师" name="assistantID">
            <SearchSelect
              query={
                roleFilter(Role.Assistant, UserResource.query) as QueryFn<User>
              }
              map={({ id, realname }) => ({ value: id, label: realname })}
              allowClear
              placeholder="全部琴师"
            />
          </Form.Item>
          <Form.Item label="客服" name="teacherID">
            <SearchSelect
              query={
                roleFilter(Role.Teacher, UserResource.query) as QueryFn<User>
              }
              map={({ id, realname }) => ({ value: id, label: realname })}
              allowClear
              placeholder="全部客服"
            />
          </Form.Item>
          <Form.Item label="班级类型" name="categoryID">
            <SearchSelect
              placeholder="全部类型"
              query={() =>
                CategoryResource.query({
                  filter: { module: CategoryModule.Class },
                })
              }
              map={({ name, id }) => ({ value: id, label: name })}
            />
          </Form.Item>



            <Form.Item label="课程" name="courseID" >
                <SearchSelect
                    placeholder="全部课程"
                    query={CourseResource.query}
                    map={({ id, name }) => ({ value: id, label: name })}
                />
            </Form.Item>

          <Form.Item style={{ textAlign: 'right' }}>
            <Space>
              <Button htmlType="reset" onClick={() => form.resetFields()}>
                重置
              </Button>
              <Button type="primary" htmlType="submit" disabled={state.loading}>
                查询
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
      <Fallback
        state={state}
        initial={<DataTable loading />}
        render={({ data }) => {
          return (
            <DataTable
              dataSource={data}
              loading={state.loading}
              onCreate={state.retry}
            />
          );
        }}
      />
    </div>
  );
};

function useClassTab(): AppTab {
  const classes = useStyles();

    const [state, fetch] = useAsyncFn(ClassResource7.query, []);
  React.useEffect(() => {
    fetch({});
  }, [fetch]);

  return {
    path: `classes`,
    tab: <Tab label={<TotalLabel state={state}>班级</TotalLabel>} />,
    fab: (
      <SubmitFab
        ModalProps={{
          title: '创建班级',
          centered: true,
          submit: resource.create,
          onSubmitted: state.retry,
        }}
        className={classes.fab}
      >
        <AddIcon />
      </SubmitFab>
    ),
    panel: <TabPanel state={state} fetch={fetch} />,
  };
}

export default useClassTab;
