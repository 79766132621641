import { useCallback, useEffect, useRef, DependencyList } from 'react';

function useEventCallback<T extends (...args: any[]) => any>(
  callback: T,
  deps: DependencyList = []
): T {
  const ref = useRef<any>(() => {
    throw new Error('Cannot call an event handler while rendering.');
  });

  useEffect(() => {
    ref.current = callback;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, ...deps]);

  return useCallback<any>(
    (...args: any[]) => {
      const callback = ref.current;
      return callback(...args);
    },
    [ref]
  );
}

export default useEventCallback;
