import {
  Box,
  Container,
  makeStyles,
  Paper,
  TextareaAutosize,
  Button,
  TextareaAutosizeProps,
  ButtonProps,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  textarea: {
    flex: 1,
    minHeight: 30,
    lineHeight: '22px',
    padding: 4,
    border: 'none',
    resize: 'none',
    '&:focus': {
      outline: 0,
    },
    '-webkit-tap-highlight-color': 'transparent',
  },
});

export interface BottomTextareaProps {
  TextareaProps?: TextareaAutosizeProps;
  ButtonProps?: ButtonProps;
}
const BottomTextarea: React.FC<BottomTextareaProps> = ({
  TextareaProps,
  ButtonProps,
}) => {
  const classes = useStyles();
  return (
    <Box position="fixed" bottom={0} left={0} right={0}>
      <Container disableGutters maxWidth="sm">
        <Box
          clone
          bgcolor="grey.100"
          display="flex"
          alignItems="flex-end"
          padding={1}
        >
          <Paper elevation={0} square>
            <TextareaAutosize
              {...TextareaProps}
              className={classes.textarea}
              rowsMax={3}
            />
            <Box ml={1}>
              <Button
                {...ButtonProps}
                size="small"
                variant="contained"
                disableElevation
                color="primary"
              >
                发送
              </Button>
            </Box>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

export default BottomTextarea;
