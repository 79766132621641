import { AppTab } from '@/components/AppTabs';
import Fallback from '@/components/Fallback';
import {
    TotalLabel,
} from '@/components/ResourceManage';
import useAsyncFn, { AsyncState } from '@/hooks/useAsyncFn';
import {Box, makeStyles, Tab} from '@material-ui/core';
import {Badge, Button, Card, Col, Form, Input, Radio, Row, Select, Space, Table, Modal, message} from 'antd';
import { TableProps } from 'antd/lib/table';
import React from 'react';
import { Point } from '@/services/entity';
import { ExchangeResource} from '@/services/admin';


import DateTime from "@/components/DateTime";

const resource = ExchangeResource;
type Entity = Point;



interface DataTableProps extends TableProps<Entity> {
    onCreate?: () => void;
}


const DataTable: React.FC<DataTableProps> = ({ ...rest }) => {
    return (

        <Table
            {...rest}
            rowKey="id"
            size="small"
            columns={[
                {
                    dataIndex: 'quantity',
                    title: '记录',
                    render: (value) => (value > 0 ? `+${value}` : value),
                },
                {
                    dataIndex: 'title',
                    title: '来源',
                },
                {
                    dataIndex: 'recipient',
                    title: '接受人',
                    render: (value) => value.realname,
                },
                {
                    dataIndex: 'createdAt',
                    title: '日期',
                    render: (value) => <DateTime value={value} />
                }
            ]}
        />
    );
};

const TabPanel1: React.FC<{
    state: AsyncState<{ total: number; data: Entity[] }>;
    fetch: typeof resource.query;
}> = ({ state, fetch }) => {
    const [form] = Form.useForm();
    // const aaa =state.value;
    return (
        <div>
            <Card style={{ marginBottom: 8 }}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={(values) => fetch({ filter: values })}
                >


                    <Form.Item label="姓名" name="search">
                        <Input placeholder="请输入姓名" />
                    </Form.Item>


                    <Form.Item style={{ textAlign: 'right' }}>
                        <Space>
                            <Button htmlType="reset" onClick={() => form.resetFields()}>
                                重置
                            </Button>
                            <Button type="primary" htmlType="submit" disabled={state.loading}>
                                查询
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>

            </Card>
            {/*<Col>{aaa?.total ? ( <Col>{aaa?.total}人</Col>):(``)}</Col>*/}
            <Fallback
                state={state}
                initial={<DataTable loading />}
                render={({ data }) => {

                    return (

                        <DataTable
                            dataSource={data}
                            loading={state.loading}
                            onCreate={state.retry}
                        />

                    );
                }}
            />
        </div>
    );
};

function useExchangeTab(): AppTab {
    const [state, fetch] = useAsyncFn(resource.query, []);
    React.useEffect(() => {
        fetch({});
    }, [fetch]);

    return {
        path: `students`,
        tab: <Tab label={<TotalLabel state={state}>琴贝兑换</TotalLabel>} />,
        panel: <TabPanel1  state={state} fetch={fetch} />,
    };
}

export default useExchangeTab;
