import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

// shopping_cart
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import RoleListItem from './RoleListItem';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import CardMembershipOutlinedIcon from '@material-ui/icons/CardMembershipOutlined';
import { Link } from 'react-router-dom';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import FavoriteIcon from "@material-ui/icons/Favorite";
import {MenuBook} from "@material-ui/icons";
import SYNC from "@material-ui/icons/AttachMoney";
import AccessibilityNew from "@material-ui/icons/AccessibilityNew";



import ShoppingCart from '@material-ui/icons/ShoppingCart';
import {useProfile} from "@/pages/Profile/context";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    list: {
      width: 250,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);

export interface MenuDrawerProps extends DrawerProps {}

const MenuDrawer: React.FC<MenuDrawerProps> = ({ onClose, ...rest }) => {

  const classes = useStyles();
  const closeDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    onClose && onClose(event, 'escapeKeyDown');
  };

  return (
    <Drawer className={classes.root} anchor="left" onClose={onClose} {...rest}>
      <div
        className={classes.list}
        role="presentation"
        onClick={closeDrawer}
        onKeyDown={closeDrawer}
      >
        <List>
          <ListItem button component={Link} to="/profile">
            <ListItemIcon>
              <AccountCircleOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="个人信息" />
          </ListItem>
          {/*<ListItem button component={Link} to="/member">*/}
          {/*  <ListItemIcon>*/}
          {/*    <CardMembershipOutlinedIcon />*/}
          {/*  </ListItemIcon>*/}
          {/*  <ListItemText primary="溪山会员" />*/}
          {/*</ListItem>*/}
          <ListItem button component={Link} to="/points">
            <ListItemIcon>
              <AccountBalanceWalletOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="积分记录" />
          </ListItem>
          {/*<ListItem button component={Link} to="/order">*/}
          {/*  <ListItemIcon>*/}
          {/*    <ListAltOutlinedIcon />*/}
          {/*  </ListItemIcon>*/}
          {/*  <ListItemText primary="订单记录" />*/}
          {/*</ListItem>*/}

          {/*  <ListItem button component={Link} to="/invitationCode">*/}
          {/*      <ListItemIcon>*/}
          {/*          <FavoriteIcon />*/}
          {/*      </ListItemIcon>*/}
          {/*      <ListItemText primary="赠亲友" />*/}
          {/*  </ListItem>*/}


            <ListItem button component={Link} to={`/allCourses`}>
                <ListItemIcon>
                    <MenuBook />
                </ListItemIcon>
                <ListItemText>全部课表</ListItemText>
            </ListItem>



            <ListItem button component={Link} to={`/userChange`}>
                <ListItemIcon>
                    <SYNC />
                </ListItemIcon>
                <ListItemText>分享奖励</ListItemText>
            </ListItem>


            <ListItem button component={Link} to={`/leave`}>
                <ListItemIcon>
                    <AccessibilityNew />
                </ListItemIcon>
                <ListItemText>我的请假</ListItemText>
            </ListItem>

            {/*<ListItem button component={Link} to={`/myOrder`}>*/}
            {/*    <ListItemIcon>*/}
            {/*        <ShoppingCart />*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText>我的订单</ListItemText>*/}
            {/*</ListItem>*/}


          <RoleListItem />
        </List>
      </div>
    </Drawer>
  );
};

export default MenuDrawer;
