import React,{createContext} from 'react';
import System1 from './System';
import { Box } from '@material-ui/core';
import {useProfile} from "@/pages/Profile/context";




export const Context = createContext(0)
export default function Kind() {

    const  {profile}  = useProfile();

    return (
    <Box padding={2}>

        {profile.courses? (profile.courses.map((r, i) => (
                <System1 courses={r}  i={i} />
        ))):(``)}


        {/*<System />*/}
        {/*<Alone />*/}

    </Box>
  );
}
