import { DatePicker } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import React from 'react';

interface FormatedDatePicker
  extends Omit<DatePickerProps, 'value' | 'onChange'> {
  value?: string;
  onChange?: (value: string | undefined) => void;
}
const FormatedDatePicker: React.FC<FormatedDatePicker> = ({
  value,
  onChange,
  ...rest
}) => {
  return (
    <DatePicker format="YYYY-MM-DD HH:mm:ss"
      value={value ? moment(value) : undefined}
      onChange={(value) => {
        onChange && onChange(value?.startOf('day')?.format());
      }}
    />
  );
};

export default FormatedDatePicker