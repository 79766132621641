import React, {Component, useRef} from 'react'
// import {
//     Button
// } from '@material-ui/core';

import {Button } from 'antd';
import PropTypes from 'prop-types'
import AvatarEditor from 'react-avatar-editor'
import {Container} from "@material-ui/core";


//设置safari浏览器更好个人头像
export default class SafariAvatar extends Component {

    editor:any;

    new_file:any;

    state = {
        image:  "h",
        allowZoomOut: false,
        scale: 1.5,
        borderRadius: 0,
        preview: null,
        width: 330,
        height: 250,
        newFile:"",

    }






    // handleNewImage = (e:any) => {
    //     this.setState({ image: e.target.files[0] })
    // }
    //
    // handleScale = (e:any) => {
    //     const scale = parseFloat(e.target.value)
    //     this.setState({ scale })
    // }







    handleSave1 = (e:any) => {


        this.setState({ image: e.target.files[0] })

        e.preventDefault();
        const file = e.target.files[0];

        // 这里的 fetch 引用了 isomorphic-fetch 包

        this.state.newFile = file


        //将获取的文件进行base64编码
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = ()=>{
            let imgBase64 =  reader.result;

            let aba =  dealImageCompression(imgBase64,null,null)

            this.new_file = dataURItoBlob(aba)
        }



        // fetch('/api/profile/avatar', {
        //     method: 'POST',
        //     body: formData,
        // }).then(function(response) {
        //
        //
        // });


    }


    handleUpdate = (e:any) => {

        if (this.state.newFile == ""){
            return
        }



        const formData = new FormData();
        // 这里的 image 是字段，根据具体需求更改
        // formData.append('avatar', this.state.newFile);

        formData.append("avatar", this.state.newFile,this.new_file.name);

        fetch('/api/profile/avatar', {
            method: 'POST',
            body: formData,
        }).then(function(response) {
            window.location.href="/profile"
        });

    }



    setEditorRef = (editor:any) => (this.editor = editor)

    render() {
        return (


            <Container disableGutters maxWidth="sm">
        <AvatarEditor
            ref={this.setEditorRef}
            image={this.state.image}
            width={this.state.width}
            height={this.state.height}
            color={[0, 0, 0, 0.3]}
            border={25}
            scale={this.state.scale}
        />
                <div  className={'upload-container'}>
                    <input type="file" name="image"  className={'upload-input'} onChange={this.handleSave1} />
                    <br />
                    {/*缩放:*/}
                    {/*<input*/}
                    {/*    name="scale"*/}
                    {/*    type="range"*/}
                    {/*    onChange={this.handleScale}*/}
                    {/*    min={this.state.allowZoomOut ? '0.1' : '1'}*/}
                    {/*    max="2"*/}
                    {/*    step="0.01"*/}
                    {/*    defaultValue="1"*/}
                    {/*/>*/}
                    {/*<br />*/}
                    <br />
                    <Button  type="primary" onClick={this.handleUpdate}  className={'upload-button'}>使用</Button>
                </div>
            </Container>

        )
    }
}

//将压缩后的base64编码图片转为File类型
export const  dataURItoBlob = (dataurl:any) =>{   //dataurl是base64格式
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:mime});
};



//利用canvas压缩图片
export const dealImageCompression = (path:any, obj:any,callback:any)  =>{
    let img = new Image();
    img.src = path;


    const compressed = document.createElement('canvas');
    compressed.width = 600;
    compressed.height = 600;
    const ctx = compressed.getContext('2d');
    if (!ctx) {
        throw new Error('get avatar canvas context failure');
    }
    ctx.drawImage(img, 0, 0, 600, 600);

    let quality = 0.5;
    var base64 = compressed.toDataURL('image/jpeg', quality);
    return base64;
}