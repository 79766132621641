import Fallback from '@/components/Fallback';
import { queryAssistantStudentsNormal } from '@/services/assistant';
import { Select, InputBase, MenuItem, Box } from '@material-ui/core';
import {Table, Divider, Card, Form, Input, Space, Button, Badge} from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import useAsyncFn from '@/hooks/useAsyncFn';
import qs from 'qs';


const renderState = (value: string) => {
    switch (value) {
        case 'normal':
            return <Badge status="success" text="正常" />;
        // case 'suspended':
        //     return <Badge status="default" text="暂停" color="red"/>;
        case 'banned':
            return <Badge status="error" text="禁用" color="red"/>;
        default:
            return null;
    }
};

const StudentNormal: React.FC = () => {
  const [form] = Form.useForm();
  const [state, fetch] = useAsyncFn(queryAssistantStudentsNormal);
  const [selected, setSelected] = React.useState<number>(0);
  React.useEffect(() => {
    fetch({});
  }, [fetch]);
  return (
    <>
      <Fallback
        state={state}
        render={({ data }) => {
          return (
            <>
              {/*<Box mb={1}>*/}
              {/*  <Select*/}
              {/*    input={<InputBase />}*/}
              {/*    value={selected}*/}
              {/*    onChange={(e) => {*/}
              {/*      setSelected(e.target.value as number);*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <MenuItem value={0}>全部</MenuItem>*/}
              {/*    {data.map((item) => (*/}
              {/*      <MenuItem value={item.id}>{item.name}</MenuItem>*/}
              {/*    ))}*/}
              {/*  </Select>*/}
              {/*</Box>*/}

              <Card style={{ marginBottom: 8 }}>
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={(values) => fetch({ filter: values })}
                >
                  <Form.Item label="姓名" name="$search">
                    <Input placeholder="请输入学号或姓名" />
                  </Form.Item>
                  <Form.Item style={{ textAlign: 'right' }}>
                    <Space>
                      <Button
                        htmlType="reset"
                        onClick={() => form.resetFields()}
                      >
                        重置
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={state.loading}
                      >
                        查询
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              </Card>



              {data.map((item, index) => {
                if (selected && selected !== item.id) {
                  return null;
                }
                return (
                  <Table
                    rowKey="id"


                    title={() => (
                        <Box display="flex" justifyContent="space-between">
                            <div>{item.name}</div>
                            {/*<Link to={`/leaderboard/class-within`}>*/}
                            {/*    本班榜单1*/}

                            {/*</Link>*/}

                            <div style={{color:'red'}}>{item.students? `共`+item.students.length+`人`:``}</div>
                        </Box>
                    )}
                    bordered
                    size="small"
                    style={{ minWidth: '100%' }}
                    columns={[
                      {
                        title: '姓名',
                        dataIndex: 'realname',
                        // render: (value, record) => (
                        //   <Link
                        //
                        //     to={`/teacher/students/${record.id}/profile`}
                        //   >
                        //     {value} ({record.username})
                        //   </Link>
                        // ),
                        //   render: (value, record) => {
                        //       return (
                        //           <>
                        //               <Link to={`/teacher/students/${record.id}/profile`}>
                        //                   {record.realname} ({record.username})
                        //               </Link>
                        //               <br />
                        //               {renderState(record.state)}
                        //           </>
                        //       );
                        //   },
                      },
                      {
                        title: '操作',
                        render: (_, record) => (
                          <>
                            <Link
                              to={`/assistant/students/${record.id}/courses`}
                            >
                              课程
                            </Link>
                            <Divider type="vertical" />
                            <Link
                              to={`/assistant/students/${record.id}/practices/${item.courseID}`}
                            >
                              练习
                            </Link>
                            <Divider type="vertical" />
                            <Link
                              to={`/assistant/students/${record.id}/points`}
                            >
                              积分
                            </Link>
                          </>
                        ),
                      },
                    ]}
                    dataSource={item.students}
                  />
                );
              })}
            </>
          );
        }}
      />
    </>
  );
};

export default StudentNormal;
