export enum Role {
  // Admin 管理员
  Admin = 'admin',
  // Student 学生
  Student = 'student',
  // Assistant 助教
  Assistant = 'assistant',
  // Teacher 教师
  Teacher = 'teacher',
}




export enum ZhuangTai {
  //在学
  Zaixue = 'zaixue',
  //暂停 停课
  Zanting = 'zanting',
  //延开
  Yankai = 'yankai',
  //冻结
  Dongjie = 'dongjie',
  //已进阶
  Jinjie= 'jinjie',
  //延期
  Yanqi = 'yanqi'
}


export function getZhuangTai(key: ZhuangTai): string {
  switch (key) {
    case ZhuangTai.Zaixue:
      return '学习';
    case ZhuangTai.Zanting:
      return '停课';
    case ZhuangTai.Yankai:
      return '延开';
    case ZhuangTai.Dongjie:
      return '冻结';
    case ZhuangTai.Jinjie:
      return '已进阶';
    case ZhuangTai.Yanqi:
      return '延期';
  }
}


export function getRoleTitle(key: Role): string {
  switch (key) {
    case Role.Admin:
      return '管理';
    case Role.Assistant:
      return '琴师';
    case Role.Student:
      return '学生';
    case Role.Teacher:
      return '客服';
  }
}






export interface Entity {
  readonly id: number;
  readonly createdAt: string;
  readonly updatedAt: string;

}

export interface UserRole extends Entity {
  name: Role;
}
export interface ContactSource extends Entity {
  CategoryID: number;
  channel: string;
  title: string;
  url: string;
  content: string;
  pic:string;
  pic1:string;
  status:number;
  x:number;
  y:number;
  size:number;
}



export interface Token extends Entity {
  mainUrl: string;
  oneUrl: string;
  twoUrl: string;
  p1: string;
  p2: string;
  p3: string;
  points: number;
  shopcontent: string;

}


export interface User extends Entity {
  classID: number;
  readonly class?: Class;
  studentID: number;
  state: string;
  courseState: string;
  avatar: string;
  points: number;
  realname: string;
  sort:string;
  username: string;
  roles: UserRole[];
  courseEndTime: string;
  courseStartTime: string;
  // readonly courses: UserCourse;
  readonly lessons: UserLesson;
  content: string;
  content1: string;
  index: number;
   courses: UserCourse[];
  haibaourl:string;
  title: string;
  durationPractice: number;
  durationRemain: number;
  resultDay: number;
  day: number;
}

export interface ContactProfile extends Entity {
  realname: string;
  gender?: string;
  mobile?: string;
  wechat?: string;
  address?: string;
  age?: number;
  occupation?: string;
  hobby?: string;
  foundation?: string;
  signup: number;
}

export interface Category extends Entity {
  module: string;
  name: string;
}

export interface ContactPayment extends Entity {
  profileID: number;
  categoryID: number;
  readonly category: Category;
  methodID: number;
  readonly method: Category;
  amount: number;
  remark?: string;
}

export interface ContactAgreement extends Entity {
  profileID: number;
  categoryID: number;
  readonly category: Category;
  url: string;
}
export interface ContactApply extends Entity {
  profileID: number;
  categoryID: number;
  readonly category: Category;
  url: string;
}

export interface ContactLog extends Entity {
  profileID: number;
  promoterID: number;
  readonly promoter: User;
  remark: string;
}

export interface GenseeCourseware extends Entity {
  coursewareCode: string;
  coursewareID: string;
  originURL: string;
  recordID: number;
  transcodeState: string;
  visitURL: string;
}

export interface Lecture extends Entity {
  lessonID: number;
  name: string;
  description: string;
  url: string;
  order: number;
  readonly genseeCourseware?: GenseeCourseware;
  videoID: string;
}

export interface Lesson extends Entity {
  courseID: number;
  name: string;
  handout: string;
  readonly lectures: Lecture[];
  readonly notes: LessonNote[];
  readonly userLesson?: UserLesson;
  practiceDuration: number;
  order: number;
}


export interface Kind extends Entity {
  name: string;
  order: number;
  picurl: string;
}


export interface Course extends Entity {
  name: string;
  description: string;
  leaveDuration:number;
  dayDuration:number;
  duration: number;
  readonly class?: Class;
  kind:Kind
  picurl: string;
}


export interface Productkind extends Entity {
  name: string;
  products: Product[];
}



export interface Order extends Entity {
  userID: number;
  pID: number;
  user: User;
  product: Product;
  status:number;
}

export interface Product extends Entity {
  name: string;
  kindID:number;
  url: string;
  price: number;
  qinbei: number;
  link: string;
  status:number;
  content: string;
  is_price:number;
}

export interface Class extends Entity {
  categoryID: number;
  courseID: number;
  readonly category: Category;
  assistantID: number;
  readonly assistant?: User;
  teacherID: number;
  readonly teacher?: User;
  readonly students: User[];
  assistantName: string;
  state: string;
  name: string;
}

export interface User1 extends Entity {
  userID: number;
  state: string;
  total: number;
  courseState: string;
  classID: number;
  realname: string;
  content: string;
  lessonID: number;
  lessonName: string;
  courseID: number;
  practiceViews: number;
  practiceRecomms: number;
  practiceDuration: number;
  durationRemain: number;
  resultDay:number;
}


export interface Class1 extends Entity {
  readonly students: User1[];
  className: string;
  dayDuration: number;
}

export interface UserCourse extends Entity {
  courseID: number;
  readonly course: Course;
  readonly state: string;
  content: string;
  readonly completed: number;
  readonly total: number;
  readonly userLessons: UserLesson[];
  readonly lessons: Lesson[];
  username :string;
  userID: number;
  classID: number;
  userTime: number;


  readonly class?: Class;
  courseEndTime: string;
  courseState: ZhuangTai;
  courseStartTime: string;
  name: string;
  durationPractice: number;
  durationRemain: number;
  resultDay: number;
}

export interface Ticket extends Entity {
  readonly userLessonID: number;
  readonly userLesson: UserLesson;
  readonly authorID: number;
  readonly author: User;
  readonly assigneeID: number;
  readonly assignee: User;
  readonly state: string;
}


export interface TiXin extends Entity {
  readonly realname: string;
  readonly name: string;
  readonly assistantName: string;
  readonly lessonName: string;
  readonly assigneeID: number;
  readonly updatedAt: string;
  readonly firstAt: string;
  readonly state: string;
  readonly authorID: number;
  readonly userCourseID: number;
  readonly courseState: string;
}



export interface UserLesson extends Entity {
  readonly lesson: Lesson;
  readonly practiceDuration: number;
  readonly practiceViews: PracticeView[];
  readonly userID: number;
  readonly ticket?: Ticket;
  lessonID: number;
  userCourseID: number;
  userCourse:UserCourse;
  courseID: number;
  state: string;
  readonly roleName: string;
}


export interface Practice extends Entity {
  userID: number;
  readonly user: User;
  userLessonID: number;
  title: string;
  videoID: string;
  videoToken: string;
  startedTime: string;
  duration: number;
  isFine: boolean;
  isRecommend: boolean;
  readonly comments: PracticeComment[];
  readonly views: PracticeView[];
}

export interface PracticeComment extends Entity {
  practiceID: number;
  readonly commenterID: number;
  readonly commenter: User;
  content: string;
}

export interface PracticeView extends Entity {
  practiceID: number;
  userID: number;
  UserLessonID: number;
  readonly user: User;
}

export interface LessonNote extends Entity {
  lessonID: number;
  content: string;
  readonly authorID: number;
  readonly author: User;
  readonly lesson: Lesson;
}

export interface Change extends Entity {
  userID: number;
  quantity: number;
  username: string;
  reason: string;
}

export interface Horse extends Entity {
  content: string;
  is_publish: number;
}


export interface AllChangeData  extends Entity{
  name: string;
  count: number;
  total: number;
}



export interface Point extends Entity {
  readonly classID: number;
  readonly recipient: User;
  readonly issuerID: number;
  readonly issuer: User;
  recipientID: number;
  quantity: number;
  title: string;
}

export interface Notice extends Entity {
  readonly type: string;
  readonly userLessonID: number;
  readonly userLesson: UserLesson;
  readonly state: string;
  readonly marked: boolean;
  readonly message: string;
  readonly senderID: number;
  readonly sender: User;
  readonly recipientID: number;
  readonly recipient: User;
}

export interface ClassRecord extends Entity {
  classID: number;
  readonly class: Class;
  categoryID: number;
  readonly category: Category;
  readonly authorID: number;
  readonly author: User;
  name: string;
  readonly count: number;
}

export interface UserEvent extends Entity {
  userID: number;
  readonly user: User;
  categoryID: number;
  readonly category: Category;
  readonly authorID: number;
  readonly author: User;
  name: string;
}



export interface UserLeave extends Entity {
   userID: number;
   user: User;
   days: number;
   courseID: number;
   content: string;
   content1: string;
   starttime: string;
}




