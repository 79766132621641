import Fallback from '@/components/Fallback';


import {ClassResource, CourseResource, DelClassResource,  UserResource,ClassResource7} from '@/services/admin';

import {Class,  Role, User} from '@/services/entity';
import { Box } from '@material-ui/core';
import {Button, Card, Col, Form, Input, Popconfirm, Radio, Space, Table} from 'antd';
import { TableProps } from 'antd/lib/table';
import React from 'react';
import {Link, useParams} from 'react-router-dom';
import useAsync from "@/hooks/useAsync";
import {comparator} from "ramda";
import qs from "qs";
import { createSubmitModal, createSubmitTrigger} from "@/components/ResourceManage";
import {FormProps} from "antd/lib/form";
import CategorySelect, {CategoryModule} from "@/components/CategorySelect";
import SearchSelect from "@/components/SearchSelect";
import {QueryFn, roleFilter} from "@/services/api";
import {CategoryResource} from "@/services/teacher";
import DateTime from "@/components/DateTime";
import AddIcon from "@material-ui/icons/Add";

const resource = ClassResource;

type Entity = Class;


const RecordForm: React.FC<FormProps> = (props) => {
    return (
        <Form labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }} {...props}>
            <Form.Item label="类型" name="categoryID" rules={[{ required: true }]}>
                <CategorySelect module={CategoryModule.Class} />
            </Form.Item>
            <Form.Item label="课程" name="courseID" rules={[{ required: true }]}>
                <SearchSelect
                    query={CourseResource.query}
                    map={({ id, name }) => ({ value: id, label: name })}
                />
            </Form.Item>
            <Form.Item label="班级名称" name="name" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item label="琴师" name="assistantID" rules={[{ required: true }]}>
                <SearchSelect
                    allowEmptyValue
                    query={
                        roleFilter(Role.Assistant, UserResource.query) as QueryFn<User>
                    }
                    map={({ id, realname }) => ({ value: id, label: realname })}
                />
            </Form.Item>
            <Form.Item label="客服" name="teacherID" rules={[{ required: true }]}>
                <SearchSelect
                    allowEmptyValue
                    query={roleFilter(Role.Teacher, UserResource.query) as QueryFn<User>}
                    map={({ id, realname }) => ({ value: id, label: realname })}
                />
            </Form.Item>
            <Form.Item
                label="状态"
                name="state"
                initialValue="normal"
                rules={[{ required: true }]}
            >
                <Radio.Group>
                    <Radio.Button value="normal">进行中</Radio.Button>
                    <Radio.Button value="closed">已结班</Radio.Button>
                </Radio.Group>
            </Form.Item>
        </Form>
    );
};



interface DataTableProps extends TableProps<Entity> {
    onUpdate?: () => void;
}

const SubmitModal = createSubmitModal<Entity>(RecordForm);
const SubmitTrigger = createSubmitTrigger(SubmitModal);



const DataTable: React.FC<DataTableProps> = ({ onUpdate, ...rest }) => {
    return (
        <Table
            {...rest}
            rowKey="id"
            bordered
            size="small"
            // pagination={false}
            //   pagination={{defaultCurrent:3}}
            columns={[
                {
                    dataIndex: 'id',
                    title: '#',
                },
                {
                    dataIndex: 'name',
                    title: '班级',
                    sorter: comparator((a, b) => a.name < b.name),
                    render: (name, record) => (
                        <Link
                            to={{ pathname: `/admin/student/classes/${record.id}/records` }}
                        >
                            {name}
                        </Link>
                    ),
                },
                {
                    dataIndex: 'assistant',
                    title: '琴师',
                    render: (assistant) => {
                        if (assistant) {
                            return (
                                <Link
                                    to={{
                                        pathname: '/admin/student/students',
                                        search: qs.stringify({
                                            filter: JSON.stringify({
                                                id: assistant.id,
                                            }),
                                        }),
                                    }}
                                >
                                    {assistant.realname}
                                </Link>
                            );
                        }
                        return '-';
                    },
                },
                {
                    dataIndex: 'students',
                    title: '人数',
                    sorter: comparator((a, b) => (a.students?(a.students.length):('')) < (b.students?(b.students.length):(''))),
                    render: (students) => (students?(students.length):('')),
                },
                // {
                //   dataIndex: 'createdAt',
                //   title: '时间',
                //   render: (createdAt) =>
                //     format(new Date(createdAt), 'yyyy-MM-dd HH:mm:ss'),
                // },
                {
                    title: '操作',
                    render: (_, record) => {
                        return (
                            <Space>
                                <SubmitTrigger
                                    ModalProps={{
                                        title: '编辑班级',
                                        centered: true,
                                        submit: resource.update(record.id),
                                        onSubmitted: onUpdate,
                                        FormProps: { initialValues: record },
                                    }}
                                    render={({ open }) => (
                                        <Button type="link" onClick={open}>
                                            编辑
                                        </Button>
                                    )}
                                />
                                {!!record.assistantID && (
                                    <Link
                                        to={`/admin/student/assistants/${record.assistantID}/classes/${record.id}`}
                                    >
                                        进度3
                                    </Link>
                                )}
                            </Space>
                        );
                    },
                },

                {
                    title: '删除班级',
                    dataIndex: 'id',
                    render: (_, record) =>
                        <Popconfirm
                            title="注意:删除班级,将不可恢复?"
                            onConfirm={async () => {
                                await DelClassResource.delete(record.id);
                                window.location.reload();
                            }}
                        >
                            <Button type="link" danger>删除</Button>
                        </Popconfirm>
                },

            ]}
        />
    );
};




const ClassOne: React.FC = () => {


    const params = useParams<{ id: string }>();
    const assistantID = parseInt(params.id);


    const pointListState = useAsync(
        () => ClassResource7.query({ filter: { assistantID } }),
        [assistantID]
    );



    return (



        <Fallback
        state={pointListState}

        render={({ data }) => (
            <>
                <Table
                    rowKey="id"
                    bordered
                    size="small"
                    dataSource={data}
                    columns={[
                        {
                            dataIndex: 'id',
                            title: '#',
                        },
                        {
                            dataIndex: 'name',
                            title: '班级',
                            sorter: comparator((a, b) => a.name < b.name),
                            render: (name, record) => (
                                <Link
                                    to={{ pathname: `/admin/student/classes/${record.id}/records` }}
                                >
                                    {name}
                                </Link>
                            ),
                        },
                        {
                            dataIndex: 'assistant',
                            title: '琴师',
                            render: (assistant) => {
                                if (assistant) {
                                    return (
                                        <Link
                                            to={{
                                                pathname: '/admin/student/students',
                                                search: qs.stringify({
                                                    filter: JSON.stringify({
                                                        id: assistant.id,
                                                    }),
                                                }),
                                            }}
                                        >
                                            {assistant.realname}
                                        </Link>
                                    );
                                }
                                return '-';
                            },
                        },
                        {
                            dataIndex: 'students',
                            title: '人数',
                            sorter: comparator((a, b) => (a.students?(a.students.length):('')) < (b.students?(b.students.length):(''))),
                            render: (students) => (students?(students.length):('')),
                        },
                        // {
                        //   dataIndex: 'createdAt',
                        //   title: '时间',
                        //   render: (createdAt) =>
                        //     format(new Date(createdAt), 'yyyy-MM-dd HH:mm:ss'),
                        // },
                        {
                            title: '操作',
                            render: (_, record) => {
                                return (
                                    <Space>
                                        <SubmitTrigger
                                            ModalProps={{
                                                title: '编辑班级',
                                                centered: true,
                                                submit: resource.update(record.id),
                                                onSubmitted: pointListState.retry,
                                                FormProps: { initialValues: record },
                                            }}
                                            render={({ open }) => (
                                                <Button type="link" onClick={open}>
                                                    编辑
                                                </Button>
                                            )}
                                        />
                                        {!!record.assistantID && (
                                            <Link
                                                to={`/admin/student/assistants/${record.assistantID}/classes/${record.id}`}
                                            >
                                                进度3
                                            </Link>
                                        )}
                                    </Space>
                                );
                            },
                        },

                        {
                            title: '删除班级',
                            dataIndex: 'id',
                            render: (_, record) =>
                                <Popconfirm
                                    title="注意:删除班级,将不可恢复?"
                                    onConfirm={async () => {
                                        await DelClassResource.delete(record.id);
                                        window.location.reload();
                                    }}
                                >
                                    <Button type="link" danger>删除</Button>
                                </Popconfirm>
                        },

                    ]}
                />
            </>
        )}
      />
  );
};

export default ClassOne;
