import { AppTab } from '@/components/AppTabs';
import Fallback from '@/components/Fallback';
import {
  createSubmitFab,
  createSubmitModal,
  createSubmitTrigger,
  TotalLabel,
} from '@/components/ResourceManage';
import SearchSelect from '@/components/SearchSelect';
import useAsyncFn, { AsyncState } from '@/hooks/useAsyncFn';
import {
    CategoryResource, ClassResource,
    CourseResource, KindResource,
    LessonResource,
} from '@/services/admin';
import { Course } from '@/services/entity';
import { makeStyles, Tab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
    Button,
    Card, Col,
    Divider,
    Form,
    Input, InputNumber, message,
    Popconfirm, Row,
    Space,
    Table, Upload,
} from 'antd';
import { FormProps } from 'antd/lib/form';
import { TableProps } from 'antd/lib/table';
import format from 'date-fns/format';
import React from 'react';
import EntityList from './EntityList';
import {UploadOutlined} from "@ant-design/icons";
import {InputNumberProps} from "antd/lib/input-number";

const resource = CourseResource;
type Entity = Course;

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));


//-----------


const DurationInput1: React.FC<InputNumberProps> = (props) => {
    return (
        <>
            <InputNumber {...props} />
            <span style={{ marginLeft: 8 }}>天</span>
        </>
    );
};


const DurationInput2: React.FC<InputNumberProps> = (props) => {
    return (
        <>
            <InputNumber {...props} />
            <span style={{ marginLeft: 8 }}>分钟</span>
        </>
    );
};

const onChange = ( info:any ) => {
    if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
        message.success(`${info.file.name},图片上传成功`);

        // let fileList = [...info.fileList];
        // fileList = fileList.slice(-1);
        //
        // // 2. Read from response and show file link
        // fileList = fileList.map(file => {
        //     if (file.response) {
        //
        //     }
        //     return file;
        // });
        setTimeout(function () {
            //内容1秒后,刷新
            window.location.reload();
        }, 1000);

    } else if (info.file.status === 'error') {
        message.error(`${info.file.name},图片上传失败`);
    }
};


const beforeUpload = ( file:any ) => {
    if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg') {
        return  true
    }else {
        message.error(`${file.name} is not a png file,or jpg,or jpeg`);
    }
    return false
};
//---------------------

const RecordForm: React.FC<FormProps> = (props) => {
  return (
    <Form labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }} {...props}>
      <Form.Item label="课程名称" name="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="备注说明"
        name="description"
      >
        <Input />
      </Form.Item>

        <Form.Item
            label="假期设置"
            name="leaveDuration"
        >
            <DurationInput1 />
        </Form.Item>

        <Form.Item
            label="标准日均"
            name="dayDuration"
        >
            <DurationInput2 />
        </Form.Item>


      <Form.Item label="课件" name="lessons" rules={[{ required: true }]}>
        <EntityList
          query={LessonResource.query}
          map={({ id, name }) => ({ value: id, label: name })}
        />
      </Form.Item>

        <Form.Item label="类别" name="kindID" rules={[{ required: true }]}>
            {/*<EntityList*/}
            {/*    query={KindResource.query}*/}
            {/*    map={({ id, name }) => ({ value: id, label: name })}*/}
            {/*/>*/}
            <SearchSelect
                allowEmptyValue
                query={KindResource.query}
                map={({ id, name}) => ({
                    value: id,
                    label: `${name} `,
                })}
            />
        </Form.Item>
    </Form>
  );
};

const SubmitModal = createSubmitModal<Entity>(RecordForm);
const SubmitTrigger = createSubmitTrigger(SubmitModal);
const SubmitFab = createSubmitFab(SubmitTrigger);

interface DataTableProps extends TableProps<Entity> {
  onCreate?: () => void;
  onDelete?: () => void;
}
const DataTable: React.FC<DataTableProps> = ({
  onCreate,
  onDelete,
  ...rest
}) => {
  return (
    <Table
      {...rest}
      rowKey="id"
      bordered
      size="small"
      columns={[
        {
          dataIndex: 'id',
          title: '#',
        },
        { dataIndex: 'name', title: '课程名称',  },
        {
          dataIndex: 'duration',
          title: '时长',
          render: (value) => `${value} 分钟`,
        },
        {
          dataIndex: 'createdAt',
          title: '时间',
          render: (createdAt) =>
            format(new Date(createdAt), 'yyyy-MM-dd HH:mm:ss'),
        },

          { dataIndex: 'leaveDuration',
              title: '请假天数',

              render: (value) => (
                  value ? (
                      <Button type="link" danger>{value}</Button>
                  ) : (
                      ``
                  )
              ),
              // render: (value) => value.name,

          },

          { dataIndex: 'dayDuration',
              title: '标准日均',

              render: (value) => (
                  value ? (
                      <Button type="link" danger>{value}</Button>
                  ) : (
                      ``
                  )
              ),
              // render: (value) => value.name,

          },

          { dataIndex: 'kind',
              title: '类别',
              // render: (value) => value.name,

              render: (value) => (
                  value ? (
                      value.name
                  ) : (
                    ``
                  )
              ),
          },
          {
              dataIndex: 'picurl',
              title: '',
              ellipsis: true,
              align: 'center',

              render: (record) =>
                  <img style={{
                      height: 60,
                      width: 250,
                  }}
                       src={record} />
          },

          {
              title: '添加图片',
              align: 'center',
              render: (_, record) => {
                  return (
                      <>
                          <Form
                              layout="vertical"
                              // onFinish={(values) => fetch({ filter: values })}
                          >

                              <Space >
                                  <Row>
                                      <Col xs={8} >
                                          <Upload name={'file'}
                                                  action={'/api/profile/mallphone2/'+record.id}
                                                  onChange={onChange}
                                                  beforeUpload={beforeUpload}>
                                              <Button  icon={<UploadOutlined />} >选择图片</Button>
                                          </Upload>
                                      </Col>
                                  </Row>
                              </Space>

                          </Form>
                      </>
                  );
              },
          },

        {
          title: '操作',
          render: (_, record) => {
            return (
              <>
                <Popconfirm
                  title="确认删除?"
                  onConfirm={async () => {
                    await resource.delete(record.id);
                    onDelete && onDelete();
                  }}
                >
                  <Button type="link">删除</Button>
                </Popconfirm>
                <Divider type="vertical" />
                <SubmitTrigger
                  ModalProps={{
                    title: '编辑课件',
                    centered: true,
                    submit: resource.update(record.id),
                    onSubmitted: onCreate,
                    FormProps: { initialValues: record },
                  }}
                  render={({ open }) => (
                    <Button type="link" onClick={open}>
                      编辑
                    </Button>
                  )}
                />
              </>
            );
          },
        },




      ]}
    />
  );
};

const TabPanel: React.FC<{
  state: AsyncState<{ total: number; data: Entity[] }>;
  fetch: typeof resource.query;
}> = ({ state, fetch }) => {
  const [form] = Form.useForm();
  return (
    <div>
      <Card style={{ marginBottom: 8 }}>
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => fetch({ filter: values })}
        >
          <Form.Item label="类别" name="categoryID">
            <SearchSelect
              placeholder="全部类别"
              query={() =>
                CategoryResource.query({
                  filter: { module: 'contact_source' },
                })
              }
              map={({ name, id }) => ({ value: id, label: name })}
            />
          </Form.Item>
          <Form.Item style={{ textAlign: 'right' }}>
            <Space>
              <Button htmlType="reset" onClick={() => form.resetFields()}>
                重置
              </Button>
              <Button type="primary" htmlType="submit" disabled={state.loading}>
                查询
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
      <Fallback
        state={state}
        initial={<DataTable loading />}
        render={({ data }) => {
          return (
            <DataTable
              dataSource={data}
              loading={state.loading}
              onCreate={state.retry}
              onDelete={state.retry}
            />
          );
        }}
      />
    </div>
  );
};

function useStudentTab(): AppTab {
  const classes = useStyles();
  const [state, fetch] = useAsyncFn(resource.query, []);
  React.useEffect(() => {
    fetch({ filter: {} });
  }, [fetch]);

  return {
    path: `courses`,
    tab: <Tab label={<TotalLabel state={state}>课程管理1</TotalLabel>} />,
    fab: (
      <SubmitFab
        ModalProps={{
          title: '创建课程',
          centered: true,
          submit: resource.create,
          onSubmitted: state.retry,
        }}
        className={classes.fab}
      >
        <AddIcon />
      </SubmitFab>
    ),
    panel: <TabPanel state={state} fetch={fetch} />,
  };
}

export default useStudentTab;
