import AppTabs from '@/components/AppTabs';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import LecturePlay from './LecturePlay';
import useCourseTab from './useCourseTab';
import useLectureTab from './useLectureTab';
import useLessonTab from './useLessonTab';
import userKindTab from './useKindTab';

const Course: React.FC = () => {
  const match = useRouteMatch();

  const courseTab = useCourseTab();
  const lessonTab = useLessonTab();
  const lectureTab = useLectureTab();
  const kindTab = userKindTab();

  return (
    <Switch>
      <Route path={`${match.path}/lectures/:id`}>
        <LecturePlay />
      </Route>
      <Route path={match.path}>
        <AppTabs tabs={[kindTab,courseTab, lessonTab, lectureTab]} />
      </Route>
    </Switch>
  );
};

export default Course;
