import React, { useRef } from 'react';
import { CategoryResource } from '@/services/admin';
import useAsyncFn from '@/hooks/useAsyncFn';
import { Select, Button, Input } from 'antd';
import Fallback from '@/components/Fallback';
import { SelectProps } from 'antd/lib/select';

export enum CategoryModule {
  ContactSource = 'contact_source',
  ContactPayment = 'contact_payment',
  ContactPaymentMethod = 'contact_payment.method',
  ContactAgreement = 'contact_agreement',
  ContactApply = 'contact_apply',
  Class = 'class',
  ClassRecord = 'class_record',
  UserEvent = 'user_event',

}

interface CategorySelectProps extends SelectProps<number> {
  module: CategoryModule;
  resource?: typeof CategoryResource;
}
const CategorySelect: React.FC<CategorySelectProps> = ({
  module,
  resource = CategoryResource,
  onChange,
  ...rest
}) => {
  const [name, setName] = React.useState('');
  const [state, query] = useAsyncFn(resource.query);
  const [created, create] = useAsyncFn(resource.create);

  const [editing, setEditing] = React.useState(false);

  React.useEffect(() => {
    query({
      filter: { module },
    });
  }, [query, module]);

  const style: React.CSSProperties = {
    flex: 1,
    marginRight: 8,
  };

  const inputRef = useRef<Input>(null);

  React.useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);
  return (
    <div style={{ display: 'flex' }}>
      {editing ? (
        <Input
          ref={inputRef}
          style={style}
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
      ) : (
        <Fallback
          state={state}
          initial={<Select style={style} {...rest} loading />}
          error={() => <Select style={style} {...rest} disabled />}
          render={(categories, loading) => (
            <Select
              style={style}
              {...rest}
              onChange={onChange}
              loading={loading}
              options={categories.data.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
            />
          )}
        />
      )}
      {editing ? (
        <>
          <Button
            loading={created.loading}
            disabled={!name}
            onClick={async () => {
              const category = await create({ name, module });
              await state.retry();
              onChange &&
                onChange(category.id, {
                  label: category.name,
                  value: category.id,
                });
              setName('');
              setEditing(false);
            }}
          >
            保存
          </Button>
          <Button
            onClick={() => {
              setName('');
              setEditing(false);
            }}
          >
            取消
          </Button>
        </>
      ) : (
        <Button onClick={() => setEditing(true)}>新建</Button>
      )}
    </div>
  );
};

export default CategorySelect;
