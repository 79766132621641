import Fallback from '@/components/Fallback';


import {MyOrderResource} from '@/services/user';

import {AllChangeData, Change, Order} from '@/services/entity';
import {Box, Container} from '@material-ui/core';
import {Button, Card, Col, Form, Input, InputNumber, message, Table} from 'antd';
import { TableProps } from 'antd/lib/table';
import format from 'date-fns/format';
import React, { useEffect, useMemo } from 'react';

import useAsync from "@/hooks/useAsync";


const DataTable: React.FC<TableProps<Order>> = (props) => (

    <Table
        {...props}
        rowKey="id"
        bordered
        size="small"
        title={() => <div>订单记录</div>}
        columns={[
            { dataIndex: 'user',
                title: '姓名',
                // render: (value) => value.name,

                render: (value) => (
                    value ? (
                        value.realname
                    ) : (
                        ``
                    )
                ),
            },
            { dataIndex: 'product',
                title: '商品',
                // render: (value) => value.name,

                render: (value) => (
                    value ? (
                        value.name
                    ) : (
                        ``
                    )
                ),
            },
            { dataIndex: 'product',
                title: '价格',
                // render: (value) => value.name,

                render: (value) => (
                    value ? (
                        value.qinbei+"琴贝"+"+"+value.price+"元"
                    ) : (
                        ``
                    )
                ),
            },
            {
                dataIndex: 'createdAt',
                title: '时间',
                render: (createdAt) =>
                    format(new Date(createdAt), 'yyyy-MM-dd HH:mm:ss'),
            },
            {
                dataIndex: 'status',
                title: '状态',
                render: (value, record) => (


                    record.status !== 1 ? (
                        <span  style={{ color: `#FF0000`}} >待完成</span>
                    ) : (
                        <span>已完成</span>
                    )

                ),
            },
        ]}
    />
);

const MyOrder: React.FC = () => {


    const pointListState = useAsync(
        () => MyOrderResource.query({ filter: 6 }),
        [6]
    );

    const aaa =pointListState.value;

    return (
        <Container maxWidth="sm" >
        <Box >
                {/*{aaa?.data.length ? (<Col> 共<span style={{ color: `#FF0000`}}>{aaa?.total}</span>记录</Col>):(`无订单记录`)}*/}
            {/*<Col>{aaa?.total ? ( <span style={{ color: `#FF0000`}}>共{aaa?.total.toString().split('.',1)}人,剩余{aaa?.total.toString().split('.',2)}点</span>):(`您尚未介绍新同学`)}</Col>*/}

                {/*<Col> 共<span style={{ color: `#FF0000`}}>{aaa?.total.toString().split('.',1)}</span>人,剩余<span style={{ color: `#FF0000`}}>{aaa?.total.toString().split('.',2).pop()}</span>点</Col>*/}
                <Fallback
                    state={pointListState}
                    initial={<DataTable loading />}
                    render={({ data }) => <DataTable dataSource={data} />}
                />
        </Box>
        </Container>
    );
};

export default  MyOrder;
