import { Practice } from '@/services/entity';
import {
  Box,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import StarBorder from '@material-ui/icons/StarBorder';
import StarOutlined from '@material-ui/icons/StarOutlined';
import { format } from 'date-fns';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Duration from './Duration';

export interface PracticeItemProps {
  value: Practice;
  onRecommend?: (value: Practice) => void;
}
const PracticeItem1: React.FC<PracticeItemProps> = ({ value, onRecommend }) => {
  const history = useHistory();
  return (
    <ListItem
      button
      onClick={() => history.push(`/course/practice/records/${value.id}`)}
    >
      <ListItemText
        primary={
          <Box
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            mr={2}
          >
            {value.title}
          </Box>
        }
        secondary={
          <Box>
            <div>
              {format(new Date(value.startedTime), 'yyyy-MM-dd HH:mm:ss')}
            </div>
          </Box>
        }
      />
      <Box mr={1}>
        <Duration value={value.duration} />
      </Box>
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          // onClick={() => onRecommend && onRecommend(value)}
        >
          {value.isRecommend ? <StarOutlined /> : <StarBorder />}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default PracticeItem1;
